import React, { createContext, useContext, useState, FC } from 'react';

export interface ICartInfo {
  cartCount: number;
}

interface ICartContext {
  cartInfo: ICartInfo;
  setCartInfo: React.Dispatch<React.SetStateAction<ICartInfo>>;
  countCart: () => void;
}

export const CartContext = createContext<ICartContext | undefined>(undefined);

export const useCartContext = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCartContext must be used within a CartProvider');
  }
  return context;
};

export const CartProvider: FC<{ children?: React.ReactNode }> = ({children}) => {
  const [cartInfo, setCartInfo] = useState<ICartInfo>({
    cartCount: 0,
  });
  const countCart = () => {
    const cart = JSON.parse(localStorage.getItem('cart') || '[]');
    setCartInfo({
      cartCount: cart.length,
    });
  }

  return (
    <CartContext.Provider value={{ cartInfo, setCartInfo, countCart }}>
      {children}
    </CartContext.Provider>
  );
};