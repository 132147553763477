import React, { FC, useEffect, useState } from 'react';
import useCheckSession from '../../hooks/useCheckSession';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Order from '../../layout/Order/Order';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSessionData } from '../../Axios';
import { useOrderContext } from '../../context/OrderContext';
import MobileNav from '../../layout/Header/MobileNav';

const OrderPage: FC = () => {
	const navigate = useNavigate();
	useCheckSession();
	const selectedProductsWithQuantities = useLocation().state;
	const { resetShippingInfo } = useOrderContext();
	const [access, setAccess] = useState(false);

	useEffect(() => {
		if (!selectedProductsWithQuantities) {
			navigate('/home');
		};
    getSessionData().then(result => {
			if (result.alc_status === 0) {
				setAccess(true);
				resetShippingInfo();
			} else {
        navigate('/home');
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

	return (
		<PageWrapper title={'주문하기'} >
			{access && <Order selectedProductsWithQuantities={selectedProductsWithQuantities} />}
			<MobileNav />
		</PageWrapper>
	);
};

OrderPage.displayName = 'OrderPage';

export default OrderPage;
