import React, { FC, useState, useEffect } from 'react';
import { useOrderContext } from '../../context/OrderContext';
import ModalClose from '../../components/Modal/ModalClose';
import ModalLoading from '../../components/Modal/ModalLoading';
import { useNavigate } from 'react-router-dom';
import { baseUrlExport, buildStatus, checkAlcSession, handlePaymentMethod, insertAlcOrder } from '../../Axios';
import * as SOL from "../../styles/solda/StyledComponentSol";
import IconArrowUp from "../../assets/icons/arrow_fold_40.svg";
import CheckBlank from "../../assets/icons/blank_checkbox.svg";
import CheckFill from "../../assets/icons/fill_checkbox.svg";
import IconCopy from "../../assets/icons/icon_copy.svg";
import { removeWhitespaceBusiness, removeWhitespacePhone, validatePhone } from '../../modules/Validation';
import { extractColorOptionNodeNames } from '../../Utils';

interface IOrderDetailProps {
    products: any[];
    respectiveShippingMemo: boolean;
}

const OrderDetails: FC<IOrderDetailProps> = ({products, respectiveShippingMemo}) => {
    const navigate = useNavigate();
    const { shippingInfo, setShippingInfo, inputErrors, setInputErrors } = useOrderContext();
    const [productQuantities, setProductQuantities] = useState<{[color_ID: number]: number}>({});
    const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState({text: ''});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [depositor, setDepositor] = useState<string>('');
    const [cashReceipts, setCashReceipts] = useState<number>(0); // 0: 신청안함, 1: 개인소득공제용, 2: 사업자증빙용, 3: 신청하기 클릭시
    const [receiptPhoneNumber, setReceiptPhoneNumber] = useState<string>('');
    const [receiptBusinessNumber, setReceiptBusinessNumber] = useState<string>('');

    const [toggleProductInfo, setToggleProductInfo] = useState<boolean>(true);
    const [togglePaymentInfo, setTogglePaymentInfo] = useState<boolean>(true);
    const [togglePaymentMethod, setTogglePaymentMethod] = useState<boolean>(true);

    const IMP = window.IMP;
    const totalAmount = products.reduce((acc, product) => {
        return acc + (product.color_b2c_sell_price * product.quantity);
    }, 0);
    const totalAmountOriginal = products.reduce((acc, product) => {
        return acc + (product.color_b2c_sell_price_ogirinal * product.quantity);
    }, 0);

    useEffect(() => {
        setShippingInfo(prevShippingInfo => ({
            ...prevShippingInfo,
            totalAmount: totalAmount
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalAmount]);


    const handlePayment = () => {
        checkAlcSession();
        const merchant_uid = `alc_${new Date().getTime()}`;
        const cashReceiptsInfo = {
            depositor_name: depositor,
            cash_receipts: cashReceipts,
            phone_number: receiptPhoneNumber,
            business_number: receiptBusinessNumber,
        }
        if (shippingInfo.selectedMethod === 'bankTransfer') {
            setIsLoading(true);
            insertAlcOrder(
                JSON.stringify(shippingInfo),
                JSON.stringify(products),
                merchant_uid,
                '',
                JSON.stringify(cashReceiptsInfo),
                totalAmount,
            ).then(result => {
                if (result === 'error_alc') {
                    setIsModalCloseOpen(true);
                    setModalContent({
                        text: '알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.'
                    });
                } else {
                    setIsLoading(false);
                    navigate('/order_success', { state: { shippingInfo, products, merchant_uid } });
                }
            });
        } else {
            IMP.init('imp13853765');
            localStorage.setItem('shippingInfo', JSON.stringify(shippingInfo));
            localStorage.setItem('orderProducts', JSON.stringify(products));
            localStorage.setItem('total_amount', totalAmount);

            const data = {
                pg: handlePaymentMethod(buildStatus, shippingInfo.selectedMethod),
                pay_method: shippingInfo.selectedMethod,
                merchant_uid,
                amount: buildStatus === 'test' ? 1000 : Math.floor(totalAmount),
                name: 'ALC 주문', 
                buyer_name: shippingInfo.name, 
                buyer_tel: shippingInfo.phoneNumber, 
                buyer_email: '', 
                buyer_addr: shippingInfo.address+shippingInfo.addressDetails, 
                buyer_postcode: shippingInfo.postalCode,
                m_redirect_url: `${baseUrlExport}alc_order/mobile_insert_alc_order`,
            };
            IMP.request_pay(data, function (response: any) {
                //결제 연동 개발 시 !바꿔서 실행
                try {
                    if (response.success) {
                        setIsLoading(true);
                        insertAlcOrder(
                            JSON.stringify(shippingInfo),
                            JSON.stringify(products),
                            merchant_uid,
                            response.imp_uid,
                            JSON.stringify(cashReceiptsInfo),
                            totalAmount,
                        ).then(result => {
                            if (result === 'error_alc') {
                                setIsModalCloseOpen(true);
                                setModalContent({
                                    text: '알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.'
                                });
                            } else {
                                setIsLoading(false);
                                navigate('/order_success', { state: { shippingInfo, products, merchant_uid } });
                            }
                        });
                    } else {
                        setModalContent({ text: response.error_msg });
                        setIsModalCloseOpen(true);
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        }
    };

    const handleCheckout = () => {
        let hasError = false;
        let newErrors = {
            postalCode: '',
            address: '',
            addressDetails: '',
            addressName: '',
            name: '',
            phoneNumber: '',
            verificationNumber: '',
        };
    
        // if (!shippingInfo.addressName) {
        //   newErrors.addressName = '배송지명은 필수로 입력해주세요.';
        //   hasError = true;
        // }
        if (!shippingInfo.name) {
          newErrors.name = '고객명은 필수로 입력해주세요.';
          hasError = true;
        }
        if (!shippingInfo.phoneNumber) {
            newErrors.phoneNumber = '연락처는 필수로 입력해주세요.';
            hasError = true;
        }
        if (!shippingInfo.postalCode) {
            newErrors.postalCode = '우편번호는 필수로 입력해주세요.';
            hasError = true;
        }
        if (!shippingInfo.address) {
            newErrors.address = '주소는 필수로 입력해주세요.';
            hasError = true;
        }
        // if (!shippingInfo.isVerified) {
            //     newErrors.verificationNumber = '인증은 반드시 하셔야 합니다.';
            //     hasError = true;
            // }
            
        setInputErrors(newErrors);

        if (shippingInfo.selectedMethod === 'bankTransfer') {
            if (depositor.trim() === '') {
                setIsModalCloseOpen(true);
                setModalContent({text: '입금자명을 입력해주세요.'});
                return;
            }
        }

        if (cashReceipts === 3) {
            setIsModalCloseOpen(true);
            setModalContent({text: '현금영수증 용도를 선택해주세요.'});
            return;
        } else if (cashReceipts === 1) {
            if (!validatePhone(receiptPhoneNumber)) {
                setIsModalCloseOpen(true);
                setModalContent({text: '개인소득공제용 휴대폰 번호를 입력해주세요.'});
                return;
            }
        } else if (cashReceipts === 2) {
            if (!receiptBusinessNumber.trim()) {
                setIsModalCloseOpen(true);
                setModalContent({text: '사업자증빙용 사업자 번호를 입력해주세요.'});
                return;
            }
        }

        //결제 연동 개발 시 !바꿔서 실행
        if (hasError) {
          return; 
        }
        handlePayment();
    };

    useEffect(() => {
        const initialQuantities = products.reduce((acc, product) => ({
            ...acc,
            [product.color_ID]: product.quantity,
        }), {});
        
        setProductQuantities(initialQuantities);
    }, [products]);

    useEffect(() => {
        setReceiptBusinessNumber('');
        setReceiptPhoneNumber('');
    }, [cashReceipts]);

    return (
        <>
            <ModalLoading isOpen={isLoading} />
            <ModalClose 
                isOpen={isModalCloseOpen} 
                onClose={() => setIsModalCloseOpen(false)}
                text={modalContent.text}/>
            <SOL.OrderDetailsContainer>
                <SOL.OrderDetailsWrapper>
                    <SOL.OrderSectionTitle onClick={() => setToggleProductInfo(!toggleProductInfo)}>
                        <span>주문상품</span>
                        <img className={`${toggleProductInfo || "rotate-180"}`} src={IconArrowUp} alt='arrow_icon' />
                    </SOL.OrderSectionTitle>
                    {toggleProductInfo && (
                        <div className='order-items-box'>
                            {products.map((tmp, idx) => (
                                <SOL.OrderItem
                                    key={idx}
                                    className={`${products.length === idx+1 && "last-product"}`}
                                >
                                    <div className='order-item-wrapper'>
                                        <div className='order-flex-left'>
                                            <div
                                                className='order-image'
                                            >
                                                <img src={tmp.color_b2c_image} alt={tmp.color_name} />
                                            </div>
                                            <div className='order-details'>
                                                <h2 className='order-brand-name'>{tmp.brand_eng_name}</h2>
                                                <h1 className="order-product-name">{tmp.product_kor_name}</h1>
                                                <h3 className="order-color-name">{tmp.color_name}</h3>
                                                {tmp.color_option_path && (
                                                    <h3 className='order-color-option-name'>
                                                        {`[ 옵션: `}
                                                        {extractColorOptionNodeNames(JSON.parse(tmp.color_option_path))}
                                                        {` ]`}
                                                    </h3>
                                                )}
                                            </div>
                                        </div>
                                        <div className='order-flex-right'>
                                            <div className='order-quantity'>
                                                <span>{tmp.quantity}개</span>
                                            </div>
                                            <div className='order-price-box'>
                                                <div className='price-wrapper'>
                                                    {Number(tmp.apply_discount_rate_1) > 0 && (
                                                        <p className='order-origin-price'>{(tmp.color_b2c_sell_price_ogirinal * (productQuantities[tmp.color_ID] || tmp.quantity)).toLocaleString()}원</p>
                                                    )}
                                                    <p className="order-total-price">{(tmp.color_b2c_sell_price * (productQuantities[tmp.color_ID] || tmp.quantity)).toLocaleString()}원</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {respectiveShippingMemo && (
                                        <div className='respective-shipping-memo'>
                                            <label>개별 배송메모</label>
                                            <div className='memo'>
                                                작업중
                                            </div>
                                        </div>
                                    )}
                                </SOL.OrderItem>
                            ))}
                            <div className='total-order-price-box'>
                                <span className='total-order-price-label'>총 주문금액</span>
                                <span className='total-order-price'>{totalAmount.toLocaleString()}원</span>
                            </div>
                        </div>
                    )}

                    <SOL.HorizontalDivider />

                    <SOL.OrderPaymentInfoContainer>
                        <SOL.OrderSectionTitle onClick={() => setTogglePaymentInfo(!togglePaymentInfo)}>
                            <span>결제정보</span>
                            <img className={`${togglePaymentInfo || "rotate-180"}`} src={IconArrowUp} alt='arrow_icon' />
                        </SOL.OrderSectionTitle>
                        {togglePaymentInfo && (
                            <div className='payment-info-box'>
                                <div className='payment-info-flex'>
                                    <span>총 주문 금액</span>
                                    <span>{totalAmountOriginal.toLocaleString()}원</span>
                                </div>
                                <div className='payment-info-flex'>
                                    <span>총 할인 금액</span>
                                    <span>-{(totalAmountOriginal - totalAmount).toLocaleString()}원</span>
                                </div>
                                <div className='payment-info-flex'>
                                    <span>총 배송비</span>
                                    {/* FIXME: 여기도 인테리어 채널 같은 곳은 물류팀과 회의해서 배송비 계산해줘야 함 */}
                                    <span>0원</span>
                                </div>
                                <div className='horizontal' />
                                <div className='payment-info-flex'>
                                    <span>총 결제 금액</span>
                                    <span className='total-price'>{totalAmount.toLocaleString()}원</span>
                                </div>
                            </div>
                        )}
                    </SOL.OrderPaymentInfoContainer>

                    <SOL.HorizontalDivider />

                    <div className='cancel-refund-notice'>
                        <h3 className='notice-title'>*반품/교환 안내</h3>
                        <p>- 미개봉 상품의 경우 상품 수령일로부터 7일 이내 교환/환불이 가능합니다.</p>
                        <p>- 조명 제품 특성상 개봉 후에는 고객 변심에 의한 교환/환불이 불가합니다.</p>
                        <p>- 고객의 단순 변심이나 실수로 인한 반품 / 교환의 경우 왕복 배송비는 고객 부담입니다.</p>
                    </div>

                    <SOL.OrderShippingPayMethod>
                        <SOL.OrderSectionTitle onClick={() => setTogglePaymentMethod(!togglePaymentMethod)}>
                            <span>결제수단</span>
                            <img className={`${togglePaymentMethod || "rotate-180"}`} src={IconArrowUp} alt='arrow_icon' />
                        </SOL.OrderSectionTitle>
                        {togglePaymentMethod && (
                            <>
                                <div className="payment-options">
                                    <button 
                                        className={shippingInfo.selectedMethod === "card" ? "payment-btn active" : "payment-btn"}
                                        onClick={() => setShippingInfo({ ...shippingInfo, selectedMethod:"card" })}
                                    >
                                        신용카드
                                    </button>
                                    <button 
                                        className={shippingInfo.selectedMethod === "kakaopay" ? "payment-btn active" : "payment-btn"}
                                        onClick={() => setShippingInfo({ ...shippingInfo, selectedMethod:"kakaopay" })}
                                    >
                                        카카오페이
                                    </button>
                                    <button 
                                        className={shippingInfo.selectedMethod === "tosspay" ? "payment-btn active" : "payment-btn"}
                                        onClick={() => setShippingInfo({ ...shippingInfo, selectedMethod:"tosspay" })}
                                    >
                                        토스페이
                                    </button>
                                    <button 
                                        className={shippingInfo.selectedMethod === "bankTransfer" ? "payment-btn active" : "payment-btn"}
                                        onClick={() => {
                                            setShippingInfo({ ...shippingInfo, selectedMethod:"bankTransfer" });
                                            setCashReceipts(0);
                                        }}
                                    >
                                        무통장 입금
                                    </button>
                                </div>
                                {shippingInfo.selectedMethod === "bankTransfer" && (
                                    <div className="bank-transfer-info">
                                        <div className='input-form-box'>
                                            <span>계좌정보</span>
                                            <input
                                                type='text'
                                                value={"주식회사 앰비언스 | 부산은행 113-2011-0981-05"}
                                                readOnly
                                            />
                                            <button
                                                className='copy-icon'
                                                onClick={async () => {
                                                    try {
                                                        await navigator.clipboard.writeText("부산은행 113-2011-0981-05");
                                                        setIsModalCloseOpen(true);
                                                        setModalContent({text: "복사를 성공했습니다."});
                                                    } catch (err) {
                                                        setIsModalCloseOpen(true);
                                                        setModalContent({text: "복사를 실패했습니다."});
                                                    }
                                                }}
                                            >
                                                <img src={IconCopy} alt='icon_copy' />
                                            </button>
                                        </div>
                                        <div className='input-form-box'>
                                            <span>입금자명</span>
                                            <input
                                                type='text'
                                                placeholder='입금자명을 입력해 주세요.'
                                                value={depositor}
                                                onChange={e => {
                                                    setDepositor(e.target.value);
                                                }}
                                                maxLength={12}
                                            />
                                        </div>
                                        <div className='horizontal' />
                                        <div className='input-form-box check-box'>
                                            <span className='form-title'>현금영수증</span>
                                            <div className='flex'>
                                                <button onClick={() => {
                                                    if (cashReceipts !== 0) return;
                                                    setCashReceipts(3);
                                                }}>
                                                    <img src={cashReceipts > 0 ? CheckFill : CheckBlank} alt='checkbox' />
                                                    <span className={`xs:translate-y-[5px] ${cashReceipts > 0 ? "on" : "off"}`}>현금영수증 신청</span>
                                                </button>
                                                <button className='ml-5' onClick={() => setCashReceipts(0)}>
                                                    <img src={cashReceipts === 0 ? CheckFill : CheckBlank} alt='checkbox' />
                                                    <span className={`xs:translate-y-[5px] ${cashReceipts === 0 ? "on" : "off"}`}>신청안함</span>
                                                </button>
                                            </div>
                                        </div>
                                        {cashReceipts !== 0 && (
                                            <>
                                                <div className='input-form-box check-box'>
                                                    <span />
                                                    <div className='short-horizontal'>
                                                        <button onClick={() => setCashReceipts(1)}>
                                                            <img src={cashReceipts === 1 ? CheckFill : CheckBlank} alt='checkbox' />
                                                            <span className={`xs:translate-y-[5px] ${cashReceipts ===1 ? "on" : "off"}`}>개인소득공제용</span>
                                                        </button>
                                                        <button className='ml-5' onClick={() => setCashReceipts(2)}>
                                                            <img src={cashReceipts === 2 ? CheckFill : CheckBlank} alt='checkbox' />
                                                            <span className={`xs:translate-y-[5px] ${cashReceipts === 2 ? "on" : "off"}`}>사업자증빙용</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='input-form-box check-box'>
                                                    <span />
                                                    {cashReceipts === 1 && (
                                                        <input
                                                            type='text'
                                                            placeholder='핸드폰 번호를 입력해 주세요.'
                                                            value={receiptPhoneNumber}
                                                            onChange={e => {
                                                                setReceiptPhoneNumber(removeWhitespacePhone(e.target.value));
                                                            }}
                                                            maxLength={13}
                                                        />
                                                    )}
                                                    {cashReceipts === 2 && (
                                                        <input
                                                            type='text'
                                                            placeholder='사업자 번호를 입력해 주세요.'
                                                            value={receiptBusinessNumber}
                                                            onChange={e => {
                                                                setReceiptBusinessNumber(removeWhitespaceBusiness(e.target.value));
                                                            }}
                                                            maxLength={12}
                                                        />
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </SOL.OrderShippingPayMethod>
                    <SOL.OrderTotalAmountContainer>
                        <div className='flex-layout'>
                            <button className="order-btn-checkout font-pretendard" onClick={handleCheckout}>
                                {totalAmount.toLocaleString()}원 결제하기
                            </button>
                            {Object.values(inputErrors).some(error => error) && (
                                <div className="error-message">모든 정보를 정확히 입력해주세요.</div>
                            )}
                        </div>
                    </SOL.OrderTotalAmountContainer>
                </SOL.OrderDetailsWrapper>
            </SOL.OrderDetailsContainer>
        </>
    );
};

export default OrderDetails;
