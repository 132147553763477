import React from 'react';
import useDisableEnterKey from '../../hooks/useDisableAllKeys';

interface ModalLoadingProps {
  isOpen: boolean;
}

const ModalLoading: React.FC<ModalLoadingProps> = ({ isOpen }) => {
  useDisableEnterKey(isOpen);
  if (!isOpen) return null;
  return (
    <div className="modal-loading-overlay">
      <div className="spinner"></div>
    </div>
  );
};

export default ModalLoading;