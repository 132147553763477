import React from 'react'
import {MainQuickMenuData} from "../../modules/Variable";
import * as DABIN from "../../styles/dabin/StyledComponentDabin";
import pendent from "../../assets/image_folder/quick_menu_images/pendent.png";
import table from "../../assets/image_folder/quick_menu_images/table.png";
import floor from "../../assets/image_folder/quick_menu_images/floor.png";
import wall from "../../assets/image_folder/quick_menu_images/wall.png";
import furniture from "../../assets/image_folder/quick_menu_images/furniture.png";
import celling from "../../assets/image_folder/quick_menu_images/celling.png";
import promotion from "../../assets/image_folder/quick_menu_images/promotion.png";
import ablack from "../../assets/image_folder/quick_menu_images/ablack.png";
import { useNavigate } from 'react-router-dom';

const images: { [key: string]: string } = {
  pendent,
  table,
  floor,
  wall,
  furniture,
  celling,
  promotion,
  ablack,
};

const MainQuickMenu = () => {
  const navigate = useNavigate();
  const handleLinkClick = (value: any) => {
    if ((value.label_eng) === "promotion"){
      navigate(`/promotion`)
      window.scrollTo(0, 0);
    } else if ((value.label_eng) === "ablack") {
      navigate(`/service`)
      window.scrollTo(0, 0);
    } else {
      navigate(`/product?product_type=${value.value}`)
    }
  };
  
  return (
    <>
      <DABIN.MainQuickMenuContainer>
        <div className="MainQuickMenuContainer">
          <ul>
            {MainQuickMenuData.map((value,index)=>
              <li key={index} onClick={() => handleLinkClick(value)}>
                <img src={images[value.label_eng]} alt={value.label_eng}/>
                <span>{value.label_kor}<br/>{value.label2_kor}</span>
              </li>
            )}
          </ul>
        </div>
      </DABIN.MainQuickMenuContainer>
    </>
  )
}

export default MainQuickMenu