import React, { useEffect, useState } from 'react'
import * as DABIN from "../../styles/dabin/StyledComponentDabin";
import { getSessionData } from "../../Axios";
import { baseUrl } from '../../Axios';
import { CiCalculator2 } from "react-icons/ci";
import { FaApple, FaGooglePlay, FaCheckCircle, FaTools, FaTruckMoving, FaRegFilePdf } from "react-icons/fa";
import { PiHandHeart } from "react-icons/pi";
import MobileNav from '../../layout/Header/MobileNav';
import useCheckSession from '../../hooks/useCheckSession';

function ServicePage() {
  useCheckSession();
  
  const [customerAlcStatus, setCustomerAlcStatus] = useState<number>(2);

	useEffect(() => {
		getSessionData().then(result => {
      setCustomerAlcStatus(result.alc_status);
		})
	}, []);

  const handleDownload = () => {
    const url = `${baseUrl}files/files_folder/ablack_file/250106_ablack_process_manual.pdf`;
      
    const newWindow = window.open(url, '_blank');
    
    if (newWindow) {
      newWindow.focus();
    }
  };


  return (
    <>
      <DABIN.ServicePageContainer>
        <div className="ServicePage_Banner_container">
          <img 
            className="ServicePage_Banner_background"
            src={`${baseUrl}files/image_folder/alc_service_images/dc033f435fb9fe2d225f198ee8425f0e505910312d4d4fda1d501bc20a9718ded05ea4af7917abe8291e4ad34ac71e47c991591dd43c905c3c9210090b49bdb7.jpg`}
            alt="Background"
          />
          <div className="ServicePage_Banner_Text-box">
            <img className='ServicePage_Banner_logo' 
              src={`${baseUrl}files/image_folder/alc_service_images/812df428702e8781bde700aba346898f1dfac97d9fefc1efb3168a00e7c094f7ad6bf7bfe5bb8ab2c72da483291770111f9d03efaac5b6559ddf2095486a53f5.png`}
              alt="logo" 
            />
            <p>KC 인증 조명 시공 전문 에이블랙,<br/>
            조명 시공을 신뢰하다.</p>
            <button className={customerAlcStatus === 0 ? "none" : "ServicePage_Banner_B2B-btn"}>
              <a href="https://ablack.ambience.kr/main" target="_blank" rel="noreferrer" >
                B2B 웹 사이트 이동
              </a>
            </button>
            <div className="ServicePage_Banner_AppDownload-box">
              <div>
                <p>에이블랙 앱 다운로드</p>
                <div className="ServicePage_Banner_AppDownload-btn">
                  <button className="download-link">
                    <a href="https://apps.apple.com/kr/app/%EC%97%90%EC%9D%B4%EB%B8%94%EB%9E%99/id6443521702" target="_blank" rel="noreferrer">
                      <FaApple/>
                    </a>
                  </button>
                  <button className="download-link">
                    <a href="https://play.google.com/store/apps/details?id=com.ambience.ablack" target="_blank" rel="noreferrer">
                    <FaGooglePlay />
                    </a>
                  </button>
                </div>
              </div>
              <div className={customerAlcStatus === 0 ? "none" : "ServicePage_Banner_AppDownload-btn"}>
                <p>시공 프로세스 메뉴얼 다운로드</p>
                <button className="download-link" onClick={handleDownload}>
                    <FaRegFilePdf/>
                  </button>
              </div>
            </div>
          </div>
        </div>
        <div className="ServicePage_APpoint ServicePage_container">
          <h2 className='ServicePage_title'>AP Point</h2>
          <p>
            AMBIENCE PREMIER 포인트는<br/>
            ‘앰비언스의 가장 중요한 고객’을 위한 특별한 포인트로<br/>
            에이블랙의 시공 서비스를 <span>현금 대신 결제할 수 있는 고유의 재화입니다.</span><br/>
            <br/>
            이 포인트는 오직 ‘에이블랙’ 내에서만 사용 가능하며,<br/>
            1포인트는 1원과 동일한 가치를 지니는 <span>1:1 교환 가치를 지니고 있습니다.</span>
          </p>
        </div>
        <div className="ServicePage_AppFunction ServicePage_container">
          <h2 className='ServicePage_title'>에이블랙 앱 기능</h2>
          <div className="ServicePage_AppFunction_box">
            <img 
              src={`${baseUrl}files/image_folder/alc_service_images/519811c4ef494d75b0feb55e154471b4f872b73368987b8a3974bc75ce5d79230510adf137e016605d240025bd166d55de1afddee3a56d29450356577f656206.png`}
              alt="AblackApp" 
            />
            <ul>
              <li className="ServicePage_AppFunction_icon">
                <div className='ServicePage_Appfuntion_icon-box'><CiCalculator2 /></div>
                <p>견적 계산기</p>
              </li>
              <li className="ServicePage_AppFunction_icon">
                <div className='ServicePage_Appfuntion_icon-box'><FaCheckCircle/></div>
                <p>시공 예약</p>
              </li>
              <li className="ServicePage_AppFunction_icon">
                <div className='ServicePage_Appfuntion_icon-box'><FaTools /></div>
                <p>A/S 예약</p>
              </li>
              <li className="ServicePage_AppFunction_icon">
                <div className='ServicePage_Appfuntion_icon-box'><FaTruckMoving /></div>
                <p>이동 시공 예약</p>
              </li>
              <li className="ServicePage_AppFunction_icon">
                <div className='ServicePage_Appfuntion_icon-box'><PiHandHeart /></div>
                <p>리얼 시공 리뷰</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="ServicePage_ablack-Information ServicePage_container">
          <h2 className='ServicePage_title'>에이블랙 시공 안내</h2>
          <iframe
            src="https://www.youtube.com/embed/klI325eeNuE?autoplay=1&mute=1&loop=1&playlist=klI325eeNuE"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="Ablack Movie">
          </iframe>
          <ul>
            <li className='ServicePage_ablack-Information_list'>
              <img 
                src={`${baseUrl}files/image_folder/alc_service_images/d5bb5a5ca24ba595cf2593aebf7c5c763ef89a3d9201b11be0f9815c7079eec52a6443a08060839e83644cba309fbe5ec6f3943fc417426f251a649251cd6ae4.jpg`}
                alt="Ablack-service" 
              />
              <div className='ServicePage_ablack-Information_list_text-box'>
                <p className='ServicePage_ablack-Information_list_number'>01</p>
                <p className='ServicePage_ablack-Information_list_title'>
                  <span>편리하게 </span>시공 예약 가능
                </p>
                <p className="ServicePage_ablack-Information_list_description">
                  에이블랙 외에도 하이엔드 조명 시공 가능하며,<br/>
                  앱 서비스 ‘견적 계산기’를 통해<br/>
                  시공 전 간편하게 견적을 볼 수 있습니다.<br/>
                  <br/>
                  또한 앱을 통해 편리하게<br/>
                  시공 견적서를 확인할 수 있습니다.
                </p>
              </div>
            </li>
            <li className='ServicePage_ablack-Information_list'>
              <img 
                src={`${baseUrl}files/image_folder/alc_service_images/6511fd497c69b004cdb3c3e43b73c1546596b32eb46f158da8cd02b2d6270f31d3a49eebc984f0d2ffd2920d38e32f7447757e715402e3867fc543e3d7c66750.jpg`}
                alt="Ablack-service" 
              />
              <div className='ServicePage_ablack-Information_list_text-box'>
                <p className='ServicePage_ablack-Information_list_number'>02</p>
                <p className='ServicePage_ablack-Information_list_title'>
                  <span>전문 인스톨러가 </span>방문하여 시공
                </p>
                <p className="ServicePage_ablack-Information_list_description">
                인스톨러의 전문 경력과 간단한 프로필을 제공하여, <br/>
                우리집에 방문하는 인스톨러들을<br/>
                안심하고 선택할 수 있는 기회를 제공합니다.<br/>
                <br/>
                이를 통해 고객님께서는 신뢰할 수 있는 인스톨러와 함께 <br/>
                더욱 편안하고 안전한 서비스를 경험하실 수 있습니다.
                </p>
              </div>
            </li>
            <li className='ServicePage_ablack-Information_list'>
              <img 
                src={`${baseUrl}files/image_folder/alc_service_images/e4dc731d5ff36ffcaf91cfc427bb8dd3ae07047eb0f13bb9f28fa017433c5c4a041fa96be0307fe483006dcb82fde77e1737948a1d76704cc0db1696777fb1a2.jpg`}
                alt="Ablack-service" 
              />
              <div className='ServicePage_ablack-Information_list_text-box'>
                <p className='ServicePage_ablack-Information_list_number'>03</p>
                <p className='ServicePage_ablack-Information_list_title'>
                  <span>편리하게 </span>이동 시공 예약
                </p>
                <p className="ServicePage_ablack-Information_list_description">
                  조명의 위치를 변경하거나 이사를 가거나, <br/>
                  사용하지 않게 되어 가족이나 지인에게 양도할 경우, <br/>
                  <br/>
                  에이블랙 앱을 통해 시공 예약처럼 <br/>
                  간편하게 이전 시공을 예약하실 수 있습니다.
                </p>
              </div>
            </li>
            <li className='ServicePage_ablack-Information_list'>
              <img 
                src={`${baseUrl}files/image_folder/alc_service_images/84d5cc7865db1a9dc7aac13729ce91f2294fff4c4b9dd40ed59f59097062ef51a67d3d1186f89372229f1f2a5952d57e2299f9fbec9fbf41b2806e6dd87811ee.jpg`}
                alt="Ablack-service" 
              />
              <div className='ServicePage_ablack-Information_list_text-box'>
                <p className='ServicePage_ablack-Information_list_number'>04</p>
                <p className='ServicePage_ablack-Information_list_title'>
                  <span>에이블랙 구매 고객 </span>5년간 보증
                </p>
                <p className="ServicePage_ablack-Information_list_description">
                  '에이블랙 앱'을 통해 원하는 날짜와 시간대에 <br/>
                  편리하게 A/S 예약 가능 합니다.<br/>
                  <br/>
                  <b>단, 전기 관련 문제는 구매일로부터 2년간 보증</b>되며,<br/>
                  정확한 사항은 구매처를 통한 A/S 접수 원칙에 따라<br/>
                  해당 구매처에서 상담을 받으실 수 있습니다.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </DABIN.ServicePageContainer>
      <MobileNav />
    </>
  )
}

export default ServicePage