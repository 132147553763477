import React, { FC, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ModalYesClose from '../Modal/ModalYesClose';
import { useNavigate } from 'react-router-dom';
import * as SOL from "../../styles/solda/StyledComponentSol";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useOrderContext } from '../../context/OrderContext';
import { useCartContext } from '../../context/CartContext';
import IconBack from "../../assets/image_folder/common_images/icon_back_image.png";
import IconMinus from "../../assets/icons/icon_minus.svg";
import IconPlus from "../../assets/icons/icon_plus.svg";
import IconArrow from "../../assets/icons/icon_only_arrow.svg";
import useDetectScroll from '@smakss/react-scroll-direction';
import { useMediaQuery } from 'react-responsive';
import ModalClose from '../Modal/ModalClose';
import { applyDiscountRateForPurpose, discountRateCalculator } from '../../Utils';
import { IColorOptionArray } from '../../modules/model';
import { from_locale_string_to_number } from '../../modules/Validation';

interface IProductProps {
    product?: any;
    apply_discount_rate: any;
    apply_discount_rate_product_type: any
    apply_discount_rate_color: any
    customerAlcStatus: number;
}

const ProductBuy: FC<IProductProps> = ({
    product,
    apply_discount_rate,
    apply_discount_rate_product_type,
    apply_discount_rate_color,
    customerAlcStatus,
}) => {
    const navigate = useNavigate();
    const { resetInputErrors } = useOrderContext();
    const { countCart } = useCartContext();
    const buyButtonRef: React.RefObject<HTMLButtonElement> = useRef(null);
    const {scrollDir, scrollPosition} = useDetectScroll();

    // 옵션 영역
    const [colorOptionArray, setColorOptionArray] = useState<IColorOptionArray>();
    const [selectedColorOption, setSelectedColorOption] = useState<{
        second_selected_color_option: any[];
        third_selected_color_option: any[];
        fourth_selected_color_option: any[];
    }>({
        second_selected_color_option: [],
        third_selected_color_option: [],
        fourth_selected_color_option: [],
    });
    const [selectedListOption, setSelectedListOption] = useState<IColorOptionArray | null>(null);

    const [nodeToggleStatus, setNodeToggleStatus] = useState({
        second_node_index: 0,
        third_node_index: 0,
        fourth_node_index: 0,
    });
    const [currentThumbnailImage, setCurrentThumbnailImage] = useState('');
    const productImageFramRef: React.RefObject<HTMLImageElement> = useRef(null);
    
    const [buyButtonBottomPosition, setBuyButtonBottomPosition] = useState(0);
	const [headerAndQuickMenu, setHeaderAndQuickMeun] = useState(false);
    const [quantity, setQuantity] = useState<number>(1);
    const [finalAmount, setFinalAmount] = useState<number>(0);
    const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
    const [isModalYesCloseOpen, setIsModalYesCloseOpen] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState({
        text: '',
        confirmText: '',
        onConfirm: () => {}
    });
    const [mobileBuyModal, setMobileBuyModal] = useState(false);
    const [discountRate, setDiscountRate] = useState({
        apply_discount_rate_1: '',
        apply_discount_rate_2: '',
        apply_discount_rate_ID: '',
        apply_discount_rate_vat: '1',
        brand_ID: '',
        product_type_ID: [],
        color_ID: [],
        use_status: '',
    });
    const isMobile = useMediaQuery({query: "(max-width: 500px)"});

    const incrementQuantity = () => setQuantity((prev) => prev < 100 ? prev + 1 : prev);
    const decrementQuantity = () => setQuantity((prev) => prev > 1 ? prev - 1 : prev);
    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuantity(parseInt(event.target.value, 10));
    };

    // 옵션 처리
    useEffect(() => {
        if (!product.color_option_status) return;
        if (!product.color_option_array) return;
        let color_option: IColorOptionArray = JSON.parse(product.color_option_array);
        setColorOptionArray(color_option);
    }, [product]);

    // 옵션 가격 계산해서 +- 표시기
    const applyOptionPirce = (normal_price: string) => {
        let price = from_locale_string_to_number(normal_price) - product.color_b2c_sell_price;
        let rate_apply = discountRateCalculator(price, Number(discountRate.apply_discount_rate_1), Number(discountRate.apply_discount_rate_2));
        return `(${rate_apply > 0 ? "+" : ""}${rate_apply.toLocaleString()}원)`;
    };

    useEffect(() => {
        if (
            selectedColorOption.fourth_selected_color_option.length > 0 &&
            Number(selectedColorOption.fourth_selected_color_option[0].color_option_node_status) === 1
        ) {
            setSelectedListOption(selectedColorOption.fourth_selected_color_option[0]);
        } else if (
            selectedColorOption.third_selected_color_option.length > 0 &&
            Number(selectedColorOption.third_selected_color_option[0].color_option_node_status) === 1
        ) {
            setSelectedListOption(selectedColorOption.third_selected_color_option[0]);
        } else if (
            selectedColorOption.second_selected_color_option.length > 0 &&
            Number(selectedColorOption.second_selected_color_option[0].color_option_node_status) === 1
        ) {
            setSelectedListOption(selectedColorOption.second_selected_color_option[0]);
        } else {
            setSelectedListOption(null);
        }
    }, [selectedColorOption]);

    const handleCurrentOptionThumbnailImage = (option_element: IColorOptionArray, e: any) => {
        if (option_element.color_option_node_hover_img_url !== '') {
            setCurrentThumbnailImage(option_element.color_option_node_hover_img_url);
        }
    };

    const addToCart = () => {
        if (!validateListOption()) return setIsModalCloseOpen(true);

        const cart = JSON.parse(localStorage.getItem('cart') || '[]');
        const isProductInCart = cart.some((item: any) => (item.color_ID === product.color_ID && item.color_option_ID === selectedListOption?.id));
        if (isProductInCart) {
            setIsModalYesCloseOpen(true);
            setModalContent({
                text: '장바구니에 이미 상품이 담겨있습니다. 장바구니로 이동하시겠습니까?',
                confirmText: '이동',
                onConfirm: () => {
                    navigate(`/cart`);
                },
            });
        } else {
            const newCartItem = {
                color_ID: product.color_ID,
                quantity,
                color_option_ID: selectedListOption?.id,
                color_option_path: makeSelectedOptionObject(),
            };
            if (cart.length >= 50) {
                setModalContent({
                    ...modalContent,
                    text: '장바구니는 50개까지 저장할 수 있습니다.',
                });
                setIsModalCloseOpen(true);
            } else {
                localStorage.setItem('cart', JSON.stringify([...cart, newCartItem]));
                countCart();
                setModalContent({
                    text: '장바구니에 상품을 담았습니다. 장바구니로 이동하시겠습니까?',
                    confirmText: '이동',
                    onConfirm: () => {
                        navigate(`/cart`);
                    },
                });
                setIsModalYesCloseOpen(true);
            }
        }
    }

    // 해당 제품에 옵션이 있으면 리스트 노드까지 제대로 선택 됐는지 확인하는 용도
    const validateListOption = () => {
        let validation = false;
        let validation_text = "";

        if (!colorOptionArray) {
            validation = true;
        } else {
            if (selectedListOption) {
                validation = true;
            } else {
                validation_text = "옵션을 선택해 주세요.";
            }
        }

        setModalContent({
            ...modalContent,
            text: validation_text,
        });
        return validation;
    };

    useEffect(() => {
        let color_array = apply_discount_rate_color.filter((tmp: {color_ID: any[]; use_status: any;}) => (
			tmp.color_ID.includes(product.color_ID) && Number(tmp.use_status) === 1
		));
		let type_array = apply_discount_rate_product_type.filter((tmp: {brand_ID: any; product_type_ID: any[]; use_status: any;}) => (
			tmp.product_type_ID.includes(product.product_type_ID) && Number(tmp.brand_ID) === product.brand_ID && Number(tmp.use_status) === 1
		));
		let rate_array = apply_discount_rate.filter((tmp: { brand_ID: any; use_status: any;}) => (
			Number(tmp.brand_ID) === product.brand_ID && Number(tmp.use_status) === 1
		));

        if (color_array.length > 0) {
            setDiscountRate(applyDiscountRateForPurpose(1, color_array));
        } else if (type_array.length > 0) {
            setDiscountRate(applyDiscountRateForPurpose(2, type_array));
        } else if (product.product_portable_status === 1) {
            setDiscountRate(applyDiscountRateForPurpose(4, rate_array));
        } else if (rate_array.length > 0) {
			setDiscountRate(applyDiscountRateForPurpose(3, rate_array));
		} else {
			setDiscountRate({
				apply_discount_rate_1: '',
				apply_discount_rate_2: '',
				apply_discount_rate_ID: '',
				apply_discount_rate_vat: '1',
				brand_ID: '',
				product_type_ID: [],
				color_ID: [],
				use_status: '',
			});
		}
    }, [apply_discount_rate, apply_discount_rate_product_type, apply_discount_rate_color, product]);

    // 선택된 옵션 리스트에 따라서 하나의 객체로 만들기
    const makeSelectedOptionObject = () => {
        let option_obj: any = null;

        if (selectedColorOption.fourth_selected_color_option.length > 0) {
            option_obj = selectedColorOption.second_selected_color_option[0];
            option_obj.children = selectedColorOption.third_selected_color_option;
            option_obj.children[0].children = selectedColorOption.fourth_selected_color_option;
        } else if (selectedColorOption.third_selected_color_option.length > 0) {
            option_obj = selectedColorOption.second_selected_color_option[0];
            option_obj.children = selectedColorOption.third_selected_color_option;
        } else if (selectedColorOption.second_selected_color_option.length > 0) {
            option_obj = selectedColorOption.second_selected_color_option[0];
        }

        const top_node_obj = {...colorOptionArray};

        if (option_obj !== null) {
            top_node_obj.children = [option_obj];
        }
        return option_obj ? JSON.stringify(top_node_obj) : null;
    };

    const handlePurchase = () => {
        if (!validateListOption()) return setIsModalCloseOpen(true);

        resetInputErrors();
        const productToPurchase = [{
            color_ID: product?.color_ID,
            quantity: quantity,
            color_option_path: makeSelectedOptionObject(),
            color_option_ID: selectedListOption?.id,
        }];
        navigate('/order', { state: productToPurchase });
    };

    useEffect(() => {

        if (parseInt(discountRate.apply_discount_rate_1) > 0) {
            setFinalAmount(
                discountRateCalculator(
                    !selectedListOption ? product.color_b2c_sell_price : from_locale_string_to_number(selectedListOption.color_option_node_product_normal_price),
                    Number(discountRate.apply_discount_rate_1),
                    Number(discountRate.apply_discount_rate_2),
                )*quantity
            );
        } else {
            setFinalAmount((!selectedListOption ? product.color_b2c_sell_price : from_locale_string_to_number(selectedListOption.color_option_node_product_normal_price)) * quantity);
        }
    }, [
        discountRate.apply_discount_rate_1,
        discountRate.apply_discount_rate_2,
        discountRate.brand_ID,
        product.color_b2c_sell_price,
        quantity,
        selectedListOption,
    ]);

    useEffect(() => {
        if (mobileBuyModal) {
            window.document.body.style.overflow = "hidden";
        } else {
            window.document.body.style.overflow = "initial";
        }
    }, [mobileBuyModal]);

    /* const movingHeaderStyle: React.CSSProperties = { FIXME: 전체 헤더를 조종하던지, 검색어를 context로 넣을지 선택
		transform: `translateY(${headerAndQuickMenu ? -80 : 0}px)`,
	}; */

    useEffect(() => {
        if (!buyButtonRef.current) return;
        let rect = buyButtonRef.current.getBoundingClientRect();
        const positionY = rect.bottom + window.scrollY;
        setBuyButtonBottomPosition(isMobile ? (positionY + 350) : (positionY + 150));
    }, [buyButtonRef, isMobile]);

	useEffect(() => {
        if (!buyButtonRef.current || !buyButtonBottomPosition) return;
        if (scrollPosition.top < buyButtonBottomPosition) return;
        if (scrollDir === 'down') {
            if (headerAndQuickMenu) return;
            setHeaderAndQuickMeun(true);
        }
        if (scrollDir === 'up') {
            if (!headerAndQuickMenu) return;
            setHeaderAndQuickMeun(false);
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buyButtonBottomPosition, scrollDir, scrollPosition.top]);

    useEffect(() => {
        productImageFramRef.current?.classList.add("invisible");
        setTimeout(() => {
            productImageFramRef.current?.classList.remove("invisible");
        }, 200);
    }, [currentThumbnailImage]);

    return (
        <>
            <ModalClose
                isOpen={isModalCloseOpen}
                onClose={() => setIsModalCloseOpen(false)}
                text={modalContent.text}
            />
            <ModalYesClose 
                isOpen={isModalYesCloseOpen} 
                onClose={() => setIsModalYesCloseOpen(false)}
                onConfirm={modalContent.onConfirm}
                confirmText={modalContent.confirmText}
                text={modalContent.text}
            />
            {/* FIXME: 나중에 모바일 UI 나오면 그때 전체 화면 계산해서 위치 제대로 잡기 */}
            <SOL.ProductDetailsFloatingBackButton
                className={`${headerAndQuickMenu && "active"}`}
                onClick={() => navigate(-1)}
            >
                <img className='w-12' src={IconBack} alt='back_image' />
            </SOL.ProductDetailsFloatingBackButton>

            {/* 스크롤에 따라서 움직이는 영역 */}
            {/* <SOL.MovingHeaderContainer style={movingHeaderStyle}>
				<Header />
			</SOL.MovingHeaderContainer> */}
            <SOL.ProductDetailsFloatingController
                className={`${(buyButtonBottomPosition > 0 && scrollPosition.top > (buyButtonBottomPosition+50)) && "active"}`}
            >
                <div className='controller-wrapper'>
                    <div className='left-side'>
                        {product.product_kor_name}
                    </div>
                    <div className='right-side'>
                        <div className='price-quantity'>
                            <div className='final-price-box'>
                                <span className='product-details-tag'>총 상품금액</span>
                                <span className="final-amount">{finalAmount.toLocaleString()}원</span>
                            </div>
                            <div className="quantity">
                                <button onClick={decrementQuantity}>
                                    <img src={IconMinus} alt='icon_minus' />
                                </button>
                                <input
                                    className='font-pretendard'
                                    type="number"
                                    id="quantity-input"
                                    name="quantity"
                                    min="1"
                                    max="100"
                                    readOnly
                                    value={quantity}
                                    onChange={handleQuantityChange}
                                />
                                <button onClick={incrementQuantity}>
                                    <img src={IconPlus} alt='icon_plus' />
                                </button>
                            </div>
                        </div>
                        <div className='buy-button-box'>
                            <button
                                className="cart-button"
                                onClick={addToCart}
                            >
                                장바구니 담기
                            </button>
                            {customerAlcStatus === 0 && (
                                <button
                                    className="buy-button"
                                    onClick={handlePurchase}
                                    ref={buyButtonRef}
                                >
                                    구입하기
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </SOL.ProductDetailsFloatingController>
            {/* 스크롤에 따라서 움직이는 영역 */}

            <SOL.ProductBuyContainer>
                <div className="product-image">
                    <img
                        ref={productImageFramRef}
                        src={currentThumbnailImage ? currentThumbnailImage : product.color_b2c_image}
                        alt={product.color_name}
                    />
                </div>
                <div className="product-details-content">
                    <div className="brand-name">
                        <span className='font-pretendard'>
                            {product.brand_eng_name}
                        </span>
                        {(customerAlcStatus === 1 && (product.inventory_status === 3 || product.inventory_status === 4)) && (
                            <span className="inventory">
                                {product.inventory_warehouse_date_status}
                            </span>
                        )}
                    </div>
                    <h1 className="product-name">{product.product_kor_name}</h1>
                    <h3 className="product-name-eng">{product.product_eng_name}</h3>
                    <h3 className="color-name">{product.color_name}</h3>
                    {(parseInt(discountRate.apply_discount_rate_1) > 0) ? (
                        <>
                            <p className="original-price">{product.color_b2c_sell_price.toLocaleString()}</p>
                            <div className='discount-price-box'>
                                <div>
                                    <span className="discount-price">
                                        {discountRateCalculator(
                                            product.color_b2c_sell_price,
                                            Number(discountRate.apply_discount_rate_1),
                                            Number(discountRate.apply_discount_rate_2),
                                        ).toLocaleString()}
                                    </span>
                                    <span className="discount-rate">
                                        {Number(discountRate.apply_discount_rate_1)}%
                                    </span>
                                    {parseInt(discountRate.apply_discount_rate_2) > 0 && (
                                        <>
                                            <div className='arrow-icon-img'>
                                                <img src={IconArrow} alt='arrow' />
                                            </div>
                                            <span className='discount-rate additional-discount'>
                                                추가 {Number(discountRate.apply_discount_rate_2)}%
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div className='discount-rate-vat'>
                                    <span>
                                        {`*부가세 ${discountRate.apply_discount_rate_vat === '0' ? '별도' : '포함'}`}
                                    </span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='discount-price-box' style={{marginTop: "20px"}}>
                                <p className="discount-price">{product.color_b2c_sell_price.toLocaleString()}</p>
                            </div>
                        </>
                    )}
                    <div className='delivery-price'>
                        {customerAlcStatus !== 1 && 
                            <>
                                <span className='product-details-tag'>배송비</span>
                                <span className='delivery-price-noti'>에이블랙 무료 시공/배송 서비스 제공 0원</span>
                            </>
                        }
                    </div>
                    {colorOptionArray ? (
                        <SOL.ColorOptionArray>
                            <div className='color-option-array-tag'>
                                <p>옵션을 선택해주세요.</p>
                                {colorOptionArray.color_option_node_group}
                            </div>
                            <div className='second-node-container'>
                                {colorOptionArray.children.map((node2_element, node2_index) =>
                                    <div
                                        key={node2_index}
                                        className={`second-node-option ${nodeToggleStatus.second_node_index === node2_index+1 ? Number(node2_element.color_option_node_status) === 1 ? "selected-list" : "selected" : ""}`}
                                    >
                                        <div
                                            className={`second-node-option-label label-basic`}
                                            onClick={() => {
                                                if (selectedColorOption.second_selected_color_option[0] === node2_element) {
                                                    setSelectedColorOption({
                                                        second_selected_color_option: [],
                                                        third_selected_color_option: [],
                                                        fourth_selected_color_option: [],
                                                    });
                                                    setNodeToggleStatus({
                                                        second_node_index: 0,
                                                        third_node_index: 0,
                                                        fourth_node_index: 0,
                                                    });
                                                } else {
                                                    setSelectedColorOption({
                                                        second_selected_color_option: [node2_element],
                                                        third_selected_color_option: [],
                                                        fourth_selected_color_option: [],
                                                    });
                                                    setNodeToggleStatus({
                                                        second_node_index: node2_index+1,
                                                        third_node_index: 0,
                                                        fourth_node_index: 0,
                                                    });
                                                }
                                            }}
                                            onMouseEnter={(e) => handleCurrentOptionThumbnailImage(node2_element, e)}
                                            onMouseLeave={() => setCurrentThumbnailImage('')}
                                        >
                                            {Number(node2_element.color_option_node_status) === 1 ? (
                                                <>
                                                    <span>{node2_element.color_option_node_list}</span>
                                                    <span className='price-text'>
                                                        {applyOptionPirce(node2_element.color_option_node_product_normal_price)}
                                                    </span>
                                                </>
                                            ) : (
                                                node2_element.color_option_node_group
                                            )}
                                        </div>
                                        {nodeToggleStatus.second_node_index === node2_index+1&& (
                                            <div
                                                className='third-node-container'
                                            >
                                                {node2_element.children.map((node3_element, node3_index) =>
                                                    <div
                                                        key={node3_index}
                                                        className={`third-node-option ${nodeToggleStatus.third_node_index === node3_index+1 ? Number(node3_element.color_option_node_status) === 1 ? "selected-list" : "selected" : ""}`}
                                                    >
                                                        <div
                                                            className={`third-node-option-label label-basic`}
                                                            onClick={() => {
                                                                if (selectedColorOption.third_selected_color_option[0] === node3_element) {
                                                                    setSelectedColorOption({
                                                                        second_selected_color_option: [node2_element],
                                                                        third_selected_color_option: [],
                                                                        fourth_selected_color_option: [],
                                                                    });
                                                                    setNodeToggleStatus({
                                                                        second_node_index: node2_index+1,
                                                                        third_node_index: 0,
                                                                        fourth_node_index: 0,
                                                                    });
                                                                } else {
                                                                    setSelectedColorOption({
                                                                        second_selected_color_option: [node2_element],
                                                                        third_selected_color_option: [node3_element],
                                                                        fourth_selected_color_option: [],
                                                                    });
                                                                    setNodeToggleStatus({
                                                                        second_node_index: node2_index+1,
                                                                        third_node_index: node3_index+1,
                                                                        fourth_node_index: 0,
                                                                    });
                                                                }
                                                            }}
                                                            onMouseEnter={(e) => {
                                                                handleCurrentOptionThumbnailImage(node3_element, e);
                                                            }}
                                                            onMouseLeave={() => {
                                                                setCurrentThumbnailImage('');
                                                            }}
                                                        >
                                                            {Number(node3_element.color_option_node_status) === 1 ? (
                                                                <>
                                                                    <span>{node3_element.color_option_node_list}</span>
                                                                    <span className='price-text'>
                                                                        {applyOptionPirce(node3_element.color_option_node_product_normal_price)}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                node3_element.color_option_node_group
                                                            )}
                                                        </div>
                                                        {nodeToggleStatus.third_node_index === node3_index+1 && (
                                                            <div
                                                                className='fourth-node-container'
                                                            >
                                                                {node3_element.children.map((node4_element, node4_index) =>
                                                                    <div
                                                                        key={node4_index}
                                                                        className={`fourth-node-option ${nodeToggleStatus.fourth_node_index === node4_index+1 ? Number(node4_element.color_option_node_status) === 1 ? "selected-list" : "selected" : ""}`}
                                                                    >
                                                                        <div
                                                                            className={`fourth-node-option-label label-basic`}
                                                                            onClick={() => {
                                                                                setSelectedColorOption({
                                                                                    second_selected_color_option: [node2_element],
                                                                                    third_selected_color_option: [node3_element],
                                                                                    fourth_selected_color_option: [node4_element],
                                                                                });
                                                                                setNodeToggleStatus({
                                                                                    second_node_index: node2_index+1,
                                                                                    third_node_index: node3_index+1,
                                                                                    fourth_node_index: node4_index+1,
                                                                                });
                                                                            }}
                                                                            onMouseEnter={(e) => {
                                                                                handleCurrentOptionThumbnailImage(node4_element, e);
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                setCurrentThumbnailImage('');
                                                                            }}
                                                                        >
                                                                            {Number(node4_element.color_option_node_status) === 1 ? (
                                                                                <>
                                                                                    <span>{node4_element.color_option_node_list}</span>
                                                                                    <span className='price-text'>
                                                                                        {applyOptionPirce(node4_element.color_option_node_product_normal_price)}
                                                                                    </span>
                                                                                </>
                                                                            ) : (
                                                                                node4_element.color_option_node_group
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            {selectedListOption && (
                                <SOL.SelectedListOptionBox>
                                    <div className='selected-list-description-box'>
                                        {selectedColorOption.second_selected_color_option.length > 0 && (
                                            <span>
                                                {Number(selectedColorOption.second_selected_color_option[0].color_option_node_status) === 1 ? (
                                                    selectedColorOption.second_selected_color_option[0].color_option_node_list
                                                ) : (
                                                    selectedColorOption.second_selected_color_option[0].color_option_node_group
                                                )}
                                                {` `}
                                            </span>
                                        )}
                                        {selectedColorOption.third_selected_color_option.length > 0 && (
                                            <span>
                                                {`/ `}
                                                {Number(selectedColorOption.third_selected_color_option[0].color_option_node_status) === 1 ? (
                                                    selectedColorOption.third_selected_color_option[0].color_option_node_list
                                                ) : (
                                                    selectedColorOption.third_selected_color_option[0].color_option_node_group
                                                )}
                                                {` `}
                                            </span>
                                        )}
                                        {selectedColorOption.fourth_selected_color_option.length > 0 && (
                                            <span>
                                                {`/ `}
                                                {Number(selectedColorOption.fourth_selected_color_option[0].color_option_node_status) === 1 ? (
                                                    selectedColorOption.fourth_selected_color_option[0].color_option_node_list
                                                ) : (
                                                    selectedColorOption.fourth_selected_color_option[0].color_option_node_group
                                                )}
                                                {` `}
                                            </span>
                                        )}
                                    </div>
                                    <div
                                        style={{borderBottomWidth: 0, padding: 0, justifyContent: selectedListOption.color_option_node_img_url ? "space-between" : "end"}}
                                        className='quantity-box option-right-box'
                                    >
                                        {selectedListOption.color_option_node_img_url && (
                                            <div
                                                className='selected-list-img-box'
                                                onMouseEnter={() => setCurrentThumbnailImage(selectedListOption.color_option_node_img_url)}
                                                onMouseLeave={() => setCurrentThumbnailImage('')}
                                            >
                                                <img src={selectedListOption.color_option_node_img_url} alt='color_option_node_img_url' />
                                            </div>
                                        )}
                                        <div className="quantity">
                                            <button onClick={decrementQuantity}>
                                                <img src={IconMinus} alt='icon_minus' />
                                            </button>
                                            <input
                                                className='font-pretendard'
                                                type="number"
                                                id="quantity-input"
                                                name="quantity"
                                                min="1"
                                                max="100"
                                                readOnly
                                                value={quantity}
                                                onChange={handleQuantityChange}
                                            />
                                            <button onClick={incrementQuantity}>
                                                <img src={IconPlus} alt='icon_plus' />
                                            </button>
                                        </div>
                                    </div>
                                </SOL.SelectedListOptionBox>
                            )}
                        </SOL.ColorOptionArray>
                    ) : (
                        <div className='quantity-box'>
                            <span className='product-details-tag'>수량</span>
                            <div className="quantity">
                                <button onClick={decrementQuantity}>
                                    <img src={IconMinus} alt='icon_minus' />
                                </button>
                                <input
                                    className='font-pretendard'
                                    type="number"
                                    id="quantity-input"
                                    name="quantity"
                                    min="1"
                                    max="100"
                                    readOnly
                                    value={quantity}
                                    onChange={handleQuantityChange}
                                />
                                <button onClick={incrementQuantity}>
                                    <img src={IconPlus} alt='icon_plus' />
                                </button>
                            </div>
                        </div>
                    )}
                    <div className='control-box'>
                        <div className='final-price-box'>
                            <span className='product-details-tag'>최종 금액</span>
                            <span className="final-amount">{finalAmount.toLocaleString()}원</span>
                        </div>
                        <div className='buy-button-box'>
                            <button
                                className="cart-button"
                                style={{width: `${customerAlcStatus === 1 && "100%"}`}}
                                onClick={addToCart}
                            >
                                장바구니 담기
                            </button>
                            {customerAlcStatus === 0 && (
                                <button
                                    className="buy-button"
                                    style={{width: `calc(100% - 140px)`}}
                                    onClick={handlePurchase}
                                    ref={buyButtonRef}
                                >
                                    구입하기
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <SOL.ProductBuyModal>
                    {mobileBuyModal &&
                        <div
                            className='modal-background'
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    setMobileBuyModal(false);
                                }
                            }}>
                            <div className='modal-content'>
                                <button
                                    className='close-modal'
                                    onClick={() => setMobileBuyModal(false)}
                                >
                                    <ArrowBackIosNewIcon className='arrow' />
                                </button>
                                <div className='product-info-box'>
                                    <div className='text-box'>
                                        <p className='brand-name'>{product.brand_eng_name}</p>
                                        <p className='product-kor-name'>{product.product_kor_name}</p>
                                        <p className='color-name'>{product.color_name}</p>
                                    </div>
                                    <div className='image-box'>
                                        <img src={product.color_b2c_image} alt={product.color_name} />
                                    </div>
                                </div>
                                <div className='control-box'>
                                    <div>
                                        <p className='final-amount-label'>최종 금액</p>
                                        {discountRate.brand_ID !== '' && (
                                            <>
                                                <p className='original-amount'>{product.color_b2c_sell_price * quantity}원</p>
                                                <span className="discount-rate">{discountRate.apply_discount_rate_1}%</span>
                                            </>
                                        )}
                                        <span className="final-amount">{finalAmount.toLocaleString()}원</span>
                                    </div>
                                    <div className="quantity">
                                        <button onClick={decrementQuantity}>-</button>
                                        <input
                                            type="number"
                                            pattern="\d*"
                                            id="quantity-input"
                                            name="quantity"
                                            min="1"
                                            max="100"
                                            readOnly
                                            value={quantity}
                                            onChange={handleQuantityChange}
                                        />
                                        <button onClick={incrementQuantity}>+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </SOL.ProductBuyModal>
            </SOL.ProductBuyContainer>
        </>
    );
};

ProductBuy.propTypes = {
    product: PropTypes.any,
    apply_discount_rate: PropTypes.any,
    apply_discount_rate_product_type: PropTypes.any,
	apply_discount_rate_color: PropTypes.any,
};

ProductBuy.defaultProps = {
    product: [],
    apply_discount_rate: {},
    apply_discount_rate_product_type: {},
	apply_discount_rate_color: {},
};

export default ProductBuy;

