import React, { FC } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import Logo from "../../assets/image_folder/common_images/ablack_white_main.png";
import Insta from "../../assets/icons/social_insta.svg";
import Blog from "../../assets/icons/social_blog.svg";
import Apple from "../../assets/icons/social_apple.svg";
import Google from "../../assets/icons/social_google.svg";
import { useNavigate } from "react-router-dom";
import { handleScrollTopPosition } from "../../Utils";

const Footer: FC = () => {
  const navigate =useNavigate();

  return (
    <SOL.FooterContainer>
      <SOL.FooterWrapper>
        <div className="left-side">
          <div className="customer-service">
            <p className="center">고객센터</p>
            <p className="phone font-pretendard">070-4286-3556</p>
            <p className="open">월-금 09:00 ~ 18:00</p>
            <p className="closed">토,일 및 공휴일 휴무</p>
          </div>
          <div className="social">
            <button className="link-btn">
              <a href="https://www.instagram.com/ambience_lighting/" target="_blank" rel="noreferrer">
                <img src={Insta} alt="insta" />
              </a>
            </button>
            <button className="link-btn">
              <a href="https://blog.naver.com/ambience_lighting" target="_blank" rel="noreferrer">
                <img src={Blog} alt="blog" />
              </a>
            </button>
            <button className="link-btn">
              <a href="https://apps.apple.com/kr/app/%EC%97%90%EC%9D%B4%EB%B8%94%EB%9E%99/id6443521702" target="_blank" rel="noreferrer">
                <img src={Apple} alt="apple" />
              </a>
            </button>
            <button className="link-btn">
              <a href="https://play.google.com/store/apps/details?id=com.ambience.ablack" target="_blank" rel="noreferrer">
                <img src={Google} alt="google" />
              </a>
            </button>
          </div>
        </div>
        <div className="right-side">
          <img className="ablack_logo" src={Logo} alt="logo-img" />
          <span className="slogan">빛의 시작과 끝 - 빛 너머의 모든것, 에이블랙</span>
          <div className="terms">
            <button
              onClick={() => {
                navigate('/privacy_policy');
                handleScrollTopPosition(0);
              }}
            >
              개인정보처리방침
            </button>
            <span>&nbsp;|&nbsp;</span>
            <button onClick={() => {
                navigate('/terms_of_service');
                handleScrollTopPosition(0);
              }}
            >
              이용약관
            </button>
          </div>
          <span className="company-registration">주식회사 앰비언스 | 사업자등록번호 : 607-86-03758  |  통신판매업 : 제 2021-부산해운대-0685호</span>
          <span className="address">대표 : 김대웅 | 개인정보책임자 : 강솔 | 부산 해운대구 센텀1로 28 (우동, WBC더팔레스) 101동 702호</span>
          <span className="copy-right">COPYRIGHT(C) Ambience Corp. ALL RIGHTS RESERVED</span>
        </div>
      </SOL.FooterWrapper>
    </SOL.FooterContainer>
  )
};

export default Footer;