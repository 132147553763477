import React, { FC, useEffect, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { getExtraAddressList, insertExtraAddress, updateExtraAddress, updateInactiveExtraAddress } from "../../Axios";
import DaumPostcode from 'react-daum-postcode';
import IconClose from "../../assets/icons/icon_close_black.svg";
import IconMapMarker from "../../assets/icons/map_marker.svg";
import IconBack from "../../assets/icons/icon_only_arrow.svg";
import { removeWhitespacePhone, validatePhone } from "../../modules/Validation";
import ModalClose from "./ModalClose";
import ModalYesClose from "./ModalYesClose";

interface IExtraAddressList {
    customer_ID_use_extra_address: number;
    extra_address_ID: number;
    extra_address_big_area: string;
    extra_address_detail_address: string;
    extra_address_modified: string;
    extra_address_name: string;
    extra_address_customer_name: string;
    extra_address_note: string;
    extra_address_phone_number: string;
    extra_address_registe: string;
    extra_address_road_address: string;
    extra_address_small_area: string;
    extra_address_status: number;
    extra_address_zip_code: string;
}

interface IModalAddressBook {
  isModalOpen: boolean;
  handleCloseAddressBook: () => void;
  handleSelectAddress: (address: any) => void;
}

const ModalAddressBook: FC<IModalAddressBook> = ({
  isModalOpen,
  handleCloseAddressBook,
  handleSelectAddress,
}) => {
  const [mainAddress, setMainAddress] = useState({
    customer_ID: 0,
    customer_name: '',
    customer_phone_number: '',
    customer_zip_code: '',
    customer_road_address: '',
    customer_detail_address: '',
    customer_big_area: '',
    customer_small_area: '',
  });
  const [extraAddressList, setExtraAddressList] = useState<IExtraAddressList[]>([]);
  const [addressBookStatus, setAddressBookStatus] = useState<number>(0); // 1. 등록, 2. 수정
  const [modalTitle, setModalTitle] = useState<string>('배송지 목록');
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  
  const [updateExtraAddressID, setUpdateExtraAddressID] = useState<number>(0);
  const [extraAddressName, setExtraAddressName] = useState<string>('');
  const [extraAddressCustomerName, setExtraAddressCustomerName] = useState<string>('');
  const [extraAddressPhoneNumber, setExtraAddressPhoneNumber] = useState<string>('');
  const [extraAddressZipCode, setExtraAddressZipCode] = useState<string>('');
  const [extraAddressRoadAddress, setExtraAdRoadAddress] = useState<string>('');
  const [extraAddressDetailAddress, setExtraAddressDetailAddress] = useState<string>('');
  const [extraAddressBigArea, setExtraAddressBigArea] = useState<string>('');
  const [extraAddressSmallArea, setExtraAddressSmallArea] = useState<string>('');
  const [inputErrors, setInputErrors] = useState({
    address_name: '',
    customer_name: '',
    phone_number: '',
    address: '',
    detail_address: '',
  });

  const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState({text: ''});
  const [isModalYesCloseOpen, setIsModalYesCloseOpen] = useState<boolean>(false);
	const [modalYesCloseContent, setModalYesCloseContent] = useState({
    text: '',
    confirmText: '',
    onConfirm: () => {}
  });

  const handleAddress = () => {
    getExtraAddressList().then(result => {
      setMainAddress(result.main_address[0]);
      setExtraAddressList(result.extra_address);
    });
  };

  useEffect(() => {
    handleAddress();
  }, []);

  const handleComplete = (data: any) => {
    setExtraAddressZipCode(data.zonecode);
    setExtraAdRoadAddress(data.roadAddress === '' ? data.autoRoadAddress : data.roadAddress);
    setExtraAddressDetailAddress(data.buildingName);
    setExtraAddressBigArea(data.sido);
    setExtraAddressSmallArea(data.sigungu);
    setIsAddressModalOpen(false);
  };

  useEffect(() => {
    if (addressBookStatus === 1) {
      setModalTitle('배송지 등록');
    } else if (addressBookStatus === 2) {
      setModalTitle('배송지 수정');
    } else {
      setModalTitle('배송지 목록');
      setUpdateExtraAddressID(0);
      setExtraAddressName('');
      setExtraAddressCustomerName('');
      setExtraAddressPhoneNumber('');
      setExtraAddressZipCode('');
      setExtraAdRoadAddress('');
      setExtraAddressDetailAddress('');
      setExtraAddressBigArea('');
      setExtraAddressSmallArea('');
    }
    setIsAddressModalOpen(false);
  }, [addressBookStatus]);

  const addAddress = () => {
    if (validateAddress()) {
      insertExtraAddress(
        mainAddress.customer_ID,
        extraAddressName,
        extraAddressCustomerName,
        extraAddressPhoneNumber,
        extraAddressZipCode,
        extraAddressRoadAddress,
        extraAddressDetailAddress,
        extraAddressBigArea,
        extraAddressSmallArea,
      ).then(result => {
        if (result === "error_alc") {
          setIsModalCloseOpen(true);
          setModalContent({
            text: '알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.'
          });
        } else if (result.serverStatus === 2) {
          setIsModalCloseOpen(true);
          setModalContent({
            text: '새로운 주소가 등록되었습니다.',
          });
          handleAddress();
          setAddressBookStatus(0);
        }
      });
    }
  };

  const updateAddress = () => {
    if (validateAddress()) {
      updateExtraAddress(
        updateExtraAddressID,
        extraAddressName,
        extraAddressCustomerName,
        extraAddressPhoneNumber,
        extraAddressZipCode,
        extraAddressRoadAddress,
        extraAddressDetailAddress,
        extraAddressBigArea,
        extraAddressSmallArea,
      ).then(result => {
        if (result.changedRows === 1) {
          setIsModalCloseOpen(true);
          setModalContent({
            text: '주소가 수정되었습니다.',
          });
          handleAddress();
          setAddressBookStatus(0);
        } else {
          setIsModalCloseOpen(true);
          setModalContent({
            text: '알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.'
          });
          handleAddress();
          setAddressBookStatus(0);
        }
      })
    }
  };

  const validateAddress = () => {
    let validation = true;
    let error_text = {...inputErrors};
    if (extraAddressName.trim() === '') {
      validation = false;
      error_text.address_name = '주소명을 입력해 주세요.';
    } else {
      error_text.address_name = '';
    }
    if (extraAddressCustomerName.trim() === '') {
      validation = false;
      error_text.customer_name = '이름을 입력해 주세요.';
    } else {
      error_text.customer_name = '';
    }
    if (!validatePhone(extraAddressPhoneNumber)) {
      validation = false;
      error_text.phone_number = '연락처를 입력해 주세요.';
    } else {
      error_text.phone_number = '';
    }
    if (extraAddressZipCode === '') {
      validation = false;
      error_text.address = '주소를 입력해 주세요.';
    } else {
      error_text.address = ''
    }
    if (extraAddressRoadAddress.trim() === '') {
      validation = false;
      error_text.address = '주소를 입력해 주세요.';
    } else {
      error_text.address = ''
    }
    if (extraAddressDetailAddress.trim() === '') {
      validation = false;
      error_text.detail_address = '상세주소를 입력해 주세요.';
    } else {
      error_text.detail_address = '';
    }
    setInputErrors(error_text);
    return validation;
  };

  return (
    <>
      <ModalClose
        isOpen={isModalCloseOpen} 
        onClose={() => setIsModalCloseOpen(false)}
        text={modalContent.text}
      />
      <ModalYesClose
        isOpen={isModalYesCloseOpen} 
				onClose={() => setIsModalYesCloseOpen(false)}
				onConfirm={modalYesCloseContent.onConfirm}
				confirmText={modalYesCloseContent.confirmText}
				text={modalYesCloseContent.text}
      />
      {isModalOpen && (
        <SOL.ModalBackground>
          <SOL.ModalAddressBook>
            <div className="title-box">
              <div>
                {modalTitle}
              </div>
              {addressBookStatus === 0 ? (
                <button className="title-btn" onClick={handleCloseAddressBook}>
                  <img src={IconClose} alt="close-btn" />
                </button>
              ) : (
                <button className="title-btn" onClick={() => setAddressBookStatus(0)}>
                  <img src={IconBack} alt="back-btn" />
                </button>
              )}
            </div>
            {addressBookStatus === 0 && (
              <div className="modal-wrapper">
                <button
                  className="add-address"
                  onClick={() => {
                    if (extraAddressList.length >= 20) {
                      setIsModalCloseOpen(true);
                      setModalContent({
                        text: '배송지 목록은 최대 20개까지 등록할 수 있습니다.',
                      });
                    } else {
                      setAddressBookStatus(1);
                    }
                  }}
                >
                  <img src={IconMapMarker} alt="map_marker_icon" />
                  <span>새로운 배송지 추가</span>
                </button>
                {extraAddressList.map((tmp, idx) => 
                  <div
                    key={idx}
                    className={`address-item`}
                  >
                    <div className="address-name">
                      {tmp.extra_address_name}
                    </div>
                    <div className="address-content">
                      <div className="customer_name">{tmp.extra_address_customer_name}</div>
                      <div className="phone-number">{tmp.extra_address_phone_number}</div>
                      <span>({tmp.extra_address_zip_code}) </span>
                      <span className="road-address">{tmp.extra_address_road_address}</span>
                      <span className="detail-address">{tmp.extra_address_detail_address}</span>
                    </div>
                    <div className="address-control-box">
                      <button
                        onClick={() => {
                          setAddressBookStatus(2);
                          setUpdateExtraAddressID(tmp.extra_address_ID);
                          setExtraAddressName(tmp.extra_address_name);
                          setExtraAddressCustomerName(tmp.extra_address_customer_name);
                          setExtraAddressPhoneNumber(tmp.extra_address_phone_number);
                          setExtraAddressZipCode(tmp.extra_address_zip_code);
                          setExtraAdRoadAddress(tmp.extra_address_road_address);
                          setExtraAddressDetailAddress(tmp.extra_address_detail_address);
                          setExtraAddressBigArea(tmp.extra_address_big_area);
                          setExtraAddressSmallArea(tmp.extra_address_small_area);
                        }}
                      >
                        수정
                      </button>
                      <button
                        onClick={() => {
                          setIsModalYesCloseOpen(true);
                          setModalYesCloseContent({
                            text: "배송지를 삭제하시겠습니까?",
                            confirmText: "확인",
                            onConfirm: () => {
                              updateInactiveExtraAddress(
                                tmp.extra_address_ID,
                              ).then(result => {
                                setIsModalYesCloseOpen(false);
                                if (result.serverStatus === 2 || result.affectedRows === 1) {
                                  setIsModalCloseOpen(true);
                                  setModalContent({
                                    text: "주소가 삭제되었습니다."
                                  });
                                  handleAddress();
                                } else {
                                  setIsModalCloseOpen(true);
                                  setModalContent({
                                    text: "알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다."
                                  });
                                  handleAddress();
                                }
                              })
                            }
                          })
                        }}
                      >
                        삭제
                      </button>
                      <button
                        className="select-btn"
                        onClick={() => {
                          handleSelectAddress(tmp);
                        }}
                      >
                        선택
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {addressBookStatus > 0 && (
              <div className="modal-wrapper">
                <SOL.ModalAddAddress>
                  <div className="notice">
                    입력한 정보를 확인 후 저장해 주세요.
                  </div>
                  <div className="modal-address-input">
                    <div className="label">배송지명</div>
                    <input
                      type="text"
                      value={extraAddressName}
                      placeholder="예) 앰비언스 사무실"
                      maxLength={20}
                      onChange={(e) => {
                        setExtraAddressName(e.target.value);
                      }}
                    />
                    <p className="input-error">{inputErrors.address_name}</p>
                  </div>
                  <div className="modal-address-input">
                    <div className="label">이름</div>
                    <input
                      type="text"
                      value={extraAddressCustomerName}
                      placeholder="이름"
                      maxLength={10}
                      onChange={(e) => {
                        setExtraAddressCustomerName(e.target.value);
                      }}
                    />
                    <p className="input-error">{inputErrors.customer_name}</p>
                  </div>
                  <div className="modal-address-input">
                    <div className="label">휴대전화</div>
                    <input
                      type="text"
                      value={extraAddressPhoneNumber}
                      maxLength={13}
                      onChange={(e: any) => {
                        setExtraAddressPhoneNumber(removeWhitespacePhone(e.target.value));
                      }}
                    />
                    <p className="input-error">{inputErrors.phone_number}</p>
                  </div>
                  <div className="modal-address-input">
                    <div className="label">주소</div>
                    <input
                      type="text" className="short"
                      maxLength={5}
                      value={extraAddressZipCode}
                      readOnly
                    />
                    <button
                      className="postal-code"
                      onClick={() => {
                        if (isAddressModalOpen) {
                          setIsAddressModalOpen(false);
                        } else {
                          setIsAddressModalOpen(true);
                        }
                      }}
                    >
                      {isAddressModalOpen ? "닫기" : "우편번호"}
                    </button>
                  </div>
                  {isAddressModalOpen && (
                    <DaumPostcode onComplete={handleComplete} autoClose={false} />
                  )}
                  <div className="modal-address-input">
                    <input
                      type="text"
                      maxLength={40}
                      value={extraAddressRoadAddress}
                      readOnly
                    />
                  </div>
                  <div className="modal-address-input">
                    <input
                      type="text"
                      maxLength={40}
                      value={extraAddressDetailAddress}
                      onChange={(e) => {
                        setExtraAddressDetailAddress(e.target.value);
                      }}
                    />
                    <p className="input-error">
                      {inputErrors.address !== '' ? inputErrors.address : inputErrors.detail_address}
                    </p>
                  </div>
                  <button
                    className="add-btn"
                    onClick={() => {
                      if (addressBookStatus === 1) {
                        addAddress();
                      } else if (addressBookStatus === 2) {
                        updateAddress();
                      }
                    }}
                  >
                    {addressBookStatus === 1 && "등록하기"}
                    {addressBookStatus === 2 && "수정하기"}
                  </button>
                </SOL.ModalAddAddress>
              </div>
            )}
          </SOL.ModalAddressBook>
        </SOL.ModalBackground>
      )}
    </>
  )
};

export default ModalAddressBook;