import React, { FC, useEffect, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import RollingSwiper from '../../layout/Swiper/RollingSwiper';
import MobileNav from '../../layout/Header/MobileNav';
import BrandGallery from '../../layout/Swiper/BrandGallery';
import MainTopBanner from '../../layout/Banner/MainTopBanner';
import MainPromotion from '../../layout/Promotion/MainPromotion';
import MainQuickMenu from '../../layout/Shared/MainQuickMenu';
import { getSessionData, getAlcB2cBannerActiveList, getAlcB2bBannerActiveList } from "../../Axios";
import useCheckSession from '../../hooks/useCheckSession';
import { IAlcBannerData } from "../../modules/model";
import Modal from '../../layout/Promotion/Modal';

interface IpromotionProps {
	headerBanner: IAlcBannerData;
	mainBanner: IAlcBannerData;
	subBanner: IAlcBannerData;
	eventBanner: IAlcBannerData;
	popup: IAlcBannerData;
}

const HomePage: FC = () => {
	useCheckSession();
	const [MainPagePromotionData, setMainPagePromotionData] = useState<IpromotionProps | null>(null);
	const [customerAlcStatus, setCustomerAlcStatus] = useState(2);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		getSessionData().then((result) => {
			setCustomerAlcStatus(result.alc_status);
		});
	}, []);

	useEffect(() => {
		if (customerAlcStatus === 2) return;
		if (customerAlcStatus === 0 || customerAlcStatus === 4) {
			getAlcB2cBannerActiveList().then((data) => {
				setMainPagePromotionData({
					headerBanner: data.header_banner_data,
					mainBanner: data.main_banner_data,
					subBanner: data.sub_banner_data,
					eventBanner: data.event_banner_data,
					popup: data.popup_banner_data,
				});
			});
		} else if (customerAlcStatus === 1) {
			getAlcB2bBannerActiveList().then((data) => {
				setMainPagePromotionData({
					headerBanner: data.header_banner_data,
					mainBanner: data.main_banner_data,
					subBanner: data.sub_banner_data,
					eventBanner: data.event_banner_data,
					popup: data.popup_banner_data,
				});
			});
		} 
	}, [customerAlcStatus]);

	useEffect(() => {
		const dontShowUntil = localStorage.getItem('dontShowModal');
		if (dontShowUntil && new Date(dontShowUntil) > new Date()) {
			setIsModalOpen(false);
		}else {
			setIsModalOpen(true);
		}
	}, []);

	const closeModal = () => setIsModalOpen(false);

	const handleDontShowForDays = (days: number) => {
		const dontShowUntil = new Date();
		dontShowUntil.setDate(dontShowUntil.getDate() + days);
		localStorage.setItem('dontShowModal', dontShowUntil.toISOString());
		closeModal();
	}

	return (
		<PageWrapper title={'홈'}>
			{MainPagePromotionData && (
        		<MainTopBanner MainPagePromotionData={MainPagePromotionData} />
      		)}

			{MainPagePromotionData && (
				<Modal 
				isOpen={isModalOpen} 
				onClose={closeModal} 
				onDontShowForDays={handleDontShowForDays}
				MainPagePromotionData={MainPagePromotionData}/>
			)}
			<RollingSwiper/>
			<MainQuickMenu/>
			{MainPagePromotionData && (
        		<MainPromotion MainPagePromotionData={MainPagePromotionData} />
      		)}
			<BrandGallery />
			<MobileNav />
		</PageWrapper>
	);
};
HomePage.displayName = 'HomePage';
export default HomePage;
