import React, { FC } from "react";
import * as H from "../../styles/han/StyledComponentHan";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import { useLocation, useNavigate } from "react-router-dom";

import mo_splash01 from "../../images/homeniqSplash/mo_splash01.png"
import mo_splash02 from "../../images/homeniqSplash/mo_splash02.png"
import mo_splash03 from "../../images/homeniqSplash/mo_splash03.png"
import mo_splash05 from "../../images/homeniqSplash/mo_splash05.png"

import pc_splash01 from "../../images/homeniqSplash/pc_splash01.png"
import pc_splash02 from "../../images/homeniqSplash/pc_splash02.png"
import pc_splash03 from "../../images/homeniqSplash/pc_splash03.png"
import pc_splash05 from "../../images/homeniqSplash/pc_splash05.png"



const HomeniqPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const code = location.state?.code || '';

  return (
    <PageWrapper title={'Welcome A.Black'}>
      <H.SplashWrap>
        <div> {/* 01 메인 */}
          <img src={mo_splash01} alt="홈닉 공식지정 에이블랙" className="SplashView_mo" />
          <img src={pc_splash01} alt="홈닉 공식지정 에이블랙" className="SplashView_pc" />
        </div>
        <div> {/* 02 브랜드 로고 */}
          <img src={mo_splash02} alt="에이블랙 취급 브랜드" className="SplashView_mo" />
          <img src={pc_splash02} alt="에이블랙 취급 브랜드" className="SplashView_pc" />
        </div>
        <div> {/* 03 에이블랙 소개 */}
          <img src={mo_splash03} alt="에이블랙 소개" className="SplashView_mo" />
          <img src={pc_splash03} alt="에이블랙 소개" className="SplashView_pc" />
        </div>
        <div className="ablack_movBox"> {/* 04 에이블랙 영상 */}
          <div>
            <iframe
              width="100%;" height="100%"
              src="https://www.youtube.com/embed/klI325eeNuE?si=6UJTXbZPRZWUkoVm"
              title="Ablack Movie" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share">
            </iframe>
          </div>
        </div>
        <div> {/* 05 특별혜택 */}
          <img src={mo_splash05} alt="에이블랙 스토어 특별혜택" className="SplashView_mo" />
          <img src={pc_splash05} alt="에이블랙 스토어 특별혜택" className="SplashView_pc" />
        </div>



        <button
          onClick={() => {
            navigate(`/?code=${code}&login_status=1`);
          }}
        >A.Black Store 로그인 하기</button>
      </H.SplashWrap>
    </PageWrapper>
  )
};

export default HomeniqPage;