import React, { FC, useEffect, useState } from "react";
import { useOrderContext } from "../../context/OrderContext";
import ModalClose from "../Modal/ModalClose";
import * as SOL from "../../styles/solda/StyledComponentSol";
import IconArrowUp from "../../assets/icons/arrow_fold_40.svg";
import IconCheckboxBlack from "../../assets/icons/check_black.svg";
import IconCheckboxGrey from "../../assets/icons/check_grey.svg";
import IconMapMarker from "../../assets/icons/map_marker.svg";
import ModalAddressBook from "../Modal/ModalAddressBook";
import { getAmbienceAddress } from "../../statusUtils";
import { ICustomerData } from "../../modules/model";

interface IOfferShippingProps {
  customerData: ICustomerData;
  respectiveShippingMemo: boolean;
  handleRespectiveShippingMemo: () => void;
}

const OfferShipping: FC<IOfferShippingProps> = ({ customerData, respectiveShippingMemo, handleRespectiveShippingMemo }) => {
  const {
    shippingInfo,
    setShippingInfo,
    inputErrors,
    setInputErrors,
    senderShippingInfo,
    setSenderShippingInfo,
    resetSenderShippingInfo ,
  } = useOrderContext();
  const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
  const [modalContent] = useState({text: ''});
  const [toggleReceiverModalAddressBook, setToggleReceiverModalAddressBook] = useState<boolean>(false);
  const [toggleReceiverShippingInfo, setToggleReceiverShippingInfo] = useState<boolean>(true);
  
  // 보내는 배송지 state
  const [sendDivision, setSendDivision] = useState<number>(0);
  const [previousDivision, setPreviousDivision] = useState<number>(0);
  
  const [toggleSenderShippingInfo, setToggleSenderShippingInfo] = useState<boolean>(true);
  const [senderInputErrors, setSenderInputErrors] = useState({
    phone_number: '',
    zip_code: '',
    road_address: '',
    detail_address: '',
    big_area: '',
    small_area: '',
  });
  const [toggleSenderrModalAddressBook, setToggleSenderrModalAddressBook] = useState<boolean>(false);

  const handleShippingInfo = (address: any) => {
    setShippingInfo({
        ...shippingInfo,
        name: address.extra_address_customer_name,
        addressName: address.extra_address_name,
        postalCode: address.extra_address_zip_code,
        address: address.extra_address_road_address,
        addressDetails: address.extra_address_detail_address,
        phoneNumber: address.extra_address_phone_number,
        bigArea: address.extra_address_big_area,
        smallArea: address.extra_address_small_area,
    });
  };

  const handleSenderShippingInfo = (address: any) => {
    setSenderShippingInfo({
      ...senderShippingInfo,
      extra_address_ID: address.extra_address_ID,
      alc_order_sender_name: address.extra_address_customer_name,
      alc_order_sender_phone_number: address.extra_address_phone_number,
      alc_order_sender_address_name: address.extra_address_name,
      alc_order_sender_zip_code: address.extra_address_zip_code,
      alc_order_sender_road_address: address.extra_address_road_address,
      alc_order_sender_detail_address: address.extra_address_detail_address,
      alc_order_sender_big_area: address.extra_address_big_area,
      alc_order_sender_small_area: address.extra_address_small_area,
    });
  };

  const getAccountAddress = () => {
    setSenderShippingInfo({
        ...senderShippingInfo,
        extra_address_ID: 0,
        alc_order_sender_name: customerData.account_name,
        alc_order_sender_address_name: customerData.account_name,
        alc_order_sender_phone_number: customerData.customer_phone_number,
        alc_order_sender_zip_code: customerData.customer_zip_code,
        alc_order_sender_road_address: customerData.customer_road_address,
        alc_order_sender_detail_address: customerData.customer_detail_address,
        alc_order_sender_big_area: customerData.customer_big_area,
        alc_order_sender_small_area: customerData.customer_small_area,
    });
  };

  useEffect(() => {
    if (!sendDivision) return;
    if (sendDivision === 1) {
      resetSenderShippingInfo();
      setSenderShippingInfo(getAmbienceAddress);
    } else if (sendDivision === 2) {
      getAccountAddress();
    } else if (sendDivision === 3) {
      resetSenderShippingInfo();
      setToggleSenderrModalAddressBook(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendDivision]);

  return (
    <>
      {toggleReceiverModalAddressBook && (
        <ModalAddressBook
          isModalOpen={toggleReceiverModalAddressBook}
          handleCloseAddressBook={() => setToggleReceiverModalAddressBook(false)}
          handleSelectAddress={(address) => {
            handleShippingInfo(address);
            setToggleReceiverModalAddressBook(false);
          }}
        />
      )}
      {toggleSenderrModalAddressBook && (
        <ModalAddressBook
          isModalOpen={toggleSenderrModalAddressBook}
          handleCloseAddressBook={() => {
            if (!senderShippingInfo.extra_address_ID) {
              setSendDivision(previousDivision);
            }
            setToggleSenderrModalAddressBook(false);
          }}
          handleSelectAddress={(address) => {
            handleSenderShippingInfo(address);
            setToggleSenderrModalAddressBook(false);
          }}
        />
      )}
      <ModalClose
        isOpen={isModalCloseOpen} 
        onClose={() => setIsModalCloseOpen(false)}
        text={modalContent.text}
      />
      <SOL.OrderShippingContainer>
        <section className="shipping-info-section">
          <div>
            <SOL.OrderSectionTitle onClick={() => setToggleSenderShippingInfo(!toggleSenderShippingInfo)}>
              <span>보내는 배송지 정보</span>
              <img className={`${toggleSenderShippingInfo || "rotate-180"}`} src={IconArrowUp} alt="arrow_icon" />
            </SOL.OrderSectionTitle>
            <>
              {toggleSenderShippingInfo && (
                <SOL.OrderPromotionContainer>
                  <div className="send-shipping-container">
                    <div className="select-send-division">
                      <button
                        className="check-item"
                        onClick={() => {
                          setSendDivision(1);
                          setPreviousDivision(1);
                        }}
                      >
                        {sendDivision === 1 ? <img src={IconCheckboxBlack} alt='checkbox-icon' /> : <img src={IconCheckboxGrey} alt='checkbox-icon' />}
                        <span>앰비언스</span>
                      </button>
                      <button
                        className="check-item"
                        onClick={() => {
                          setSendDivision(2);
                          setPreviousDivision(2);
                        }}
                      >
                        {sendDivision === 2 ? <img src={IconCheckboxBlack} alt='checkbox-icon' /> : <img src={IconCheckboxGrey} alt='checkbox-icon' />}
                        <span>본인업체</span>
                      </button>
                      <button
                        className="check-item"
                        onClick={() => {
                          setSendDivision(3);
                        }}
                      >
                        {sendDivision === 3 ? <img src={IconCheckboxBlack} alt='checkbox-icon' /> : <img src={IconCheckboxGrey} alt='checkbox-icon' />}
                        <span>신규주소</span>
                      </button>
                    </div>
                    <div className="order-inline-input">
                      <label>보내는 이</label>
                      <div className='input-box'>
                        <input
                            className={`order-full-width`}
                            type="text"
                            maxLength={15}
                            value={senderShippingInfo.alc_order_sender_name}
                            readOnly
                            onChange={(e) => {
                                setSenderShippingInfo({
                                    ...senderShippingInfo,
                                    alc_order_sender_name: e.target.value,
                                });
                            }}
                        />
                      </div>
                    </div>
                    <div className="order-inline-input">
                      <label>휴대전화</label>
                      <div className='input-box'>
                        <input 
                            className={`order-full-width ${senderInputErrors.phone_number ? 'input-error' : ''}`}
                            type="text"
                            value={senderShippingInfo.alc_order_sender_phone_number}
                            readOnly
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const newPhoneNumber = e.target.value.replace(/\D/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3').substring(0, 13);
                              if (!newPhoneNumber || !/\d{3}-\d{3,4}-\d{4}/.test(newPhoneNumber)) {
                                setSenderInputErrors(prev => ({ ...prev, phone_number: '유효한 휴대폰 번호를 입력해주세요.' }));
                              } else {
                                setSenderInputErrors(prev => ({ ...prev, phone_number: '' }));
                              };
                              setSenderShippingInfo({
                                ...senderShippingInfo,
                                alc_order_sender_phone_number: newPhoneNumber,
                              });
                            }}
                        />
                      </div>
                    </div>
                    <div className="order-inline-input connected">
                      <label>주소</label>
                      <div className='input-box'>
                        <input
                          className={`order-full-width post-code ${senderInputErrors.zip_code ? 'input-error' : ''}`}
                          type="text"
                          value={senderShippingInfo.alc_order_sender_zip_code}
                          readOnly
                        />
                        <button className="btn-address-search">
                          우편번호
                        </button>
                      </div>
                    </div>
                    <div className="order-inline-input connected">
                      <div className='input-box'>
                        <input 
                          type="text" 
                          className={`order-full-width address ${senderInputErrors.road_address ? 'input-error' : ''}`}
                          value={senderShippingInfo.alc_order_sender_road_address}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="order-inline-input">
                      <div className='input-box'>
                        <input 
                          type="text" 
                          className="order-full-width address"
                          value={senderShippingInfo.alc_order_sender_detail_address}
                          readOnly
                          onChange={(e) => {
                            setSenderShippingInfo({
                                ...senderShippingInfo,
                                alc_order_sender_detail_address: e.target.value,
                            });
                          }} 
                          maxLength={150}
                        />
                      </div>
                    </div>
                  </div>
                </SOL.OrderPromotionContainer>
              )}
            </>

            <SOL.HorizontalDivider />

            <SOL.OrderSectionTitle onClick={() => setToggleReceiverShippingInfo(!toggleReceiverShippingInfo)}>
              <span>받는 배송지 정보</span>
              <img className={`${toggleReceiverShippingInfo || "rotate-180"}`} src={IconArrowUp} alt='arrow_icon' />
            </SOL.OrderSectionTitle>
            <>
              {toggleReceiverShippingInfo && (
                <SOL.OrderPromotionContainer>
                  <div className="send-shipping-container">
                    <div className="order-inline-input">
                      <div className="flex-line">
                        <label>받는이</label>
                        <button
                          className="change-address"
                          onClick={() => setToggleReceiverModalAddressBook(true)}
                        >
                          <img src={IconMapMarker} alt="map_marker_icon" />
                          배송지 추가 및 변경
                        </button>
                      </div>
                      <div className="input-box">
                        <input
                          className="order-full-width"
                          type="text"
                          maxLength={15}
                          value={shippingInfo.name}
                          readOnly
                          onChange={(e) => {
                            setShippingInfo({
                              ...shippingInfo,
                              name: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="order-inline-input">
                      <label>휴대전화</label>
                      <div className='input-box'>
                        <input 
                          className={`order-full-width ${inputErrors.phoneNumber ? 'input-error' : ''}`}
                          type="text"
                          value={shippingInfo.phoneNumber}
                          readOnly
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const newPhoneNumber = e.target.value.replace(/\D/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3').substring(0, 13);
                              if (!newPhoneNumber || !/\d{3}-\d{3,4}-\d{4}/.test(newPhoneNumber)) {
                                  setInputErrors(prev => ({ ...prev, phoneNumber: '유효한 휴대폰 번호를 입력해주세요.' }));
                              } else {
                                  setInputErrors(prev => ({ ...prev, phoneNumber: '' }));
                              };
                              setShippingInfo({
                                  ...shippingInfo,
                                  phoneNumber: newPhoneNumber,
                              });
                          }}
                        />
                      </div>
                    </div>
                    <div className="order-inline-input connected">
                      <label>주소</label>
                      <div className='input-box'>
                        <input
                            className={`order-full-width post-code`}
                            type="text"
                            value={shippingInfo.postalCode}
                            readOnly
                        />
                        <button className="btn-address-search">
                          우편번호
                        </button>
                      </div>
                    </div>
                    <div className="order-inline-input connected">
                      <div className='input-box'>
                        <input 
                          type="text" 
                          className={`order-full-width address`}
                          value={shippingInfo.address}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="order-inline-input">
                      <div className='input-box'>
                        <input 
                          type="text" 
                          className="order-full-width address"
                          value={shippingInfo.addressDetails}
                          readOnly
                          onChange={(e) => {
                              setShippingInfo({
                                  ...shippingInfo,
                                  addressDetails: e.target.value,
                              });
                          }} 
                          maxLength={150}
                        />
                          {/* {inputErrors.address && <div className="error-message">{inputErrors.address}</div>} */}
                      </div>
                    </div>
                    <div className='select-send-division' style={{margin: "30px 0 10px"}}>
                      <button className='check-item' onClick={handleRespectiveShippingMemo}>
                        {respectiveShippingMemo ? <img src={IconCheckboxBlack} alt='checkbox-icon' /> : <img src={IconCheckboxGrey} alt='checkbox-icon' /> }
                        <span>배송메모 개별 입력</span>
                      </button>
                    </div>
                    <div className="order-inline-input" style={{marginTop: 0}}>
                      {/* <label>배송 요청사항</label> */}
                      <div className='input-box'>
                        <input
                          className={`order-full-width`}
                          type='text'
                          value={shippingInfo.requestDetails}
                          maxLength={500}
                          placeholder='배송지 변경, 기타 배송 메모를 작성해 주세요.'
                          onChange={(e) => {
                            setShippingInfo({ ...shippingInfo, requestDetails: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </SOL.OrderPromotionContainer>
              )}
            </>
          </div>
          {/* <h2 className="section-title">배송정보</h2>
          <div className="order-inline-input">
            <label htmlFor="addressName">배송지명</label>
            <div className='input-box'>
              <input
                className={`order-full-width ${inputErrors.addressName ? 'input-error' : ''}`} 
                id="addressName"
                type="text"
                maxLength={30} 
                value={shippingInfo.addressName}
                onChange={(e) => setShippingInfo({ ...shippingInfo, addressName: e.target.value })}
              />
              {inputErrors.addressName && <div className="error-message">{inputErrors.addressName}</div>}
            </div>
          </div>
          <div className="address-group">
            <div className="order-inline-input connected">
              <label htmlFor="postalCode">우편번호</label>
              <div className='input-box'>
                <input
                  className={`order-full-width post-code ${inputErrors.postalCode ? 'input-error' : ''}`}
                  id="postalCode"
                  type="text"
                  value={shippingInfo.postalCode}
                  readOnly
                />
                <button className="btn-address-search" onClick={() => setIsAddressModalOpen(!isAddressModalOpen)}>
                  {isAddressModalOpen === false ? '주소 찾기' : '주소 닫기'}
                </button>
              </div>
            </div>
            {isAddressModalOpen && (
              <div className="address-modal">
                <DaumPostcode onComplete={handleComplete} autoClose={false} />
              </div>
            )}
            <div className="order-inline-input connected">
              <label>주소</label>
              <div className='input-box'>
                <input 
                  type="text" 
                  className={`order-full-width address ${inputErrors.address ? 'input-error' : ''}`}
                  value={shippingInfo.address} 
                  readOnly
                />
              </div>
            </div>
            <div className="order-inline-input">
              <label>상세주소</label>
              <div className='input-box'>
                <input
                  type="text" 
                  className="order-full-width address"
                  value={shippingInfo.addressDetails}
                  onChange={(e) => setShippingInfo({ ...shippingInfo, addressDetails: e.target.value })} 
                  maxLength={150}
                />
                {inputErrors.address && <div className="error-message">{inputErrors.address}</div>}
              </div>
            </div>
            <div className="order-inline-input" style={{height: "fit-content"}}>
              <label htmlFor="requestDetails">요청사항</label>
              <div className='input-box' style={{display: "block"}}>
                <textarea
                  id="requestDetails"
                  className="order-full-width"
                  placeholder="배송 시 요청사항을 입력해주세요 (500자 이내)"
                  value={shippingInfo.requestDetails || ''}
                  onChange={handleRequestChange}
                  maxLength={500}
                ></textarea>
                <div className="char-counter">{(shippingInfo.requestDetails || '').length}/500</div>
              </div>
            </div>
          </div> */}
        </section>
     </SOL.OrderShippingContainer>
     <SOL.HorizontalDivider />
    </>
  );
};

export default OfferShipping;