const theme = {
  screens: {
    xs: "screen and (max-width : 500px)",
    sm: "screen and (max-width : 769px)",
    md: "screen and (max-width : 900px)",
    lg: "screen and (max-width : 1024px)",
    xl: "screen and (max-width : 1200px)",
  },
  colors :{
    main_white: '#FFFFFF',
    white_light_grey: '#EDEDED',
    light_grey: '#D8D8D8',
    light_mid_grey: '#BDBDBD',
    mid_grey: '#9E9E9E',
    dark_grey: '#7D7D7D',
    black_dark_grey: '#414141',
    main_black: '#101010',
    point_red: '#D94E49',
    point_blue: '#3A51C9',
    point_yellow: '#DB8712',
    product_list_bg: '#F3F3F6',
    opacityWhite: 'rgba(255,255,255,0.3)',
    opacityBlack: 'rgba(0,0,0,0.8)',
    opacityBlack2: 'rgba(0,0,0,0.2)'
  },
  typography: {
    large_30_B: `font-size: 30px; font-weight: 700;`,
    title_24_B: `font-size: 24px; font-weight: 700;`,
    title_20_SB: `font-size: 20px; font-weight: 500;`,
    text_16_M: `font-size: 16px; font-weight: 500;`,
    text_14_M: `font-size: 14px; font-weight: 500;`,
    text_14_R: `font-size: 14px; font-weight: 400;`,
    caption_12_R: `font-size: 12px; font-weight: 400;`,
    caption_10_R: `font-size: 10px; font-weight: 400;`,
    pretendard: `font-family: 'Pretendard';`
  },
  zAxis: {
    items: 972,
  },
  utility: {
    
  },
  common: {
    header_height: "100px",
  }
}

export default theme;