import React, { useState } from 'react'
import * as DABIN from "../../styles/dabin/StyledComponentDabin";
import { MdClose } from "react-icons/md";
import { IAlcBannerData } from "../../modules/model";
import { baseUrl } from '../../Axios';

const Modal = ({
  isOpen,
  onClose,
  onDontShowForDays,
  MainPagePromotionData,
}:{
  isOpen: boolean;
  onClose: () => void;
  onDontShowForDays: (days: number) => void;
  MainPagePromotionData: {
    headerBanner: IAlcBannerData;
    mainBanner: IAlcBannerData;
    subBanner: IAlcBannerData;
    eventBanner: IAlcBannerData;
    popup: IAlcBannerData;
  }
}) => {
  const [isMoved, setIsMoved] = useState(false);

  const handleClose = () => {
    setIsMoved(true); 
    setTimeout(() => {
      onClose();
      setIsMoved(false); 
    }, 500); 
  };

  const handleDontShowForDays = (days: number) => {
    setIsMoved(true);
    setTimeout(() => {
      onDontShowForDays(days); 
      onClose(); 
      setIsMoved(false); 
    }, 500); 
  };

  const handleClick = () => {
    window.location.href =`${MainPagePromotionData.popup.alc_banner_link_url}`;
  }


  if (!isOpen) return null;

  return (
    <>
      <DABIN.ModalContainer>
        <div className="Modal_background"></div>
        <div 
          className={`Modal ${isMoved ? 'move' : ''}`}>
          <div className="Modal_img">
            <img 
              src={`${baseUrl}${MainPagePromotionData.popup.alc_banner_img}`} 
              alt="PopupImage"
              onClick={handleClick}
            />
          </div>
          <div className="Modal_button">
            <button onClick={() => handleDontShowForDays(1)}>오늘 하루 보지않기</button>
            <button onClick={handleClose}><MdClose/> 닫기</button>
          </div>
        </div>

      </DABIN.ModalContainer>
    </>
  );
};

export default Modal