import React, { FC, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { baseUrl, checkExistsProductTypeByBrand, getAlcB2bBrandBannerList, getAlcB2cBrandBannerList, getBrandAlsStatus, getProductAlc } from '../../Axios';
import Product from '../../components/Product/Product';
import * as SOL from "../../styles/solda/StyledComponentSol";
import { sortProductList } from '../../modules/Variable';
import useDetectScroll from '@smakss/react-scroll-direction';
import { useNavigate, useLocation } from 'react-router-dom';
import Pagination from '../Shared/Pagination';
import { handleScrollTopPosition } from '../../Utils';
import { useDiscountRateContext } from '../../context/DiscountRateContext';
import IconDelete from "../../assets/icons/icon_x_mini_white.svg";
import { useMediaQuery } from 'react-responsive';
import IconClose from "../../assets/icons/icon_close_black.svg";
import IconFilter from "../../assets/icons/icon_filter.svg";
import IconSortArrow from "../../assets/icons/sort_arrow.svg";
import { useSearchTextContext } from '../../context/SearchTextContext';
import { useShowInventoryContext } from '../../context/ShowInventoryContext';
import BrandPromotion from '../Promotion/BrandPromotion';
import { IAlcBannerData, AlcBannerDataDefaultValue } from "../../modules/model";


interface IProductListProps {
		customerAlcStatus: number;
    brand_ID?: string;
		minMaxPrice: {
			min_price: number;
			max_price: number;
		};
};

interface ProductTypeList {
	product_type_ID : number;
	product_type_name: string;
};

interface IGeneralFilter {
	category: string;
	value: number;
	label: string;
}

interface ICombinationFilter {
	brand_list: number[];
	product_type_list: number[];
	price: number;
	inventory: number;
	search_text: string;
	sort: string;
	page: number;
}

interface IBrandBannerImage {
	alc_banner_img:string;
	alc_banner_img_mobile:string;
}

const ProductList: FC<IProductListProps> = ({customerAlcStatus, brand_ID, minMaxPrice}) => {

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const productType = queryParams.get('product_type');

	const navigate = useNavigate();
	const { discountRateInfo, discountRateProductTypeInfo, discountRateColorInfo } = useDiscountRateContext();
	const { showInventoryInfo } = useShowInventoryContext();

	const {searchTextInfo, setSearchTextInfo} = useSearchTextContext();
	const [transparentBackground, setTransparentBackground] = useState<boolean>(false);
	const brandNavRef: React.RefObject<HTMLDivElement> = useRef(null);
	const { scrollDir, scrollPosition } = useDetectScroll();
	const [brand, setBrand] = useState<[]>([]);

	const productTopRef = useRef<HTMLDivElement | null>(null);
	
	const [filterTab, setFilterTab] = useState<number>(0);
	const [sortName, setSortName] = useState<string>('인기순');

	const [pageSize] = useState<string>('12');

	const [productTypeList, setProductTypeList] = useState<ProductTypeList[]>([]);

	const [generalFilter, setGeneralFilter] = useState<IGeneralFilter[]>([]);

	const [totalCount, setTotalCount] = useState<number>(0);
	const [product, setProduct] = useState<[]>([]);

	const [combinationFilter, setCombinationFilter] = useState<ICombinationFilter>({
		brand_list: [],
		product_type_list: productType !== null ? [Number(productType)] : [],
		price: 0,
		inventory: 0,
		search_text: searchTextInfo.search_text,
		sort: 'popularity',
		page: 1,
	});
	const [isInit, setIsInit] = useState(true);

	// 모바일 state
	const isMobile = useMediaQuery({query: "(max-width: 500px)"});
	const isTablet = useMediaQuery({query: "(max-width: 769px)"});
	const [toggleMobileFilter, setToggleMobileFilter] = useState<boolean>(false);
	const [toggleMobileSort, setToggleMobileSort] = useState<boolean>(false);
	const local_filter_list: IGeneralFilter[] = JSON.parse(localStorage.getItem('alc_product_filter') || '[]');
	const local_page_number: number = Number(localStorage.getItem('alc_product_page') || 1);

	// 브랜드 배너 프로모션
	const [brandPromotionData, setbrandPromotionData] = useState<IAlcBannerData>(AlcBannerDataDefaultValue);
	const [brandBannerimage, setBrandBannerimage] = useState<IBrandBannerImage>({
		alc_banner_img:"",
		alc_banner_img_mobile:"",
	});
	
	// 초기 랜더링 시 
	useEffect(() => {
		let combination = {...combinationFilter};
		if (brand.length === 0) return;
		if (productTypeList.length === 0) return;
		if (local_filter_list.length <= 1 && local_page_number === 1) {
			setIsInit(false);
			return;
		};
		if (local_filter_list.length > 0) {
			const brand_list = local_filter_list.filter(tmp => tmp.category === 'brand').map(tmp => tmp.value);
			if (brand_list.length > 0) {
				combination.brand_list = [...brand_list];
			}
			const product_type_list = local_filter_list.filter(tmp => tmp.category === 'product_type').map(tmp => tmp.value);
			if (product_type_list.length > 0) {
				combination.product_type_list = product_type_list;
			}
			const inventory_list = local_filter_list.filter(tmp => tmp.category === 'inventory');
			if (inventory_list.length > 0) {
				combination.inventory = inventory_list[0].value;
			}
			const price_list = local_filter_list.filter(tmp => tmp.category === 'price');
			if (price_list.length > 0) {
				combination.price = Number(PriceFilterList[price_list[0].value-1].value);
			}
			const search_text_list = local_filter_list.filter(tmp => tmp.category === 'search_text');
			if (search_text_list.length > 0) {
				combination.search_text = search_text_list[0].label.replace('검색어: ', "");
				setSearchTextInfo({search_text: search_text_list[0].label.replace('검색어: ', "")});
			}
		}
		if (local_page_number > 1) {
			combination.page = local_page_number;
		};
		combination.sort = local_filter_list.filter(tmp => tmp.category === 'sort')[0].label;
		setCombinationFilter(combination);
		setIsInit(false);
		setTimeout(() => {
			localStorage.setItem('alc_product_filter', '[]');
			localStorage.setItem('alc_product_page', '1');
		}, 0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [local_filter_list, local_page_number]);

	useEffect(() => {
		if (searchTextInfo.search_text) {
			const local_page_number: number = Number(localStorage.getItem('alc_product_page') || 1);
			if (local_page_number > 1) {
				setCombinationFilter({
					...combinationFilter,
					search_text: searchTextInfo.search_text,
					page: local_page_number,
				});
			} else {
				setCombinationFilter({
					...combinationFilter,
					search_text: searchTextInfo.search_text,
					page: 1,
				});
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTextInfo.search_text]);

	useEffect(() => {
		getBrandAlsStatus().then((result: any) => {
			setBrand(result);
		});
	}, []);
	
	useEffect(() => {
		if (!brand_ID) {
			const defaultBanner = {
				alc_banner_ID: 0,
				alc_banner_img: "files/image_folder/brand_images/brand_0.jpg",
				alc_banner_img_mobile: "files/image_folder/brand_images/brand_mobile_0.jpg",
			};
			setBrandBannerimage(defaultBanner);
			setbrandPromotionData(AlcBannerDataDefaultValue)
		} else if (Number(brand_ID) > 0) {
			if (customerAlcStatus === 0 || customerAlcStatus === 4) {
				getAlcB2cBrandBannerList(Number(brand_ID)).then((result) => {
					setbrandPromotionData(result.brand_banner_data);
					const updatedBanner = {
						alc_banner_ID: result.brand_banner_data.alc_banner_ID,
						alc_banner_img: result.brand_banner_data.alc_banner_img,
						alc_banner_img_mobile: result.brand_banner_data.alc_banner_img_mobile,
					};
					setBrandBannerimage(updatedBanner);
				});
			} else if (customerAlcStatus === 1) {
				getAlcB2bBrandBannerList(Number(brand_ID)).then((result) => {
					setbrandPromotionData(result.brand_banner_data);
					const updatedBanner = {
						alc_banner_ID: result.brand_banner_data.alc_banner_ID,
						alc_banner_img: result.brand_banner_data.alc_banner_img,
						alc_banner_img_mobile: result.brand_banner_data.alc_banner_img_mobile,
					};
					setBrandBannerimage(updatedBanner);
				});
			}
		}
	}, [brand_ID, customerAlcStatus, isTablet]);

	useEffect(() => {
		checkExistsProductTypeByBrand(brand_ID).then(result => {
			setProductTypeList(result);
		});
	}, [brand_ID]);

	const handlePage = (page: number) => {
		setCombinationFilter({
			...combinationFilter,
			page: page,
		})
		if (page > 1) {
			const productBoundingReact = productTopRef.current?.getBoundingClientRect();
			if (productBoundingReact) {
				const productScrollPositionY = window.scrollY + productBoundingReact.top - 110;
				handleScrollTopPosition(productScrollPositionY);
			}
		}
	};
	

	const resetBrandList = () => {
		if (!brand_ID) {
			setCombinationFilter({
				brand_list: [],
				product_type_list: productType !== null ? [Number(productType)] : [],
				price: 0,
				inventory: 0,
				search_text: searchTextInfo.search_text,
				sort: 'popularity',
				page: 1,
			});
		} else {
			setCombinationFilter({
				brand_list: [Number(brand_ID)],
				product_type_list: productType !== null ? [Number(productType)] : [],
				price: 0,
				inventory: 0,
				search_text: searchTextInfo.search_text,
				sort: 'popularity',
				page: 1,
			});
		}
	}

	useEffect(() => {
		resetBrandList();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brand_ID]);

	const deleteSearchText = () => {
		setSearchTextInfo({search_text: ''});
		setCombinationFilter({
			...combinationFilter,
			search_text: '',
			page: 1,
		});
	}

	const PriceFilterList = [
		{
			value: 1,
			label: '500,000 만원 이하',
			min_price: 0,
			max_price: 500000,
		},
		{
			value: 2,
			label: '1,000,000 만원 이하',
			min_price: 0,
			max_price: 1000000,
		},
		{
			value: 3,
			label: '2,000,000 만원 이하',
			min_price: 0,
			max_price: 2000000,
		},
		{
			value: 4,
			label: '3,000,000 만원 이하',
			min_price: 0,
			max_price: 3000000,
		},
		{
			value: 5,
			label: '5,000,000 만원 초과',
			min_price: 5000000,
			max_price: minMaxPrice.max_price,
		},
	];

	// 필터링 된 제품 리스트 가져오기
	useEffect(() => {
		if (isInit) return;
		getProductAlc(
			customerAlcStatus,
			pageSize,
			JSON.stringify(combinationFilter),
		).then((result: any) => {
			setTotalCount(result.total_count);
			setProduct(result.product_data);
		});
	}, [combinationFilter, customerAlcStatus, isInit, pageSize]);

	useEffect(() => {
		if (filterTab) {
			setTransparentBackground(true);
		} else {
			setTransparentBackground(false);
		}
	}, [filterTab]);

	useEffect(() => {
		if (scrollPosition.top < 400) {
			brandNavRef.current?.classList.add("show-nav");
		} else {
			if (scrollDir === "down") {
				brandNavRef.current?.classList.remove("show-nav");
			} else if (scrollDir === "up") {
				brandNavRef.current?.classList.add("show-nav");
			}
		}
	}, [scrollDir, scrollPosition.top]);

	const navigateBrand = (brand_ID: number) => {
		navigate(`/product?brand_ID=${brand_ID}`);
		window.scrollTo({top:0});
	};

	// 검색어 & 필터 선택한 것들 태그로 관리하는 useEffect
	useEffect(() => {
		let list: IGeneralFilter[] = [];
		if (searchTextInfo.search_text !== '') {
			const filter_obj = {
				category: 'search_text',
				value: 1,
				label: `검색어: ${searchTextInfo.search_text}`
			}
			list.push(filter_obj);
		}
		if (combinationFilter.brand_list.length > 0 && brand_ID === '') {
			const selected_brand_list: any[] = brand.filter((tmp: any) => combinationFilter.brand_list.includes(tmp.brand_ID));
			selected_brand_list.forEach(item => {
				const filter_obj = {
					category: 'brand',
					value: item.brand_ID,
					label: item.brand_kor_name,
				};
				list.push(filter_obj);
			});
		}
		if (combinationFilter.product_type_list.length > 0) {
			const selected_type_list = productTypeList.filter((tmp: any) => combinationFilter.product_type_list.includes(tmp.product_type_ID));
			selected_type_list.forEach(item => {
				const filter_obj = {
					category: 'product_type',
					value: item.product_type_ID,
					label: item.product_type_name,
				};
				list.push(filter_obj);
			});
		}
		if (combinationFilter.inventory > 0) {
			let inventory_label = '';
			if (combinationFilter.inventory === 1) {
				inventory_label = '재고 보유';
			} else if (combinationFilter.inventory === 2) {
				inventory_label = '입고 예정';
			} else if (combinationFilter.inventory === 3) {
				inventory_label = '생산 완료';
			} else if (combinationFilter.inventory === 4) {
				inventory_label = '발주 완료';
			}
			const filter_obj = {
				category: 'inventory',
				value: combinationFilter.inventory,
				label: inventory_label,
			};
			list.push(filter_obj);
		}
		if (combinationFilter.price > 0) {
			const selected_price_filter = PriceFilterList[combinationFilter.price-1];
			const filter_obj = {
				category: 'price',
				value: selected_price_filter.value,
				label: `${selected_price_filter.min_price.toLocaleString()}원~${selected_price_filter.max_price.toLocaleString()}원`
			};
			list.push(filter_obj);
		}
		list.push({
			category: 'sort',
			value: 0,
			label: combinationFilter.sort,
		});
		setGeneralFilter(list);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [combinationFilter]);
	
	/* 가격 슬라이더 영역 */

	// 검색 필터 초기화
	const resetFilter = () => {
		setSearchTextInfo({search_text: ''});
		setCombinationFilter({
			brand_list: [],
			product_type_list: productType !== null ? [Number(productType)] : [],
			price: 0,
			inventory: 0,
			search_text: '',
			sort: 'popularity',
			page: 1,
		});
		setGeneralFilter([]);
	};

	useEffect(() => {
		if (toggleMobileFilter) {
			document.body.style.position = "fixed";
		} else {
			document.body.style.position = "static";
		};

		return () => {
			document.body.style.position = "static";
		}
	}, [toggleMobileFilter]);

	const selectProduct = (color_ID: number) => {
		navigate(`/product_detail?color_ID=${color_ID}`, {state: {filter: generalFilter, page: combinationFilter.page}});
	};

	
	return (
		<>
			{transparentBackground &&
				<div
					className="transparent-background"
					onClick={() => {
						setFilterTab(0);
					}}
				/>
			}
			<SOL.MobileBrandNav
				ref={brandNavRef}
			>
				<button
					className={`${Number(brand_ID) === 0 && "active-nav"}`}
					onClick={() => {
						navigate('/product');
						handleScrollTopPosition(0);
					}}
				>
					ALL
				</button>
				{brand.map((tmp: any) => (
					<button
						key={tmp.brand_ID}
						className={`${tmp.brand_ID === Number(brand_ID) && "active-nav"}`}
						onClick={() => navigateBrand(tmp.brand_ID)}
					>
						{tmp.brand_eng_name.toUpperCase()}
					</button>
				))}
			</SOL.MobileBrandNav>

			<SOL.ProductBrandBanner>
				<div className='banner-container'>
					<img
						src={isTablet ? `${baseUrl}${brandBannerimage.alc_banner_img_mobile}` : `${baseUrl}${brandBannerimage.alc_banner_img}`}
						alt='brand_banner_image'
					/>
				</div>
			</SOL.ProductBrandBanner>
			
			{/* WEB 전용 화면 여기서 부터 */}
			<SOL.ProductListContainer>
				<SOL.ProductSideNav className={`${toggleMobileFilter && "mobile-show"}`}>
					<div className={`${Number(brand_ID) > 0 ? `productSideContainer productSideContainer-sticky` : `productSideContainer`}`}>
						<div className='mobile-close' onClick={() => {
							resetFilter();
							setToggleMobileFilter(false);
							handleScrollTopPosition(0);
						}}>
							<button>
								<img src={IconClose} alt='close_btn' />
							</button>
						</div>
						<div className='total-count-box'>
							<span>
								총 {totalCount}개
							</span>
						</div>
						{generalFilter.filter(tmp => tmp.category !== 'sort').length > 0 && (
							<div className='general-filter-section'>
								<div className='control'>
									<span>적용된 필터</span>
									<button
										onClick={() => {
											resetFilter();
										}}
									>
										전체 삭제
									</button>
								</div>
								<div className='grid-box'>
									{generalFilter.filter(tmp => tmp.category !== 'sort').map((tmp, idx) =>
										<button
											key={idx}
											className='filter-tag'
											onClick={() => {
												if (tmp.category === 'search_text') {
													deleteSearchText();
												} else if (tmp.category === 'brand') {
													let list = [...combinationFilter.brand_list];
													let index = list.indexOf(tmp.value);
													list.splice(index, 1);
													setCombinationFilter({
														...combinationFilter,
														brand_list: list,
													});
												} else if (tmp.category === 'product_type') {
													let list = [...combinationFilter.product_type_list];
													let index = list.indexOf(tmp.value);
													list.splice(index, 1);
													setCombinationFilter({
														...combinationFilter,
														product_type_list: list,
													});
												} else if (tmp.category === 'price') {
													setCombinationFilter({
														...combinationFilter,
														price: 0,
													})
												} else if (tmp.category === 'inventory') {
													setCombinationFilter({
														...combinationFilter,
														inventory: 0,
													});
												}
												let filter_list = [...generalFilter];
												filter_list.splice(idx, 1);
												setGeneralFilter(filter_list);
											}}
										>
											<span>{tmp.label}</span>
											<img src={IconDelete} alt='delete_icon' />
										</button>
									)}
								</div>
							</div>
						)}
						{/* 브랜드 필터 */}
						{brand_ID === '' && (
							<div className='side-section brand-list-box'>
								<h3>브랜드</h3>
								<button
									className={`list-item ${combinationFilter.brand_list.length === 0 && "active"}`}
									onClick={() => {
										let list = [...generalFilter];
										setGeneralFilter(list.filter(tmp => tmp.category !== 'brand'));
										setCombinationFilter({
											...combinationFilter,
											brand_list: [],
											page: 1,
										});
									}}
								>
									브랜드 전체
								</button>
								{brand.map((tmp: any, idx: number) =>
									<button
										key={idx}
										className={`list-item ${combinationFilter.brand_list.includes(tmp.brand_ID) && "active"}`}
										onClick={() => {
											let list: any[] = [...combinationFilter.brand_list];
											if (list.includes(tmp.brand_ID)) {
												let index = list.indexOf(tmp.brand_ID);
												list.splice(index, 1);
											} else {
												list.push(tmp.brand_ID);
											};
											setCombinationFilter({
												...combinationFilter,
												brand_list: list.sort((a, b) => a - b),
												page: 1,
											});
										}}
									>
										{tmp.brand_kor_name}
									</button>
								)}
							</div>
						)}
						{/* 브랜드 필터 */}
						{/* 제품타입 필터 */}
						<div className='side-section category-list-box'>
							<h3>제품 타입</h3>
							<button
								className={`list-item ${combinationFilter.product_type_list.length === 0 && "active"}`}
								onClick={() => {
									let list = [...generalFilter];
									setGeneralFilter(list.filter(tmp => tmp.category !== 'product_type'));
									setCombinationFilter({
										...combinationFilter,
										product_type_list: productType !== null ? [Number(productType)] : [],
										page: 1,
									});
								}}
							>
								제품 타입 전체
							</button>
							{productTypeList.map((tmp: any, idx: number) =>
								<button
									key={idx}
									className={`list-item ${combinationFilter.product_type_list.includes(tmp.product_type_ID) && "active"}`}
									onClick={() => {
										let list: any[] = [...combinationFilter.product_type_list];
										if (list.includes(tmp.product_type_ID)) {
											let index = list.indexOf(tmp.product_type_ID);
											list.splice(index, 1);
										} else {
											list.push(tmp.product_type_ID);
										};
										setCombinationFilter({
											...combinationFilter,
											product_type_list: list.sort((a, b) => a - b),
											page: 1,
										});
									}}
								>
									{tmp.product_type_name}
								</button>
							)}
						</div>
						{/* 제품타입 필터 */}
						{/* 가격 필터 */}
						<div className='side-section price-list-box'>
							<h3>가격 <span className='notice'>(미할인 가격 기준)</span></h3>
							<button
								className={`list-item ${combinationFilter.price === 0 && "active"}`}
								onClick={() => {
									setCombinationFilter({
										...combinationFilter,
										price: 0,
									})
								}}
							>
								가격 전체
							</button>
							{PriceFilterList.filter(tmp => tmp.min_price < minMaxPrice.max_price).map((tmp: any, idx: number) =>
								<button
									key={idx}
									className={`list-item ${combinationFilter.price === tmp.value && "active"}`}
									onClick={() => {
										setCombinationFilter({
											...combinationFilter,
											price: tmp.value,
										});
									}}
								>
									{tmp.label}
								</button>
							)}
						</div>
						{/* 가격 필터 */}
						{/* 재고 필터 */}
						{(
							customerAlcStatus === 1 || 
							(customerAlcStatus === 4 && showInventoryInfo.show_inventory === false)
						) && (
							<div className='side-section'>
								<h3>재고 현황</h3>
								<button
									className={`list-item ${combinationFilter.inventory === 0 && "active"}`}
									onClick={() => {
										setCombinationFilter({
											...combinationFilter,
											inventory: 0,
										});
									}}
								>
									전체
								</button>
								<button
									className={`list-item ${combinationFilter.inventory === 1 && "active"}`}
									onClick={() => {
										setCombinationFilter({
											...combinationFilter,
											inventory: 1,
										});
									}}
								>
									재고 보유
								</button>
								<button
									className={`list-item ${combinationFilter.inventory === 2 && "active"}`}
									onClick={() => {
										setCombinationFilter({
											...combinationFilter,
											inventory: 2,
										});
									}}
								>
									입고 예정
								</button>
								{customerAlcStatus === 4 && 
									<button
										className={`list-item ${combinationFilter.inventory === 3 && "active"}`}
										onClick={() => {
											setCombinationFilter({
												...combinationFilter,
												inventory: 3,
											});
										}}
									>
										생산 완료
									</button>
								} 
								{customerAlcStatus === 4 && 
									<button
										className={`list-item ${combinationFilter.inventory === 4 && "active"}`}
										onClick={() => {
											setCombinationFilter({
												...combinationFilter,
												inventory: 4,
											});
										}}
									>
										발주 완료
									</button>
								}
							</div>
						)}
						{/* 재고 필터 */}
						<div className='side-section control-box'>
							<button
								className='reset-btn'
								onClick={() => {
									resetFilter();
								}}
							>
								초기화
							</button>
							<button
								className='apply-btn'
								onClick={() => {
									setToggleMobileFilter(false);
									handleScrollTopPosition(0);
								}}
							>
								적용하기
							</button>
						</div>
						{customerAlcStatus === 1 && (
							<div className='side-section control-box border-hide'>
								<button
									className='massive-order'
									onClick={() => {
										window.open('https://pf.kakao.com/_HMQQs', "_blank");
									}}
								>
									<p>대량주문 별도문의</p>
									<p className='massive-order-phone-number'>070-4286-3556</p>
								</button>
							</div>
						)}
					</div>
				</SOL.ProductSideNav>
				{product.length > 0 ? (
					<div className='w-[920px]'>
						{brandPromotionData.alc_banner_ID > 0 && <BrandPromotion brandPromotionData={brandPromotionData} />}
						<div 
							className='w-[100%] relative flex justify-center'
							ref={productTopRef}
						>
							<SOL.ProductSort>
								{isTablet ? (
									<>
										<div className='mobile-sort-control'>
											<span>총 {totalCount}개</span>
											<div className='sort-and-filter'>
												<div className='sort-box'>
													<button className='sort-button' onClick={() => setToggleMobileSort(!toggleMobileSort)}>
														{sortName}
														<img src={IconSortArrow} alt='sort_arrow' className={`${toggleMobileSort && "rotate-180"}`} />
													</button>
													<div className={`sort-dropdown ${toggleMobileSort && "active"}`}>
														{sortProductList.map((tmp, idx) => 
															<button
																key={idx}
																className={`sort-items ${((sortProductList.length-1) === idx) && "last-item"}`}
																onClick={() => {
																	setCombinationFilter({
																		...combinationFilter,
																		sort: tmp.value,
																		page: 1,
																	});
																	setSortName(tmp.label);
																	setToggleMobileSort(false);
																}}
															>
																{tmp.label}
															</button>
														)}
													</div>
												</div>
												<button onClick={() => setToggleMobileFilter(true)}>
													<img src={IconFilter} alt='filter_icon' />
												</button>
											</div>
										</div>
										{generalFilter.filter(tmp => tmp.category !== 'sort').length > 0 && (
											<SOL.ProductFilterSectionMobile>
												<div className='control'>
													<span>적용된 필터</span>
													<button
														onClick={() => {
															resetFilter();
														}}
													>
														전체 삭제
													</button>
												</div>
												<div className='grid-box'>
													{generalFilter.filter(tmp => tmp.category !== 'sort').map((tmp, idx) =>
														<button
															key={idx}
															className='filter-tag'
															onClick={() => {
																if (tmp.category === 'search_text') {
																	deleteSearchText();
																} else if (tmp.category === 'brand') {
																	let list = [...combinationFilter.brand_list];
																	let index = list.indexOf(tmp.value);
																	list.splice(index, 1);
																	setCombinationFilter({
																		...combinationFilter,
																		brand_list: list,
																		page: 1,
																	});
																} else if (tmp.category === 'product_type') {
																	let list = [...combinationFilter.product_type_list];
																	let index = list.indexOf(tmp.value);
																	list.splice(index, 1);
																	setCombinationFilter({
																		...combinationFilter,
																		product_type_list: list,
																		page: 1,
																	});
																} else if (tmp.category === 'price') {
																	setCombinationFilter({
																		...combinationFilter,
																		price: 0,
																		page: 1,
																	})
																} else if (tmp.category === 'inventory') {
																	setCombinationFilter({
																		...combinationFilter,
																		inventory: 0,
																		page: 1,
																	});
																}
																let filter_list = [...generalFilter];
																filter_list.splice(idx, 1);
																setGeneralFilter(filter_list);
															}}
														>
															<span>{tmp.label}</span>
															<img src={IconDelete} alt='delete_icon' />
														</button>
													)}
												</div>
											</SOL.ProductFilterSectionMobile>
										)}
									</>
								) : (	
									<div className='sort-button-box'>
										{sortProductList.map((tmp, idx) =>
											<button
												key={idx}
												className={`${combinationFilter.sort === tmp.value && "active"}`}
												onClick={() => {
													setCombinationFilter({
														...combinationFilter,
														sort: tmp.value,
														page: 1,
													});
													setSortName(tmp.label);
												}}
											>
												{tmp.label}
											</button>
										)}
									</div>
								)}
							</SOL.ProductSort>
						</div>
						<SOL.ProductGrid>
							{product.map((element: any, index: number) => (
								<Product 
									product_element={element} 
									apply_discount_rate={discountRateInfo}
									apply_discount_rate_product_type={discountRateProductTypeInfo}
									apply_discount_rate_color={discountRateColorInfo}
									customerAlcStatus={customerAlcStatus}
									selectProduct={selectProduct}
									key={index}/>
							))}
						</SOL.ProductGrid>
						<Pagination
							totalCount={totalCount}
							handlePage={handlePage}
							listCount={Number(pageSize)}
							page={combinationFilter.page}
						/>
					</div>
				) : (
					isMobile ? (

						<div className='w-[100%] px-[15px]'>
							{generalFilter.filter(tmp => tmp.category !== 'sort').length > 0 && (
								<SOL.ProductFilterSectionMobile>
									<div className='control'>
										<span>적용된 필터</span>
										<button
											onClick={() => {
												resetFilter();
											}}
										>
											전체 삭제
										</button>
									</div>
									<div className='grid-box'>
										{generalFilter.filter(tmp => tmp.category !== 'sort').map((tmp, idx) =>
											<button
												key={idx}
												className='filter-tag'
												onClick={() => {
													if (tmp.category === 'search_text') {
														deleteSearchText();
													} else if (tmp.category === 'brand') {
														let list = [...combinationFilter.brand_list];
														let index = list.indexOf(tmp.value);
														list.splice(index, 1);
														setCombinationFilter({
															...combinationFilter,
															brand_list: list,
															page: 1,
														});
													} else if (tmp.category === 'product_type') {
														let list = [...combinationFilter.product_type_list];
														let index = list.indexOf(tmp.value);
														list.splice(index, 1);
														setCombinationFilter({
															...combinationFilter,
															product_type_list: list,
															page: 1,
														});
													} else if (tmp.category === 'price') {
														setCombinationFilter({
															...combinationFilter,
															price: 0,
															page: 1,
														})
													} else if (tmp.category === 'inventory') {
														setCombinationFilter({
															...combinationFilter,
															inventory: 0,
															page: 1,
														});
													}
													let filter_list = [...generalFilter];
													filter_list.splice(idx, 1);
													setGeneralFilter(filter_list);
												}}
											>
												<span>{tmp.label}</span>
												<img src={IconDelete} alt='delete_icon' />
											</button>
										)}
									</div>
								</SOL.ProductFilterSectionMobile>
							)}
							<div className="no-products">검색하신 상품이 없습니다.</div>
						</div>
					) : (
						<div className="no-products">검색하신 상품이 없습니다.</div>
					)
				)}
			</SOL.ProductListContainer>
		</>
	);
};

ProductList.propTypes = {
	brand_ID: PropTypes.string,
};

ProductList.defaultProps = {
	brand_ID: '',
};

export default ProductList;
