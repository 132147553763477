import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getEstimateOne } from "../../Axios";
import PDFViewer from "../PDF/PDFViewer";

interface IEstimateSelectOneProps {
  customerAlcStatus: number;
}

const EstimateSelectOne: FC<IEstimateSelectOneProps> = ({
  customerAlcStatus,
}) => {
  const location = useLocation();
  const [customerData, setCustomerData] = useState({});
  const [estimate, setEstimate] = useState({});
  const [estimateDetails, setestimateDetails] = useState<any[]>([]);

  useEffect(() => {
    if (!location.state) {
      
    } else {
      getEstimateOne(
        location.state.aos_estimate_ID,
      ).then(result => {
        if (result.status === 200) {
          let array = [...result.detail_data];
          for (let i = 0; i < array.length; i++) {
            array[i].product_number = i+1;
          };
          setCustomerData(result.customer_data);
          setEstimate(result.estimate_data);
          setestimateDetails(array);
        } else {

        }
      })
    }
  }, [location.state]);

  return (
    <PDFViewer
      pdfDivision={1}
      customerData={customerData}
      mainData={estimate}
      subData={estimateDetails}
    />
  )
};

export default EstimateSelectOne;