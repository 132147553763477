import React, { FC, useEffect, useRef, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { useLocation, useNavigate } from "react-router-dom";
import DaumPostcode from 'react-daum-postcode';
import { checkSignedUpEmail, getAlcComplexMatchingList, requestCustomerJoin, sendMessage } from "../../Axios";
import { eliminateHyphen, onlyNumber, removeWhitespacePhone, validateEmail, validatePhone } from "../../modules/Validation";
import { generateRandomCode } from "../../Utils";
import SimpleHeader from "../Header/SimpleHeader";
import CheckBlank from "../../assets/icons/blank_checkbox.svg";
import CheckFill from "../../assets/icons/fill_checkbox.svg";
import ModalLoading from "../../components/Modal/ModalLoading";
import ModalClose from "../../components/Modal/ModalClose";
import TermsOfService from "../../modules/TermsOfService";
import PrivacyPolicy from "../../modules/PrivacyPolicy";
import MarketingConsent from "../../modules/MarketingConsent";
import { useMediaQuery } from "react-responsive";
import NewWindow from "react-new-window";
import ModalYesClose from "../../components/Modal/ModalYesClose";

const Join: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const code = location.state?.code || '';
  const isMobile = useMediaQuery({query: "(max-width: 500px)"});
  const inputRef = useRef<null[] | HTMLInputElement[]>([]);
  const genderRef = useRef<HTMLDivElement>(null);
  const complexRef = useRef<HTMLDivElement>(null);

  const [postcodeModal, setPostcodeModal] = useState<boolean>(false);
  const [complexList, setComplexList] = useState<any[]>([]);
  const [customerAccountID, setCustomerAccountID] = useState<string>('ambience');

  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [customerPassword, setCustomerPassword] = useState<string>('');
  const [customerPassword2, setCustomerPassword2] = useState<string>('');
  const [customerName, setCustomerName] = useState<string>('');
  const [customerGender, setCustomerGender] = useState<number>(0);
  const [customerBirthday, setCustomerBirthday] = useState<string>('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>('');
  const [customerAddress, setCustomerAddress] = useState({
    postalCode: '',
    address: '',
    addressDetails: '',
    bigArea: '',
    smallArea: '',
  });
  const [customerComplexID, setCustomerComplexID] = useState<number>(0);
  const [inputErrors, setInputErrors] = useState({
    password: "",
    password_confirm: "",
    name: "",
    gender: "",
    birthday: "",
    phone: "",
    address: "",
    complex: "",
    agree: "",
    reason: "",
  });

  // 이메일 아이디 중복검사
  const [certificationEmail, setCertificationEmail] = useState<boolean>(false);
  const [emailError, setEmailError] = useState('');
  
  // 전화번호 인증용
  const [codeInput, setCodeInput] = useState<string>('');
  const [certificationCode, setCertificationCode] = useState<string>('AAAAAA'); // 인증 코드
  const [certificationTimer, setCertificationTimer] = useState<boolean>(false) // 타이머 여부;
  const [countdown, setCountdown] = useState<number>(600); // 인증 세팅
  const [renderCountdown, setRenderCountdown] = useState<string>(""); // 인증 시간 출력
  const [phoneCertificationText, setPhoneCertificationText] = useState(''); // 인증 결과 출력
  const [isCertification, setIsCertification] = useState(false); // 인증완료 확인

  // 이용약관 동의
  const [agreeTermsOfUse, setAgreeTermsOfUse] = useState<boolean>(false);
  const [agreePersonalInformationCollection, setAgreePersonalInformationCollection] = useState<boolean>(false);
  const [agreeMarketingConsent, setAgreeMarketingConsent] = useState<boolean>(false);
  const [agreeAllCondition, setAgreeAllCondition] = useState<boolean>(false);

  // 모달창 state
  const [isModalCompleteJoin, setIsModalCompleteJoin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState({text: ''});
  const [isModalYesCloseOpen, setIsModalYesCloseOpen] = useState<boolean>(false);
  const [modalYesCloseContent, setModalYesCloseContent] = useState({
    text: '',
    confirmText: '',
    onConfirm: () => {}
  });

  const [isNewTermsOfService, setIsNewTermsOfService] = useState<boolean>(false);
  const [isNewPrivacyPolicy, setIsNewPrivacyPolicy] = useState<boolean>(false);
  const [isNewMarketingConsent, setIsNewMarketingConsent] = useState<boolean>(false);
  const [joinApplyReason, setJoinApplyReason] = useState<string>("");

  useEffect(() => {
    if (!code) return;
    getAlcComplexMatchingList(code.toUpperCase()).then(result => {
      if (result.alc_code_data.length === 1) {
        setCustomerAccountID(result.alc_code_data[0].account_ID_use_alc_code);
        setComplexList(result.alc_complex_data);
      }
    });
  }, [code]);

  const handleComplete = (data: any) => {
    setCustomerAddress({
      ...customerAddress,
      postalCode: data.zonecode,
      address: data.roadAddress === '' ? data.autoRoadAddress : data.roadAddress,
      addressDetails: data.buildingName,
      bigArea: data.sido,
      smallArea: data.sigungu,
    });
    setPostcodeModal(false);
  };

  // 전화번호 인증 => 인증번호 전송, 타이머 세팅
  const requestSendMobileMessage = () => {
    if (isCertification) return;
    if (!validatePhone(customerPhoneNumber)) {
      setIsModalCloseOpen(true);
      setModalContent({text: "전화번호를 정확히 입력해주세요."});
      return;
    } ;
    setCodeInput('');
    const phone = eliminateHyphen(customerPhoneNumber);
    const random_number = generateRandomCode(6);
    const message_data = `에이블랙 인증 번호 : [${random_number}] 10분 이내에 입력해주세요.`;
    setCertificationCode(random_number);
    sendMessage(phone, 1, message_data).then(result => {
      if (result === 1) {
        setIsModalCloseOpen(true);
        setModalContent({text: "인증번호가 발송 되었습니다."});
        setPhoneCertificationText('인증번호가 발송되었습니다. 인증번호를 받지 못하셨다면 입력한 번호를 확인해 주세요.');
        setCertificationTimer(true);
        setCountdown(600);
      } else {
        setIsModalCloseOpen(true);
        setModalContent({text: "인증번호 발송을 실패 했습니다. 다시 시도하거나 문의하여 주세요."});
      }
    });
  };

  // 타이머 동작
  useEffect(() => {
    let timer: any;
    if (certificationTimer) {
      timer = setInterval(handleClock, 1000);
    } else {
      clearInterval(timer);
    };
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificationTimer]);

  const handleClock = () => {
    setCountdown(props => props - 1);
  };

  // 타이머 시간 초과시 초기화 및 타이머 시간 출력
  useEffect(() => {
    if (countdown < 1) {
      setCertificationTimer(false);
      setCountdown(600);
      setPhoneCertificationText("인증시간이 초과되었습니다. 다시 인증해주세요.");
      return;
    }
    let min = Math.floor(countdown / 60);
    let sec = (countdown % 60);
    setRenderCountdown(`${min.toString()} : ${sec.toString()}`);
  }, [countdown]);

  // 인증번호 검사
  const checkCertification = () => {
    if (isCertification) return;
    if (codeInput.length < 6) {
      setPhoneCertificationText('인증번호 6자리를 입력해 주세요.');
    }
    if (codeInput === certificationCode) {
      setIsCertification(true);
    } else {
      setPhoneCertificationText('인증번호가 일치하지 않습니다.');
    }
  };

  // 인증완료 시 타이머 종료
  useEffect(() => {
    if (isCertification) {
      setPhoneCertificationText("인증되었습니다.");
      setCertificationTimer(false);
      setInputErrors({
        ...inputErrors,
        phone: "",
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCertification]);

  // 전체 약관 동의
  useEffect(() => {
    if (agreeMarketingConsent && agreePersonalInformationCollection && agreeTermsOfUse) {
      setAgreeAllCondition(true);
    } else {
      setAgreeAllCondition(false);
    }
  }, [agreeMarketingConsent, agreePersonalInformationCollection, agreeTermsOfUse]);

  const handleJoin = () => {
    if (validateJoin()) {
      setIsLoading(true);
      let str = customerBirthday;
      let birth_date = str.substring(0, 4) + "-" + str.substring(4, 6) + "-" + str.substring(6,8);
      requestCustomerJoin(
        customerEmail,
        customerPassword,
        customerName,
        customerGender,
        birth_date,
        customerPhoneNumber,
        customerAddress.postalCode,
        customerAddress.address,
        customerAddress.addressDetails,
        customerAddress.bigArea,
        customerAddress.smallArea,
        2, // 가입목적 => 개인고객은 조명구매 밖에 안되므로 (2)로 설정
        agreePersonalInformationCollection ? 1 : 0, // 개인정보동의여부 customer_privacy_status
        agreeMarketingConsent ? 1: 0, // 마케팅동의여부 customer_marketing_status
        customerComplexID,
        customerAccountID,
        joinApplyReason,
      ).then(result => {
        setIsLoading(false);
        if (result === 1) {
          setIsModalCompleteJoin(true);
        } else {
          setIsModalCloseOpen(true);
          setModalContent({text: "알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다."});
        }
      });
    }
  };

  const validateJoin = () => {
    let validation = true;
    let focus_index = 0;
    let div_focus = 0;
    let errors_text = {...inputErrors};
    if (!validateEmail(customerEmail)) {
      validation = false;
      setEmailError('이메일 형식에 맞게 입력해주세요.');
      focus_index = 1;
    } else {
      setEmailError('');
    }
    if (!certificationEmail) {
      validation = false;
      setEmailError('이메일 중복확인 완료해주세요.');
      focus_index = 1;
    } else {
      setEmailError('해당 이메일을 아이디로 사용합니다.');
    }
    if (customerPassword === '') {
      validation = false;
      errors_text.password = '비밀번호를 입력해주세요.';
      if (!focus_index) {focus_index = 2;}
    } else {
      errors_text.password = '';
    }
    if (customerPassword2 === '') {
      validation = false;
      errors_text.password_confirm = '비밀번호를 재입력해주세요.';
      if (!focus_index) {focus_index = 3;}
    } else if (customerPassword !== customerPassword2) {
      validation = false;
      errors_text.password_confirm = '비밀번호가 서로 일치하지 않습니다.';
      if (!focus_index) {focus_index = 3;}
    } else {
      errors_text.password_confirm = '';
    }
    if (customerName === '') {
      validation = false;
      errors_text.name = '이름을 입력해 주세요.';
      if (!focus_index) {focus_index = 4;}
    } else {
      errors_text.name = '';
    }
    if (!customerGender) {
      validation = false;
      errors_text.gender = '성별을 선택해 주세요.';
      if (!focus_index) {div_focus = 1}
    } else {
      errors_text.gender = '';
    }
    if (customerBirthday.length < 8) {
      validation = false;
      errors_text.birthday = '생년월일 (YYYY.MM.DD) 8자리를 입력해주세요';
      if (!focus_index && !div_focus) {focus_index = 5;}
    } else {
      errors_text.birthday = '';
    }
    if (customerPhoneNumber === '') {
      validation = false;
      errors_text.phone = '휴대폰 번호를 입력해 주세요.';
      if (!focus_index && !div_focus) {focus_index = 6;}
    } else if (!validatePhone(customerPhoneNumber)) {
      validation = false;
      errors_text.phone = '유효하지 않은 휴대폰 번호입니다. 입력한 번호를 확인해 주세요.';
      if (!focus_index && !div_focus) {focus_index = 6;}
    } else {
      errors_text.phone = '';
    }
    if (!isCertification) {
      validation = false;
      setPhoneCertificationText('인증을 완료해 주세요.');
      if (!focus_index && !div_focus) {focus_index = 6;}
    }
    if (complexList.length > 0) {
      if (!customerComplexID) {
        validation = false;
        errors_text.complex = "추가정보를 선택해 주세요.";
        if (!focus_index && !div_focus) {div_focus = 2}
      } else {
        errors_text.complex = "";
      }
    }
    if (customerAddress.postalCode === '' || customerAddress.address === '') {
      validation = false;
      errors_text.address = '주소를 입력해주세요.';
      if (!focus_index && !div_focus) {focus_index = 8;}
    } else {
      errors_text.address = '';
    }
    if (customerAccountID === 'ambience' && !joinApplyReason) {
      validation = false;
      errors_text.reason = "회원가입 경로 및 목적을 입력해주세요.";
    } else {
      errors_text.reason = "";
    }
    if (!agreeTermsOfUse || !agreePersonalInformationCollection) {
      validation = false;
      errors_text.agree = "필수 동의를 선택해 주세요.";
    } else {
      errors_text.agree = "";
    }

    if (!validation) {
      if (div_focus === 1) {
        if (genderRef.current) {
          const absoluteTop: any = window.scrollY + genderRef.current.getBoundingClientRect().top;
          window.scrollTo({
            top: absoluteTop-100
          });
        }
      } else if (div_focus === 2) {
        if (complexRef.current) {
          const absoluteTop: any = window.scrollY + complexRef.current.getBoundingClientRect().top;
          window.scrollTo({
            top: absoluteTop-80
          });
        }
      } else if (focus_index > 0) {
        inputRef.current[focus_index-1]?.focus();
      } else if (!agreeTermsOfUse || !agreePersonalInformationCollection) {
        setIsModalCloseOpen(true);
        setModalContent({text: "필수 동의를 선택해 주세요."});
      }
    }
    
    setInputErrors(errors_text);
    return validation;
  };

  // FIXME: 여기도 신청로그에 맞춰서 수정 필요함
  const checkDuplicatedEmail = () => {
    if (!validateEmail(customerEmail)) return setEmailError('이메일 형식에 맞게 입력해주세요.');
    checkSignedUpEmail(customerEmail).then(result => {
      if (result.length === 0) {
        setIsModalYesCloseOpen(true);
        setModalYesCloseContent({
          text: "사용가능한 아이디입니다.\n해당 이메일을 아이디로 사용하시겠습니까?",
          confirmText: "사용",
          onConfirm: () => {
            setCertificationEmail(true);
            setIsModalYesCloseOpen(false);
          }
        });
      } else {
        const account_data = result[0];
        if (account_data.customer_activate !== 1) {
          setModalContent({text: "휴면/탈퇴 계정입니다.\n070-4286-3556로 문의해주시기 바랍니다."});
        } else if (account_data.customer_join_division !== 1) {
          setModalContent({text: "간편로그인 계정은 이용하실 수 없습니다."});
        } else if (account_data.customer_alc_status !== 1) {
          setModalContent({text: "가입 승인대기 중입니다.\n070-4286-3556로 문의해주시기 바랍니다."});
        } else {
          setModalContent({text: "해당 이메일로 가입된 계정이 존재합니다"});
        }
        setIsModalCloseOpen(true);
        setCertificationEmail(false);
      }
    });
  };

  useEffect(() => {
    if (customerEmail === '') {
      setEmailError('');
    } else if (!validateEmail(customerEmail)) {
      setEmailError('이메일 형식에 맞게 입력해주세요.');
    } else if (validateEmail(customerEmail) && !certificationEmail) {
      setEmailError('이메일 중복확인을 완료해주세요.');
    } else {
      setEmailError('해당 이메일을 아이디로 사용합니다.');
    }
  }, [certificationEmail, customerEmail]);

  return (
    <>
      <ModalLoading isOpen={isLoading} />
      <ModalClose
        isOpen={isModalCloseOpen}
        onClose={() => setIsModalCloseOpen(false)}
        text={modalContent.text}
      />
      <ModalYesClose
        isOpen={isModalYesCloseOpen}
        onClose={() => setIsModalYesCloseOpen(false)}
        onConfirm={modalYesCloseContent.onConfirm}
        confirmText={modalYesCloseContent.confirmText}
				text={modalYesCloseContent.text}
      />
      {isModalCompleteJoin && (
        <SOL.ModalBackground>
          <SOL.JoinCompleteModal>
            <p className="join-complete-title">회원가입 및 승인 안내</p>
            <div className="join-complete-content">
              <p>회원 승인이 필요하며,</p>
              <p>회원가입 후 승인처리가 되면 이용하실 수 있습니다.</p>
              <br />
              <p>고객센터 070-4286-3556</p>
            </div>
            <button
              onClick={() => {
                setIsModalCompleteJoin(false);
                if (!code) {
                  navigate('/');
                } else {
                  navigate(`/?code=${code}`,);
                }
              }}
            >
              확인
            </button>
          </SOL.JoinCompleteModal>
        </SOL.ModalBackground>
      )}
      <SimpleHeader
        code={code}
      />
      <SOL.JoinContainer>
        <div className="join-wrapper">
          <div className="join-title">
            회원가입
          </div>
          <div className="section">
            <div className="items connect-2">
              <label><span>*</span> 아이디(이메일)</label>
              <input
                type="text"
                className="short"
                ref={el => inputRef.current[0] = el}
                value={customerEmail}
                maxLength={30}
                placeholder="이메일 계정의 아이디를 작성해 주세요."
                readOnly={certificationEmail}
                onChange={e => {
                  setCustomerEmail(e.target.value);
                }}
              />
              <button
                className="text-xs ml-2"
                onClick={() => {
                  if (certificationEmail) {
                    setCertificationEmail(false);
                    setCustomerEmail('');
                  } else {
                    checkDuplicatedEmail();
                  }
                }}
              >
                {certificationEmail ? "사용취소" : "중복확인"}
              </button>
              <p className={`input-error ${certificationEmail && "okay"}`}>{emailError}</p>
            </div>
            <div className="items">
              <label><span>*</span> 비밀번호{isMobile && <span className="text-[12px] font-light text-dark_grey"> (영문 / 숫자 / 특수문자 2개 이상 10~20자)</span>}</label>
              <input
                type="password"
                ref={el => inputRef.current[1] = el}
                value={customerPassword}
                placeholder={isMobile ? "비밀번호를 입력해 주세요." : "비밀번호를 입력해 주세요. (영문 / 숫자 / 특수문자 2개 이상 10~20자)"}
                maxLength={20}
                onChange={e => {
                  setCustomerPassword(e.target.value);
                }}
              />
              <p className="input-error">{inputErrors.password}</p>
            </div>
            <div className="items">
              <label><span>*</span> 비밀번호 확인</label>
              <input
                type="password"
                ref={el => inputRef.current[2] = el}
                value={customerPassword2}
                placeholder="비밀번호를 재입력해 주세요."
                maxLength={20}
                onChange={e => {
                  setCustomerPassword2(e.target.value);
                }}
              />
              <p className="input-error">{inputErrors.password_confirm}</p>
            </div>
            <div className="items">
              <label><span>*</span> 이름</label>
              <input
                type="text"
                ref={el => inputRef.current[3] = el}
                value={customerName}
                maxLength={12}
                onChange={e => {
                  setCustomerName(e.target.value);
                }}
              />
              <p className="input-error">{inputErrors.name}</p>
            </div>
            <div className="items" ref={genderRef}>
              <label><span>*</span> 성별</label>
              <div className="flex">
                <button
                  className={`gender-button ${customerGender === 1 && "active"}`}
                  onClick={() => setCustomerGender(1)}
                >
                  남성
                </button>
                <button
                  className={`gender-button ${customerGender === 2 && "active"}`}
                  onClick={() => setCustomerGender(2)}
                >
                  여성
                </button>
              </div>
              <p className="input-error">{inputErrors.gender}</p>
            </div>
            <div className="items">
              <label><span>*</span> 생년월일</label>
              <input
                type="text"
                ref={el => inputRef.current[4] = el}
                value={customerBirthday}
                maxLength={8}
                placeholder="생년월일 (YYYY.MM.DD) 8자리를 입력해주세요"
                onChange={e => {
                  setCustomerBirthday(onlyNumber(e.target.value));
                }}
              />
              <p className="input-error">{inputErrors.birthday}</p>
            </div>
            <div className="items connect-2">
              <label><span>*</span> 휴대폰 번호</label>
              <input
                type="text"
                className="short"
                ref={el => inputRef.current[5] = el}
                value={customerPhoneNumber}
                maxLength={13}
                onChange={e => {
                  if (certificationCode !== 'AAAAAA') {
                    setCertificationCode('AAAAAA');
                    setCertificationTimer(false);
                    setCountdown(600);
                    setRenderCountdown('');
                    setPhoneCertificationText('');
                    setIsCertification(false);
                  }
                  setCustomerPhoneNumber(removeWhitespacePhone(e.target.value));
                }}
                readOnly={isCertification}
              />
              <button
                className="text-xs ml-2"
                onClick={() => {
                  requestSendMobileMessage();
                }}
              >
                인증번호
              </button>
              <p className={`input-error`}>{inputErrors.phone}</p>
            </div>
            {(isCertification || certificationTimer) && (
              <div className="items">
                <input
                  type="text"
                  className="short"
                  onChange={e => {
                    setCodeInput(onlyNumber(e.target.value));
                  }}
                  maxLength={6}
                  value={codeInput}
                  readOnly={isCertification}
                  // readOnly={certificationTimer ? false : true}
                />
                {certificationTimer && <span className="count-down">{renderCountdown}</span>}
                <button
                  className="text-xs ml-2"
                  onClick={checkCertification}
                >
                  확인
                </button>
                <p className={`input-error ${isCertification && "okay"}`}>{phoneCertificationText}</p>
              </div>
            )}
          </div>
          <div className="section address">
            {complexList.length > 0 && (
              <div className="items mt-[50px]" ref={complexRef}>
                <h4 className="additional-information">* 추가정보</h4>
                <div className="complex-box">
                  <span className="complex-noti">
                    거주단지를 선택해 주세요. (입주예정자 포함)
                  </span>
                  <div className="complex-grid">
                    {complexList.map((tmp, idx) =>
                      <div
                        key={idx}
                        className={`complex-items ${customerComplexID === tmp.alc_complex_ID && "active"}`}
                        onClick={() => {
                          setCustomerComplexID(tmp.alc_complex_ID);
                          setCustomerAddress({
                            postalCode: !tmp.alc_complex_zip_code ? '' : tmp.alc_complex_zip_code,
                            address: !tmp.alc_complex_road_address ? '' : tmp.alc_complex_road_address,
                            addressDetails: !tmp.alc_complex_detail_address ? '' : tmp.alc_complex_detail_address,
                            bigArea: !tmp.alc_complex_big_area ? '' : tmp.alc_complex_big_area,
                            smallArea: !tmp.alc_complex_small_area ? '' : tmp.alc_complex_small_area,
                          });
                          inputRef.current[7]?.focus();
                        }}
                      >
                        <img src={customerComplexID === tmp.alc_complex_ID ? CheckFill : CheckBlank} alt="fill" />
                        <span>{tmp.alc_complex_name}</span>
                      </div>
                    )}
                  </div>
                </div>
                <p className="input-error">{inputErrors.complex}</p>
              </div>
            )}
            <div className="items connect">
              <label><span>*</span> 주소</label>
              <p className="noti">
                입주 예정 포함 거주단지 주소를 작성해 주세요.<br />
                주소 확인 후 회원가입 승인되며, 제품 주문시 해당 주소로 시공/배송됩니다.
              </p>
              <input
                type="text"
                className="short"
                ref={el => inputRef.current[6] = el}
                placeholder="우편번호"
                value={customerAddress.postalCode}
                readOnly
              />
              <button
                onClick={() => setPostcodeModal(!postcodeModal)}
              >
                우편번호
              </button>
             {postcodeModal && (
                <div className="mt-5">
                  <DaumPostcode onComplete={handleComplete} autoClose={false} />
                </div>
             )}
            </div>
            <div className="items connect">
              <label />
              <input
                type="text"
                placeholder="기본주소"
                value={customerAddress.address}
                readOnly
              />
            </div>
            <div className="items connect">
              <input
                type="text"
                placeholder="상세주소를 입력해 주세요."
                ref={el => inputRef.current[7] = el}
                value={customerAddress.addressDetails}
                maxLength={50}
                onChange={e => {
                  let obj = {...customerAddress};
                  obj.addressDetails = e.target.value;
                  setCustomerAddress(obj);
                }}
              />
              <p className="input-error">{inputErrors.address}</p>
            </div>
            {customerAccountID === 'ambience' && (
              <div className="items mt-[50px]">
                <label><span>*</span> 회원가입 경로 및 목적</label>
                <input
                  type="text"
                  value={joinApplyReason}
                  maxLength={100}
                  onChange={e => {
                    setJoinApplyReason(e.target.value);
                  }}
                />
                <p className="input-error">{inputErrors.reason}</p>
              </div>
            )}
          </div>

          <div className="section term-condition">
            <div className="items">
              <h4 className="additional-information">전체 동의</h4>
              <div className="complex-box">
                <div className="terms-flex">
                  <div
                    className="terms-check-box align-marketing"
                    onClick={() => {
                      if (agreeAllCondition) {
                        setAgreeMarketingConsent(false);
                        setAgreePersonalInformationCollection(false);
                        setAgreeTermsOfUse(false);
                      } else {
                        setAgreeMarketingConsent(true);
                        setAgreePersonalInformationCollection(true);
                        setAgreeTermsOfUse(true);
                      }
                    }}
                  >
                    <img src={agreeAllCondition ? CheckFill : CheckBlank} alt="" />
                    <span>이용약관 및 개인정보 수집 및 이용, 마케팅 수신(선택)에 모두 동의 합니다.</span>
                  </div>
                </div>
                <div className="terms-flex">
                  <div
                    className="terms-check-box"
                    onClick={() => setAgreeTermsOfUse(!agreeTermsOfUse)}
                  >
                    <img src={agreeTermsOfUse ? CheckFill : CheckBlank} alt="" />
                    <span>(필수) 이용약관 동의</span>
                  </div>
                  <div
                    className="terms-content"
                    onClick={() => setIsNewTermsOfService(true)}
                  >
                    약관보기
                  </div>
                </div>
                <div className="terms-flex">
                  <div
                    className="terms-check-box"
                    onClick={() => setAgreePersonalInformationCollection(!agreePersonalInformationCollection)}
                  >
                    <img src={agreePersonalInformationCollection ? CheckFill : CheckBlank} alt="" />
                    <span>(필수) 개인정보 수집 및 이용</span>
                  </div>
                  <div
                    className="terms-content"
                    onClick={() => setIsNewPrivacyPolicy(true)}
                  >
                    약관보기
                  </div>
                </div>
                <div className="terms-flex">
                  <div
                    className="terms-check-box"
                    onClick={() => setAgreeMarketingConsent(!agreeMarketingConsent)}
                  >
                    <img src={agreeMarketingConsent ? CheckFill : CheckBlank} alt="" />
                    <span>(선택) 이메일, SMS 수신 동의</span>
                  </div>
                  <div
                    className="terms-content"
                    onClick={() => setIsNewMarketingConsent(true)}
                  >
                    약관보기
                  </div>
                </div>
              </div>
              <p className="input-error">{inputErrors.agree}</p>
            </div>
          </div>
          <div className="control-box">
            <button
              className="control-button cancel"
              onClick={() => {
                if (code !== '') {
                  navigate(`/?code=${code}`);
                } else {
                  navigate('/');
                }
              }}
            >
              취소
            </button>
            <button
              className="control-button join"
              onClick={handleJoin}
            >
              회원가입
            </button>
          </div>

          <div className="guide-join">
            <p className="guide-title">회원가입 및 승인 안내</p>
            <p className="guide-detail">회원 승인이 필요하며, {isMobile && <br />} 회원가입 후 승인처리가 되면 이용하실 수 있습니다.</p>
          </div>
        </div>
      </SOL.JoinContainer>
      {isNewTermsOfService && (
        <NewWindow onUnload={() => setIsNewTermsOfService(false)}>
          <TermsOfService /> 
        </NewWindow>
      )}
      {isNewPrivacyPolicy && (
        <NewWindow onUnload={() => setIsNewPrivacyPolicy(false)}>
          <PrivacyPolicy />
        </NewWindow>
      )}
      {isNewMarketingConsent && ( <MarketingConsent onUnload={() => setIsNewMarketingConsent(false)} /> )}
    </>
  )
};

export default Join;