import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getAosOrderDetail } from "../../Axios";
import PDFViewer from "../../components/PDF/PDFViewer";

interface IOfferSelectOneProps {
  customerAlcStatus: number;
}

const OfferSelectOne: FC<IOfferSelectOneProps> = ({
  customerAlcStatus,
}) => {
  const location = useLocation();
  const [customerData, setCustomerData] = useState({});
  const [order, setOrder] = useState({});
  const [orderDetails, setOrderDetails] = useState<any[]>([]);

  useEffect(() => {
    if (!location.state) {
      
    } else {
      getAosOrderDetail(
        location.state.aos_order_ID,
      ).then(result => {
        if (result.status === 200) {
          let array = [...result.detail_data];
          for (let i = 0; i < array.length; i++) {
            array[i].product_number = i+1;
          }
          setCustomerData(result.customer_data);
          setOrder(result.order_data);
          setOrderDetails(array);
        } else {
          
        }
      });
    }
  }, [location.state]);

  return (
    <>
      <PDFViewer
        pdfDivision={2}
        customerData={customerData}
        mainData={order}
        subData={orderDetails}
      />
    </>
  )
};

export default OfferSelectOne;