import React, { FC, useEffect, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { useNavigate } from "react-router-dom";
import { getEstimateList, updateEstimateInactive } from "../../Axios";
import ModalYesClose from "../Modal/ModalYesClose";
import ModalClose from "../Modal/ModalClose";
import { handleScrollTopPosition } from "../../Utils";

interface IEstimateListProps {
  customerID: number;
  customerAlcStatus: number;
  handleSelectOneEstimateID: (aos_estimate_ID: number) => void;
}

interface IEstimate {
  aos_estimate_ID: number;
  customer_ID_use_aos_estimate: number;
  promotion_ID_use_aos_estimate: number;
  aos_estimate_number: string;
  aos_estimate_supply_price_existing: number;
  aos_estimate_total_separate_vat: number;
  aos_estimate_promotion_discount_pay: number;
  aos_estimate_promotion_discount_rate: number;
  aos_estimate_supply_price_final: number;
  aos_estimate_status: number;
  aos_estimate_registe: string;
  aos_estimate_memo: string;
  aos_detail_estimate_ID: number;
  color_ID_use_aos_estimate_detail: number;
  aos_detail_estimate_sell_price_original: number;
  aos_detail_estimate_sell_price: number;
  aos_detail_estimate_separate_vat: number;
  aos_detail_estimate_quantity: number;
  aos_detail_estimate_registe: string;
  aos_detail_estimate_memo: string;
  details_count: number;
  product_kor_name: string;
  brand_eng_name: string;
  brand_kor_name: string;
  color_name: string;
  color_b2c_image: string;
}

const EstimateList: FC<IEstimateListProps> = ({
  customerID,
  customerAlcStatus,
  handleSelectOneEstimateID,
}) => {
  const navigate = useNavigate();
  const [estimates, setEstimates] = useState<IEstimate[]>([]);
  const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState({text: ''});
  const [isModalYesCloseOpen, setIsModalYesCloseOpen] = useState<boolean>(false);
	const [modalYesCloseContent, setModalYesCloseContent] = useState({
    text: '',
    confirmText: '',
    onConfirm: () => {}
  });

  useEffect(() => {
    if (customerAlcStatus === 1) {
      getEstimateList(
        customerID,
      ).then(result => {
        setEstimates(result);
      });
    }
  }, [customerAlcStatus, customerID, navigate]);

  const reloadEstimateList = () => {
    getEstimateList(
      customerID,
    ).then(result => {
      setEstimates(result);
    });
  };

  return (
    <>
      <ModalClose
				isOpen={isModalCloseOpen} 
				onClose={() => {
          reloadEstimateList();
          handleScrollTopPosition(0);
          setIsModalCloseOpen(false);
        }}
				text={modalContent.text}
			/>
      <ModalYesClose
				isOpen={isModalYesCloseOpen}
				onClose={() => setIsModalYesCloseOpen(false)}
				onConfirm={modalYesCloseContent.onConfirm}
				confirmText={modalYesCloseContent.confirmText}
				text={modalYesCloseContent.text}
			/>
      <SOL.OrderListContainer style={{width: "100%"}}>
        <div className="order-list-title">
          <h3>견적내역</h3>
          {estimates.length > 0 && (
            <p className="estimate-notice">
              *등록일 기준 30일 이후 자동 삭제 됩니다.
            </p>
          )}
        </div>
        {estimates.length === 0 ? (
          <SOL.EmptyBox>
            <div>
              <p>견적내역이 비어있습니다.</p>
            </div>
          </SOL.EmptyBox>
        ) : (
          <>
            {estimates.map(estimateData => (
              <SOL.OrderListItem
                key={estimateData.aos_estimate_ID}
                onClick={(e: any) => {
                  if (e.target.dataset.button === 'request') return;
                  handleSelectOneEstimateID(estimateData.aos_estimate_ID);
                }}
              >
                <div className="order-item-header">
                  <button className="order-info">
                    <div className="order-label">견적번호</div>
                    <div className="order-info-flex-box">
                      <p data-title="주문번호 " className='order-number'>{estimateData.aos_estimate_number}</p>
                      <p data-title="결제일자 " className='pay-date font-pretendard'>
                        {`(${new Date(estimateData.aos_detail_estimate_registe).toLocaleDateString()} ${new Date(estimateData.aos_detail_estimate_registe).toLocaleTimeString()})`}
                      </p>
                    </div>
                  </button>
                </div>
                <div className="order-detail-box">
                  <div className='order-detail-left'>
                    <div className='order-detail-image'>
                      <img src={estimateData.color_b2c_image} alt={estimateData.color_name} />
                    </div>
                    <div className='order-detail-product'>
                      <p className='brand'>{estimateData.brand_kor_name}</p>
                      <p className='product'>{estimateData.product_kor_name}</p>
                      <p className='color'>{estimateData.color_name}</p>
                      <p className='quantity estimate'>
                        {estimateData.details_count > 1 && `외 ${estimateData.details_count}건`}
                      </p>
                    </div>
                  </div>
                  <div className='order-detail-right'>
                    <div className='order-detail-price'>
                      <p className='estimate-price-text'>최종 공급가</p>
                      <p className='detail-rated-price'>{estimateData.aos_estimate_supply_price_final.toLocaleString()}원</p>
                    </div>
                    <div className='order-detail-status'>
                      <div className='deilvery-status estimate'>
                        프로모션 할인금액
                      </div>
                      <div className='exchange-return-status estimate'>
                        {estimateData.aos_estimate_promotion_discount_pay > 0 ? `${estimateData.aos_estimate_promotion_discount_pay.toLocaleString()}원`: "0원"}
                      </div>
                    </div>
                    <div className='order-detail-refund-button'>
                      <button
                        data-button='request'
                        onClick={() => {
                          setIsModalYesCloseOpen(true);
                          setModalYesCloseContent({
                            text: '견적서를 삭제하시겠습니까?',
                            confirmText: '삭제',
                            onConfirm: () => {
                              updateEstimateInactive(
                                estimateData.aos_estimate_ID
                              ).then(result => {
                                if (result.changedRows > 0 || result.affectedRows > 0) {
                                  setModalContent({text: '삭제가 완료 되었습니다.'});
                                } else {
                                  setModalContent({text: '알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.'});
                                }
                                setIsModalCloseOpen(true);
                                setIsModalYesCloseOpen(false);
                              });
                            }
                          })
                        }}
                      >
                        견적 내역 삭제
                      </button>
                    </div>
                  </div>
                </div>
              </SOL.OrderListItem>
            ))}
          </>
        )}
      </SOL.OrderListContainer>
    </>
  )
};

export default EstimateList;