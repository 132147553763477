import React, { FC, useEffect } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { useNavigate } from "react-router-dom";

const OrderFailure: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('shippingInfo');
    localStorage.removeItem('senderShippingInfo');
    localStorage.removeItem('orderProducts');
    localStorage.removeItem('total_amount');
}, []);

  return (
    <>
      <SOL.OrderFailureContainer>
        <div className="order-failure-wrapper">
          <div className="order-failure-title">
            <p>주문이 정상적으로</p>
            <p>완료되지 않았습니다.</p>
          </div>
          <div className="order-failure-notice">
            <p>재시도를 하시거나 계속해서 오류가 발생할 경우</p>
            <p>고객센터 (070-4286-3556)로 문의해 주세요.</p>
          </div>
          <div className="order-failure-controller">
            <button onClick={() => navigate('/home')}>메인으로 가기</button>
          </div>
        </div>
      </SOL.OrderFailureContainer>
    </>
  )
};

export default OrderFailure;