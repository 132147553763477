import React, { FC, useEffect, useState } from 'react';
import { getCustomerShippingInfo } from '../../Axios';
import ModalClose from '../../components/Modal/ModalClose';
import { IInputErrors, IShippingInfo, useOrderContext } from '../../context/OrderContext';
import * as SOL from "../../styles/solda/StyledComponentSol";
import IconArrowUp from "../../assets/icons/arrow_fold_40.svg";

interface IOrderShipping {
    customerDivision: number;
    respectiveShippingMemo: boolean;
    handleRespectiveShippingMemo: () => void;
}

interface IGeneralComponent {
    shippingInfo: IShippingInfo;
    setShippingInfo: React.Dispatch<React.SetStateAction<IShippingInfo>>;
    inputErrors: IInputErrors;
    setInputErrors: React.Dispatch<React.SetStateAction<IInputErrors>>;
}

const GeneralCustomerComponent: FC<IGeneralComponent> = ({
    shippingInfo, setShippingInfo, inputErrors, setInputErrors
}) => {
    const [toggleShippingInfo, setToggleShippingInfo] = useState<boolean>(true);
    const combinedAddress = `(${shippingInfo.postalCode}) ${shippingInfo.address} ${shippingInfo.addressDetails}`;
    
    useEffect(() => {
        getCustomerShippingInfo().then(result => {
            if (result.length > 0) {
                setShippingInfo({
                    ...shippingInfo,
                    name: result[0].customer_name,
                    phoneNumber: result[0].customer_phone_number,
                    postalCode: result[0].customer_zip_code,
                    address: result[0].customer_road_address,
                    addressDetails: result[0].customer_detail_address,
                    bigArea: result[0].customer_big_area,
                    smallArea: result[0].customer_small_area,
                });
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SOL.OrderSectionTitle onClick={() => setToggleShippingInfo(!toggleShippingInfo)}>
                <span>배송지</span>
                <img className={`${toggleShippingInfo || "rotate-180"}`} src={IconArrowUp} alt='arrow_icon' />
            </SOL.OrderSectionTitle>
            {toggleShippingInfo && (
                <SOL.OrderCustomerContainer>
                    <h3 className='customer-name'>{shippingInfo.name}</h3>
                    <div className="order-inline-input">
                        <div className='input-box'>
                            <input 
                                className={`order-full-width font-pretendard ${inputErrors.phoneNumber ? 'input-error' : ''}`}
                                style={{width: "130px"}}
                                type="text"
                                value={shippingInfo.phoneNumber}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const newPhoneNumber = e.target.value.replace(/\D/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3').substring(0, 13);
                                    if (!newPhoneNumber || !/\d{3}-\d{3,4}-\d{4}/.test(newPhoneNumber)) {
                                        setInputErrors(prev => ({ ...prev, phoneNumber: '유효한 휴대폰 번호를 입력해주세요.' }));
                                    } else {
                                        setInputErrors(prev => ({ ...prev, phoneNumber: '' }));
                                    };
                                    setShippingInfo({
                                        ...shippingInfo,
                                        phoneNumber: newPhoneNumber,
                                    });
                                }}
                            />
                        </div>
                        <p className='error-message'>{inputErrors.phoneNumber}</p>
                    </div>
                    <div className='customer-address font-pretendard'>{combinedAddress}</div>
                    <div className='order-address-notice font-pretendard'>
                        <p>*승인된 회원으로 배송지 변경이 불가합니다.</p>
                        <p>배송지 변경은 고객센터에 문의해 주세요. 070-4286-3556</p>
                    </div>
                    <div className="order-inline-input" style={{height: "fit-content"}}>
                        <label>배송 요청사항</label>
                        <div className='input-box'>
                            <input
                                className={`order-full-width`}
                                type='text'
                                value={shippingInfo.requestDetails}
                                maxLength={500}
                                onChange={(e) => {
                                    setShippingInfo({ ...shippingInfo, requestDetails: e.target.value });
                                }}
                            />
                        </div>
                    </div>
                </SOL.OrderCustomerContainer>
            )}
        </>
    )
};

const OrderShipping: FC<IOrderShipping> = ({customerDivision, handleRespectiveShippingMemo, respectiveShippingMemo}) => {
    const {shippingInfo, setShippingInfo, inputErrors, setInputErrors} = useOrderContext();
    // const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
    const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
	const [modalContent] = useState({text: ''});

    return (
        <>
            <ModalClose
                isOpen={isModalCloseOpen} 
                onClose={() => setIsModalCloseOpen(false)}
                text={modalContent.text}
            />
            <SOL.OrderShippingContainer>
                <section className="shipping-info-section">
                    {customerDivision === 1 && (
                        <GeneralCustomerComponent
                            shippingInfo={shippingInfo} 
                            setShippingInfo={setShippingInfo} 
                            inputErrors={inputErrors} 
                            setInputErrors={setInputErrors}
                        />
                    )}
                </section>
            </SOL.OrderShippingContainer>
            <SOL.HorizontalDivider />
        </>
    );
};

export default OrderShipping;
