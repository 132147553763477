import React, { FC, useEffect, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import ModalClose from "../../components/Modal/ModalClose";
import { useNavigate } from "react-router-dom";
import { getOrderDetail } from "../../Axios";
import { getAlcDetailStatusAndDeliveryProgress } from "../../statusUtils";
import { cancelReasonSelectList } from "../../modules/Variable";
import { get_today_plus_object } from "../../modules/Validation";
import { extractColorOptionNodeNames } from "../../Utils";

interface IOrderData {
  alc_order_ID: number;
  alc_order_memo: string | null;
  alc_order_method: string;
  alc_order_method_kor: string;
  alc_order_modified: string | null;
  alc_order_number: string;
  alc_order_pay_date: string;
  alc_order_pay_status: number;
  alc_order_pay_status_name: string;
  alc_order_pay_total: number;
  alc_order_progress: number;
  alc_order_receiver_address_name: string;
  alc_order_receiver_big_area: string;
  alc_order_receiver_detail_address: string;
  alc_order_receiver_name: string;
  alc_order_receiver_phone_number: string;
  alc_order_receiver_road_address: string;
  alc_order_receiver_small_area: string;
  alc_order_receiver_zip_code: string;
  alc_order_refund_total: number;
  alc_order_registe: string;
  alc_order_requests: string;
  alc_order_status: number;
  customer_ID_use_alc_order: number;
  imp_uid: string;
  alc_status: number;
};

interface OrderSelectOneProps {
  customerID: number;
  orderID: number;
  handleCancelOrderTab: (tab_name: string, category: string) => void;
  handleSelectOneDetailOrderID: (alc_detail_order_ID: number, category: string) => void;
}

const OrderSelectOne: FC<OrderSelectOneProps> = ({customerID, orderID, handleCancelOrderTab, handleSelectOneDetailOrderID}) => {
  const navigate = useNavigate();

  const [orderData, setOrderData] = useState<IOrderData>({
    alc_order_ID: 0,
    alc_order_memo: null,
    alc_order_method: '',
    alc_order_method_kor: '',
    alc_order_modified: null,
    alc_order_number: '',
    alc_order_pay_date: '',
    alc_order_pay_status: 0,
    alc_order_pay_status_name: '',
    alc_order_pay_total: 0,
    alc_order_progress: 0,
    alc_order_receiver_address_name: '',
    alc_order_receiver_big_area: '',
    alc_order_receiver_detail_address: '',
    alc_order_receiver_name: '',
    alc_order_receiver_phone_number: '',
    alc_order_receiver_road_address: '',
    alc_order_receiver_small_area: '',
    alc_order_receiver_zip_code: '',
    alc_order_refund_total: 0,
    alc_order_registe: '',
    alc_order_requests: '',
    alc_order_status: 0,
    customer_ID_use_alc_order: 0,
    imp_uid: '',
    alc_status: 0,
  });
  const [detailOrderList, setDetailOrderList] = useState<any[]>([]);
  const [totalPriceOrder, setTotalPriceOrder] = useState<number>(0);
  const [totalPriceOriginal, setTotalPriceOriginal] = useState<number>(0);
  const [totalRefund, setTotalRefund] = useState<number>(0);

  const [orderStatus, setOrderStatus] = useState<number>(1);
  const [orderTitle, setOrderTitle] = useState<string>('주문');
  
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>('');

  useEffect(() => {
    if (!orderID) {
      setModalContent('잘못된 접근입니다.');
      setIsModalOpen(true);
    } else {
      getOrderDetail(
        orderID,
      ).then(result => {
        setOrderData(result.alc_order);
        setDetailOrderList(result.detail_rows);
        setOrderStatus(result.alc_order.alc_order_status);
      });
    };
  }, [customerID, orderID]);

  useEffect(() => {
    if (detailOrderList.length === 0) return;
    const price_total = detailOrderList.reduce((accumulator, current) => {
      return accumulator + (current.alc_detail_order_sell_price * current.alc_detail_order_quantity);
    }, 0);
    const price_total_origin = detailOrderList.reduce((accumulator, current) => {
      return accumulator + (current.alc_detail_order_sell_price_original * current.alc_detail_order_quantity);
    }, 0);
    const refund_total = detailOrderList.reduce((accumulator, current) => {
      return accumulator + (current.alc_detail_order_refund * current.alc_detail_order_quantity);
    }, 0);
    setTotalPriceOrder(price_total);
    setTotalPriceOriginal(price_total_origin);
    setTotalRefund(refund_total);
  }, [detailOrderList]);

  useEffect(() => {
    switch (orderStatus) {
      case 1 : setOrderTitle('주문'); break;
      case 2 : setOrderTitle('취소'); break;
      case 3 : setOrderTitle('교환'); break;
      case 4 : setOrderTitle('반품'); break;
      default : setOrderTitle('주문');
    }
  }, [orderStatus]);

  const validateAvailableExchangeAndReturn = (detail_order: any) => {
    let validation = true;
    if (detail_order.alc_detail_order_delivery_progress !== 4) {
      validation = false;
    } else if (
      detail_order.alc_detail_construction_complete_date > get_today_plus_object(7).year_month_day ||
      detail_order.alc_detail_delivery_complete_date > get_today_plus_object(7).year_month_day
    ) {
      validation = false;
    } else if (
      detail_order.alc_detail_order_refund_status !== 0 ||
      detail_order.alc_detail_exchange_status !== 0 ||
      detail_order.alc_detail_return_status !== 0
    ) {
      validation = false;
    }
    return validation;
  }
  
  return (
    <>
      <ModalClose
        isOpen={isModalOpen} 
        onClose={() => {
          setIsModalOpen(false);
          navigate('/home');
        }} 
        text={modalContent}
      />
      <SOL.OrderSelectOneContainer>
        <div className="order-select-one-header">
          <h2 className='order-select-one-title'>
            {orderTitle} 상세 내역
            <span className="count font-pretendard"> ({detailOrderList.length}건)</span>
          </h2>
          {/* 나중에 기능 추가되면 활성화 */}
          {/* <button className="receipt-btn">
            영수증
          </button> */}
        </div>
        <SOL.OrderListItem>
          <div className="order-item-header">
            <div className='order-info'>
              <div className="order-label">주문번호</div>
              <div className="order-info-flex-box">
                <p data-title="주문번호 " className='order-number'>{orderData.alc_order_number}</p>
                <p data-title="결제일자 " className='pay-date font-pretendard'>
                  ({orderData.alc_order_pay_date})
                </p>
              </div>
            </div>
            <div className='total-price'>
              <div className="price-label">총 결제금액</div>
              <span className='price-text font-pretendard'>
                {totalPriceOrder.toLocaleString()}원
                {/* {handleTotalPriceByDetails(orderData.details).toLocaleString()}원 */}
              </span>
            </div>
          </div>
          {detailOrderList.map((tmp, idx) =>
            <div
              key={idx}
              className="order-detail-box"
            >
              <div className="order-detail-left">
                <div className='order-detail-image'>
                    <img src={tmp.color_b2c_image} alt={tmp.color_name} />
                </div>
                <div className='order-detail-product'>
                    <p className='brand'>{tmp.brand_kor_name}</p>
                    <p className='product'>{tmp.product_kor_name}</p>
                    <p className='color'>{tmp.color_name}</p>
                    <p className='quantity'>{tmp.alc_detail_order_quantity}개</p>
                    {tmp.alc_detail_order_option_array && (
                      <p className="offer-list-option">
                        {extractColorOptionNodeNames(JSON.parse(tmp.alc_detail_order_option_array))}
                      </p>
                    )}
                </div>
              </div>
              <div className="order-detail-right">
                <div className='order-detail-price'>
                    <p className='detail-original-price'>{(tmp.alc_detail_order_sell_price_original * tmp.alc_detail_order_quantity).toLocaleString()}원</p>
                    <p className='detail-rated-price'>{(tmp.alc_detail_order_sell_price * tmp.alc_detail_order_quantity).toLocaleString()}원</p>
                </div>
                <div className='order-detail-status'>
                    <div className="deilvery-status">
                      {getAlcDetailStatusAndDeliveryProgress(tmp.alc_detail_status, tmp.alc_detail_order_delivery_progress)}
                    </div>
                    <div className="exchange-return-status">
                      {tmp.alc_detail_order_refund_status === 2 && (" (취소 신청)")}
                      {tmp.alc_detail_exchange_status !== 0 && ` (${tmp.alc_detail_exchange_status_name})`}
                      {tmp.alc_detail_return_status !== 0 && ` (${tmp.alc_detail_return_status_name})`}
                    </div>
                </div>
                <div className='order-detail-refund-button'>
                  {(tmp.alc_detail_order_delivery_progress <= 2 && tmp.alc_detail_order_refund_status === 0) && (
                    <button
                      onClick={() => handleSelectOneDetailOrderID(tmp.alc_detail_order_ID, 'cancel')}
                    >
                      취소 신청
                    </button>
                  )}
                  {validateAvailableExchangeAndReturn(tmp) && (
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleSelectOneDetailOrderID(tmp.alc_detail_order_ID, 'exchange')}
                      >
                        교환 신청
                      </button>
                      <button
                        onClick={() => handleSelectOneDetailOrderID(tmp.alc_detail_order_ID, 'return')}
                      >
                        반품 신청
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </SOL.OrderListItem>
        {(detailOrderList.filter(tmp => tmp.alc_detail_status !== 2).length > 0) && (
          <SOL.OrderSelectOneInfo>
            <div className="title-head">
              <h3>배송지 정보</h3>
            </div>
            <div className="content-box">
              <div className="flex-box">
                <span className="label">수령인</span>
                <span className="content">{orderData.alc_order_receiver_name}</span>
              </div>
              <div className="flex-box">
                <span className="label">휴대폰 번호</span>
                <span className="content">{orderData.alc_order_receiver_phone_number}</span>
              </div>
              <div className="flex-box">
                <span className="label">배송주소</span>
                <span className="content">
                  ({orderData.alc_order_receiver_zip_code}) {orderData.alc_order_receiver_road_address} {orderData.alc_order_receiver_detail_address}
                </span>
              </div>
              <div className="flex-box">
                <span className="label">배송메모</span>
                <span className="content">{!orderData.alc_order_memo ? "-" : orderData.alc_order_memo}</span>
              </div>
            </div>
          </SOL.OrderSelectOneInfo>
        )}
        {(detailOrderList.filter(tmp => tmp.alc_detail_order_refund_status === 2).length > 0) && (
          <SOL.OrderSelectOneInfo>
            <div className="title-head">
              <h3>취소 사유</h3>
            </div>
            <div className="content-box">
              {detailOrderList[0].alc_detail_cancel_reason === 9 ? (
                <div className="flex-box">
                  <p className="text-area"
                  >{detailOrderList[0].alc_detail_reason_direct}</p>
                </div>
              ) : (
                <div className="flex-box">
                  <span className="content">{cancelReasonSelectList[detailOrderList[0].alc_detail_cancel_reason].label}</span>
                </div>
              )}
            </div>
          </SOL.OrderSelectOneInfo>
        )}
        <SOL.OrderSelectOneInfo>
          <div className="title-head">
            <h3>결제 정보</h3>
            {(detailOrderList.filter(tmp => tmp.alc_detail_order_delivery_progress > 2).length === 0) && (
              <button
                onClick={() => {
                  handleCancelOrderTab('cancel_order', 'cancel');
                }}
              >
                전체 취소 신청
              </button>
            )}
            {(detailOrderList.filter(tmp => (validateAvailableExchangeAndReturn(tmp))).length === detailOrderList.length) && (
              <div>
                <button
                  onClick={() => {
                    handleCancelOrderTab('cancel_order', 'exchange');
                  }}
                >
                  전체 교환 신청
                </button>
                <button
                  className="ml-2"
                  onClick={() => {
                    handleCancelOrderTab('cancel_order', 'return');
                  }}
                >
                  전체 반품 신청
                </button>
              </div>
            )}
          </div>
          <div className="content-box between">
            <div className="flex-box">
              <span className="label">주문 금액</span>
              <span className="content">{totalPriceOriginal.toLocaleString()}원</span>
            </div>
            <div className="flex-box">
              <span className="label">할인 금액</span>
              <span className="content">-{(totalPriceOriginal-totalPriceOrder).toLocaleString()}원</span>
            </div>
            <div className="flex-box">
              <span className="label">배송비</span>
              <span className="content">{orderData.alc_status === 0 && `0원`}</span>
            </div>
            <div className="flex-box mt-3">
              <span className="label">총 결제금액</span>
              <p className="content final-price">
                {totalPriceOrder.toLocaleString()}
                <span>원</span>
              </p>
            </div>
            <div className="horizontal" />
            {/* TODO: Iamport에서 결제 정보 가져와서 스토리보드에 맞게 뿌려주자 */}
            {(detailOrderList.filter(tmp => tmp.alc_detail_order_refund > 0).length > 0) && (
              <>
                <div className="flex-box">
                  <span className="label">환불 금액</span>
                  <span className="content">-{totalRefund.toLocaleString()}원</span>
                </div>
                <div className="horizontal" />
              </>
            )}
            <div className="flex-box">
              <span className="label">{orderData.alc_order_pay_status_name}</span>
            </div>
            <div className="flex-box">
              <span className="label" style={{fontWeight: 400}}>{orderData.alc_order_method_kor}</span>
              <span className="content text-[12px]">{orderData.alc_order_pay_status === 2 ? "0원" : `${(orderData.alc_order_pay_total - totalRefund).toLocaleString()}원`}</span>
            </div>
          </div>
        </SOL.OrderSelectOneInfo>
      </SOL.OrderSelectOneContainer>
    </>
  )
};

export default OrderSelectOne;