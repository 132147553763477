import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as SOL from "../../styles/solda/StyledComponentSol";
import MySideNav from "./MySideNav";
import { getMyMainPageData, getSessionData } from "../../Axios";
import { handleScrollTopPosition } from "../../Utils";
import MyMain from "../../components/My/MyMain";
import OrderList from "../Order/OrderList";
import OrderCancelList from "../Order/OrderCancelList";
import OrderSelectOne from "../Order/OrderSelectOne";
import MyAddress from "../../components/My/MyAddress";
import CheckPassword from "../../components/My/CheckPassword";
import UpdateProfile from "../../components/My/UpdateProfile";
import { useMediaQuery } from "react-responsive";
import { ICustomerSummary, IOrderSummary } from "../../modules/model";
import HamburgerIcon from "../../assets/icons/icon_menu.svg";
import CancelOrder from "../../components/My/CancelOrder";
import OneCancelOrder from "../../components/My/OneCancelOrder";
import EstimateList from "../../components/My/EstimateList";
import EstimateSelectOne from "../../components/My/EstimateSelectOne";
import OfferList from "../Offer/OfferList";
import OfferSelectOne from "../Offer/OfferSelectOne";

const My: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [customerID, setCustomerID] = useState<number>(0);
  const [customerAlcStatus, setCustomerAlcStatus] = useState<number>(2);
  const [passwordLength, setPasswordLength] = useState<number>(0);
  const [orderID, setOrderID] = useState<number>(0);

  const isMobile = useMediaQuery({query: "(max-width: 500px)"});
  const [toggleMobileNav, setToggleMobileNav] = useState<boolean>(false);

  const [customerSummary, setCustomerSummary] = useState<ICustomerSummary>({
    customer_ID: 0,
    customer_name: '',
    account_name: '',
    alc_complex_ID: 0,
    alc_complex_name: '',
    alc_complex_logo: '',
    alc_complex_registe: '',
    alc_complex_expiration_period: '',
    alc_complex_modified: '',
  });
  const [orderSummary, setOrderSummary] = useState<IOrderSummary>({
    order_total: 0,
    pay_waiting: 0,
    pay_complete: 0,
    delivery_preparing: 0,
    delivery: 0,
    delivery_complete: 0,
    order_cancel: 0,
    order_return: 0,
    order_exchange: 0,
  });

  useEffect(() => {
    handleScrollTopPosition(0);
  }, [location.state.tab_name]);

  useEffect(() => {
    getSessionData().then(result => {
      setCustomerID(result.alc_customer_ID);
      setCustomerAlcStatus(result.alc_status);
    })
  }, [customerID]);

  useEffect(() => {
    if (!customerID) return;
    getMyMainPageData(customerID).then(result => {
      setCustomerSummary(result.customer_data);
      setOrderSummary(result.order_count);
    });
  }, [customerID, location.state]);

  const handleChangeTab = (tab_name: string) => {
    navigate(location.pathname, {state: {tab_name: tab_name}});
    setTimeout(() => {
      setToggleMobileNav(false);
    }, 100);
  };

  const handleCancelOrderTab = (tab_name: string, category: string) => {
    navigate(location.pathname, {state: {tab_name : tab_name, category: category}});
    setTimeout(() => {
      setToggleMobileNav(false);
    }, 100);
  };

  const changePasswordLength = (password_length: number) => {
    setPasswordLength(password_length);
  };

  useEffect(() => {
    if (!isMobile) setToggleMobileNav(false);
  }, [isMobile]);

  const handleCloseMobileNav = () => {
    setToggleMobileNav(false);
  };

  const handleSelectOneOrderID = (alc_order_ID: number) => {
    setOrderID(alc_order_ID);
    if (!alc_order_ID) return;
    handleChangeTab('order_detail');
  };

  const handleSelectOneEstimateID = (aos_estimate_ID: number) => {
    navigate(location.pathname, {state: {tab_name: 'estimate_detail', aos_estimate_ID: aos_estimate_ID}});
  };

  const handleSelectOneOfferID = (aos_order_ID: number) => {
    navigate(location.pathname, {state: {tab_name : 'offer_detail', aos_order_ID: aos_order_ID}});
  }

  const handleSelectOneDetailOrderID = (alc_detail_order_ID: number, category: string) => {
    navigate(
      location.pathname,
      {
        state: {
          tab_name: 'one_cancel_order',
          category: category,
          alc_detail_order_ID: alc_detail_order_ID,
        }
      },
    );
    setTimeout(() => {
      setToggleMobileNav(false);
    }, 100);
  };

  useEffect(() => {
		if (toggleMobileNav) {
      setTimeout(() => {
        document.body.style.position = "fixed";
      }, 300);
		} else {
      document.body.style.position = "static";
		};

		return () => {
			document.body.style.position = "static";
		}
	}, [toggleMobileNav]);

  return (
    <SOL.MyContainer>
      <MySideNav
        customerID={customerID}
        customerAlcStatus={customerAlcStatus}
        myPageTabName={location.state.tab_name}
        handleChangeTab={handleChangeTab}
        toggleMobileNav={toggleMobileNav}
        handleCloseMobileNav={handleCloseMobileNav}
      />
      <div className={`my-outlet ${(location.state.tab_name === 'check_password' || location.state.tab_name === 'update_profile') && "short-width"}`}>
        <div className="mobile-menu-box" onClick={() => setToggleMobileNav(true)}>
          <img src={HamburgerIcon} alt="" />
        </div>
        {location.state.tab_name === '' && (
          <MyMain
            customerID={customerID}
            customerAlcStatus={customerAlcStatus}
            customerSummary={customerSummary}
            orderSummary={orderSummary}
            handleChangeTab={handleChangeTab}
          />
        )}
        {/* ALC B2C */}
        {location.state.tab_name === 'order_list' && (
          <OrderList
            customerID={customerID}
            handleSelectOneOrderID={handleSelectOneOrderID}
            orderSummary={orderSummary}
            handleSelectOneDetailOrderID={handleSelectOneDetailOrderID}
          />
        )}
        {location.state.tab_name === 'order_cancel_list' && (
          <OrderCancelList
            customerID={customerID}
            orderSummary={orderSummary}
            handleSelectOneOrderID={handleSelectOneOrderID}
          />
        )}
        {location.state.tab_name === 'order_detail' && (
          <OrderSelectOne
            customerID={customerID}
            orderID={orderID}
            handleCancelOrderTab={handleCancelOrderTab}
            handleSelectOneDetailOrderID={handleSelectOneDetailOrderID}
          />
        )}
        {location.state.tab_name === 'check_password' && (
          <div className="flex justify-center">
            <CheckPassword
              customerID={customerID}
              handleChangeTab={handleChangeTab}
              changePasswordLength={changePasswordLength}
            />
          </div>
        )}
        {location.state.tab_name === 'update_profile' && (
          <div className="flex justify-center">
            <UpdateProfile
              customerID={customerID}
              handleChangeTab={handleChangeTab}
              passwordLength={passwordLength}
            />
          </div>
        )}
        {location.state.tab_name === 'cancel_order' && (
          <div className="flex justify-center">
            <CancelOrder
              customerID={customerID}
              orderID={orderID}
              handleSelectOneOrderID={handleSelectOneOrderID}
            />
          </div>
        )}
        {location.state.tab_name === 'one_cancel_order' && (
          <div className="flex justify-center">
            <OneCancelOrder
              customerID={customerID}
              handleSelectOneOrderID={handleSelectOneOrderID}
            />
          </div>
        )}
        {/* ALC B2C */}

        {/* AOS B2B */}
        {location.state.tab_name === 'offer_list' && (
          <div className="flex justify-center">
            <OfferList
              customerID={customerID}
              handleSelectOneOfferID={handleSelectOneOfferID}
            />
          </div>
        )}
        {location.state.tab_name === 'estimate_list' && (
          <div className="flex justify-center">
            <EstimateList
              customerID={customerID}
              customerAlcStatus={customerAlcStatus}
              handleSelectOneEstimateID={handleSelectOneEstimateID}
            />
          </div>
        )}
        {location.state.tab_name === 'estimate_detail' && (
          <div className="flex justify-center">
            <EstimateSelectOne
              customerAlcStatus={customerAlcStatus}
            />
          </div>
        )}
        {location.state.tab_name === 'offer_detail' && (
          <div className="flex justify-center">
            <OfferSelectOne
              customerAlcStatus={customerAlcStatus}
            />
          </div>
        )}
        {location.state.tab_name === 'my_address' && (
          <MyAddress
            customerID={customerID}
          />
        )}
        {/* AOS B2B */}
      </div>
    </SOL.MyContainer>
  );
};

export default My;