import React, { FC, useEffect, useRef, useState } from 'react';
import { getApplyDiscountRateFromSession, getBrandAlsStatus, getSessionData } from '../../Axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as SOL from "../../styles/solda/StyledComponentSol";
import useDetectScroll from '@smakss/react-scroll-direction';
import DefaultLogo from "../../assets/image_folder/common_images/ablack_white_text.png";
import IconSearch from "../../assets/icons/icon_search.svg";
import IconSearchBlack from "../../assets/icons/icon_search_black.svg"
import IconBag from "../../assets/icons/icon_bag.svg";
import IconUser from "../../assets/icons/icon_user.svg";
import { handleScrollTopPosition } from '../../Utils';
import { useCartContext } from '../../context/CartContext';
import { useDiscountRateContext } from '../../context/DiscountRateContext';
import { useSearchTextContext } from '../../context/SearchTextContext';
import { useShowInventoryContext } from '../../context/ShowInventoryContext';

import { useMediaQuery } from 'react-responsive';

const Header: FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { cartInfo, countCart } = useCartContext();
	const {searchTextInfo, setSearchTextInfo} = useSearchTextContext();
	const headerContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
	const {scrollPosition} = useDetectScroll();
	const { setDiscountRateInfo, setDiscountRateProductTypeInfo, setDiscountRateColorInfo } = useDiscountRateContext();
	const { showInventoryInfo, setShowInventoryInfo } = useShowInventoryContext();

	const [logoSource, setLogoSource] = useState(DefaultLogo);
	const [brand, setBrand] = useState<[]>([]);
	const [brandID, setBrandID] = useState(0);

	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const [searchText, setSearchText] = useState('');
	// const [displaySearchText, setDisplaySearchText] = useState('');
	
	const [alcStatus, setAlcStatus] = useState<number>(2);
	const [showOriginalPrice, setShowOriginalPrice] = useState<boolean>(JSON.parse(localStorage.getItem('show_original_price') || 'false'));

	const searchInputRef: React.RefObject<HTMLInputElement> = useRef(null);
	const isMobile = useMediaQuery({query: "(max-width: 500px)"});

	useEffect(() => {
		getSessionData().then(result => {
			setAlcStatus(result.alc_status);
		});
	}, []);

	useEffect(() => {
		if (!showOriginalPrice) {
			localStorage.setItem('show_original_price', 'false');
		} else {
			localStorage.setItem('show_original_price', 'true');
		}
		getApplyDiscountRateFromSession(
			localStorage.getItem('show_original_price') || 'false'
		).then((result: any) => {
			if (result === 'error_alc') {
				return
			} else {
				setDiscountRateInfo(JSON.parse(result.apply_discount_rate));
				setDiscountRateProductTypeInfo(JSON.parse(result.apply_discount_rate_product_type));
				setDiscountRateColorInfo(JSON.parse(result.apply_discount_rate_color));
			}
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showOriginalPrice]);

	useEffect(() => {
		const logo_url = localStorage.getItem('alc_logo_img') || '';
		if (logo_url !== '') {
			// setLogoSource(DefaultLogo); // FIXME: 모바일 레이아웃 잡기 위해서 기본 로고로
			setLogoSource(logo_url);
		} else {
			setLogoSource(DefaultLogo);
		}
	}, []);

	useEffect(() => {
		getBrandAlsStatus().then((result: any) => {
			setBrand(result);
		});
		countCart();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (location.pathname === '/product') {
			if (!headerContainerRef.current) return;
			if (scrollPosition.top < 30) {
				headerContainerRef.current.classList.remove("active");
			} else {
				headerContainerRef.current.classList.add("active");
			}
		}
	}, [location.pathname, scrollPosition.top]);

	useEffect(() => {
		if (location.pathname === '/product') {
			let splited_query_str = location.search.split('=');
			setBrandID(Number(splited_query_str[splited_query_str.length-1]));
		} else {
			setSearchTextInfo({search_text: ''});
		}
	}, [location, setSearchTextInfo]);

	useEffect(() => {
		if (!isSearchOpen) {
			setSearchText('');
			searchInputRef.current?.classList.remove("active", "inactive");
			searchInputRef.current?.classList.add("inactive");
			searchInputRef.current?.blur();
		} else {
			searchInputRef.current?.classList.remove("init", "inactive", "active");
			searchInputRef.current?.classList.add("active");
			setSearchText(searchTextInfo.search_text);
		}
	}, [isSearchOpen, searchTextInfo.search_text]);

	const handleSearch = () => {
		// FIXME: 일단 검색어 없어도 되고 2자 이상 적어도 검색하도록 해놓음
		if (searchText.trim() === '') {
			setIsSearchOpen(false);
			return;
		}
			// else {
			// if (searchText.trim().length < 2) return alert("최소 2글자 이상 입력하셔야 합니다.");

			// 일종의 초기화 검색어만 입력해주고
			localStorage.setItem('alc_product_filter', JSON.stringify([
				{
					category: 'search_text',
					value: 1,
					label: `검색어: ${searchText.replaceAll(' ', '')}`
				},
				{
					category: 'sort',
					value: 0,
					label: `"popularity"`
				}
			]));
			localStorage.setItem('alc_product_page', '1');
			setSearchTextInfo({search_text: searchText.replaceAll(' ', '')});
			navigate("/product");
			setIsSearchOpen(false);
			if (isMobile) {
				handleScrollTopPosition(400);
			} else {
				handleScrollTopPosition(650);
			}
		// }
	};

	useEffect(() => {
		if (searchTextInfo.search_text === '') {
			setSearchText('');
		}
	}, [searchTextInfo.search_text]);

	return (
		<header>
			<SOL.HeaderNavContainer ref={headerContainerRef} className={`${location.pathname === '/product' ? "" : "bg-main_black"}`}>
				<div className='header-width-wrapper'>
					<div className='nav-left-side'>
						<Link to="/home" className='navbar-logo'>
							<img src={logoSource} alt='logo_img' />
						</Link>
						<ul className={`navbar-menu`}>
							<li>
								<button
									className={`${brandID === 0 ? "text-main_white font-medium" : "text-white_light_grey"}`}
									onClick={() => {
										navigate("/product");
										handleScrollTopPosition(0);
										setSearchTextInfo({search_text: ''});
										localStorage.setItem('alc_product_filter', '[]');
										localStorage.setItem('alc_product_page', '1');
									}}
								>
									ALL
								</button>
							</li>
							{brand.map((element: any) => (
							<li key={element.brand_ID}>
								<button
									className={`${(element.brand_ID === brandID && location.pathname === '/product') ? "text-main_white font-medium" : "text-white_light_grey"}`}
									onClick={() => {
										navigate("/product?brand_ID="+element.brand_ID);
										handleScrollTopPosition(0);
										setSearchTextInfo({search_text: ''});
										localStorage.setItem('alc_product_filter', '[]');
										localStorage.setItem('alc_product_page', '1');
									}}
								>
									{element.brand_eng_name.toUpperCase()}
								</button>
							</li>
							))}
						</ul>
					</div>
					<div className='nav-right-side'>
						<span className='display-search-text'>
							
						</span>
						<button className={`navbar-search`}>
							<input
								type='text'
								ref={searchInputRef}
								className={`init`}
								maxLength={20}
								value={searchText}
								onBlur={() => {
									setTimeout(() => {
										setIsSearchOpen(false);
									}, 600);
								}}
								onChange={(e) => {
									setSearchText(e.target.value);
								}}
								onKeyDown={(e) => {
									if (e.nativeEvent.isComposing) return;
									if (e.key === 'Enter') {
										handleSearch();
									}
								}}
							/>
							<img
								src={isSearchOpen ? IconSearchBlack : IconSearch}
								alt='icon_search'
								className={`search-icon ${isSearchOpen ? 'active' : 'inactive'}`}
								onClick={() => {
									if (!isSearchOpen) {
										setIsSearchOpen(true);
										searchInputRef.current?.focus();
									} else {
										handleSearch();
									}
								}}
							/>
						</button>
						<Link to="/cart" className='navbar-cart mobile-none'>
							<img src={IconBag} alt='icon_bag' />
								<div className={`cart-badge ${cartInfo.cartCount === 0 && "cart-zero"}`}>
									{cartInfo.cartCount > 0 && cartInfo.cartCount}
								</div>
						</Link>
						<div 
							className='navbar-cart mobile-none cursor-pointer'
							onClick={() => navigate("/mypage", {state: {tab_name: ''}})}
						>
							<img src={IconUser} alt='icon_user' />
						</div>
						{(alcStatus === 1 || alcStatus === 3) && (
							<div className='navbar-cart'>
								<button className={`toggle-switch ${showOriginalPrice && "on"}`} onClick={() => setShowOriginalPrice(!showOriginalPrice)}>
									{/* {showOriginalPrice ? "ON" : "OFF"} */}
									<div className={`power-button ${showOriginalPrice && "on"}`} />
								</button>
							</div>
						)}
						{(alcStatus === 4) && (
							<div className='navbar-cart'>
								<button 
									className={`toggle-switch ${showInventoryInfo.show_inventory && "on"}`}
									onClick={() => {
										setShowInventoryInfo({
											show_inventory: !showInventoryInfo.show_inventory
										});
									}}
								>
									{/* {showOriginalPrice ? "ON" : "OFF"} */}
									<div className={`power-button ${showInventoryInfo.show_inventory && "on"}`} />
								</button>
							</div>
						)}
					</div>
				</div>
			</SOL.HeaderNavContainer>
		</header>
	);
};
export default Header;
