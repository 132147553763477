import React, { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { b2bUrl, baseUrl, getAlcb2bPromotionActiveList, getAlcb2cPromotionActiveList, getProductDataByColorArray } from '../../Axios';
import * as DABIN from "../../styles/dabin/StyledComponentDabin";
import { FaAngleRight } from "react-icons/fa6";
import { RiDoubleQuotesL,RiDoubleQuotesR } from "react-icons/ri";
import { IAlcBannerData,AlcBannerDataDefaultValue,IAlcBannerProductList } from "../../modules/model";
import { useMediaQuery } from 'react-responsive';
import { applyDiscountsForBannerProduct, mappingBannerProductData } from '../../Utils';
import { useDiscountRateContext } from '../../context/DiscountRateContext';

interface IPromotionProps {
  customerAlcStatus : number;
}

const PromotionDetail : FC<IPromotionProps> = ({
  customerAlcStatus,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [mainBannerData, setMainBannerData] = useState<IAlcBannerData>(AlcBannerDataDefaultValue);
  const [subBannerData, setSubBannerData] = useState<IAlcBannerData>(AlcBannerDataDefaultValue);
  const [eventBannerData, setEventBannerData] = useState<IAlcBannerData>(AlcBannerDataDefaultValue);

  const [mainBannerProducts, setMainBannerProducts] = useState<IAlcBannerProductList[]>([]);
  const [mainBannerSmallProducts, setMainBannerSmallProducts] = useState<IAlcBannerProductList[]>([]);
  const [subBannerProducts, setSubBannerProducts] = useState<IAlcBannerProductList[]>([]);

  const {alc_banner_group_ID} = location.state as { alc_banner_group_ID: number };

  const { discountRateInfo, discountRateProductTypeInfo, discountRateColorInfo } = useDiscountRateContext();

  useEffect(() => {
    // console.log("여기임 => ", alc_banner_group_ID)
    window.scrollTo(0, 0);
    if (!alc_banner_group_ID) return;
    if (customerAlcStatus === 0 || customerAlcStatus === 4) {
      getAlcb2cPromotionActiveList(alc_banner_group_ID).then((result) => {
        setMainBannerData(result.main_banner_data)
        setSubBannerData(result.sub_banner_data)
        setEventBannerData(result.event_banner_data)
      })
    } else if (customerAlcStatus === 1) {
      getAlcb2bPromotionActiveList(alc_banner_group_ID).then((result) => {
          setMainBannerData(result.main_banner_data)
          setSubBannerData(result.sub_banner_data)
          setEventBannerData(result.event_banner_data)
      })
    }
  } ,[alc_banner_group_ID, customerAlcStatus, navigate]);

  useEffect(() => {
    if (!mainBannerData) return;
    if (mainBannerData.alc_banner_product_list.length > 0) {
      const color_array: number[] = [];
      const main_banner_products = mainBannerData.alc_banner_product_list;
      main_banner_products.forEach(tmp => {
        color_array.push(tmp.alc_banner_product_color_ID);
      });
      getProductDataByColorArray(
        color_array,
      ).then(result => {
        const mapped_main_banner_products = mappingBannerProductData(
          main_banner_products,
          result,
        );
        const main_banner_product_list_apply_rate = applyDiscountsForBannerProduct(
          mapped_main_banner_products,
          discountRateInfo,
          discountRateProductTypeInfo,
          discountRateColorInfo,
        );
        setMainBannerProducts(main_banner_product_list_apply_rate.slice(0, 2));
        setMainBannerSmallProducts(main_banner_product_list_apply_rate.slice(2));
      })
    }
    if (subBannerData.alc_banner_product_list.length > 0) {
      const color_array: number[] = [];
      const sub_banner_products = subBannerData.alc_banner_product_list;
      sub_banner_products.forEach(tmp => {
        color_array.push(tmp.alc_banner_product_color_ID);
      });
      getProductDataByColorArray(
        color_array,
      ).then(result => {
        const mapped_sub_banner_products = mappingBannerProductData(
          sub_banner_products,
          result,
        );
        const sub_banner_product_list_apply_rate = applyDiscountsForBannerProduct(
          mapped_sub_banner_products,
          discountRateInfo,
          discountRateProductTypeInfo,
          discountRateColorInfo,
        );
        setSubBannerProducts(sub_banner_product_list_apply_rate);
      })
    }

    setMainBannerData(mainBannerData)
    setSubBannerData(subBannerData)

  },[discountRateColorInfo, discountRateInfo, discountRateProductTypeInfo, mainBannerData, subBannerData]);

  const handleClcik = (link:any ,index: number) => {
    window.location.href =`${link[index].alc_banner_product_link_url}`;
  };

  // 모바일 이미지 변경
  const isMobile = useMediaQuery({query: "(max-width: 769px)"});

  // hover 이미지 변경
  const [mousehoverIndex, setMouseoverIndex] = useState(0);
  const handleImageHover = (index: number) => {
    setMouseoverIndex(index + 1);
  };
  const handleImageLeave = (index: number) => {
    setMouseoverIndex(0);
  };

  return (
    <>
      <DABIN.PromotionPageContainer>
        <div className='PromotionPage_Banner'>
          <img 
            src={`${baseUrl}files/image_folder/alc_promotion_images/common/d73cdb22b821bbdcb567d80ff039a6f4d67d4c5f9af204cabf4525b36ed9324926ff119e33b93e475f52d7fbdc80e6c658d49b6c85cc0e8e3b7db30b3fb1b173.png`}
            alt="MainBannerImage"
          />
          <p>에이블랙 프로모션</p>
        </div>
        <div className="PromotionPage_Banner_line"></div>
        <DABIN.PromotionBannerContainer>
          {mainBannerData !== null && (
            <>
              <div className="PromotionPage_text-box">
                <span className='PromotionPage_date'>{mainBannerData.alc_banner_start_date}부터</span>
                <span className='PromotionPage_date'>{mainBannerData.alc_banner_end_date}까지</span>
                <p className='PromotionPage_title'>{mainBannerData.alc_banner_title}</p>
                <div className='PromotionPage_descripiton'>
                  <RiDoubleQuotesL />
                  <p>{mainBannerData.alc_banner_description_1}</p>
                  <p>{mainBannerData.alc_banner_description_2}</p>
                  <p>{mainBannerData.alc_banner_description_3}</p>
                  <RiDoubleQuotesR />
                </div>
              </div>
              <div className="MainPromotionContainer">
                <div className="MainPromotion_img" onClick={()=> window.location.href =`${mainBannerData.alc_banner_link_url}`}>
                  <img 
                  src={isMobile? `${baseUrl}${mainBannerData.alc_banner_img_mobile}` : `${baseUrl}${mainBannerData.alc_banner_img}`}
                  alt="ProductImage"
                  />
                </div>
                <div className="MainPromotion_box">
                  <div className="MainPromotion_text-box">
                    <p className='MainPromotion_title'>{mainBannerData.alc_banner_title}</p>
                    <div className='MainPromotion_description'>
                      <p>{mainBannerData.alc_banner_description_1}</p>
                      <p>{mainBannerData.alc_banner_description_2}</p>
                      <p>{mainBannerData.alc_banner_description_3}</p>
                    </div>
                  </div>
                  <div className="MainPromotion_products">
                    {mainBannerProducts.length > 0 && (
                    mainBannerProducts.map((product:any, index:number) => (
                      <div 
                        key={index}
                        className={`MainPromotion_product`} 
                        onClick={() => handleClcik(mainBannerProducts, index)}
                      >
                        <img 
                          className={`MainPromotion_product_img`}
                          src={mousehoverIndex === index+1 ? `${baseUrl}${product.alc_banner_product_img_hover}` : `${baseUrl}${product.alc_banner_product_img}`} 
                          alt="ProductImage" 
                          onMouseEnter={() => handleImageHover(index)}
                          onMouseLeave={() => handleImageLeave(index)}
                        />
                        <div className="Promotion_product_info MainPromotion_product_info">
                          <p className='Promotion_product-brand'>{product.alc_banner_product_brand_name}</p>
                          <p className='Promotion_product-name'>{product.alc_banner_product_name}</p>
                          <p className='Promotion_product-color'>{product.alc_banner_product_color}</p>
                          <p className='Promotion_product-Price original-Price'>
                            {product.alc_banner_product_original_price !== product.alc_banner_product_final_price 
                              ? product.alc_banner_product_original_price.toLocaleString() + "원" 
                              : null}
                          </p>
                          <p className='Promotion_product-Price'>
                            {product.alc_banner_product_final_price.toLocaleString()}원<FaAngleRight />
                          </p>
                          <span className='Promotion_product-Price rate-vat'>
                            {Number(product.apply_discount_rate_vat) === 0 ? "*부가세 별도" : "*부가세 포함"}
                          </span>
                        </div>
                      </div>
                    )))}
                  </div>
                  <div className="MainPromotion_small-products">
                    {mainBannerSmallProducts.map((product:any, index:number) => (
                      <div 
                        key={index}
                        className="MainPromotion_small-product"
                        onClick={() => handleClcik(mainBannerSmallProducts ,index)}
                      >
                        <img src={`${baseUrl}${product.alc_banner_product_img}`}
                          alt="ProductImage"
                          />
                        <div className="Promotion_product_info Promotion_small-product_info">
                          <p className='Promotion_product-brand Promotion_small-product-brand'>{product.alc_banner_product_brand_name}</p>
                          <p className='Promotion_product-name Promotion_small-product-name'>{product.alc_banner_product_name}</p>
                          <p className='Promotion_product-color Promotion_small-product-color'>{product.alc_banner_product_color}</p>
                          <p className='Promotion_product-Price original-Price Promotion_small-product-original-Price'>
                          {product.alc_banner_product_original_price !== product.alc_banner_product_final_price 
                            ? product.alc_banner_product_original_price.toLocaleString() + "원" 
                            : null}
                          </p>
                          <p className='Promotion_product-Price Promotion_small-product-Price'>
                            {product.alc_banner_product_final_price.toLocaleString()}원<FaAngleRight />
                          </p>
                          <span className='Promotion_product-Price rate-vat Promotion_small-product-rate-vat'>
                            {Number(product.apply_discount_rate_vat) === 0 ? "*부가세 별도" : "*부가세 포함"}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="PromotionPage_small-products">
                <h2 className='PromotionPage_title'>추천 상품</h2>
                {mainBannerSmallProducts.map((product:any, index:number) => (
                  <div 
                    key={index}
                    className="MainPromotion_small-product"
                    onClick={() => handleClcik(mainBannerSmallProducts, index)}
                  >
                    <img src={`${product.alc_banner_product_img.startsWith('files/') ? baseUrl : b2bUrl}${product.alc_banner_product_img}`}
                      alt="ProductImage"
                      />
                    <div className="Promotion_product_info Promotion_small-product_info">
                      <p className='Promotion_product-brand Promotion_small-product-brand'>{product.alc_banner_product_brand_name}</p>
                      <p className='Promotion_product-name Promotion_small-product-name'>{product.alc_banner_product_name}</p>
                      <p className='Promotion_product-color Promotion_small-product-color'>{product.alc_banner_product_color}</p>
                      <p className='Promotion_product-Price original-Price Promotion_small-product-original-Price'>
                        {product.alc_banner_product_original_price !== product.alc_banner_product_final_price 
                          ? product.alc_banner_product_original_price.toLocaleString() + "원" 
                          : null}
                      </p>
                      <p className='Promotion_product-Price Promotion_small-product-Price'>
                        {product.alc_banner_product_final_price.toLocaleString()}원<FaAngleRight />
                      </p>
                      <span className='Promotion_product-Price rate-vat Promotion_small-product-rate-vat'>
                        {Number(product.apply_discount_rate_vat) === 0 ? "*부가세 별도" : "*부가세 포함"}
                      </span>
                    </div>
                  </div>
                ))}
              </div> {/* 메인 프로모션 */}
            </>
          )}
          <div className="SubPromotionContainer">
            {subBannerData !== null && (
              <>
                <div className="SubPromotion_text-box">
                        <p className='SubPromotion_title'>{subBannerData.alc_banner_title}</p>
                        <div className='SubPromotion_description'>
                          <p>{subBannerData.alc_banner_description_1}</p>
                          <p>{subBannerData.alc_banner_description_2}</p>
                          <p>{subBannerData.alc_banner_description_3}</p>
                        </div>
                </div>
                <div className="SubPromotion_products">
                  {subBannerProducts.length > 0 && (
                    subBannerProducts.map((product:any, index:number) => (
                      <div 
                        key={index}
                        className="SubPromotion_product" 
                        onClick={() => handleClcik(subBannerProducts, index)}
                      >
                        <img 
                          className="SubPromotion_product_img"
                          src={isMobile? `${baseUrl}${product.alc_banner_product_img_mobile}` : `${baseUrl}${product.alc_banner_product_img}`}
                          alt="ProductImage" 
                        />
                        <div className="Promotion_product_info SubPromotion_product_info">
                          <p className='Promotion_product-brand'>{product.alc_banner_product_brand_name}</p>
                          <p className='Promotion_product-name'>{product.alc_banner_product_name}</p>
                          <p className='Promotion_product-color'>{product.alc_banner_product_color}</p>
                          <p className='Promotion_product-Price original-Price'>
                            {product.alc_banner_product_original_price !== product.alc_banner_product_final_price 
                              ? product.alc_banner_product_original_price.toLocaleString() + "원" 
                              : null}
                          </p>
                          <p className='Promotion_product-Price'>
                            {product.alc_banner_product_final_price.toLocaleString()}원<FaAngleRight />
                          </p>
                          <span className='Promotion_product-Price rate-vat'>
                            {Number(product.apply_discount_rate_vat) === 0 ? "*부가세 별도" : "*부가세 포함"}
                          </span>
                        </div>
                      </div>
                    ))
                  )}
              </div>
              </>
            )}
          </div> {/* 서브 프로모션 */}
        </DABIN.PromotionBannerContainer>
        <DABIN.EventBannerContainer>
          {eventBannerData !== null && (
            <>
              <div className="EventBanner" onClick={()=>{window.location.href =`${eventBannerData.alc_banner_link_url}`}}>
                <img
                  src={isMobile? `${baseUrl}${eventBannerData.alc_banner_img_mobile}` : `${baseUrl}${eventBannerData.alc_banner_img}`}
                  alt="ProductImage"
                  />
                <div className="EventBanner_text-box">
                  <p className="EventBanner_title">{eventBannerData.alc_banner_title}</p>
                  <div className='EventBanner_description'>
                    <p>{eventBannerData.alc_banner_description_1}</p>
                    <p>{eventBannerData.alc_banner_description_3}</p>
                    <p>{eventBannerData.alc_banner_description_2}</p>
                  </div>
                  <p 
                    className="EventBanner_description-LinkText"
                  >
                    {eventBannerData.alc_banner_description_4}<FaAngleRight />
                    </p>
                </div>
              </div>
            </>
          )}
        </DABIN.EventBannerContainer>
      </DABIN.PromotionPageContainer>
    </>
  )
}

export default PromotionDetail