import React, { FC, useEffect, useState } from 'react';
import { getSessionData } from '../../Axios';
import PropTypes from 'prop-types';
import * as SOL from "../../styles/solda/StyledComponentSol";
import ShippingPrecautions from "../../assets/image_folder/common_images/shipping_precautions.jpg"
import CommonNotice from "../../assets/image_folder/common_images/common_notice.jpg";

interface IProductProps {
    product?: any;
}

const ProductTab: FC<IProductProps> = ({ product }) => {
	const [activeTab, setActiveTab] = useState<string>('details');
    const [alcStatus, setAlcStatus] = useState<number>(2);
    const operatedBrandByAmbienceList = [3, 1, 2, 19, 65]; // 베르판, 루이스폴센, 루체플랜, 비비아, 리파

    useEffect(() => {
        getSessionData().then(result => {
            setAlcStatus(result.alc_status);
        });
    }, []);

	const handleTabChange = (tabName: string) => {
		setActiveTab(tabName);
	};

    return (
        <SOL.ProductTabContainer>
            <div className="tabs">
                <button className={`tab-button ${activeTab === 'details' ? 'active' : ''}`} onClick={() => handleTabChange('details')}>제품 상세</button>
                <button className={`tab-button ${activeTab === 'shipping' ? 'active' : ''}`} onClick={() => handleTabChange('shipping')}>유의사항</button>
            </div>
            <div className={`tab-content detail-content ${activeTab === 'details' ? 'active' : ''}`}>
                {(alcStatus === 0 && operatedBrandByAmbienceList.includes(product.brand_ID)) && (
                    <>
                        <img src={CommonNotice} alt='common_image' />
                    </>
                )}
                <img src={product.color_b2c_detail_image} alt={product.color_name} />
                {product.color_b2c_detail_image2 && <img src={product.color_b2c_detail_image2} alt={product.color_name} />}
                {product.color_b2c_detail_image3 && <img src={product.color_b2c_detail_image3} alt={product.color_name} />}
            </div>
            <div className={`tab-content ${activeTab === 'shipping' ? 'active' : ''}`}>
                <div>
                    <img src={ShippingPrecautions} alt='Shipping_precautions' />
                </div>
            </div>
            {product.color_editor && (
                <div
                    dangerouslySetInnerHTML={{ __html: product.color_editor }}
                    className='max-w-[100%] p-5'
                />
            )}
        </SOL.ProductTabContainer>
    );
};

ProductTab.propTypes = {
    product: PropTypes.any,
};

ProductTab.defaultProps = {
    product: [],
};

export default ProductTab;
