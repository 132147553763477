import styled, { css } from "styled-components";
import theme from "../theme";
import check_done_icon from "../../assets/icons/check-done.svg";
import check_done_gray_icon from "../../assets/icons/check_done_gray.svg";

const pretendard = css`
  @import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");
  font-family: 'Pretendard' !important;
`;

const xs = theme.screens.xs;
const sm = theme.screens.sm;
const md = theme.screens.md;
const lg = theme.screens.lg;
const xl = theme.screens.xl;

const discount_rate_vat_style = `
  margin-top: 10px;
  color: ${theme.colors.dark_grey};
  ${theme.typography.caption_12_R}
`;

export const HeaderNavContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: ${theme.colors.main_black}; */
  height: 80px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @media ${lg} {
    padding: 0 15px;
    height: 60px;
  }

  &::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 0%;
    background-color: ${theme.colors.main_black};
    transition-duration: 400ms;
    transition-property: all;
    z-index: -1;

    @media ${lg} {
      height: 100%;
    }
  }

  &.active {

    &::after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: ${theme.colors.main_black};
      z-index: -1;
    }
  }

  & .header-width-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    & .nav-left-side, .nav-right-side {
      display: flex;
      height: 100%;
      align-items: center;
    }

    & .display-search-text {
      color: ${theme.colors.main_white};
      margin-right: 10px;
      transition: all 500ms;
    }
  
    & .navbar-logo {
  
      & img {
        height: 34px;
        margin-right: 8px;
  
        @media ${lg} {
          margin-top: 5px;
          height: 35px;
        }
      }
    }
  
    & .navbar-menu {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
  
      & li {
        padding: 0 12px;
        ${theme.typography.text_14_R}
  
        & button{
          text-decoration: none;
          transition: color 0.3s ease;
  
          &:hover {
            color: ${theme.colors.main_white};
          }
        }
      }

      @media ${lg} {
        display: none;
      }
    }
  
    & .menu-toggle {
      display: none; /* 기본적으로는 표시하지 않음 */
      background: none;
      border: none;
      color: ${theme.colors.main_white};
      font-size: 24px;
      cursor: pointer;
      position: fixed; /* 고정 포지셔닝 */
      right: 10px; /* 오른쪽 끝에서 적당한 간격 */
      z-index: 1001; /* 헤더 바로 위에 올라가게 z-index 설정 */
    }
  
    & .navbar-search {
      position: relative;
      display: flex;
      align-items: center;
  
      & .search-icon {
        position: absolute;
        right: 0;
        color: ${theme.colors.main_white};
        transition: 150ms all;
  
        &.active {
          color: ${theme.colors.main_black};
        }
        &.inactive {
          transition-delay: 150ms;
        }
      }
  
      & input {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        font-size: 13px;
        outline: none;
        opacity: 0;

        @media ${lg} {
          height: 30px;
        }
  
        &.active {
          animation: slideShow 0.5s ease-in-out;
          animation-fill-mode: forwards;

          @media ${lg} {
            animation: slideShowMini 0.5s ease-in-out;
            animation-fill-mode: forwards;
          }
        }
        @keyframes slideShow {
          0% {
            padding-left: 0;
            opacity: 0;
            width: 40px;
            color: ${theme.colors.main_white};
          }
          30% {
            padding-left: 0;
            opacity: 1;
            width: 40px;
            color: ${theme.colors.main_white};
          }
          60% {
            padding-left: 0;
            opacity: 1;
            width: 40px;
            color: ${theme.colors.main_white};
          }
          100% {
            padding-left: 20px;
            opacity: 1;
            width: 200px;
            color: ${theme.colors.main_black};
          }
        }
        @keyframes slideShowMini {
          0% {
            padding-left: 0;
            opacity: 0;
            width: 40px;
            color: ${theme.colors.main_white};
          }
          30% {
            padding-left: 0;
            opacity: 1;
            width: 40px;
            color: ${theme.colors.main_white};
          }
          60% {
            padding-left: 0;
            opacity: 1;
            width: 40px;
            color: ${theme.colors.main_white};
          }
          100% {
            padding-left: 20px;
            opacity: 1;
            width: 160px;
            color: ${theme.colors.main_black};
          }
        }
        
        &.inactive {
          animation: slideHide 500ms ease-in-out;
          animation-fill-mode: forwards;

          @media ${lg} {
            animation: slideHideMini 0.5s ease-in-out;
            animation-fill-mode: forwards;
          }
        }
        @keyframes slideHide {
          0% {
            padding-left: 20px;
            opacity: 1;
            width: 200px;
            color: ${theme.colors.main_black};
          }
          40% {
            padding-left: 0;
            opacity: 1;
            width: 40px;
            color: ${theme.colors.main_white};
          }
          70% {
            padding-left: 0;
            opacity: 1;
            width: 40px;
            color: ${theme.colors.main_white};
          }
          100% {
            padding-left: 0;
            opacity: 0;
            width: 40px;
            color: ${theme.colors.main_white};
          }
        }
        @keyframes slideHideMini {
          0% {
            padding-left: 20px;
            opacity: 1;
            width: 160px;
            color: ${theme.colors.main_black};
          }
          40% {
            padding-left: 0;
            opacity: 1;
            width: 40px;
            color: ${theme.colors.main_white};
          }
          70% {
            padding-left: 0;
            opacity: 1;
            width: 40px;
            color: ${theme.colors.main_white};
          }
          100% {
            padding-left: 0;
            opacity: 0;
            width: 40px;
            color: ${theme.colors.main_white};
          }
        }
  
        &.init {
          animation: none;
        }
      }
    }
  
    & .navbar-cart {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;

      & .cart-badge {
        position: absolute;
        bottom: 3px;
        right: 4px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        border-radius: 50%;
        background-color: ${theme.colors.point_red};
        text-align: center;
        font-size: 10px;
        color: ${theme.colors.main_white};

        &.cart-zero {
          bottom: 5px;
          right: 5px;
          width: 12px;
          height: 12px;
        }
      }

      @media ${lg} {

        &.mobile-none {
          display: none;
        }
      }
    }

    & .toggle-switch {
      position: relative;
      display: flex;
      align-items: center;
      height: 16.5px;
      width: 26px;
      border-width: 1.5px;
      border-style: solid;
      border-color: ${theme.colors.main_white};
      border-radius: 15px;
      background-color: rgba(217, 78, 73, 0);
      transition: all 0.3s;

      &.on {
        background-color: rgba(217, 78, 73, 1);
        border-color: ${theme.colors.point_red};
      }

      & .power-button {
        position: absolute;
        left: 10%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${theme.colors.main_white};
        transition: all 0.3s;

        &.on {
          left: 50%;
        }
      }
    }
  }
`;

export const FooterContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background-color: ${theme.colors.main_black};
  z-index: 1;

  @media ${sm} {
    padding-bottom: 60px;
    height: 620px;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1200px;
  height: 100%;

  & .left-side {
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${theme.colors.main_white};

    & .customer-service {
      
      & .center {
        ${theme.typography.text_16_M}
      }
      & .phone {
        margin: 20px 0;
        ${theme.typography.large_30_B}
        font-weight: 600;
      }
      & .open, .closed {
        line-height: 18px;
        ${theme.typography.text_14_R}
      }
    }

    & .social {
      display: flex;
      flex-direction: row;
      gap: 5px;
      
      & .link-btn {
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  & .right-side {
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    color: ${theme.colors.light_grey};

    & .ablack_logo {
      height: 78px;
    }
    & .slogan {
      color: ${theme.colors.main_white};
      font-size: 15px;
      ${theme.typography.text_16_M}
    }

    & .terms {
      margin: 25px 0 40px;
      ${theme.typography.text_14_R}
    }

    & .company-registration, .address, .copy-right {
      ${theme.typography.caption_12_R}
    }

    & .copy-right {
      line-height: 12px;
      margin-top: 10px;
    }
  }
  
  @media ${sm} {
    flex-direction: column;
    padding: 0 30px;
    width: 100%;

    & .left-side {
      margin-top: 40px;
      width: 100%;
      height: 200px;

      & .customer-service {

        & .center {
          
        }
        & .phone {

        }
        & .open, .closed {

        }
      }
    }

    & .right-side {
      border-top: 1px solid ${theme.colors.main_white};
      align-items: start;
      padding: 30px 0;
      width: 100%;
      height: 50%;

      & .ablack_logo {
        height: 46px;
      }
      & .slogan {
        display: none;
      }
      & .terms {
        margin: 20px 0;
        ${theme.typography.text_14_R}
      }

      & .company-registration, .address, .copy-right {
        ${theme.typography.caption_12_R}
        line-height: 14px;
      }

      & .copy-right {
        ${theme.typography.caption_10_R}
      }
    }
  }

  @media ${xs} {
    padding: 0 15px; 
  }
`;

export const ProductBrandBanner = styled.div`
  width: 100%;
  & .banner-container {
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;

    & img {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 700px;
      z-index: -1;
      object-fit: cover;
      /* object-position: center 35%; */
    }

    & h3 {
      position: fixed;
      font-size: 128px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.7);
      letter-spacing: 5px;
    }

    @media ${sm} {
      
      & img {
        margin-top: 60px;
      }
    }
    @media ${xs} {
      height: 500px;
      
      & img {
        background-color: ${theme.colors.main_black};
        height: 500px;
      }
      
      & h3 {
        font-size: 54px;
        color: rgba(255, 255, 255, 0.7);
        text-align: center;
      }
    }
  }
`;

export const ProductCategory = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid ${theme.colors.light_mid_grey};
  background-color: ${theme.colors.main_white};

  & .category-grid {
    display: flex;
    /* grid-template-columns: repeat(4, 1fr); */
    width: fit-content;
    /* justify-content: center;
    align-items: center; */
    
    & button {
      margin: 0 16px;
      ${theme.typography.title_20_SB}
    }
  }

  @media ${xs} {

    & .category-grid {
      display: flex;
      overflow-x: scroll;
      padding: 20px;

      & button {
        font-size: 16px;
        margin: 5px 10px 0;
      }
    }
  }
`;

export const ProductListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 60px;
  padding: 80px 0;
  background-color: ${theme.colors.product_list_bg};
  @media ${xl} {
    padding: 80px 20px;
    gap: 40px;
  }

  @media ${md} {
    gap: 20px;
  }

  @media ${sm} {
    padding: 20px 0 60px;
  }

  & .no-products {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5em;
    min-height: 100vh;
    width: 100%;
    max-width: 920px;
    @media ${xs} {
      max-width: 360px;
      min-height: 50vh;
    }
  }
`;

export const ProductSideNav = styled.div`
  width: 220px;
  min-width: 220px;
  & .productSideContainer {
      position: initial;
    &.productSideContainer-sticky {
      position: sticky;
      top: 100px;
      @media ${lg} {
        position: initial;
        top: initial;
      }
    }
    & .mobile-close {
    display: none;
    }
    & .total-count-box {
      margin-bottom: 15px;

      & span {
        color: ${theme.colors.main_black};
        ${theme.typography.text_14_R}
      }
    }
    & .general-filter-section {
      & .control {
        display: flex;
        justify-content: space-between;
        ${theme.typography.caption_12_R}

        & span {
          color: ${theme.colors.main_black};
        }
        & button {
          color: ${theme.colors.dark_grey};
        }
      }

      & .grid-box {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        & .filter-tag {
          display: flex;
          margin-right: 8px;
          margin-bottom: 8px;
          padding: 6px;
          color: ${theme.colors.main_white};
          background-color: ${theme.colors.mid_grey};
          ${theme.typography.caption_12_R}

          & img {
            margin-left: 6px;
          }
        }
      }

    }

    & .side-section {
      margin-top: 15px;
      border-top: 1px solid #000;

      &.border-hide {
        margin-top: 0;
        border: 0;
      }

      & h3 {
        line-height: 40px;
        color: ${theme.colors.main_black};
        ${theme.typography.title_20_SB}

        & .notice {
          color: ${theme.colors.dark_grey};
          ${theme.typography.caption_12_R}
        }
      }

      & .list-item {
        display: block;
        line-height: 30px;
        color: ${theme.colors.mid_grey};
        ${theme.typography.text_16_M}

        &.active {
          color: ${theme.colors.main_black};
        }
      }

      & .reset-btn {
        margin-top: 20px;
        width: 100%;
        height: 50px;
        border: 1px solid #000;
        ${theme.typography.text_16_M}
      }

      & .massive-order {
        margin-top: 15px;
        width: 100%;
        height: 60px;
        border-radius: 40px;
        background-color: ${theme.colors.main_black};
        text-align: center;

        & p {
          color: ${theme.colors.main_white};
          ${theme.typography.title_20_SB}

          &.massive-order-phone-number {
            ${theme.typography.text_16_M}
          }
        }
      }

      & .apply-btn {
        display: none;
      }

      &.price-list-box {

        & .price-slide-indicator {
          display: flex;
          justify-content: center;
          margin-bottom: 15px;
          width: 100%;
          ${theme.typography.text_14_R}
        }
      }

      &.control-box {
        display: flex;
      }
    }

    & .brand-list-box {

    }
  }
  @media ${lg} {
    width: 180px;
    min-width: 180px;
  }
  @media ${sm} {
    position: fixed;
    padding-inline: 15px;
    top: 100vh;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1001;
    transition: all 1s;
    opacity: 0;
    &.mobile-show {
      top: 0;
      opacity: 1;
    }
    & .productSideContainer {
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      padding-block: 20px;
      position: relative;
      top: initial;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & .mobile-close {
        display: flex;
        justify-content: end;
      }
      & .total-count-box {
        display: none;
      }
      & .side-section {
        &.border-hide {
          width: 200%;
        }
        & h3 {
          margin-top: 10px;
          & .notice {
          }
        }
        & .list-item {
          line-height: 34px;
        }
        &.control-box {
          gap: 10px;
        }
        & .reset-btn {
          width: 50%;
        }
        & .massive-order {
          display: none;
        }
        & .apply-btn {
          display: inline;
          margin-top: 20px;
          width: 50%;
          height: 50px;
          background-color: ${theme.colors.main_black};
          color: ${theme.colors.main_white};
        }
      }
    }
  }
`;

export const ProductFilterSectionMobile = styled.div`
  margin-top: 20px;
  padding: 18px 0;
  border-color: ${theme.colors.mid_grey};
  border-style: solid;
  border-width: 1px 0 1px;

  & .control {
    display: flex;
    justify-content: space-between;
    ${theme.typography.caption_12_R}

    & span {
      color: ${theme.colors.main_black};
    }
    & button {
      color: ${theme.colors.dark_grey};
    }
  }

  & .grid-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    & .filter-tag {
      display: flex;
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 6px;
      color: ${theme.colors.main_white};
      background-color: ${theme.colors.mid_grey};
      ${theme.typography.caption_12_R}

      & img {
        margin-left: 6px;
      }
    }
  }
`;

export const FilterPriceSlider = styled.div`
  position: relative;
  margin-bottom: 30px;
  height: 2px;
  width: 100%;
  border-radius: 10px;
  background-color: ${theme.colors.main_black};
`;

export const FilterPriceInnerSlider = styled.div<{$rangeMinPercent: number,$rangeMaxPercent: number}>`
  position: absolute;
  left: ${props => props.$rangeMinPercent}%;
  right: ${props => props.$rangeMaxPercent}%;
  height: 2px;
  border-radius: 10px;
  background-color: ${theme.colors.main_black};
`;

export const FilterPriceRangeWrapper = styled.div`
  position: relative;
`;

export const FilterPriceRangeMin = styled.input`
  position: absolute;
  height: 2px;
  width: 100%;
  appearance: none;
  background: none;
  pointer-events: none;
  outline: none;

  &::-webkit-slider-thumb {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: ${theme.colors.main_black};
    -webkit-appearance: none;
    pointer-events: auto;
    cursor: pointer;

    @media ${xs} {
        background-color: ${theme.colors.main_white};
        border: 2px solid ${theme.colors.main_black};
    }
  }
`;
export const FilterPriceRangeMax = styled(FilterPriceRangeMin)``;

export const ProductSort = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  max-width: 1200px;

  & .sort-button-box {
    & button {
      font-size: 12px;
      margin-left: 10px;
  
      &.active {
        text-decoration : underline;
        text-underline-position : under;
      }
    }
  }

  @media ${sm} {
    display: block;
    margin: 0 15px;
    min-width: 100%;
    
    & .mobile-sort-control {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${theme.typography.text_14_R}
      @media ${sm} {
        padding-inline: 20px;
      }
      @media ${xs} {
        padding-inline: initial;
        width: 360px;
        margin: 0 auto;
      }
      & .sort-and-filter {
        display: flex;
        align-items: center;
        gap: 15px;

        @media ${xs} {
          gap: 10px;
        }

        & .sort-box {
          position: relative;

          & .sort-button {
            display: flex;
            align-items: center;
            gap: 0 4px;
          }

          & .sort-dropdown {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 10px;
            left: 50%;
            top: 24px;
            width: 100px;
            height: 0;
            border: 1px solid ${theme.colors.product_list_bg};
            background-color: ${theme.colors.main_white};
            overflow-y: hidden;
            z-index: 100;
            transform: translateX(-50%);
            
            &.active {
              transition: all 0.3s;
              border-color: #000;
              height: 140px;
            }

            & .sort-items {
              line-height: 30px;
              border-bottom: 1px solid ${theme.colors.dark_grey};

              &.last-item {
                border: 0;
              }
            }
          }
        }
      }
    }
  }

`;

export const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 그리드 아이템의 최소 크기를 250px로 설정하고 가능한 많은 열을 채움 */
  gap: 10px;
  justify-content: center;
  width: 100%;
  max-width: 920px;
  margin: 0 auto;

  @media ${md} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${sm} {
    padding: 0 20px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${xs} {
    padding: 0;
    max-width: 360px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
`;

export const ProductCard = styled.div`
  position: relative; // 할인율 태그를 절대 위치로 설정하기 위함
  margin: 15px auto; // 상품 카드 주위에 마진 추가
  width: 100%; // 기본 너비 설정
  /* max-width: 250px; // 최대 너비 설정 */
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;

  & .product-image-container {
    position: relative;
    width: 100%;
    aspect-ratio: 1;
    /* height: 200px; // 이미지 컨테이너의 높이 */
    background-color: ${theme.colors.main_white};
    display: flex;
    align-items: center;
    justify-content: center;

    & .inventory-warehouse {
      position: absolute;
      left: 0;
      top: 0;
      padding: 6px 10px;
      background-color: ${theme.colors.dark_grey};
      color: ${theme.colors.main_white};
      ${theme.typography.text_14_R}
    }
  
    img {
      max-width: 100%;
      max-height: 100%;
      aspect-ratio: 1;
      object-fit: contain;
    }

    & .product-discount {
      position: absolute;
      display: block; // 할인율을 항상 보이게 함
      top: 0;
      left: 0;
      padding: 8px 10px;
      text-align: center;
      color: white;
      ${theme.typography.caption_12_R}
    }

    & .add-to-cart-icon {
      position: absolute;
      bottom: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  &:hover  {
    visibility: visible; /* 호버 상태에서 보임 */
    opacity: 1;
  }

  & .product-details {

    & .product-brand {
      margin-top: 10px;
      color: ${theme.colors.main_black};
      ${theme.typography.caption_12_R}
    }
  
    & .product-name {
      margin-top: 7px;
      color: #000;
      ${theme.typography.text_14_R}
      font-weight: 500;
    }
  
    & .product-color {
      margin-top: 3px;
      color: ${theme.colors.dark_grey};
      ${theme.typography.caption_12_R}
    }
  
    & .product-price {
      margin-top: 16px;
  
      & .original-price {
        display: block;
        margin-bottom: 2px;
        color: ${theme.colors.dark_grey};
        ${theme.typography.caption_12_R}
        text-decoration: line-through;
      }
  
      & .final-price {
        color: ${theme.colors.main_black};
        ${theme.typography.text_16_M}
      }

      & .product-discount {
        margin-left: 10px;
        color: ${theme.colors.point_red};
        ${theme.typography.text_16_M}

        &.additional-discount {
          margin-left: 0;
        }
      }

      & .arrow-icon-img {
        display: inline-block;
        margin: 0 4px;
        width: 18px;
        height: 18px;
        transform: translateY(3px) rotate(180deg);
      }

      & .compound-discount {
        margin-left: 4px;
        ${theme.typography.caption_12_R}
      }
    }

    & .product-discount-rate-vat {
      ${discount_rate_vat_style}
    }
  }

  @media ${xs} {
    
    & .product-image-container {

      & .inventory-warehouse {
        ${theme.typography.caption_12_R}
      }
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  list-style: none; /* 기본 목록 스타일 제거 */
  flex-wrap: wrap;

  & .page-navigation-box {
    display: flex;
    justify-content: center;
    width: 50px;

    & button {
      display: flex;
      justify-content: center;
      width: 18px;
      margin: 0 4px;
      
      & img {
        height: 14px;
      }
    }
  }

  & .pagination-link {
    width: 24px;
    margin: 0 4px;
    color: ${theme.colors.mid_grey};
    font-size: 16px;
    text-align: center;
    text-decoration: none;

    &.active {
      color: ${theme.colors.main_black};
      text-decoration: underline;
      text-underline-offset : 5px;
    }
  }
`;

export const SelectDropDownContainer = styled.div<{$length: number}>`
  position: relative;
  margin-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
  z-index: 971;

  & .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    width: 100px;
    height: 35px;
    border: 1px solid ${theme.colors.light_mid_grey};
    border-radius: 5px;
    background-color: ${theme.colors.main_white};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  & .dropdown-body {
    position: absolute;
    top: 40px;
    width: inherit;
    height: ${props => (props.$length * 35)}px;
    overflow: hidden;
    background-color: ${theme.colors.main_white};
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: ${theme.zAxis.items};
    transition: all 0.3s;
    
    & .dropdown-item {
      display: flex;
      padding: 0 10px;
      width: 100px;
      height: 35px;
      line-height: 35px;
      border-bottom: 1px solid ${theme.colors.light_mid_grey};

      &:hover {
        background-color: red;
      }
    }
  }
`;

export const MobileNavContainer = styled.header`
  position: fixed;
  display: none;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 2%;
  width: 100%;
  height: 60px;
  background-color: ${theme.colors.main_black};
  z-index: 1000;

  & .mobile-nav-tab {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 24%;

    & .icon-image-box {
      position: relative;
      width: 40px;
      height: 40px;

      & .cart-badge {
        position: absolute;
        bottom: 2px;
        right: 2px;
        width: 13px;
        height: 13px;
        line-height: 13px;
        border-radius: 50%;
        background-color: ${theme.colors.point_red};
        text-align: center;
        font-size: 9px;
        color: ${theme.colors.main_white};
  
        &.cart-zero {
          display: none;
        }
      }
    }

    & .tab-name {
      color: ${theme.colors.dark_grey};
      ${theme.typography.caption_12_R}

      &.active {
        color: ${theme.colors.main_white};
      }
    }
  }

  @media ${lg} {
    display: flex;
  }

  @media ${xs} {
    
    & .mobile-nav-tab {
      flex-direction: column;
      gap: 4px;

      & .icon-image-box {
        width: 28px;
        height: 28px;

        & .cart-badge {
          bottom: 0;
          right: 0;
        }
      }

      & .tab-name {
        ${theme.typography.caption_10_R}
      }
    }
  }
`;

export const MobileBrandNav = styled.div`
  display: none;
  position: fixed;
  top: 0;
  padding: 0 5px;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  border-top: 0.5px solid ${theme.colors.dark_grey};
  background-color: ${theme.colors.main_black};
  box-shadow: 0 2px 4px rgba(0,0,0,.5);
  overflow-x: scroll;
  z-index: 999;
  transition: all 0.3s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }

  & button {
    width: max-content;
    white-space: nowrap;
    margin: 0 8px;
    padding: 5px 0;
    color: ${theme.colors.light_mid_grey};
    ${theme.typography.text_14_R}
    box-sizing: border-box;

    &.active-nav {
      color: ${theme.colors.main_white};
      font-weight: 500;
    }
  }

  @media ${lg} {
    display: flex;
    justify-content: center;

    & button {
      margin: 0 10px;
    }

    &.show-nav {
      top: 60px;
    }
  }

  @media ${sm} {
    justify-content: start;
  }
`;

export const FloatingActionButtonContainer = styled.div<{$plusBottom: number}>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  bottom: 90px;
  right: 15px;
  z-index: 500;

  & button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    transition: all 0.3s;

    &.none {
      display: none;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 1);
    }
  }

  @media ${lg} {
    bottom: ${props => props.$plusBottom + 90}px;
  }

  @media ${xs} {
    right: 10px;
    
    & button {
      width: 40px;
      height: 40px;

      & img {
        height: 22px;
      }
    }
  }
`;

export const ProductDetailsFloatingBackButton = styled.button`
  position: fixed;
  left: 30px;
  top: 160px;
  z-index: 900;
  transition: all 0.3s;

  @media ${xl} {
    left: 10px;
    top: 120px;
  }

  @media ${xs} {
    top: 80px;
    left: 15px;
  }

  &.active {
    top: 20px;
  }
`;

export const ProductBuyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* 항목들을 중앙에 배치 */
  margin-top: 160px; /* 상단 여백 */

  & .product-image {
    display: flex;
    justify-content: center;
    flex: 1;
    margin-right: 20px;
    max-width: 50%;
    height: 50%;
    border: 1px solid ${theme.colors.light_grey};
    aspect-ratio: 1;

    & img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      transition: opacity 0.2s ease-in-out;
      opacity: 1;

      &.invisible {
        opacity: 0;
      }
    }
  }

  & .product-details-content {
    position: relative;
    margin-left: 20px;
    border-top: 1px solid ${theme.colors.black_dark_grey};
    flex: 1;
    max-width: 50%;

    & .brand-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 14px;

      & span {
        ${theme.typography.text_14_M}
      }
      & button {
        padding: 6px 12px;
        border: 1px solid ${theme.colors.main_black};
        ${theme.typography.text_14_R}
      }
      
      & .inventory {
        padding: 6px 10px;
        background-color: ${theme.colors.dark_grey};
        color: ${theme.colors.main_white};
        ${theme.typography.text_14_R}
      }
    }
    & .product-name {
      margin-top: 15px;
      margin-bottom: 12px;
      ${theme.typography.large_30_B}
      font-weight: 500;
    }
    & .product-name-eng {
      line-height: 15px;
      color: ${theme.colors.dark_grey};
      ${theme.typography.caption_12_R}
    }
    & .color-name {
      padding-bottom: 20px;
      line-height: 15px;
      color: ${theme.colors.dark_grey};
      ${theme.typography.caption_12_R}
    }

    & .original-price {
      margin-top: 20px;
      color: ${theme.colors.light_mid_grey};
      ${theme.typography.caption_12_R}
      text-decoration: line-through;
    }

    & .discount-price-box {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-top: 8px;
      
      & .discount-price {
        color: ${theme.colors.main_black};
        ${theme.typography.text_16_M}
      }

      & .discount-rate {        
        margin-left: 10px;
        color: ${theme.colors.point_red};
        ${theme.typography.text_16_M}

        &.additional-discount {
          margin-left: 0;
        }
      }

      & .discount-rate-vat {
        display: flex;
        flex-direction: column;
        justify-content: flex-end; /* 하단 정렬 */
        color: ${theme.colors.dark_grey};
        ${theme.typography.text_14_R}

        @media ${xs} {
          font-size: 12px;
        }
      }

      & .arrow-icon-img {
        display: inline-block;
        margin: 0 4px;
        width: 18px;
        height: 18px;
        transform: translateY(3px) rotate(180deg);
      }
    }

    & .product-details-tag {
      color: ${theme.colors.main_black};
      ${theme.typography.text_16_M}
    }

    & .delivery-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      padding-bottom: 20px;
      border-bottom: 1px solid ${theme.colors.black_dark_grey};

      & .delivery-price-noti {
        color: ${theme.colors.dark_grey};
        ${theme.typography.text_14_R}
      }
    }

    & .quantity-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid ${theme.colors.light_mid_grey};
      
      & .quantity {
        display: flex;
        align-items: center;

        & button {
          border: 1px solid ${theme.colors.dark_grey}; /* 테두리 색상 */
          width: 30px; /* 버튼 너비 */
          height: 30px; /* 버튼 높이 */
          display: flex;
          align-items: center;
          justify-content: center;
        }

        & input {
          text-align: center;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${theme.colors.main_white};
          color: ${theme.colors.main_black};
          outline: none;

          &::-webkit-inner-spin-button,::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
        /* & input[type='number'] {
          -moz-appearance: textfield;
        } */
      }
    }

    & .control-box {
      width: 100%;

      & .final-price-box {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        & .final-amount {
          ${theme.typography.title_24_B}
          font-weight: 500;
        }
      }

      & .buy-button-box {
        display: flex;
        gap: 20px;
        margin-top: 30px;
        width: 100%;
        height: 45px;

        & button {
          width: 50%;
          height: 50px;
          ${theme.typography.text_16_M}

          &.cart-button {
            width: 140px; height: 50px;
            border: 1px solid ${theme.colors.main_black};
            background-color: ${theme.colors.main_white};
          }
          &.buy-button {
            background-color: ${theme.colors.main_black};
            color: ${theme.colors.main_white};
          }
        }
      }
    }
  }

  @media ${xl} {
    padding: 0 20px;
  }

  @media ${md} {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    padding: 60px 20px 0;

    .product-image {
      margin: 0;
      max-width: 100%;
      border: 0;
    }

    .product-details-content {
      width: 100%;
      max-width: 100%;
      margin: 0;
      /* border-top: 0; */

      & .discount-price-box {
        padding-bottom: 20px;
        border-bottom: 1px solid ${theme.colors.light_mid_grey};
      }

      & .quantity, .control-box {
        /* display: none; */
      }

      & .control-box {
        
        & .buy-button-box {
          gap: 10px;
        }
      }
    }
  }
`;

export const ColorOptionArray = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid ${theme.colors.light_mid_grey};

  & .color-option-array-tag {
    margin-bottom: 12px;
    ${theme.typography.text_16_M}

    & p {
      margin-bottom: 8px;
      ${theme.typography.caption_12_R}
      color: ${theme.colors.dark_grey};
    }
  }

  & .label-basic {
    margin: 8px 0;
    line-height: 32px;
    text-indent: 10px;
    cursor: pointer;

    & .price-text {
      margin-left: 4px;
      ${theme.typography.caption_12_R}
    }
  }

  & .second-node-container {
    border: 1px solid ${theme.colors.light_mid_grey};
    max-height: 240px;
    overflow-y: scroll;

    & .second-node-option {
      padding: 0 8px;

      & .third-node-container {

        & .third-node-option {
          margin-left: 28px;

          & .third-node-option-label {
            
          }

          & .fourth-node-container {

            & .fourth-node-option {
              margin-left: 28px;
              
              & .fourth-node-option-label {

              }

              &.selected {

                & .fourth-node-option-label {

                }
              }

              &.selected-list {

                & .fourth-node-option-label {
                  background-color: ${theme.colors.dark_grey};
                  color: ${theme.colors.main_white};
                }
              }
            }
          }

          &.selected {
            position: relative;

            & .third-node-option-label {
              background-color: ${theme.colors.white_light_grey};
            }

            &::after {
              position: absolute;
              content: '';
              top: 32px;
              left: 20px;
              width: 1px;
              height: calc(100% - 32px);
              background-color: ${theme.colors.light_mid_grey};
            }
          }

          &.selected-list {

            & .third-node-option-label {
              background-color: ${theme.colors.dark_grey};
              color: ${theme.colors.main_white};
            }
          }
        }
      }

      & .second-node-option-label {

      }

      &.selected {
        position: relative;

        & .second-node-option-label {
          background-color: ${theme.colors.white_light_grey};
        }

        &::after {
          position: absolute;
          content: '';
          top: 32px;
          left: 20px;
          width: 1px;
          height: calc(100% - 32px);
          background-color: ${theme.colors.light_mid_grey};
        }
      }

      &.selected-list {

        & .second-node-option-label {
          background-color: ${theme.colors.dark_grey};
          color: ${theme.colors.main_white};
        }
      }
    }
  }

  & .list-thumbnail-box {
    height: 102px;
    background-color: ${theme.colors.main_white};

    & img {
      width: 100px;
      height: 100px;
      outline: 1px solid ${theme.colors.main_black};
    }
  }
`;

export const SelectedListOptionBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  height: 72px;

  & .selected-list-description-box {
    padding-right: 20px;
    width: calc(100% - 200px);
    line-height: 24px;
  }

  & .option-right-box {
    width: 200px;
    display: flex;

    & .selected-list-img-box {
      width: 72px;
      background-color: ${theme.colors.main_white};
      border: 1px solid ${theme.colors.light_mid_grey};
  
      & img {
        width: 72px;
        height: 72px;
      }
    }

    & .quantity {

    }
  }

  @media ${theme.screens.md} {
    display: block;
    height: auto;

    & .selected-list-description-box {
      width: 100%;
    }

    & .option-right-box {
      margin-top: 10px;
      width: 100%;
    }
  }
`;

export const ColorOptionThumbnail = styled.div`
  position: fixed;
  width: 100px;
  height: 100px;
  background-color: ${theme.colors.main_white};
  z-index: 10002;

  & img {
    width: 100%;
    height: 100%;
  }
`;

export const ProductBuyModal = styled.div`

  & .modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1001;

    & .modal-content {
      position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;
      background-color: ${theme.colors.main_white};
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;

      & .close-modal {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 4px 0;
        
        & .arrow {
          width: 40px;
          color: ${theme.colors.light_mid_grey};
          transform: rotate(-90deg);
        }
      }

      & .product-info-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        border-top: 1px solid ${theme.colors.light_mid_grey};
        border-bottom: 1px solid ${theme.colors.light_mid_grey};

        & .text-box {

          & .brand-name {
            color: ${theme.colors.light_mid_grey};
            font-size: 13px;
            font-weight: 600;
          }
          & .product-kor-name {
            margin-top: 10px;
            font-size: 20px;
          }
          & .color-name {
            margin-top: 5px;
            color: ${theme.colors.light_mid_grey};
            font-size: 13px;
          }
        }

        & .image-box {
          display: flex;
          justify-content: center;
          align-items: end;
          
          & img {
            max-height: 100px;
          }
        }
      }

      & .control-box {
        display: flex;
        justify-content: space-between;
        align-items: end;
        padding: 10px 20px 20px;

        & .final-amount-label {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        & .original-amount {
          color: ${theme.colors.light_mid_grey};
          font-weight: 600;
          text-decoration: line-through;
        }
        & .discount-rate {
          color: ${theme.colors.point_red};
          font-weight: 600;
          margin-right: 5px;
        }
        & .final-amount {
          font-size: 22px;
          font-weight: 600;
        }
        
        & .quantity {
          display: flex;
          align-items: center;

          & button {
            border: 1px solid ${theme.colors.main_black}; /* 테두리 색상 */
            width: 35px; /* 버튼 너비 */
            height: 35px; /* 버튼 높이 */
            display: flex;
            align-items: center;
            justify-content: center;
          }

          & input {
            text-align: center;
            width: 35px;
            height: 35px;
            display: flex;
            background-color: ${theme.colors.main_white};
            color: ${theme.colors.main_black};
            align-items: center;
            justify-content: center;
            outline: none;

            &::-webkit-inner-spin-button,::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }
  }
`;

export const ProductTabContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 100px auto 0;
  padding: 20px;
  box-sizing: border-box;
  
  & .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    border-bottom: 2px solid ${theme.colors.white_light_grey};

    & .tab-button {
      padding: 10px 20px;
      margin-bottom: -1px;
      border: none;
      color: ${theme.colors.mid_grey};
      ${theme.typography.title_20_SB}
      outline: none;
      cursor: pointer;
      transition: color 0.3s, border-bottom-color 0.3s;

      &.active {
        color: ${theme.colors.main_black};
        border-bottom: 2px solid #4A5568; /* 활성화 또는 호버 시 하단 테두리 색상 변경 */
      }
    }
  }

  & .tab-content {
    display: none;
    padding: 20px;
    background-color: ${theme.colors.main_white};

    &.active {
      display: block;
    }

    & img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }

  @media ${xs} {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 100px;
    
    & .tabs {
      /* border-bottom: 0; */

      & .tab-button {
        width: 50%;
      }
    }

    & .tab-content {
      padding: 0;
      
      &.detail-content {
        padding: 0;
      }

      & .shipping-info-content {
        padding: 20px;

        & ul {
          
          & li {
            font-size: 11px;
          }
        }
      }
    }
  }
`;

export const ProductDetailsFloatingController = styled.div`
  position: fixed;
  bottom: -80px;
  left: 0;
  right: 0;
  background-color: ${theme.colors.main_black};
  transition: all 0.3s;
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.25);
  z-index: 2;
  
  &.active {
    bottom: 0;
  }

  & .controller-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 1200px;

    & .left-side {
      color: ${theme.colors.main_white};
      ${theme.typography.large_30_B}
      font-weight: 500;
    }

    & .right-side {
      display: flex;
      align-items: center;

      & .price-quantity {
        display: flex;

        & .final-price-box {
          display: flex;
          align-items: center;
          color: ${theme.colors.main_white};
  
          & .product-details-tag {
            ${theme.typography.text_16_M}
          }
  
          & .final-amount {
            /* padding: 0 40px 0 30px; */
            width: 200px;
            ${theme.typography.title_24_B}
            font-weight: 500;
            text-align: center;
          }
        }
  
        & .quantity {
          display: flex;
          align-items: center;
  
          & button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px; /* 버튼 너비 */
            height: 30px; /* 버튼 높이 */
            color: ${theme.colors.dark_grey};
            border: 1px solid ${theme.colors.dark_grey}; /* 테두리 색상 */
          }
  
          & input {
            text-align: center;
            width: 50px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            color: ${theme.colors.main_white};
            outline: none;
  
            &::-webkit-inner-spin-button,::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }


      & .buy-button-box {
        margin-left: 20px;
        color: ${theme.colors.main_white};

        & button {
          width: 140px;
          height: 50px;
          border: 1px solid ${theme.colors.main_white};
          ${theme.typography.text_16_M}

          &.buy-button {
            margin-left: 15px;
            color: ${theme.colors.main_black};
            background-color: ${theme.colors.main_white};
          }
        }
      }
    }
  }

  @media ${xl} {
    padding: 0 20px;
  }

  @media ${lg} {
    padding: 0;
    background-color: ${theme.colors.main_white};
    
    &.active {
      bottom: 60px;
    }

    & .controller-wrapper {
      height: 100px;

      & .left-side {
        display: none;
      }

      & .right-side {
        flex-direction: column;
        width: 100%;

        & .price-quantity {
          justify-content: space-between;
          align-items: center;
          padding: 0 15px;
          width: 100%;

          & .final-price-box {
            color: ${theme.colors.main_black};
  
            & .product-details-tag {
              display: none;
            }
  
            & .final-amount {
              width: auto;
              ${theme.typography.title_20_SB}
            }
          }
  
          & .quantity {
  
            & button {
  
            }
  
            & input {
              color: ${theme.colors.main_black};
            }
          }
        }

        & .buy-button-box {
          display: flex;
          justify-content: space-between;
          margin: 0;
          margin-top: 6px;
          padding: 0 15px;
          width: 100%;
          color: ${theme.colors.main_black};

          & button {
            width: 100%;
            height: 40px;
            border-color: ${theme.colors.main_black};

            &.buy-button {
              background-color: ${theme.colors.main_black};
              color: ${theme.colors.main_white};
            }
          }
        }
      }
    }
  }
`;

export const CartContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  margin-top: 130px;

  &:not(.with-items) {
    & .checkout {
      display: none;
    }
  }

  & .cart-header {
    padding-bottom: 90px;
    border-bottom: 2px solid ${theme.colors.main_black};
    text-align: center;
    ${theme.typography.large_30_B}
  }
  
  & .cart-items {

    & .cart-item-header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      line-height: 50px;
      ${theme.typography.text_14_R}

      & .select-all-checkbox {
        display: flex;
        align-items: center;
        padding: 0 22px;

        & input[type="checkbox"] {
          display: none;
  
          &+label {
            display: inline-block;
            width: 16px;
            height: 16px;
            border: 1.5px solid ${theme.colors.light_mid_grey};
            position: relative;
            cursor: pointer;

            &::after {
              position: absolute;
              content: '';
              width: 16px;
              height: 16px;
              left: -1px;
              top: -1px;
              background-image: url(${check_done_gray_icon});
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
  
        & input[id="check-all"]:checked + label::after {
          content: '';
          background-color: ${theme.colors.main_black};
          background-image: url(${check_done_icon});
          background-position: center;
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
          text-align: center;
          position: absolute;
          left: -1px;
          top: -1px;
        }

        & span {
          display: none;
        }
      }

      & .cart-image {
        flex: 2;
      }
      & .cart-details {
        flex: 3;
        margin-left: 10px;
      }
      & .cart-price {
        flex: 2;
        text-align: center;
      }
      & .cart-quantity {
        flex: 2;
        text-align: center;
      }
      & .cart-total {
        flex: 2;
        text-align: center;
      }
      & .cart-buy {
        flex: 2;
      }
      & .cart-vat {
        flex: 2;
        text-align: center;
      }
    }

    & .empty-cart-message {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 330px;
      color: ${theme.colors.dark_grey};
      text-align: center;
      ${theme.typography.text_14_R}
    }
  }

  & .cart-control-box {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    width: 100%;

    & .cart-controls {
      display: flex;
      width: 100%;
      
      & button {
        border: 1px solid ${theme.colors.main_black};
        margin-top: 10px;
        padding: 0 10px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.delete-button {
          margin-left: 10px;
        }
      }
    }

    & .applied-promtion {
      margin-top: 60px;
      padding: 15px;
      background-color: ${theme.colors.product_list_bg};
      border: 1px solid ${theme.colors.light_grey};

      & h3 {
        color: ${theme.colors.dark_grey};
        ${theme.typography.text_16_M}
      }

      & h4 {
        margin-top: 20px;
        color: ${theme.colors.main_black};
        ${theme.typography.text_14_M}
      }

      & .promtion-notice {
        color: ${theme.colors.point_red};
        ${theme.typography.caption_12_R}
        line-height: 26px;
      }

      & .promotion-content {
        display: flex;
        margin-top: 6px;
        ${theme.typography.text_14_R}

        & .label {
          min-width: 126px;
        }
        & .content {

        }
      }
    }

    & .checkout {
      padding: 10px 0;

      & .checkout-layout {
        border-top: 1px solid #000;
        width: 320px;

        & .checkout-notice {
          margin-top: 30px;
          color: ${theme.colors.point_red};
          ${theme.typography.caption_12_R}
        }

        & .checkout-rows {
          padding: 10px 0;
          display: flex;
          justify-content: space-between;

          &.total-payment {
            align-items: baseline;
            border-top: 1px solid ${theme.colors.main_black};

            & .price {
              font-size: 20px;
              font-weight: 500;
            }
          }

          & .checkout-button {
            width: 100%;
            height: 40px;
            background-color: ${theme.colors.main_black};
            color: ${theme.colors.main_white};

            &.disable {
              background-color: ${theme.colors.dark_grey};
              cursor: not-allowed;
            }
            &.white {
              background-color: ${theme.colors.main_white};
              color: ${theme.colors.main_black};
              border: 1px solid ${theme.colors.main_black};
              margin-right: 10px;
            }
          }
          
          & .is-vat {
            ${theme.typography.caption_10_R}
          }
        }
      }

      & .cart-selected-total-price {

      }
    }
  }

  &.order-details {
    margin-top: 0;
    margin-bottom: 0;

    & .order-details-title {
      font-weight: 600;
      transform: translateY(-5px);
    }
    
    & .cart-items {

      & .cart-item-header {
        border-top: 1px solid ${theme.colors.main_black};
      }
    }
  }

  @media ${md} {
    padding: 0 20px;
  }

  @media ${xs} {
    margin-top: 110px;
    padding: 0 15px;

    & .cart-header {
      padding-bottom: 60px;
      border-bottom-width: 0;
    }
    
    & .cart-items {

      & .cart-item-header {

        & .select-all-checkbox {
          padding: 0;

          & span {
            display: block;
            margin-left: 4px;
            color: ${theme.colors.black_dark_grey};
            ${theme.typography.caption_12_R}
          }
        }

        & .cart-image, .cart-details, .cart-price, .cart-quantity, .cart-total, .cart-buy, .cart-vat {
          display: none;
        }
      }

      & .empty-cart-message {
        margin-top: 20px;
        border-top: 2px solid ${theme.colors.main_black};
      }
    }

    & .cart-control-box {
      display: block;
      margin-top: 10px;

      & .checkout {
        margin-top: 40px;
        ${theme.typography.text_14_R}
        
        & .checkout-layout {
          width: 100%;

          & .checkout-rows {

            &.total-payment {
              margin-top: 10px;
              padding-top: 15px;
              border-top-width: 0.5px;
            }

            & .checkout-button {
              margin-top: 15px;

              &.offer-btn {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
`;

export const CartItem = styled.div<{$checkboxIndex: number}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${theme.colors.dark_grey};
  padding: 28px 0;

  &.last-product {
    border-bottom: 1px solid ${theme.colors.dark_grey};
  }

  & .cart-item-check {
    padding: 0 22px;

    & input[type="checkbox"] {
      display: none;
  
      &+label {
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 1.5px solid ${theme.colors.light_mid_grey};
        position: relative;
        cursor: pointer;
  
        &::after {
          position: absolute;
          content: '';
          width: 16px;
          height: 16px;
          left: -1px;
          top: -1px;
          background-image: url(${check_done_gray_icon});
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  
    & input[id="cart-checkbox-${props => props.$checkboxIndex}"]:checked + label::after {
      content: '';
      background-color: ${theme.colors.main_black};
      background-image: url(${check_done_icon});
      background-position: center;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      text-align: center;
      position: absolute;
      left: -1px;
      top: -1px;
    }
  }
  
  & .cart-image {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    aspect-ratio: 1;
    border: 0.5px solid ${theme.colors.light_grey};
    cursor: pointer;

    & img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  & .cart-details {
    position: relative;
    flex: 3;
    margin-left: 10px;

    & .cart-brand-name {
      color: ${theme.colors.main_black};
      ${theme.typography.caption_12_R}
    }

    & .cart-product-name {
      margin: 10px 0 6px;
      ${theme.typography.text_14_M}
    }

    & .cart-color-name {
      color: ${theme.colors.dark_grey};
      ${theme.typography.caption_12_R}
    }

    & .cart-option-section {
      margin-top: 8px;
      line-height: 16px;
      color: ${theme.colors.dark_grey};
      ${theme.typography.caption_12_R}

      &.using-pdf {
        width: 150px;
        line-height: 14px;
        ${theme.typography.caption_10_R}
      }
    }

    & .cart-inventory {
      position: absolute;
      margin-top: 20px;
      color: ${theme.colors.point_blue};
      ${theme.typography.text_14_R}

      &.has-option {
        margin-top: 8px;
      }
    }

    & > * {
      width: fit-content;
      cursor: pointer;
    }
  }

  & .cart-price {
    flex: 2;

    & .cart-original-price {
      padding-bottom: 6px;
      color: ${theme.colors.dark_grey};
      ${theme.typography.caption_12_R}
      text-decoration: line-through;
      text-align: center;
    }
    & .discount-rate {
      padding-right: 10px;
      color: ${theme.colors.point_red};
      font-size: 14px;
      font-weight: bold;
    }
    & .cart-sale-price {
      color: ${theme.colors.main_black};
      text-align: center;
      ${theme.typography.text_16_M}
      /* font-size: 22px; */
      /* font-weight: 500; */
    }
    & .cart-discount-rate-vat {
      padding-top: 10px;
      font-size: 13px;
    }
  }

  & .cart-quantity {
    flex: 2;
    display: flex;
    justify-content: center;

    &  button {
      border: 1px solid ${theme.colors.main_black};
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & input {
      text-align: center;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${theme.colors.main_white};
      color: ${theme.colors.main_black};
      outline: none;

      &::-webkit-inner-spin-button,::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    & span {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & .cart-total {
    flex: 2;
    text-align: center;

    & .cart-total-original {
      padding-bottom: 6px;
      color: ${theme.colors.dark_grey};
      ${theme.typography.caption_12_R}
      text-decoration: line-through;
      text-align: center;
    }

    & .cart-total-price {
      color: ${theme.colors.main_black};
      text-align: center;
      ${theme.typography.text_16_M}
    }
  }

  & .cart-buy {
    flex: 2;
    display: flex;
    justify-content: center;

    & button {
      padding: 8px 10px;
      background-color: ${theme.colors.main_black};
      color: ${theme.colors.main_white};
      ${theme.typography.text_14_R}
    }
  }

  & .cart-vat {
    flex: 2;
    text-align: center;

    & p {
      color: ${theme.colors.black_dark_grey};
      ${theme.typography.text_14_R}
    }

    & .no-include-vat {
      display: none;
    }
  }

  @media ${xs} {
    display: block;
    padding: 20px 0;

    &.first-product {
      border-top: 2px solid ${theme.colors.main_black};
    }

    & .cart-item-check {
      margin-bottom: 10px;
      padding: 0;
    }
    
    & .cart-image {
      position: relative;
      display: inline-block;

      & img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    & .cart-details {
      display: inline-block;
      transform: translateY(-25px);

      &.has-option {
        transform: translateY(-5px);
      }

      & .cart-option-section {
        width: 200px;
      }

      & .cart-inventory {
        /* position: static; */
        margin-top: 10px;
        white-space: pre;
        ${theme.typography.caption_12_R}
      }
    }

    & .cart-price {
      display: none;
    }

    & .cart-quantity {
      position: relative;
      justify-content: start;
      margin-top: 20px;
      max-width: 100px;
      width: fit-content;
      z-index: 1;

      & input {
        width: 40px;
      }
    }

    & .cart-total {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      transform: translateY(-30px);

      & .cart-total-price {

        &.no-original {
          line-height: 30px;
        }
      }
    }

    & .cart-buy {
      width: 100%;
      background-color: blue;
      display: block;
      flex: auto;

      & button {
        padding: 12px 10px;
        width: 100%;
        background-color: ${theme.colors.mid_grey};
      }
    }

    & .cart-vat {
      text-align: right;

      & .no-include-vat {
        display: contents;
      }

      & .include-vat {
        display: none;
      }
    }
  }

  &.for-pdf {

    &.empty-place {
      
      & .page-index {
        width: 100%;
        display: flex;
        justify-content: end;
  
        & span {
          ${theme.typography.caption_12_R}
        }
  
        &.bottom {
          align-items: end;
        }
  
        &.top {
          align-items: start;
        }
      }
    }
  }
`;

export const CartProductCheckModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 20px;
  width: 40%;
  min-width: 360px;
  max-width: 500px;
  /* height: 70%; */
  background-color: ${theme.colors.main_white};
  transform: translate(-50%, -50%);
  z-index: 1002;

  & .cart-modal-item-flex-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.colors.black_dark_grey};

    & .image {
      width: 60px;
    }
    & .detail {
      margin-left: 20px;
      flex: 5;
    }
    & .quantity {
      flex: 1;
    }
    & .final-price {
      flex: 3;
      text-align: end;
    }
  }

  & .cart-modal-overflow-box {
    height: 400px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .cart-modal-price-info-box {
    margin-top: 20px;
    padding-top: 4px;
    border-top: 1px solid ${theme.colors.black_dark_grey};

    & .price-info-layout {

      & .price-info {
        display: flex;
        justify-content: space-between;
        padding: 4px 0;
        ${theme.typography.text_14_R}

        &.total-payment {
          margin-top: 4px;
          padding-top: 8px;
          border-top: 1px solid ${theme.colors.black_dark_grey};

          & .price {
            ${theme.typography.text_16_M}
          }
        }
      }
    }
  }

  & .cart-modal-button-box {
    margin-top: 20px;
    width: 100%;

    & button {
      width: 100%;
      height: 50px;
      background-color: ${theme.colors.main_black};
      color: ${theme.colors.main_white};

      &.close-button {
        margin-top: 10px;
        height: 48px;
        background-color: ${theme.colors.main_white};
        color: ${theme.colors.main_black};
        border: 1px solid ${theme.colors.black_dark_grey};
      }
    }
  }

  @media ${xs} {
    width: calc(100% - 20px);

    & .cart-modal-overflow-box {
      height: 300px;
    }
  }
`;

export const CartProductCheckModalItem = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid ${theme.colors.light_grey};

  &.last-item {
    border-bottom-width: 0;
  }

  & .cart-modal-item-flex-body {
    display: flex;
    justify-content: space-between;

    & .cart-modal-item-img-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      aspect-ratio: 1;
      border: 0.5px solid ${theme.colors.light_grey};

      & img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    & .cart-modal-item-detail-box {
      margin-left: 20px;
      flex: 5;

      & .modal-item-brand {
        color: ${theme.colors.main_black};
        ${theme.typography.caption_12_R}
      }
      & .modal-item-name {
        margin: 10px 0 6px;
        ${theme.typography.text_14_M}
      }
      & .modal-item-color {
        color: ${theme.colors.dark_grey};
        ${theme.typography.caption_12_R}
      }
      & .modal-item-section {
        margin-top: 4px;
        color: ${theme.colors.mid_grey};
        ${theme.typography.caption_10_R}
      }
    }

    & .cart-modal-item-quantity-box {
      flex: 1;
      display: flex;
      align-items: center;
      ${theme.typography.text_14_R}
    }

    & .cart-modal-item-final-price-box {
      flex: 3;
      display: flex;
      align-items: center;
      justify-content: end;
      ${theme.typography.text_14_R}
    }
  }
`;

export const OrderContainer = styled.div`

  & .title {
    margin-top: 140px;
    text-align: center;
    ${theme.typography.large_30_B}
  }

  & .shipping-and-details {
    /* display: flex;
    flex-direction: row; */
    margin: 40px auto 0;
    max-width: 600px;
  }

  @media ${xs} {
    padding: 0 15px;

    & .title {
      margin-top: 95px;
    }
  }
`;

export const OrderSectionTitle = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 2px solid ${theme.colors.main_black};
  ${theme.typography.title_24_B}

  & span {

  }
  & img {

  }
`;

export const OrderDetailsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: white;
`;

export const OrderDetailsWrapper = styled.div`

  & .order-items-box {

    & .total-order-price-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;

      & .total-order-price-label {
        ${theme.typography.text_14_R}
      }
      & .total-order-price {
        color: ${theme.colors.main_black};
        ${theme.typography.text_16_M}
        ${pretendard}
      }
    }
  }

  & .cancel-refund-notice {
    margin-bottom: 50px;
    padding: 17.5px 20px;
    width: 100%;
    border: 1px solid ${theme.colors.light_mid_grey};

    & .notice-title {
      padding-bottom: 4px;
      color: ${theme.colors.point_red};
      ${theme.typography.text_16_M}
    }

    & p {
      line-height: 16px;
      color: ${theme.colors.dark_grey};
      ${theme.typography.text_14_R}
    }

    @media ${xs} {
      padding: 14px 10px;
      
      & .notice-title {
        ${theme.typography.caption_12_R}
      }
      & p {
        ${theme.typography.caption_10_R}
      }
    }
  }
`;

export const OrderItem = styled.div`
  margin-top: 18px;
  padding-bottom: 18px;
  padding-left: 10px;
  border-bottom: 1px solid ${theme.colors.dark_grey};

  & .order-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .order-flex-left {
      display: flex;
      align-items: center;
      flex: 4;

      & .order-image {
        border: 0.5px solid ${theme.colors.light_grey};
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        aspect-ratio: 1;
        
        & img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    
      & .order-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 84px;
        margin-left: 10px;
    
        & .order-brand-name {
          color: ${theme.colors.main_black};
          ${theme.typography.caption_12_R}
        }
        & .order-product-name {
          margin: 8px 0 8px;
          ${theme.typography.text_14_M}
        }
        & .order-color-name {
          color: ${theme.colors.dark_grey};
          ${theme.typography.caption_12_R}
        }
        & .order-color-option-name {
          color: ${theme.colors.dark_grey};
          ${theme.typography.caption_12_R}
        }
      }
    }

    & .order-flex-right {
      display: flex;
      align-items: center;
      flex: 2;

      & .order-quantity {
        flex: 2;
        text-align: center;
    
        & span {
          ${theme.typography.text_16_M}
        }
      }
    
      & .order-price-box {
        flex: 4;
        display: flex;
        justify-content: end;
    
        & .price-wrapper {
    
          & .order-origin-price {
            padding-bottom: 4px;
            color: ${theme.colors.dark_grey};
            ${theme.typography.caption_12_R}
            ${pretendard}
            text-align: right;
            text-decoration: line-through;
          }
      
          & .order-total-price {
            ${pretendard}
            ${theme.typography.text_16_M}
            text-align: end;

            & .order-total-price-vat {
              margin-top: 2px;
              ${theme.typography.caption_12_R}
              color: ${theme.colors.dark_grey};
            }
          }

          & .order-vat {
            margin-top: 4px;
            color: ${theme.colors.dark_grey};
            ${theme.typography.caption_12_R}
            text-align: end;
          }
        }
      }
    }
  }

  & .respective-shipping-memo {
    margin: 10px 0;

    & label {
      display: block;
      line-height: 30px;
      ${theme.typography.text_14_R}
      color: ${theme.colors.black_dark_grey};
      border-top: 1px solid transparent;
    }

    & .memo {
      padding: 0 10px;
      width: 100%;
      height: 40px;
      color: ${theme.colors.main_black};
      ${theme.typography.text_14_R}
      border: 1px solid ${theme.colors.light_mid_grey};
      outline: none;
    }
  }

  &.order-success-item {
    padding: 0;
    padding-bottom: 20px;
    border-color: ${theme.colors.mid_grey};
    
    & .order-image {
      width: 84px;
    }
    
    &.last-item {
      border: none;
    }
  }

  @media ${xs} {
    
    & .order-item-wrapper {
      flex-direction: column;
      align-items: start;

      & .order-flex-left {
        
        & .order-image {
          width: 98px;
          height: 98px;
        }

        & .order-details {
          height: 60px;
        }
      }

      & .order-flex-right {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-left: 108px;
        width: calc(100% - 108px);

        & .order-quantity {
          text-align: start;
        }

        & .order-price-box {

      
          & .price-wrapper {
      
            & .order-origin-price {

            }
        
            & .order-total-price {
              
            }
          }
        }
      }
    }
  }
`;

export const OrderShippingContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  
  & .section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 2px solid ${theme.colors.main_black};
    ${theme.typography.title_24_B}
  }

  & .shipping-info-section {
    position: relative;
    width: 100%;

    & .change-shipping-button {
      position: absolute;
      top: 65px;
      right: 0;

      & button {
        color: ${theme.colors.dark_grey};
        ${theme.typography.text_14_R}
        text-decoration: underline;
      }
    }

    & .account-info-check-box {
      display: flex;
      justify-content: end;
      align-items: center;
      margin-bottom: 15px;
      padding: 5px 0;

      & button {
        display: flex;
        align-items: center;

        & input {
          cursor: pointer;
        }
        & span {
          padding-left: 5px;
          ${theme.typography.text_14_R}
        }
      }
    }

    & .order-inline-input {
      margin-top: 15px;

      & .flex-line {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }

      & label, .change-address {
        line-height: 30px;
        ${theme.typography.text_14_R}
        color: ${theme.colors.black_dark_grey};
        border-top: 1px solid transparent;
      }

      & .change-address {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding: 0 10px;
        border: 1px solid ${theme.colors.main_black};

        & img {
          margin-right: 5px;
          width: 15px;
          height: 15px;
        }
      }

      & .input-box {
        position: relative;
        display: flex;
        width: 100%;

        & input {
          padding-left: 10px;
          max-width: 100%;
          width: 100%;
          height: 40px;
          color: ${theme.colors.main_black};
          ${theme.typography.text_14_R}

          &.order-full-width {
            /* flex-grow: 1; */
            border: 1px solid ${theme.colors.light_mid_grey};
            outline: none;
          }

          &.post-code {

          }
          &.address {
            width: 100%;
          }
          
          &.input-error {
            border: 1px solid ${theme.colors.point_red};
          }

          &:read-only {
            background-color: #f5f5f5;
          }
        }

        & .error-message {
          position: absolute;
          bottom: -13px;
          color: ${theme.colors.point_red};
          font-size: 11px;
        }

      }

      &.connected {

        & label {

        }
      }

      & .phone-number-verification-send-btn,
      .btn-address-search {
        margin-left: 10px;
        width: 120px;
        height: 40px;
        background-color: ${theme.colors.main_black};
        color: ${theme.colors.main_white};
        padding: 0 8px;
        font-size: 12px;
      }

      & .verification-status {
        color: green;
        font-size: 14px;
      }

      & textarea {
        width: 100%;
        border: 1px solid ${theme.colors.light_mid_grey};
        padding: 10px;
        min-height: 150px;
        font-size: 14px;
        outline: none;
        resize: none;
      }

      & .char-counter {
        margin-top: 4px;
        text-align: right;
        color: ${theme.colors.dark_grey};
        font-size: 12px;

        &.exceed {
          color: ${theme.colors.point_red}; /* 글자 수 초과 시 빨간색으로 표시 */
        }
      }

      & .error-message {
        margin-top: 4px;
        height: 20px;
        color: ${theme.colors.point_red};
        ${theme.typography.caption_12_R}
      }
    }

    & .address-group {

      & .address-modal {
        position: relative; /* 상위 위치에 따라 위치 조정 */
        margin: 10px 0;
        z-index: 10; /* 다른 요소 위에 표시 */
      }
    }
  }
`;

export const OrderShippingPayMethod = styled.div`

  & .payment-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 15px;
    margin-top: 15px;

    & .payment-btn {
      padding: 0 10px;
      height: 40px;
      border: 1px solid ${theme.colors.main_black};
      flex: 1 1 auto;

      &.active {
        background-color: ${theme.colors.main_black};
        color: ${theme.colors.main_white};
      }
    }
  }

  & .bank-transfer-info {
    padding: 30px;
    margin-top: 30px;
    background-color: ${theme.colors.product_list_bg};

    & .horizontal {
      padding-top: 10px;
      border-bottom: 1px solid ${theme.colors.mid_grey};
    }

    & .input-form-box {
      position: relative;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${theme.typography.text_14_R}

      & span {

      }

      & .copy-icon {
        position: absolute;
        right: 11px;
      }

      & input {
        padding-left: 14px;
        width: 460px;
        height: 40px;
        outline: none;
        border: 1px solid ${theme.colors.mid_grey};
        color: ${theme.colors.main_black};

        &:read-only {
          background-color: ${theme.colors.main_white};
        }
      }

      &.check-box {
        justify-content: start;
        margin-top: 20px;

        & .short-horizontal {
          padding-top: 20px;
          width: 460px;
          border-top: 1px solid ${theme.colors.dark_grey};
          display: flex;
        }

        & span {
          width: 80px;
        }

        & button {
          display: flex;
          align-items: center;

          & img {

          }

          & span {
            margin-left: 5px;
            width: 90px;
            text-align: left;

            &.on {
              color: ${theme.colors.main_black};
            }
            &.off {
              color: ${theme.colors.dark_grey};
            }
          }
        }
      }
    }
  }

  @media ${xs} {
    
    & .payment-options {
      gap: 20px 10px;

      & .payment-btn {
        width: 45%;
      }
    }

    & .bank-transfer-info {
      padding: 20px 10px 5px;

      & .horizontal {
        padding-top: 5px;
        border-width: 0.5px;
      }

      & .input-form-box {
        display: block;
        margin-bottom: 15px;

        & span {
          display: block;
          margin-bottom: 10px;
        }

        & .copy-icon {
          transform: translateY(10px);
        }

        & input {
          padding-left: 10px;
          width: 100%;
        }

        &.check-box {

          & .short-horizontal {
            width: 100%;
            border-width: 0.5px;
          }

        }
      }
    }
  }
`;

export const OrderTotalAmountContainer = styled.div`
  display: flex;
  justify-content: end;
  margin: 80px 0 200px;
  width: 100%;

  & .flex-layout {
    width: 100%;
    background-color: ${theme.colors.main_white};
  
    & .order-btn-checkout {
      width: 100%;
      height: 60px;
      background-color: ${theme.colors.main_black};
      color: ${theme.colors.main_white};
      ${theme.typography.text_16_M}
    }
  }

  @media ${xs} {
    margin: 50px 0 120px;
    
    & .flex-layout {

      & .order-btn-checkout {
        height: 50px;
      }
    }
  }
`;

export const RecentProductContainer = styled.div<{$rows: number}>`
  position: relative;
  margin: 40px auto 100px;
  padding: 20px 0;
  width: 100%;
  max-width: 900px;

  & .recent-product-main-head {
    margin-top: 140px;
    margin-bottom: 25px;
    padding-bottom: 80px;
    border-bottom: 2px solid ${theme.colors.main_black};
    ${theme.typography.large_30_B}
    text-align: center;
  }

  & .recent-product-head {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px solid ${theme.colors.main_black};

    & h3 {
      ${theme.typography.title_24_B}
    }

    & button {
      padding-bottom: 1px;
      color: ${theme.colors.dark_grey};
      ${theme.typography.text_14_R}
      border-bottom: 1px solid ${theme.colors.dark_grey};
    }
  }

  & .recent-product-flex-box {
    display: grid;
    grid-template-columns: repeat(${props => props.$rows}, 1fr);
    gap: 60px 20px;
    width: 100%;

    & .recent-product-item {
      cursor: pointer;
      width: 100%;
      min-width: 100%;

      & .product-image {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 0.5px solid ${theme.colors.light_grey};
        aspect-ratio: 1;

        & img {
          max-height: 100%;
          max-width: 100%;
          object-fit: contain;
        }

        & .inventory {
          position: absolute;
          left: 0;
          top: 0;
          padding: 6px 10px;
          background-color: ${theme.colors.dark_grey};
          color: ${theme.colors.main_white};
          ${theme.typography.text_14_R}
        }
      }

      & .product-text {
        margin-top: 10px;

        & .brand {
          ${theme.typography.caption_12_R}
          color: ${theme.colors.main_black};
        }
        & .product {
          display: block;
          margin-top: 8px;
          ${theme.typography.text_14_R}
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        & .color {
          margin-top: 4px;
          ${theme.typography.caption_12_R}
          color: ${theme.colors.dark_grey};
        }
      }

      & .product-price {
        margin-top: 10px;

        & .original-price {
          height: 16px;
          color: ${theme.colors.dark_grey};
          ${theme.typography.caption_12_R}
          text-decoration: line-through;
        }
        & .sale-price {
          ${theme.typography.text_16_M}
        }
        & .discount-rate {
          margin-left: 10px;
          color: ${theme.colors.point_red};
          ${theme.typography.text_16_M}

          &.additional-discount {
            margin-left: 0;
          }
        }

        & .additional-discount-box {
          display: flex;
          align-items: center;

          & .arrow-icon-img {
            margin-top: 4px;
            margin-right: 2px;
            width: 14px;
            height: 14px;
            transform: translateY(-1.5px) rotate(180deg);
          }

          & .product-discount {
            font-size: 12px;
          }
        }

        & .compound-discount {
          margin-left: 4px;
          ${theme.typography.caption_12_R}
        }
      }

      & .discount-rate-vat {
        ${discount_rate_vat_style}
      }
    }
  }

  /* @media ${md} {
    padding: 0 20px;
  } */

  @media ${xs} {
    padding: 0 0 200px;

    & .recent-product-main-head {
      margin-top: 120px;
    }

    &.location-cart {
      padding: 20px;
    }
    margin-bottom: 0;

    & .recent-product-flex-box {
      gap: 30px 10px;

      & .recent-product-item {
        max-width: 180px;

        & .product-image {

          & .inventory {
            ${theme.typography.caption_12_R}
          }
        }
      }
    }
  }
`;

export const JoinContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  padding: 50px 0 200px;
  width: 100%;
  min-height: 100vh;
  background-color: ${theme.colors.product_list_bg};

  & button {
    margin-left: 10px;
    height: 50px;
    width: 100px;
    color: ${theme.colors.main_white};
    background-color: ${theme.colors.main_black};
    ${theme.typography.text_14_R}
  }

  & .join-wrapper {
    margin: 0 auto;
    width: 480px;

    & .join-title {
      text-align: center;
      ${theme.typography.title_24_B}
    }

    & .section {
      margin-top: 30px;
      width: 100%;

      & .items {
        position: relative;
        margin-bottom: 50px;

        & label {
          display: block;
          ${theme.typography.text_14_M}
          margin-bottom: 6px;

          & span {
            color: ${theme.colors.point_red};
          }
        }

        & input {
          padding-left: 10px;
          height: 50px;
          width: 480px;

          &.short {
            width: 370px;
          }

          &::placeholder {
            color: ${theme.colors.dark_grey};
          }
        }

        & input[type="text"], input[type="password"] {
          ${theme.typography.text_14_R}
          outline: 1px solid ${theme.colors.light_mid_grey};

          &:read-only {
            background-color: #fff;
          }
        }

        &.connect {
          margin: 10px 0;
        }
        &.connect-2 {
          margin: 30px 0;
        }

        & .input-error {
          position: absolute;
          padding-left: 10px;
          padding-top: 6px;
          color: ${theme.colors.point_blue};
          ${theme.typography.text_14_R}

          &.okay {
            color: ${theme.colors.dark_grey};
            ${theme.typography.caption_12_R}
          }
        }

        & .noti {
          margin-bottom: 12px;
          color: ${theme.colors.point_red};
          ${theme.typography.caption_12_R}
        }

        & .gender-button {
          margin: 0;
          margin-right: 10px;
          width: 80px;
          height: 50px;
          border: 1px solid ${theme.colors.main_black};
          color: ${theme.colors.black_dark_grey};
          background-color: ${theme.colors.main_white};
          ${theme.typography.text_14_R}

          &.active {
            background-color: ${theme.colors.main_black};
            color: ${theme.colors.main_white};
          }
        }

        & .count-down {
          position: absolute;
          right: 120px;
          top: 50%;
          transform: translateY(-50%);
        }

        & .additional-information {
          color: ${theme.colors.main_black};
          ${theme.typography.title_20_SB}
        }

        & .complex-box {
          margin: 6px 0;
          padding: 10px 0 20px;
          width: 100%;
          border-color: ${theme.colors.main_black};
          border-style: solid;
          border-top-width: 1px;
          border-bottom-width: 1px;
  
          & .complex-noti {
            ${theme.typography.text_14_M}
          }
  
          & .complex-grid {
            display: flex;
            flex-wrap: wrap;
            /* justify-content: space-between; */
            gap: 20px;
            margin-top: 20px;
  
            & .complex-items {
              display: flex;
              align-items: center;
              gap: 5px;
              width: max-content;
              cursor: pointer;

              & span {
                color: ${theme.colors.dark_grey};
              }

              &.active {

                & span {
                  color: ${theme.colors.main_black};
                }
              }
            }
          }

          & .terms-flex {
            display: flex;
            justify-content: space-between;
            align-items: end;
            margin: 12px 0;

            & .terms-check-box {
              display: flex;
              align-items: center;
              gap: 5px;
              cursor: pointer;

              & span {
                ${theme.typography.text_14_R}
              }
            }

            & .terms-content {
              color: ${theme.colors.dark_grey};
              ${theme.typography.caption_12_R}
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }

        &.modal-items {
          text-align: left;

          & input {
            width: 400px;
          }
        }
      }

      & .select-box {
        position: relative;
        width: 360px;
        border: 1px solid ${theme.colors.light_mid_grey};

        & .select-item {
          padding: 10px;
          border-top: 1px solid ${theme.colors.mid_grey};
          
          &:hover {
            background-color: ${theme.colors.white_light_grey};
          }

          &.select-indicator {
            border-top: 0;

            &:hover {
              background-color: initial;
            }
          }
          &.select-none {
            color: ${theme.colors.mid_grey};
          }
        }
      }

      &.address {
        margin-top: 50px;
      }

      &.term-condition {
        margin-top: 50px;
      }
    }

    & .control-box {
      display: flex;
      justify-content: space-between;
      width: 480px;
      gap: 10px;

      & .control-button {
        margin: 0;
        width: 50%;
        ${theme.typography.text_16_M}

        &.cancel {
          color: ${theme.colors.main_black};
          background-color: ${theme.colors.main_white};
          border: 1px solid ${theme.colors.main_black};
        }

        &.join {
          background-color: ${theme.colors.main_black};
          color: #fff;
        }
      }
    }

    & .guide-join {
      margin-top: 40px;
      padding: 20px 0;
      border: 1px solid #000;
      text-align: center;

      & .guide-title {
        line-height: 20px;
        color: ${theme.colors.point_red};
        ${theme.typography.text_16_M}
      }

      & .guide-detail {
        margin-top: 10px;
        line-height: 20px;
        color: ${theme.colors.black_dark_grey};
        ${theme.typography.text_14_R}
      }
    }
  }

  @media ${xs} {
    margin-top: 60px;
    
    & .join-wrapper {
      padding: 0 15px;
      width: 100%;

      & .section {

        & .items {

          & input {
            width: 100%;

            &.short {
              width: calc(100% - 110px);
            }
          }

          & .complex-box {

            & .complex-grid {
              gap: 20px 14px;
            }

            & .terms-flex {
              
              & .terms-check-box {
                
                &.align-marketing {
                  align-items: start;
                }
                & span {
                  color: ${theme.colors.dark_grey};
                }
              }
            }
          }
        }
      }

      & .control-box {
        padding-top: 20px;
        width: 100%;
      }
    }
  }
`;

export const LoginContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${theme.colors.main_white};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1));
    z-index: 0;
  }

  & .login-wrapper {
    position: relative;
    z-index: 1;

    & .title {
      margin-bottom: 40px;
      text-align: center;
      ${theme.typography.large_30_B}

      & img {
        margin: 0 auto;
        width: 240px;
      }

      & p {
        ${theme.typography.text_14_R}
        font-weight: 300;
      }
    }
  
    & .input-box {
      
      & input {
        margin-bottom: 10px;
        width: 400px;
        height: 60px;
        border-radius: 100px;
        text-align: center;
        background-color: transparent;
        color: ${theme.colors.main_white};
        outline: 1px solid ${theme.colors.light_grey};

        &::placeholder {
          color: ${theme.colors.light_grey};
        }
      }

      & .login-button {
        display: block;
        width: 400px;
        height: 60px;
        background-color: ${theme.colors.white_light_grey};
        border-radius: 100px;
        color: ${theme.colors.main_black};
      }
    }


    & .control-box {
      text-align: center;
      color: ${theme.colors.light_mid_grey};
      ${theme.typography.text_14_R}

      & button {
        margin-top: 15px;
        padding: 10px;

        &:hover {
          color: ${theme.colors.main_white};
        }
      }
    }
  }

  @media ${xs} {

    &::after {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
    }
    
    & .login-wrapper {
      width: 100%;
      & .title {

      }

      & .input-box {
        padding: 0 15px;

        & input {
          width: 100%;
        }

        & .login-button {
          width: 100%;
        }
      }
    }
  }
`;

export const MyContainer = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  margin-top: 165px;
  min-height: calc(100vh - 400px);
  max-width: 1200px;

  & .mobile-menu-box {
    position: absolute;
    display: none;
    right: 15px;
    top: -60px;
  }

  & .my-outlet {
    position: relative;
    left: 300px;
    width: 100%;
    max-width: 900px;
  }

  @media ${xl} {
    margin-top: 140px;

    @media ${md} {
      margin-top: 100px;
    }

    & .mobile-menu-box {
      display: block;
    }
    
    & .my-outlet {
      margin: 60px auto 0;
      left: 0;

      &.short-width {
        padding: 0;
        max-width: 510px;
      }
    }
  }

  @media ${xs} {
    padding: 0 15px;

    & .mobile-menu-box {
      transform: translateX(15px);
    }
  }
`;

export const MyMainContainer = styled.div`
  
  & .section-title {
    ${theme.typography.title_24_B}
  }
  & .section-flex-box {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 60px;
    padding-bottom: 10px;
    border-bottom: 2px solid ${theme.colors.main_black};

    & button {
      padding-bottom: 1px;
      color: ${theme.colors.dark_grey};
      ${theme.typography.text_14_R}
      border-bottom: 1px solid ${theme.colors.dark_grey};
    }
  }

  & .customer-info {

    & .complex-and-ablack-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;
      height: 100px;
      padding: 0 30px;
      border:1px solid ${theme.colors.light_mid_grey};

      & .complex-info {

        & .account-name {
          ${theme.typography.title_24_B}
        }
        & .complex-name {
          margin-left: 10px;
          color: ${theme.colors.dark_grey};
          ${theme.typography.title_20_SB}
        }
        & .period {
          margin-top: 10px;
          ${theme.typography.text_14_M}
        }
      }

      & .ablack-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-left: 2px solid #D9D9D9;
        height: 60px;
        
        & .logo {
          padding-left: 25px;
          height: 34px;

          & img {
            height: 100%;
          }
        }

        & .download {
          margin-left: 10px;
          height: 34px;
          line-height: 17px;
          ${theme.typography.caption_12_R}
        }
      }
    }
  }

  & .temp-cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 16px 0;
    border: solid ${theme.colors.dark_grey};
    border-width: 1px 0;
    ${theme.typography.text_16_M}

    & .cancel-category {
      display: flex;
      gap: 15px;
    }
  }

  @media ${xl} {
    
    & .section-title {
      
    }
    & .section-flex-box {

      & button {
      }
    }

    & .customer-info {

      & .complex-and-ablack-box {
        flex-direction: column;
        align-items: start;
        padding: 0 20px;
        height: auto;

        & .complex-info {
          padding: 30px 0 20px;
          width: 100%;
          border-bottom: 1px solid #D9D9D9;

          & .account-name {
            ${theme.typography.title_20_SB}
          }
          & .complex-name {
            display: block;
            margin-top: 4px;
            margin-left: 0;
            ${theme.typography.text_16_M}
          }
          & .period {
            margin-top: 15px;
            line-height: 18px;
          }
        }

        & .ablack-info {
          margin: 10px 0 15px;
          border: 0;
          width: 100%;
          
          & .logo {
            padding: 0;

            & img {
            }
          }

          & .download {
          }
        }
      }
    }

    & .temp-cancel {
      ${theme.typography.text_14_M}
    }
  }

  @media ${md} {
    padding: 0 15px;
  }
  @media ${xs} {
    padding: 0;
  }
`;

export const MySideNav = styled.div`
  position: fixed;
  margin-top: 20px;
  min-width: 240px;
  padding-bottom: 400px;
  width: 240px;

  & .nav-title-box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 18px;
    border-bottom: 2px solid #000;

    & .nav-title {
      width: 100%;
      ${theme.typography.title_20_SB}
      text-align: start;
    }

    & .close-btn {
      display: none;
    }
  }

  & .nav-box {
    margin-top: 18px;
    border-bottom: 1px solid ${theme.colors.main_black};
    ${theme.typography.text_16_M}
      
    & button {
      padding-bottom: 18px;
      width: 100%;
      color: ${theme.colors.mid_grey};
      text-align: start;

      &.single {
        color: #000;
      }
      &.logout {
        padding-bottom: 20px;
        border-bottom: 2px solid #000;
      }
      &.current-link {
        color: ${theme.colors.main_black};
      }
    }
  }

  @media ${xl} {
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 80px 15px;
    width: 100%;
    background-color: ${theme.colors.main_white};
    z-index: 990;
    transition: all 0.3s;

    &.mobile-show {
      top: 0;
    }
    & .nav-title-box {

      & .nav-title {
        
      }

      & .close-btn {
        display: block;
      }
    }

    & .nav-box {
        
      & button {

        &.single {

        }
        &.logout {

        }
        &.current-link {

        }
      }
    }
  }
`;

export const MyContent = styled.div`
  width: 100%;
`;

export const MyAddressContainer = styled.div`
  position: relative;
  width: 520px;
  padding: 20px 0 80px;

  & .title {
    padding-bottom: 20px;
    border-bottom: 1px solid ${theme.colors.main_black};
    ${theme.typography.title_24_B}
  }

  & .main-address {
    padding: 20px;
  }

  & .address-notice {
    margin: 0 20px;
    padding: 20px 0 40px;
    color: ${theme.colors.point_red};
    ${theme.typography.text_14_R}
    border-bottom: 1px solid ${theme.colors.light_mid_grey};
  }

  & .add-new-address {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-left: 20px;
    width: calc(100% - 40px);
    height: 50px;
    border: 1px solid ${theme.colors.main_black};
    ${theme.typography.text_16_M}
  }

  & .extra-address-wrapper {
    margin-top: 20px;
  }

  @media ${xl} {
    margin: 0 auto;
  }

  @media ${sm} {
    padding: 20px 15px 80px;
    width: 100%;
  }

  @media ${xs} {
    padding: 20px 0 80px;
  }
`;

export const ExtraAddress = styled.div`
  margin: 20px;
  padding: 30px 0 25px;
  border-bottom: 1px solid ${theme.colors.main_black};

  & .address-name {
    ${theme.typography.title_20_SB}
  }

  & .address-content {
    margin-top: 10px;
    line-height: 15px;
    ${theme.typography.text_14_R}

    & .customer-name, .phone-number {
      line-height: 32px;
    }

    & .detail-address {
      margin-top: 4px;
    }
  }

  & .address-control-box {
    margin-top: 20px;
    display: flex;
    justify-content: end;
    gap: 10px;
    ${theme.typography.text_16_M}

    & button {
      padding: 10px 14px;
      border: 1px solid ${theme.colors.light_mid_grey};
    }
  }
  
  & .label {
    width: 80px;
    min-width: 80px;
  }
`;

export const AddAddressModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 20px;
  width: 320px;
  background-color: ${theme.colors.main_white};
  transform: translate(-50%, -50%);

  & .modal-items {
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    height: 30px;

    & label {
      width: 80px;
      min-width: 80px;
      height: 30px;
      line-height: 30px;
    }

    & input {
      outline: 1px solid #000;
      height: 30px;
      padding-left: 10px;
    }
  }

  & .control-box {
    display: flex;
    justify-content: end;
    width: 100%;
    padding: 5px 20px;
  }
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
`;

export const SwiperContainer = styled.div<{
  $currentIndex: number,
  $viewportHeight: number,
}>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 700px;
  cursor: pointer;
  & .swiper-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    transform: translateX(-${props => props.$currentIndex * 100}%);
    transition-property: all;
  }

  & .control-box {
    position: absolute;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    & .control {
      padding: 2%;
      /* background-color: rgba(0, 0, 0, 0.5); */
      pointer-events: initial;
      @media ${xs} {
        display: none;
      }
  
      /* &.back {
        left: 0;
      }
      &.front {
        right: 0;
      } */
    }
  }

  & .indicator {
    position: absolute;
    display: none;
    justify-content: center;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);

    & .index-button {
      margin: 10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);

      @media ${xs} {
        margin: 4px;
        width: 10px;
        height: 10px;
      }

      &.active {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  @media ${sm} {
    margin-top: 60px;
    height: ${props => props.$viewportHeight-120}px;
  }
`;

export const SwiperItem = styled.div`
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  font-size: 100px;
  color: white;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  & .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.main_white};

    & .modal-wrapper {
      margin: 32px 0;
      min-width: 420px;
      padding: 0 32px;
      text-align: center;

      & h3 {
        ${theme.typography.title_24_B}
      }

      & .modal-text {
        margin: 35px 0;
        line-height: 20px;
        color: ${theme.colors.dark_grey};
        ${theme.typography.text_14_R}
        white-space: pre-wrap;
      }

      & button {
        margin: 0 10px;
        padding: 10px 20px;
        ${theme.typography.text_14_R}

        &.modal-close-btn {
          border: 1px solid ${theme.colors.dark_grey};
          color: ${theme.colors.dark_grey};
        }
  
        &.modal-confirm-btn {
          border: 1px solid ${theme.colors.main_black};
          background-color: ${theme.colors.main_black};
          color: ${theme.colors.main_white};
        }
      }
    }
  }

  @media ${xs} {
    
    .modal {

      & .modal-wrapper {
        min-width: 330px;
      }
    }
  }
`;

export const OrderListContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 100px;
  max-width: 900px;

  &.my-main {
    margin-bottom: 0;
  }

  & .order-list-title {
    position: relative;
    padding-bottom: 90px;
    border-bottom: 2px solid ${theme.colors.main_black};
    text-align: center;
    ${theme.typography.large_30_B}

    & .estimate-notice {
      position: absolute;
      left: 0;
      bottom: 40px;
      color: ${theme.colors.point_red};
      ${theme.typography.text_14_M}
    }
  }

  & .order-summary-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 140px;

    & .summary-item {
      flex-grow: 1;
      padding: 20px 0;
      width: 100%;
      height: 96px;
      
      & .value {
        text-align: center;
        color: ${theme.colors.light_mid_grey};
        ${theme.typography.large_30_B}
      }
  
      & .label {
        margin-top: 12px;
        color: ${theme.colors.dark_grey};
        ${theme.typography.text_14_R}
      }

      &.active {

        & .value {
          color: ${theme.colors.main_black};
        }
      }
    }

    & img {
      width: 30px;
      height: 30px;
    }

    &.my-main {
      & .summary-item {
        & .value {
          color: ${theme.colors.main_black};
        }
      }
    }
  }

  & .order-sort-filter {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 20px;

    & .sort-name {
      ${theme.typography.title_24_B}
    }
    
    & .calendar-box {
      display: flex;
    }
  }

  @media ${md} {
    padding: 0 15px;
  }

  @media ${xs} {
    padding: 0;
    
    & .order-summary-box {

      & .summary-item {

        & .value {

        }
        & .label {
          line-height: 12px;
          ${theme.typography.caption_10_R}
        }
      }
    }
  }
`;

export const OrderListItem = styled.div`
  margin-top: 30px;
  padding: 20px 30px 0;
  background-color: ${theme.colors.product_list_bg};

  & .order-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0 15px;

    & .order-info {
      display: flex;
      align-items: center;

      & .order-label {
        ${theme.typography.text_14_R}
      }

      & .order-info-flex-box {
        display: flex;
        align-items: center;

        & .order-number {
          margin-left: 10px;
          ${theme.typography.text_16_M}
        }

        & .pay-date {
          margin-left: 6px;
          color: ${theme.colors.dark_grey};
          ${theme.typography.caption_12_R}
        }
      }
    }

    & .total-price {
      display: flex;
      ${theme.typography.text_14_R}

      & .price-label {
        ${theme.typography.text_14_R}
      }

      & .price-text {
        margin-left: 10px;
        ${theme.typography.text_16_M}
      }
    }

  }

  & .order-detail-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-top: 1px solid ${theme.colors.dark_grey};

    & .order-detail-left {
      display: flex;
      align-items: center;
      flex: 2;

      & .order-detail-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 84px;
        height: 84px;
        background-color: ${theme.colors.main_white};
        border: 0.5px solid ${theme.colors.light_grey};
        aspect-ratio: 1;
        cursor: pointer;
  
        & img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
  
      & .order-detail-product {
        flex: 3;
        padding-top: 2px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 84px;
        margin-left: 10px;
  
        & .brand {
          ${theme.typography.caption_12_R}
        }
        & .product {
          color: ${theme.colors.main_black};
          ${theme.typography.text_14_M}
        }
        & .color, .quantity {
          color: ${theme.colors.dark_grey};
          ${theme.typography.caption_12_R}
        }
        & .estimate {
          color: ${theme.colors.point_blue};
        }
        & .offer-list-option {
          color: ${theme.colors.dark_grey};
          ${theme.typography.caption_10_R}
        }
      }
    }

    & .order-detail-right {
      display: flex;
      align-items: center;
      flex: 3;

      & .order-detail-price {
        flex: 2;
        text-align: center;
        
        & .detail-original-price {
          color: ${theme.colors.dark_grey};
          ${theme.typography.caption_12_R}
          text-decoration: line-through;
          ${pretendard}
        }
        & .detail-rated-price {
          color: ${theme.colors.main_black};
          ${theme.typography.text_16_M}
          ${pretendard}
        }
        & .estimate-price-text {
          line-height: 18px;
          color: ${theme.colors.dark_grey};
          ${theme.typography.caption_12_R}
        }
      }
  
      & .order-detail-status {
        flex: 2;
        text-align: center;
        ${theme.typography.text_14_M}

        & .deilvery-status {
          line-height: 18px;

          &.estimate {
            color: ${theme.colors.dark_grey};
            ${theme.typography.caption_12_R}
          }
        }

        & .exchange-return-status {
          color: ${theme.colors.dark_grey};
          ${theme.typography.text_14_R}

          &.estimate {
            color: ${theme.colors.main_black};
            ${theme.typography.text_16_M}
          }
        }
      }
  
      & .order-detail-refund-button {
        flex: 2;
        text-align: end;
        ${theme.typography.text_14_M}
        
        & button {
          padding: 8px 10px;
          border: 0.5px solid #000;
          background-color: ${theme.colors.main_white};
        }
      }

      &.order-cancel {

        & .order-detail-price {
          text-align: end;
        }
      }
    }
  }

  @media ${xs} {
    margin: 30px 0 0;
    padding: 20px 20px 0;

    & .order-item-header {
      flex-direction: column;
      padding: 0;

      & .order-info {
        padding: 10px 0;

        & .order-label {
         width: 80px;
         height: 30px;
         text-align: start;
        }

        & .order-info-flex-box {
          display: block;
          height: 30px;

          & .order-number {
            margin: 0;
          }
          & .pay-date {
            margin: 0;
            margin-top: 2px;
            text-align: start;
          }
        }
      }

      & .total-price {
        padding: 10px 0 20px;
      
        & .price-label {
          width: 80px;
        }

        & .price-text {
          margin: 0;
        }
      }
    }

    & .order-detail-box {
      flex-direction: column;
      align-items: start;

      & .order-detail-left {

        & .order-detail-image {
          width: 98px;
          height: 98px;
        }
    
        & .order-detail-product {
          height: 98px;
        }
      }

      & .order-detail-right {
        flex-direction: column;
        align-items: start;
        margin-top: 20px;
        margin-left: 108px;

        & .order-detail-price {

          & .detail-original-price {
            margin-bottom: 4px;
            text-align: start;
          }
          & .estimate-price-text {
            text-align: start;
          }
        }
    
        & .order-detail-status {
          margin: 15px 0;

          & .exchange-return-status {
            text-align: start;
          }
        }
      }
    }
  }
`;

export const OrderSelectOneContainer = styled.div`
  margin-bottom: 200px;
  width: 100%;

  & .order-select-one-header {
    display: flex;
    justify-content: space-between;

    & .order-select-one-title {
      ${theme.typography.title_24_B}
  
      & .count {
        ${theme.typography.text_16_M}
      }
    }

    & .receipt-btn {
      padding: 8px 10px;
      background-color: ${theme.colors.main_black};
      color: ${theme.colors.main_white};
      ${theme.typography.text_14_R}
    }
  }

  @media ${md} {
    padding: 0 15px;
  }

  @media ${xs} {
    padding: 0;
  }
`;

export const ProductDetailContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;

  & .no-product-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    & .no-product-detail-wrapper {
      
      & h3 {
        ${theme.typography.title_20_SB}
        text-align: center;
      }

      & .control-box {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        gap: 10px;

        & button {
          padding: 0 15px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid ${theme.colors.main_black};

          &.to-main {
            background-color: ${theme.colors.main_black};
            color: ${theme.colors.main_white};
          }
        }
      }
    }
  }

  @media ${xs} {
    margin-top: 40px;
  }
`;

export const MovingHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s;
`;

export const MyPageComponentContainer = styled.div`
  width: 480px;

  & .horizontal {
    margin: 30px 0 30px;
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.main_black};
  }

  & .text-box {

    & .title {
      margin-bottom: 20px;
      ${theme.typography.title_24_B}
    }
    & .sub-title {
      ${theme.typography.title_20_SB}
    }
    & .notice {
      line-height: 17px;
      ${theme.typography.text_14_M}
    }
    & .flex-text-box {
      position: relative;
      display: flex;
      align-items: center;
      line-height: 50px;
      ${theme.typography.text_16_M}

      & .label {
       min-width: 120px;
      }
      & .content {

        &.lighter {
          color: ${theme.colors.main_black};
          ${theme.typography.text_14_R}
        }
      }
      & .update-btn {
        position: absolute;
        right: 0;
        width: 80px;
        outline: 1px solid ${theme.colors.main_black};
        ${theme.typography.text_14_R}
      }

      & .multi-line {
        line-height: normal;
      }

      & .red-notice {
        color: ${theme.colors.point_red};
        ${theme.typography.caption_12_R}
      }

      &.terms {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & button {
          display: flex;
          align-items: center;
          color: ${theme.colors.dark_grey};
          ${theme.typography.caption_12_R}

          &.terms-btn {
            line-height: normal;
            border-bottom: 1px solid ${theme.colors.dark_grey};
          }
        }

        & span {
          margin-left: 5px;
          color: ${theme.colors.main_black};
          ${theme.typography.text_14_R}
        }
      }
    }
  }

  & .password-box {
    margin-top: 40px;
    width: 480px;

    & .password {
      padding-left: 10px;
      width: 100%;
      height: 50px;
      ${theme.typography.text_14_R}
      outline: 1px solid ${theme.colors.light_grey};

      &::placeholder {
        color: ${theme.colors.dark_grey};
      }
    }

    & button {
      margin-top: 20px;
      width: 100%;
      height: 50px;
      display: block;
      background-color: ${theme.colors.main_black};
      color: ${theme.colors.main_white};
    }
  }

  & .quit-box {
    display: flex;
    justify-content: end;

    & button {
      padding-bottom: 1px;
      border-bottom: 1px solid ${theme.colors.main_black};
      ${theme.typography.text_14_M}
    }
  }

  & .save-box {
    display: flex;
    justify-content: center;

    & button {
      margin-top: 60px;
      width: 50%;
      height: 50px;
      background-color: ${theme.colors.main_black};
      color: ${theme.colors.main_white};
      ${theme.typography.text_16_M}
    }
  }

  @media ${xs} {
    width: 100%;

    & .password-box {
      width: 100%;
    }
  }
`;

export const ChangeProfileInputContainer = styled.div`
  margin-top: 20px;

  & .description-box {
    padding-top: 5px;
    ${theme.typography.text_14_R}
    color: ${theme.colors.black_dark_grey};
  }

  & .input-box {
    position: relative;
    display: flex;
    margin: 10px 0;
    
    & input {
      padding-left: 10px;
      width: 480px;
      height: 50px;
      outline: 1px solid ${theme.colors.light_grey};

      &.phone-number {
        width: 350px;
      }
    }

    & button {
      margin-left: 10px;
      width: 120px;
      height: 50px;
      background-color: ${theme.colors.main_black};
      color: ${theme.colors.main_white};
      ${theme.typography.text_14_R}
    }

    & .count-down {
      position: absolute;
      right: 140px;
      line-height: 50px;
    }

    & .input-error {
      position: absolute;
      bottom: -10px;
      left: 10px;
      color: ${theme.colors.dark_grey};
      ${theme.typography.caption_12_R}
    }
  }

  & .control-box {
    display: flex;
    gap: 20px;
    padding-top: 10px;
    ${theme.typography.text_16_M}

    & button {
      width: 50%;
      height: 50px;
      background-color: ${theme.colors.main_black};
      color: ${theme.colors.main_white};

      &.cancel {
        border: 1px solid ${theme.colors.main_black};
        background-color: ${theme.colors.main_white};
        color: ${theme.colors.main_black};
      }
    }
  }
`;

export const HorizontalDivider = styled.div`
  margin: 40px 0;
  width: 100%;
  height: 10px;
  background-color: ${theme.colors.white_light_grey};
`;

export const OrderPaymentInfoContainer = styled.div`
  
  & .payment-info-box {

    & .payment-info-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      ${theme.typography.text_14_R}

      & .total-price {
        color: ${theme.colors.point_red};
        ${theme.typography.title_20_SB}
      }
    }

    & .horizontal {
      margin-top: 20px;
      border-top: 1px solid ${theme.colors.dark_grey};
    }
  }
`;

export const OrderCustomerContainer = styled.div`
  margin-top: 20px;
  
  & .customer-name {
    ${theme.typography.title_20_SB}
  }

  & .customer-phone-number {

  }

  & .customer-address {
    ${theme.typography.text_14_R}
  }

  & .order-address-notice {
    margin: 30px 0 20px;
    color: ${theme.colors.point_red};
    ${theme.typography.caption_12_R}

    & p {
      line-height: 16px;
    }
  }
`;

export const OrderPromotionContainer = styled.div`

  & .send-shipping-container {

    & .select-send-division {
      display: flex;
      height: 30px;
      line-height: 30px;
      margin-bottom: 20px;

      & .check-item {
        display: flex;
        align-items: center;

        & img {

        }
        & span {
          margin: 0 18px 0 8px;
          color: ${theme.colors.black_dark_grey};
          ${theme.typography.text_14_R}
        }
      }
    }
  }

  & .receiver-shipping-container {

  }
`;

export const ModalAddressBook = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  background-color: ${theme.colors.main_white};
  transform: translate(-50%, -50%);

  & .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    border-bottom: 1px solid ${theme.colors.light_grey};
    ${theme.typography.title_24_B}

    & .title-btn {

      & img {
        width: 40px;
        height: 40px;
      }
    }
  }
  
  & .modal-wrapper {
    padding: 0 30px;
    height: 600px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* 인터넷 익스플로러 */
      scrollbar-width: none; /* 파이어폭스 */
    }

    & .add-address {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      margin-top: 20px;
      width: 100%;
      height: 60px;
      border: 1px solid ${theme.colors.main_black};
      ${theme.typography.text_16_M}
    }

    & .address-item {
      padding: 30px 0 25px;
      border-bottom: 1px solid ${theme.colors.light_grey};

      & .address-name {
        ${theme.typography.title_20_SB}
      }
      
      & .address-content {
        margin-top: 10px;
        line-height: 15px;
        ${theme.typography.text_14_R}

        & .phone-number {
          line-height: 26px;
        }
      }

      & .address-control-box {
        margin-top: 20px;
        display: flex;
        justify-content: end;
        gap: 10px;
        ${theme.typography.text_16_M}

        & button {
          padding: 8px 13px;
          border: 1px solid ${theme.colors.light_mid_grey};

          &.select-btn {
            background-color: ${theme.colors.main_black};
            color: ${theme.colors.product_list_bg};
            border-color: ${theme.colors.main_black};
          }
        }
      }
    }
  }

  & .modal-control {
    display: flex;
    justify-content: end;
    padding: 10px 15px 10px 0;
    box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.15), 0 4px 4px 0 rgba(0, 0, 0, 0.25);

    & .close-btn {
      border: 1px solid ${theme.colors.main_black};
      padding: 10px;
    }
  }

  @media ${xs} {
    max-width: 360px;

    & .modal-wrapper {

      & .add-address {
        height: 40px;
      }
    }
  }
`;

export const ModalAddAddress = styled.div`
  
  & .notice {
    margin-top: 40px;
    color: ${theme.colors.point_red};
    ${theme.typography.text_16_M}
  }

  & .modal-address-input {
    margin-top: 10px;

    & .label {
      margin: 20px 0 6px;
      ${theme.typography.text_14_M}
    }

    & input {
      padding-left: 10px;
      width: 100%;
      height: 50px;
      border: 1px solid ${theme.colors.light_grey};
      outline: none;
      color: ${theme.colors.main_black};
      ${theme.typography.text_14_R}
      ${pretendard}

      &.short {
        margin-right: 10px;
        width: calc(100% - 130px);
      }
    }

    & .postal-code {
      width: 120px;
      height: 50px;
      background-color: ${theme.colors.main_black};
      color: ${theme.colors.main_white};
    }

    & .input-error {
      margin-top: 4px;
      color: ${theme.colors.point_red};
      ${theme.typography.caption_12_R}
    }
  }

  & .add-btn {
    margin: 50px 0 30px;
    width: 100%;
    height: 60px;
    background-color: ${theme.colors.main_black};
    color: ${theme.colors.main_white};
    ${theme.typography.text_16_M}
  }
`;

export const SimpleHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${theme.colors.main_black};
  z-index: 1000;

  & .container-wrapper {
    position: relative;
    height: 80px;

    & .back {
      position: absolute;
      top: 50%;
      left: 25%;
      transform: translate(-50%, -50%);

      @media ${sm} {
        left: 18%;
      }
    }
  
    & .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media ${xs} {
    
    & .container-wrapper {
      height: 60px;

      & .back {
        left: 8%;
      }
    }
  }
`;

export const JoinCompleteModal = styled.div`
  position: absolute;
  padding: 30px 60px;
  top: 50%;
  left: 50%;
  min-width: 420px;
  background-color: ${theme.colors.main_white};
  text-align: center;
  transform: translate(-50%, -50%);

  & .join-complete-title {
    ${theme.typography.title_24_B}
  }

  & .join-complete-content {
    margin: 25px 0;
    color: ${theme.colors.dark_grey};
    ${theme.typography.text_14_R}
  }

  & button {
    width: 85px;
    height: 37px;
    background-color: ${theme.colors.main_black};
    color: ${theme.colors.main_white};
    ${theme.typography.text_14_R}
  }

  @media ${xs} {
    padding: 30px 10px;
    min-width: 330px;
  }
`;

export const FindAccountResult = styled.div`
  margin-top: 80px;
  padding: 40px;
  background-color: ${theme.colors.main_white};
  border: 1px solid ${theme.colors.dark_grey};

  & .email-result-wrapper {

    & .email-results {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid ${theme.colors.dark_grey};

      & .email-result-title {
        ${theme.typography.text_16_M}
      }
  
      & .email-result-status {
        text-align: end;
        width: 100%;
        height: 60px;

        & .status {
          position: absolute;
          right: 0;
          top: 50%;
          ${theme.typography.text_14_M}
          transform: translateY(-50%);
        }
      }

      & .reason {
        position: absolute;
        right: 0;
        bottom: 15px;
        color: ${theme.colors.mid_grey};
        ${theme.typography.caption_12_R}
      }
    }
  }

  & .email-result-control {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;

    & button {
      margin-left: 0;
      width: 120px;
      height: 40px;

      &.result-white-btn {
        background-color: ${theme.colors.main_white};
        color: ${theme.colors.main_black};
        border: 1px solid ${theme.colors.main_black};
      }
    }
  }
`;

export const FindAccountControlBox = styled.div`
  margin-top: 60px;
  padding-top: 40px;
  width: 100%;
  border-top: 1px solid ${theme.colors.main_black};

  & .find-account-control {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid ${theme.colors.mid_grey};
    cursor: pointer;

    &.password {
      background-color: ${theme.colors.white_light_grey};
      color: ${theme.colors.black_dark_grey};
    }
    &.join {
      margin-top: 10px;
      background-color: ${theme.colors.main_black};
      color: ${theme.colors.white_light_grey};
    }
  }
`;

export const OrderSuccessContainer = styled.div`
  padding: 140px 0;
  background-color: ${theme.colors.product_list_bg};
  
  & .order-success-wrapper {
    margin: 0 auto;
    padding: 100px 60px;
    width: 600px;
    background-color: ${theme.colors.main_white};

    & .order-success-title {
      padding-bottom: 30px;
      border-bottom: 2px solid ${theme.colors.main_black};
      text-align: center;
      & h3 {
        ${theme.typography.large_30_B}
        font-weight: 500;
      }
      & p {
        margin-top: 14px;
        color: ${theme.colors.main_black};
        ${theme.typography.text_14_R}
      }
    }

    & .order-success-info {
      padding: 20px;
      border-bottom: 1px solid ${theme.colors.main_black};
      width: 100%;

      & .info-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 28px;

        & .label {
          color: ${theme.colors.dark_grey};
          ${theme.typography.text_14_R}
        }
        & .content {
          margin-left: 10px;
          color: ${theme.colors.main_black};
          ${theme.typography.text_14_M}
        }
      }
    }

    & .order-success-shipping-info {
      padding: 30px 20px;
      border-bottom: 1px solid ${theme.colors.main_black};
      color: ${theme.colors.main_black};

      & .shipping-name {
        margin-bottom: 10px;
        ${theme.typography.title_20_SB}
      }
      & .shipping-phone, .shipping-address {
        margin-top: 5px;
        ${theme.typography.text_14_R}
      }
    }

    & .order-success-product-box {
      padding: 30px 20px;
      border-bottom: 2px solid ${theme.colors.main_black};

      & .product-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }

      & .product-item {
        
      }
    }

    & .order-success-control-box {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-top: 50px;

      & button {
        width: 50%;
        height: 50px;
        border: 1px solid ${theme.colors.main_black};

        &.darker {
          background-color: ${theme.colors.main_black};
          color: ${theme.colors.main_white};
        }
      }
    }

    & .bank-transfer-section {
      padding: 0 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid ${theme.colors.mid_grey};

      & .bank-transfer-price {
        margin: 15px 0;
        color: ${theme.colors.point_red};
        ${theme.typography.title_20_SB}
        text-align: center;

        & span {
          color: ${theme.colors.main_black};
        }
      }

      & .input-form-box {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ${theme.typography.text_14_R}
  
        & .copy-icon {
          position: absolute;
          right: 11px;
        }
  
        & input {
          padding-left: 14px;
          width: 100%;
          height: 40px;
          outline: none;
          border: 1px solid ${theme.colors.mid_grey};
          color: ${theme.colors.main_black};
  
          &:read-only {
            background-color: ${theme.colors.main_white};
          }
        }
      }
    }
  }

  @media ${xs} {
    padding: 110px 15px 50px;
    
    & .order-success-wrapper {
      padding: 100px 15px;
      width: 100%;

      & .order-success-info {
        padding: 30px 10px;
      }

      & .order-success-shipping-info {
        padding: 30px 10px;

        & .shipping-name {
          margin-bottom: 20px;
        }

        & .shipping-address {
          margin-top: 10px;
          line-height: 18px;
        }
      }

      & .order-success-product-box {
        padding: 30px 10px;
      }

      & .order-success-control-box {
        gap: 10px;
      }

      & .bank-transfer-section {
        padding: 10px 0;

        & .bank-transfer-price {
          margin-bottom: 0;
        }

        & .input-form-box {
          justify-content: space-between;
          margin-top: 10px;

          & .copy-icon {
            position: relative;
            right: 0;
          }

          & input {
            padding: 0;
            border: none;
            ${pretendard}
          }
        }
      }

    }
  }
`;

export const OrderFailureContainer = styled.div`
  padding: 140px 0 160px;
  background-color: ${theme.colors.product_list_bg};

  & .order-failure-wrapper {
    margin: 0 auto;
    padding: 100px 60px;
    width: 600px;
    background-color: ${theme.colors.main_white};
    text-align: center;

    & .order-failure-title {

      & p {
        line-height: 36px;
        ${theme.typography.large_30_B}
      }
    }
    
    & .order-failure-notice {
      margin: 24px 0 50px;
      padding: 30px 0;
      border-color: ${theme.colors.main_black};
      border-style: solid;
      border-width: 2px 0;
      ${theme.typography.text_14_R}

      & p {
        line-height: 18px;
      }
    }
  
    & .order-failure-controller {
      
      & button {
        width: 100%;
        height: 50px;
        background-color: ${theme.colors.main_black};
        color: ${theme.colors.main_white};
      }
    }
  }

  @media ${xs} {
    padding: 110px 15px 50px;

    & .order-failure-wrapper {
      width: 100%;
      padding: 100px 15px;
    }
  }
`;

export const BrandGallery = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 120px;

  & .gallery-wrapper {
    position: relative;
    display: grid;
    margin: 60px 0;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    width: 1200px;
    height: 600px;
    background-color: #fff;
    box-sizing: border-box;
    transition: 0.4s;
  
    &:hover .item-box {
      filter: grayscale(1);

      @media ${xs} {
        filter: grayscale(0);
      }
    }
  
    & .item-box {
      position: relative;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transition: 0.4s;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: grayscale(0);
  
      &:hover {
        filter: grayscale(0);
      }
  
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent, transparent, transparent,  transparent, transparent, transparent, transparent, #000);
      }
  
      /* &:nth-child(even) {
        transform: translateY(30px);
      }
      &:nth-child(odd) {
        transform: translateY(-30px);
      } */
    }
    &:has(.one:hover) {
      grid-template-columns: 2fr 0.5fr 0.5fr 0.5fr 0.5fr;
    }
    &:has(.two:hover) {
      grid-template-columns: 0.5fr 2fr 0.5fr 0.5fr 0.5fr;
    }
    &:has(.three:hover) {
      grid-template-columns: 0.5fr 0.5fr 2fr 0.5fr 0.5fr;
    }
    &:has(.four:hover) {
      grid-template-columns: 0.5fr 0.5fr 0.5fr 2fr 0.5fr;
    }
    &:has(.five:hover) {
      grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 2fr;
    }
  }

  @media ${xs} {
    padding: 0;
    overflow-x: scroll;
    margin-bottom: 60px;
    padding: 0 15px;
    scroll-snap-type: x mandatory;
    
    & .gallery-wrapper {
      position: relative;
      gap: 0;
      margin: 20px 0 0;
      width: 100%;
      height: 100%;

      & .item-box {
        width: 310px;
        height: 300px;
        scroll-snap-align: start;
        background-size: 300px 300px;
        background-position: 10px 0;

        &.one {
          width: 315px;
          background-position: 15px 0;
        }

        &.five {
          width: 330px;
          background-position: 10px 0;
        }

        &:before {
          width: 0;
          height: 0;
        }
      }
    }
  }
`;

export const TermsTemplate = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 120px;
  padding: 0 20px;
  width: 100%;
`;

export const OrderSelectOneInfo = styled.div`
  margin-top: 50px;
  
  & .title-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;

    & h3 {
      ${theme.typography.title_20_SB}
    }
    & button {
      padding: 8px 10px;
      background-color: ${theme.colors.main_black};
      color: ${theme.colors.main_white};
      ${theme.typography.text_14_M}
    }
  }

  & .content-box {
    padding: 10px 30px;
    border-color: ${theme.colors.main_black};
    border-style: solid;
    border-width: 2px 0 2px;

    & .flex-box {
      display: flex;
      line-height: 32px;
      
      & .label {
        width: 80px;
        ${theme.typography.text_14_M}
      }
      & .content {
        ${theme.typography.text_14_R}
        ${pretendard}
      }
      & .text-area {
        ${theme.typography.text_14_R}
        white-space: pre-wrap;
        line-height: 18px;
      }
    }

    &.between {

      & .flex-box {
        justify-content: space-between;
        
        & .content {
          ${theme.typography.text_14_M}

          &.final-price {
            color: ${theme.colors.point_red};
            ${theme.typography.title_20_SB}
            font-weight: 700;

            & span {
              color: #000;
              ${theme.typography.text_14_M}
            }
          }
        }
      }
    }

    & .horizontal {
      margin: 14px 0;
      width: 100%;
      height: 1px;
      background-color: ${theme.colors.dark_grey};
    }
  }

  @media ${xs} {
    
    & .content-box {

      & .flex-box {

        & .content {
          width: 200px;
        }
      }
    }
  }
`;

export const ToastCartComp = styled.div`
  position: fixed;
  padding: 0 25px;
  left: 50%;
  bottom: 0;
  background-color: ${theme.colors.main_white};
  box-shadow: 0 2px 4px rgba(0,0,0,.5);
  transform: translate(-50%, 40px);
  animation-fill-mode: forwards;
  animation: cartToast 4s ease-in-out;
  z-index: 500;

  @keyframes cartToast {
    0% {
      transform: translate(-50%, 40px);
      opacity: 1;
    }
    10% {
      transform: translate(-50%, -70px);
      opacity: 1;
    }
    90% {
      transform: translate(-50%, -70px);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, 40px);
      opacity: 0;
    }
  }

  & .toast-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    height: 40px;
    gap: 0 30px;
    
    & span {
      ${theme.typography.text_14_R}
    }

    & button {
      color: ${theme.colors.point_red};
      ${theme.typography.caption_12_R}
    }
  }

  @media ${xs} {
    
  }
`;

export const CancelOrderInfo = styled.div`
  margin-top: 40px;

  & .cancel-order-title {
    padding-bottom: 10px;
    border-bottom: 2px solid ${theme.colors.main_black};
    ${theme.typography.title_20_SB}
  }

  & .cancel-reason-section {
    padding: 20px 30px;

    & .select-box {
      position: relative;
      height: 50px;
      ${theme.typography.text_14_R}
      cursor: pointer;

      & .selected-reason {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        height: 50px;
        line-height: 50px;
        border: 1px solid ${theme.colors.light_grey};

        &.none-select {
          color: ${theme.colors.dark_grey};
        }

        & img {
          width: 30px;
          height: 30px;
        }
      }
    }

    @media ${xs} {
      padding: 20px 0;
    }
  }

  & .other-reason-box {
    padding: 0 30px;
    
    & textarea {
      width: 100%;
      padding: 10px;
      height: 100px;
      border: 1px solid ${theme.colors.light_grey};
      font-size: 14px;
      outline: none;
      resize: none;
    }

    @media ${xs} {
      padding: 0;
    }
  }

  & .horizontal-divide {
    margin: 50px 0;
    width: 100%;
    height: 10px;
    background-color: ${theme.colors.white_light_grey};
  }

  & .refund-announce-box {
    padding: 30px;
    border: 1px solid ${theme.colors.light_grey};

    & h3 {
      line-height: 20px;
      ${theme.typography.text_14_M}
    }
    & p {
      line-height: 16px;
      color: ${theme.colors.dark_grey};
      ${theme.typography.caption_12_R}

      &.indentation {
        padding-left: 8px;
      }
    }
  }

  & .button-box {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 100px;
  }
`;

export const BasicSelectListItem = styled.div`
  position: relative;
  padding-left: 10px;
  height: 50px;
  line-height: 50px;
  background-color: ${theme.colors.main_white};
  color: ${theme.colors.main_black};
  border-color: ${theme.colors.light_grey};
  border-style: solid;
  border-width: 0 1px 1px;
`;

export const BasicButton = styled.button<{$width: number}>`
  width: ${props => props.$width}px;
  height: 50px;
  background-color: ${theme.colors.main_white};
  color: ${theme.colors.main_black};
  border: 1px solid ${theme.colors.main_black};

  &.black {
    background-color: ${theme.colors.main_black};
    color: ${theme.colors.main_white};
  }
`;

export const PaymentInfo = styled.div`
  & .payment-wrapper {

    & .payment-rows {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: end;
      ${theme.typography.text_14_R}

      & .label {

      }
      & .content {

        &.final-price {
          color: ${theme.colors.point_red};
          ${theme.typography.title_20_SB}
        }
      }
    }

    & .divide-line {
      margin-top: 20px;
      width: 100%;
      height: 1px;
      background-color: ${theme.colors.main_black};
    }

    & .payment-notice {
      margin-top: 20px;
      color: ${theme.colors.point_red};
      ${theme.typography.caption_12_R}
      text-align: end;
    }
  }
`;

export const EmptyBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  color: ${theme.colors.dark_grey};
  text-align: center;
  ${theme.typography.text_14_R}
`;

export const OfferSelectOneContainer = styled.div`
  
`;

export const PDFContainer = styled.div`
  position: relative;
  margin-bottom: 100px;

  & .title-box {
    margin-bottom: 60px;

    & h1 {
      ${theme.typography.large_30_B}
      text-align: center;
    }

    & button {
      position: absolute;
      right: 0;
      top: 0;
      padding: 8px 10px;
      background-color: ${theme.colors.main_black};
      color: ${theme.colors.main_white};
      ${theme.typography.text_14_R}
    }
  }
`;

export const PDFComponent = styled.div`
  position: relative;
  padding: 40px 30px 0;
  width: 210mm;
  aspect-ratio: 1/1.414;
  z-index: 1;
  background-color: ${theme.colors.main_white};
  border-color: ${theme.colors.light_grey};
  border-style: solid;

  & .pdf-headline {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 30px;

    & .title {
      ${theme.typography.large_30_B}
    }

    & span {
      ${theme.typography.caption_12_R}
    }
  }

  & .company-info-box {
    display: flex;
    margin: 35px 0 60px;
    padding-top: 15px;
    border-top: 1px solid #000;

    & .channel {
      width: 50%;

      & .label {
        font-size: 12px;
      }

      & .payment-place-label {
        ${theme.typography.text_14_M}
      }
      & .payment-place-content {
        margin-left: 10px;
        ${theme.typography.text_14_R}
      }

      &.b2b {

        & .account-name {
          margin-top: 10px;
          ${theme.typography.title_24_B}
        }
      }

      &.ambience {
        & .name {
          margin-top: 10px;
          ${theme.typography.text_14_M}
        }

        & .content {
          margin-top: 20px;
          ${theme.typography.caption_12_R}
          
          & p {
            line-height: 15px;
          }
        }
      }

    }
    &.price-box {
      margin: 0;
      padding: 0;
      border-style: solid;
      border-color: ${theme.colors.main_black};
      border-top-width: 2px;
      border-bottom-width: 2px;

      & .price-general {
        display: flex;
        justify-content: space-between;
        line-height: 40px;
        ${theme.typography.text_14_M}

        & .label {
          ${theme.typography.text_14_M}

          & .description {
            color: ${theme.colors.dark_grey};
            ${theme.typography.caption_12_R}
          }
        }

        &.final {
          padding-top: 4px;

          & .label {
            ${theme.typography.title_20_SB}
          }
        }
      }

      & .middle-line {
        height: 1px;
        background-color: ${theme.colors.light_mid_grey};
      }
    }
  }

  & .price-info {
    padding-bottom: 30px;
  }

  @media print {
    width: 210mm;
    height: 297mm;
  }
`;


export const AvailablePromotionListContainer = styled.div`
  margin-top: 60px;

  & .promotion-wrapper {
    border-bottom: 1px solid ${theme.colors.dark_grey};

    & .promotion-title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 2px solid ${theme.colors.main_black};
      
      & span {
        ${theme.typography.title_24_B}
      }
      & button {
        ${theme.typography.text_14_R}
        color: ${theme.colors.dark_grey};
      }
    }

    & .promotion-list-box {

      & .promotion-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;

        & .promotion-name {
            margin-left: 10px;

          & span {
            ${theme.typography.title_20_SB}
          }
        }

        & .promotion-content {
          display: flex;
          justify-content: center;

          & .label {
            margin-right: 10px;
            color: ${theme.colors.dark_grey};
            ${theme.typography.text_14_R}
          }
          & .content {
            ${theme.typography.text_16_M}
          }

          &.rate {
            justify-content: start;
            min-width: 90px;
          }
        }

        @media ${sm} {
          display: block;

          & .promotion-name {
            margin-bottom: 20px;

            & span {
              ${theme.typography.text_16_M}
            }
          }

          & .promotion-content {
            display: block;
            margin-top: 15px;
            padding-left: 10px;
            justify-content: start;

            & .label {
              ${theme.typography.caption_12_R}
            }
            & .content {
              margin-top: 6px;
              ${theme.typography.text_14_M}
            }
          }
        }
      }
    }
  }
`;