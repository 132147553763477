
import React, { FC, useState, useEffect } from 'react';
import { getProductCartAlc, getSessionData } from '../../Axios';
import { applyDiscountsForList, serializationOptionArray } from '../../Utils';
import OrderShipping from '../../components/Order/OrderShipping';
import OrderDetails from '../../components/Order/OrderDetails';
import { OrderContainer } from '../../styles/solda/StyledComponentSol';
import { useDiscountRateContext } from '../../context/DiscountRateContext';

interface IOrderProps {
    selectedProductsWithQuantities?: any;
}

const Order: FC<IOrderProps> = ({selectedProductsWithQuantities}) => {
	const { discountRateInfo, discountRateProductTypeInfo,  discountRateColorInfo} = useDiscountRateContext();
	const [products, setProducts] = useState<any[]>([]);
	const [customerDivision, setCustomerDivision] = useState(0); // 0: 확인전, 1: 일반고객, 2: 일단 나머지 FIXME: 나중에 더 구분해야 하면 변경
	const [respectiveShippingMemo, setRespectiveShippingMemo] = useState<boolean>(false);

	useEffect(() => {
		if (selectedProductsWithQuantities.length === 0) return;
		getProductCartAlc(JSON.stringify(selectedProductsWithQuantities)).then(result => {
			try {
				const obj: any = {};
				selectedProductsWithQuantities.forEach((tmp: any) => {
          obj[tmp.color_ID] = (obj[tmp.color_ID] || 0) +1;
        });
        let list: any = [];
        selectedProductsWithQuantities.forEach((tmp: any) => {
          if (obj[tmp.color_ID] > 0) {
            list.push(result.filter((result_item: any) => result_item.color_ID === tmp.color_ID)[0]);
						obj[tmp.color_ID] = obj[tmp.color_ID] - 1;
          }
        });
				let apply_option_price = serializationOptionArray(selectedProductsWithQuantities, list);
				const productsWithQuantity = apply_option_price.map((product: any) => ({
					...product,
					quantity: selectedProductsWithQuantities.find((item: { color_ID: number, color_option_ID: number }) => (
            item.color_ID === product.color_ID && item.color_option_ID === product.color_option_ID
          ))?.quantity || 1,
					color_option_ID: product.color_option_ID || 0,
				}));
				const updatedProducts = applyDiscountsForList(
					productsWithQuantity,
					discountRateInfo,
					discountRateProductTypeInfo,
					discountRateColorInfo,
				);
				setProducts(updatedProducts);
			} catch (err) {
				console.error("Fetch 실패", err);
			} finally {
			}
		})
	}, [discountRateInfo, discountRateProductTypeInfo, discountRateColorInfo, selectedProductsWithQuantities]);

	// 일반 고객 / 기업 고객 구분
	useEffect(() => {
		getSessionData().then(result => {
			if (result.alc_status === 0) {
				setCustomerDivision(1);
			} else {
				setCustomerDivision(2);
			}
		});
	}, []);

	const handleRespectiveShippingMemo = () => {
		setRespectiveShippingMemo(!respectiveShippingMemo);
	};

	return (
		<OrderContainer>
			<div className='title'>주문/결제</div>
			<div className="shipping-and-details">
				<OrderShipping
					customerDivision={customerDivision}
					respectiveShippingMemo={respectiveShippingMemo}
					handleRespectiveShippingMemo={handleRespectiveShippingMemo}
				/>
				<OrderDetails
					products={products}
					respectiveShippingMemo={respectiveShippingMemo}
				/>
			</div>
	  </OrderContainer>
	);
};

export default Order;
