import React, { createContext, FC, useContext, useState } from "react";

export interface ISearchTextInfo {
  search_text: string;
}

interface ISearchTextContext {
  searchTextInfo: ISearchTextInfo;
  setSearchTextInfo: React.Dispatch<React.SetStateAction<ISearchTextInfo>>;
};

export const SearchTextContext = createContext<ISearchTextContext | undefined>(undefined);

export const useSearchTextContext = () => {
  const context = useContext(SearchTextContext);
  if (!context) {
    throw new Error('useSearchTextContext must be used within a SearchTextProvider');
  }
  return context;
};

export const SearchTextProvider: FC<{children?: React.ReactNode}> = ({children}) => {
  const [searchTextInfo, setSearchTextInfo] = useState<ISearchTextInfo>({
    search_text: '',
  });

  return (
    <SearchTextContext.Provider value={{searchTextInfo, setSearchTextInfo}}>
      {children}
    </SearchTextContext.Provider>
  );
};