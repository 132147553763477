import React, { FC, useEffect, useRef, useState } from 'react';
import { getAlcRollingBanner } from '../../Axios';
// import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import * as SOL from "../../styles/solda/StyledComponentSol";
import SwiperLeftArrow from "../../assets/icons/swiper_left_arrow.svg";

const RollingSwiper: FC = () => {
	const sliderRef: React.RefObject<HTMLDivElement> = useRef(null);
	const [alcRollingBanner, setAlcRollingBanner] = useState<any[]>([]);
	const [currentIndex, setCurrentIndex] = useState<number>(1);
	const [isHover, setIsHover] = useState(false);
	const [isButtonAction, setIsButtonAction] = useState(false);
	const [indicators, setIndicators] = useState<[]>([]);
	const [viewportHeight] = useState<number>(window.innerHeight); // 나중에 다른 곳에서도 많이 필요하면 APP에서 props로 내리는 방법도 있음
	const [showBanner, setShowBanner] = useState(false);
	const [isMouseDown, setIsMouseDown] = useState(false);
	const [mouseDownClientX, setMouseDownClientX] = useState<number>(0);
  const [mouseDownClientY, setMouseDownClientY] = useState<number>(0);
  const [mouseUpClientX, setMouseUpClientX] = useState<number>(0);
  const [mouseUpClientY, setMouseUpClientY] = useState<number>(0);
  const [touchedX, setTouchedX] = useState<number>(0);
  const [touchedY, setTouchedY] = useState<number>(0);

	useEffect(() => {
		window.addEventListener("mouseup", getMouseUpClientX);

		return () => window.document.removeEventListener("mouseup", getMouseUpClientX);
	}, []);

	useEffect(() => {
    if (!isMouseDown) return;
    const dragSpaceX = Math.abs(mouseDownClientX - mouseUpClientX);
    const dragSpaceY = Math.abs(mouseDownClientY - mouseUpClientY);
    const vector = dragSpaceX / dragSpaceY;
    if(mouseDownClientX !== 0 && dragSpaceX > 100 && vector > 2 ) {
      if(mouseDownClientX > mouseUpClientX) {
        handleSwipe(1);
      } else {
        handleSwipe(-1);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouseUpClientX]);

	const getMouseUpClientX = (e: any) => {
    setMouseUpClientX(e.clientX);
    setMouseUpClientY(e.clientY);
    setTimeout(() => {
      setIsMouseDown(false);
    }, 200);
    window.document.body.style.cursor = "initial";
  };

	useEffect(() => {
		getAlcRollingBanner().then((result: any) => {
			if (result.length > 0) {
				setIndicators(result);
				let list: any = [...result];
				list.unshift(result[result.length-1]);
				list.push(result[0]);
				setAlcRollingBanner(list);
				setShowBanner(true);
			}
		});
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			if (currentIndex < alcRollingBanner.length-1) {
				setCurrentIndex(prev => prev+1);
				if (!sliderRef.current) return;
				sliderRef.current.style.transitionDuration = "1s";
			}
		}, 5000);
		if (isHover) clearInterval(interval);
		return () => clearInterval(interval);
	}, [alcRollingBanner.length, currentIndex, isHover]);

	useEffect(() => {
		if (currentIndex === alcRollingBanner.length-1) {
			setTimeout(() => {
				if (!sliderRef.current) return;
				sliderRef.current.style.transitionDuration = "0s";
				setCurrentIndex(1);
			}, 1000);
		} else if (currentIndex === 0) {
			setTimeout(() => {
				if (!sliderRef.current) return;
				sliderRef.current.style.transitionDuration = "0s";
				setCurrentIndex(alcRollingBanner.length-2);
			}, 1000);
		}
	}, [currentIndex, alcRollingBanner]);

	const handleSwipe = (index: number) => {
		if (isButtonAction) return;
		setCurrentIndex(prev => prev + index);
		setIsButtonAction(true);
		if (!sliderRef.current) return;
		sliderRef.current.style.transitionDuration = "1s";
		setTimeout(() => {
			setIsButtonAction(false);
		}, 1000);
	};

	return (
		<>
			{showBanner && (
				<SOL.SwiperContainer
					$currentIndex={currentIndex}
					$viewportHeight={viewportHeight}
					onMouseOver={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
					onMouseDown={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
						setIsMouseDown(true);
						setMouseDownClientX(e.clientX);
						setMouseDownClientY(e.clientY);
					}}
					onTouchStart={(e: React.TouchEvent) => {
						setTouchedX(e.changedTouches[0].pageX);
						setTouchedY(e.changedTouches[0].pageY);
					}}
					onTouchEnd={(e: React.TouchEvent) => {
						const distanceX = touchedX - e.changedTouches[0].pageX;
						const distanceY = touchedY - e.changedTouches[0].pageY;
						const vector = Math.abs(distanceX / distanceY);
						if (distanceX > 30 && vector > 2) {
							handleSwipe(1);
						} else if (distanceX < -30 && vector > 2) {
							handleSwipe(-1);
						}
					}}
				>
					<div
						ref={sliderRef}
						className='swiper-wrapper'
					>
						{alcRollingBanner.map((tmp: any, idx: number) => (
							<SOL.SwiperItem
								key={idx}
								style={{
									backgroundImage: isMobile ? `url(${tmp.alc_rolling_banner_mobile})` : `url(${tmp.alc_rolling_banner})`
								}}
								onClick={() => {
									if (alcRollingBanner[currentIndex].alc_rolling_banner_a_link === '') return;
									window.open(alcRollingBanner[currentIndex].alc_rolling_banner_a_link, '_blank');
								}}
							>
								
							</SOL.SwiperItem>
						))}
					</div>
					<div className='control-box'>
						<button
							className='control back'
							onClick={() => handleSwipe(-1)}
						>
							<img src={SwiperLeftArrow} alt='arrow' />
						</button>
						<button
							className='control front'
							onClick={() => handleSwipe(1)}
						>
							<img src={SwiperLeftArrow} alt='arrow' className='rotate-180' />
						</button>
					</div>
					<div className='indicator'>
						{indicators.map((tmp: any, idx) =>
							<button
								key={idx}
								className={`
									index-button
									${tmp.alc_rolling_banner_ID === alcRollingBanner[currentIndex].alc_rolling_banner_ID && "active"}
								`}
								onClick={() => {
									if (isButtonAction) return;
									setIsButtonAction(true);
									setCurrentIndex(idx+1);
									if (!sliderRef.current) return;
									sliderRef.current.style.transitionDuration = "1s";
									setTimeout(() => {
										setIsButtonAction(false);
									}, 750);
								}}
							/>
						)}
					</div>
				</SOL.SwiperContainer>
			)}
		</>
	);
};

export default RollingSwiper;
