export interface ICustomerSummary {
  customer_ID: number;
  customer_name: string;
  account_name: string;
  alc_complex_ID: number;
  alc_complex_name: string;
  alc_complex_logo: string;
  alc_complex_registe: string;
  alc_complex_expiration_period: string;
  alc_complex_modified: string;
}

export interface IOrderSummary {
  order_total: number;
  pay_waiting: number;
  pay_complete: number;
  delivery_preparing: number;
  delivery: number;
  delivery_complete: number;
  order_cancel: number;
  order_return: number;
  order_exchange: number;
};

export interface ICustomerData {
  account_detail_address: string;
  account_name: string;
  account_phone_number1: string;
  account_road_address: string;
  account_zip_code: string;
  customer_ID: number;
  customer_big_area: string;
  customer_detail_address: string;
  customer_name: string;
  customer_phone_number: string;
  customer_road_address: string;
  customer_small_area: string;
  customer_zip_code: string;
};

export interface IColorOptionArray {
  id: number;
  color_option_node_group: string;
  color_option_node_hover_img: string;
  color_option_node_hover_img_url: string;
  color_option_node_img: string;
  color_option_node_img_url: string;
  color_option_node_list: string;
  color_option_node_name: string;
  color_option_node_po_cost: string;
  color_option_node_product_cost: string;
  color_option_node_product_normal_price: string;
  color_option_node_status: string;
  color_option_node_unique_number: string;
  children: IColorOptionArray[];
}
export interface IAlcBannerData {
  alc_banner_ID: number;
  alc_banner_group_ID: number;
  alc_banner_brand_ID: number;
  alc_banner_status: number;
  alc_banner_usage: number;
  alc_banner_level: number;
  alc_banner_link_url: string;
  alc_banner_video_url: string;
  alc_banner_title: string;
  alc_banner_description_1: string;
  alc_banner_description_2: string;
  alc_banner_description_3: string;
  alc_banner_description_4: string;
  alc_banner_img: string;
  alc_banner_img_mobile: string;
  alc_banner_img_hover: string;
  alc_banner_memo: string;
  alc_banner_registe: string;
  alc_banner_start_date: string;
  alc_banner_end_date: string;
  alc_banner_product_list: IAlcBannerProductList[];
};

export const AlcBannerDataDefaultValue = {
  alc_banner_ID: 0,
  alc_banner_brand_ID: 0,
  alc_banner_group_ID: 0,
  alc_banner_status: 0,
  alc_banner_usage: 0,
  alc_banner_level: 0,
  alc_banner_link_url: "",
  alc_banner_video_url: "",
  alc_banner_title: "",
  alc_banner_description_1: "",
  alc_banner_description_2: "",
  alc_banner_description_3: "",
  alc_banner_description_4: "",
  alc_banner_img: "",
  alc_banner_img_mobile: "",
  alc_banner_img_hover: "",
  alc_banner_memo: "",
  alc_banner_registe: "",
  alc_banner_start_date: "",
  alc_banner_end_date: "",
  alc_banner_product_list: [],
};

export interface IAlcBannerProductList {
  alc_banner_product_color_ID: number;
  alc_banner_product_link_url: string;
  alc_banner_product_order: number;
  alc_banner_product_brand_name: string;
  alc_banner_product_brand_ID: number;
  alc_banner_product_name: string;
  alc_banner_product_type_ID : number;
  alc_banner_product_portable_status : number;
  alc_banner_product_original_price: number;
  alc_banner_product_final_price: number;
  alc_banner_product_color: number;
  alc_banner_product_img: string;
  alc_banner_product_img_mobile: string;
  alc_banner_product_img_hover: string;
};