import React, { createContext, FC, useContext, useState } from "react";

export interface IShowInventoryInfo {
  show_inventory: boolean;
}

interface IShowInventoryContext {
  showInventoryInfo: IShowInventoryInfo;
  setShowInventoryInfo: React.Dispatch<React.SetStateAction<IShowInventoryInfo>>;
};

export const ShowInventoryContext = createContext<IShowInventoryContext | undefined>(undefined);

export const useShowInventoryContext = () => {
  const context = useContext(ShowInventoryContext);
  if (!context) {
    throw new Error('useShowInventoryContext must be used within a ShowInventoryProvider');
  }
  return context;
};

export const ShowInventoryProvider: FC<{children?: React.ReactNode}> = ({children}) => {
  const [showInventoryInfo, setShowInventoryInfo] = useState<IShowInventoryInfo>({
    show_inventory: false,
  });

  return (
    <ShowInventoryContext.Provider value={{showInventoryInfo, setShowInventoryInfo}}>
      {children}
    </ShowInventoryContext.Provider>
  );
};