import React, { FC } from "react";
import ABlack from "../../assets/image_folder/common_images/ablack_white_main.png";
import { useNavigate } from "react-router-dom";
import * as HAN from "../../styles/han/StyledComponentHan";

const NotFound: FC = () => {

  const navigate = useNavigate();
  return (
    // <HAN.Test>
    <HAN.ErrorPage>
      <div className="err-box">
        <img src={ABlack} alt="a_black_text" />
        <div className="mid-box">
            <p>페이지를 찾을 수 없습니다.</p>
            <p>페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.<br></br>
            입력한 주소가 정확한지 다시 한번 확인해 주세요.</p>
        </div>
        <div className="back-btn">
          <button onClick={()=>{navigate(-1);}}>이전으로 돌아가기</button>
          <button onClick={()=>{navigate("/home");}}>메인으로 돌아가기</button>
        </div> 
      </div>
    </HAN.ErrorPage>
  )
};

export default NotFound;