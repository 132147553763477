
import React, { useLayoutEffect, ReactNode, FC } from 'react';
import PropTypes from 'prop-types';

interface IPageWrapperProps {
    children?: ReactNode;
	title?: string;
}
const PageWrapper: FC<IPageWrapperProps> = ({children, title}) => {

	useLayoutEffect(() => {
		// @ts-ignore
		document.getElementsByTagName('TITLE')[0].text = title
	});

	return (
		<div>
			{children}
		</div>
	);
};
PageWrapper.propTypes = {
    children: PropTypes.node,
	title: PropTypes.string.isRequired,
};
PageWrapper.defaultProps = {
    children: null,
	title: undefined,
};

export default PageWrapper;
