import React, { FC, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { checkCustomerPassword } from "../../Axios";
import ModalClose from "../Modal/ModalClose";

interface CheckPasswordProps {
  customerID: number;
  handleChangeTab: (tab_name: string) => void;
  changePasswordLength: (password_length: number) => void;
}

const CheckPassword: FC<CheckPasswordProps> = ({customerID, handleChangeTab, changePasswordLength}) => {
  const [password, setPassword] = useState<string>('');
  const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState({text: ''});

  const handleCheckPassword = () => {
    checkCustomerPassword(customerID, password).then(result => {
      if (result === 1) {
        handleChangeTab('update_profile');
        changePasswordLength(password.length);
      } else if (result === 2) {
        setIsModalCloseOpen(true);
        setModalContent({text: '비밀번호가 일치하지 않습니다.'});
      } else {
        setIsModalCloseOpen(true);
        setModalContent({
          text: '알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.'
        });
      }
    });
  };

  return (
    <>
      <ModalClose
        isOpen={isModalCloseOpen} 
        onClose={() => setIsModalCloseOpen(false)}
        text={modalContent.text}
      />
      <SOL.MyPageComponentContainer>
        <div className="text-box">
          <h3 className="title">회원정보 수정</h3>
          <p className="notice">소중한 개인정보를 보호하기 위해 비밀번호를 다시 한번 확인합니다.</p>
          <p className="notice">비밀번호 입력 시 타인에게 노출되지 않도록 주의해 주시기 바랍니다.</p>
        </div>
        <div className="password-box">
          <input
            type="password"
            className="password"
            placeholder="비밀번호를 입력해 주세요."
            maxLength={20}
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
          />
          <button
            onClick={handleCheckPassword}
          >
            확인
          </button>
        </div>
      </SOL.MyPageComponentContainer>
    </>
  )
};

export default CheckPassword;