import React, { useEffect, useState } from 'react'
import { baseUrl, getProductDataByColorArray } from '../../Axios';
import * as DABIN from "../../styles/dabin/StyledComponentDabin";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { IAlcBannerData, IAlcBannerProductList } from "../../modules/model";
import { applyDiscountsForBannerProduct, mappingBannerProductData } from '../../Utils';
import { useDiscountRateContext } from '../../context/DiscountRateContext';

  interface IpromotionProps {
    brandPromotionData: IAlcBannerData;
  };

  const BrandPromotion = ({ brandPromotionData }: IpromotionProps) => {
    const navigate = useNavigate();

    const mainProductClcik = (index: number) => {
      window.location.href =`${brandPromotionData.alc_banner_product_list[index].alc_banner_product_link_url}`;
    };
    const serviceBannerhandClick = () => {
      window.scrollTo(0, 0);
      navigate(`/service`)
    };
    const isMobile = useMediaQuery({query: "(max-width: 769px)"});

    const [brandProducts, setBrandProducts] = useState<IAlcBannerProductList[]>([]);

    const { discountRateInfo, discountRateProductTypeInfo, discountRateColorInfo } = useDiscountRateContext();

    useEffect(() => {
      const color_array: number[] = [];

      if (brandPromotionData.alc_banner_product_list.length > 0) {
        const brand_banner_products = brandPromotionData.alc_banner_product_list;
        brand_banner_products.forEach(tmp => {
          color_array.push(tmp.alc_banner_product_color_ID);
        });
        getProductDataByColorArray(
          color_array,
        ).then(result => {
          const mapped_brand_banner_products = mappingBannerProductData(
            brand_banner_products,
            result,
          );
          const brand_banner_product_list_apply_rate = applyDiscountsForBannerProduct(
            mapped_brand_banner_products,
            discountRateInfo,
            discountRateProductTypeInfo,
            discountRateColorInfo,
          );
          setBrandProducts(brand_banner_product_list_apply_rate)
        })
      }
    },[brandPromotionData, discountRateColorInfo, discountRateInfo, discountRateProductTypeInfo]);

  return (
    <>
      <DABIN.BrandPromotionContainer>
        {brandPromotionData.alc_banner_ID > 0 && (
          <>
            <div className="BrandPromotion_text-box">
              <h2 className='BrandPromotion_title'>{brandPromotionData.alc_banner_title}</h2>
              <div className="BrandPromotion_description">
                <p>{brandPromotionData.alc_banner_description_1}</p>
                <p>{brandPromotionData.alc_banner_description_2}</p>
                <p>{brandPromotionData.alc_banner_description_3}</p>
              </div>
            </div>
            <div className="BrandPromotion_products">
              {brandProducts.length > 0 && (
              brandProducts.map((product: any, index: number) => (
                <div 
                  key={index}
                  className={`BrandPromotion_product`} 
                  onClick={() => mainProductClcik(index)}
                >
                  <img 
                    className={`BrandPromotion_product_img`}
                    src={`${baseUrl}${product.alc_banner_product_img}`} 
                    alt="ProductImage"
                  /> 
                  <div className="Promotion_product_info SubPromotion_product_info">
                    <p className='Promotion_product-brand'>{product.alc_banner_product_brand_name}</p>
                    <p className='Promotion_product-name'>{product.alc_banner_product_name}</p>
                    <p className='Promotion_product-color'>{product.alc_banner_product_color}</p>
                    <p className='Promotion_product-Price original-Price'>
                      {product.alc_banner_product_original_price !== product.alc_banner_product_final_price 
                        ? product.alc_banner_product_original_price.toLocaleString() + "원" 
                        : null}
                    </p>
                    <p className='Promotion_product-Price'>
                      {product.alc_banner_product_final_price.toLocaleString()}원<FaAngleRight />
                    </p>
                    <span className='Promotion_product-Price rate-vat'>
                      {Number(product.apply_discount_rate_vat) === 0 ? "*부가세 별도" : "*부가세 포함"}
                    </span>
                  </div>
                </div>
              )))}
            </div>
            <div className="BrandPromotion_ablack-Banner">
              <img 
                src={isMobile ? `${baseUrl}files/image_folder/alc_promotion_images/common/f26f50ec97d0f67b27778a40ff5eddcb5ce1baae59b921d56c7ab57c84ad4fcffa032bd829e450c7c7598ba8b897e2eb1293f687e53fa8dd6f251c6f2ff2414c.jpg` : `${baseUrl}files/image_folder/alc_promotion_images/common/de3eab7ea9789c51673a5cdc753e01249f93ef00db5be30d6bbcc87412bc34ec84d5f52a5246bc7f3bd6af42e2b084198dc44a2557a8e49425ed6da6a4a207af.jpg`}
                alt="에이블랙 서비스 안내"
                onClick={serviceBannerhandClick}
              />
            </div>
          </>
        )}
      </DABIN.BrandPromotionContainer>
    </>
  )
}

export default BrandPromotion