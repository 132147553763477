import React, { FC } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { useNavigate } from "react-router-dom";
import LogoABlack from "../../assets/image_folder/common_images/ablack_white_1.png";
import IconArrowBack from "../../assets/icons/icon_arrow_back_white.svg";

interface ISimpleHeader {
  code: string;
}

const SimpleHeader: FC<ISimpleHeader> = ({code}) => {
  const navigate = useNavigate();

  return (
    <>
      <SOL.SimpleHeaderContainer>
        <div className="container-wrapper">
          <button
            onClick={() => {
              if (code !== '') {
                navigate(`/?code=${code}`);
              } else {
                navigate('/');
              }
            }}
          >
            <img className="back" src={IconArrowBack} alt="arrow_back" />
          </button>
          <img className="logo" src={LogoABlack} alt="ablack_logo" />
        </div>
      </SOL.SimpleHeaderContainer>
    </>
  )
};

export default SimpleHeader;