import React, { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDiscountRateContext } from "../../context/DiscountRateContext";
import { getRecentProductData, getSessionData } from "../../Axios";
import { applyDiscountsForList } from "../../Utils";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { useNavigate } from "react-router-dom";
import IconArrow from "../../assets/icons/icon_only_arrow.svg";

interface Product {
	brand_ID: number;
	color_ID: number;
	color_b2c_image: string;
	brand_eng_name: string;
	product_kor_name: string;
	color_name: string;
	color_b2c_sell_price_ogirinal: number;
	color_b2c_sell_price: number;
	color_b2c_detail_image: string;
	quantity: number;
	apply_discount_rate_1: string;
	apply_discount_rate_vat: string;
  inventory_status: number;
  inventory_warehouse_date: string;
  inventory_warehouse_date_status: string;
}

const MyRecentProduct: FC = () => {
  const navigate = useNavigate();
  const { discountRateInfo, discountRateProductTypeInfo, discountRateColorInfo } = useDiscountRateContext();
  const [recentProductList, setRecentProductList] = useState<any[]>([]);
  const [customerAlcStatus, setCustomerAlcStatus] = useState<number>(2);
  const isMobile = useMediaQuery({query: "(max-width: 500px)"});

  useEffect(() => {
    getSessionData().then(result => {
      setCustomerAlcStatus(result.alc_status);
    });
  }, []);

  useEffect(() => {
    const alc_recent_product = JSON.parse(localStorage.getItem('alc_recent_product') || '[]');
    if (alc_recent_product.length > 0) {
      getRecentProductData(
        alc_recent_product,
        20,
      ).then(result => {
        try {
          const productsWithQuantity = result.map((product: Product) => ({
            ...product
          }));
          const updatedProducts = applyDiscountsForList(
            productsWithQuantity,
            discountRateInfo,
            discountRateProductTypeInfo,
            discountRateColorInfo,
          );
          setRecentProductList(updatedProducts);
        } catch (err) {
          console.error("Fetch 실패", err);
        } finally {

        }
      });
    }
  }, [discountRateInfo, discountRateProductTypeInfo, discountRateColorInfo]);

  return (
    <div className="md:px-5 sm:px-5 xs:px-[15px]">
      <SOL.RecentProductContainer $rows={isMobile ? 2 : 4}>
        {recentProductList.length > 0 && (
          <>
            <div className="recent-product-main-head">
              <h3>최근 본 상품</h3>
            </div>
            <div className="recent-product-flex-box">
              {recentProductList.map((tmp: any, idx) => 
                <div
                  key={idx}
                  className="recent-product-item"
                  onClick={() => navigate(`/product_detail?color_ID=${tmp.color_ID}`)}
                >
                  <div className="product-image">
                    <img src={tmp.color_b2c_image} alt="product" />
                    {customerAlcStatus === 1 && (tmp.inventory_status === 3 || tmp.inventory_status === 4) && (
                      <span className="inventory">
                        {tmp.inventory_warehouse_date_status}
                      </span>
                    )}
                  </div>
                  <div className="product-text">
                    <p className="brand">{tmp.brand_eng_name}</p>
                    <p className="product">{tmp.product_kor_name}</p>
                    <p className="color">{tmp.color_name}</p>
                  </div>
                  <div className="product-price">
                    {tmp.apply_discount_rate_1 ? (
                      <p className="original-price">{tmp.color_b2c_sell_price_ogirinal.toLocaleString()}원</p>
                    ) : (
                      <p className="original-price"></p>
                    )}
                    <span className="sale-price">{tmp.color_b2c_sell_price.toLocaleString()}원</span>
                    {Number(tmp.apply_discount_rate_1) > 0 ? (
                      <>
                        <span className="discount-rate">{tmp.apply_discount_rate_1}%</span>
                        {parseInt(tmp.apply_discount_rate_2) > 0 ? (
                          <>
                            <div className="additional-discount-box">
                              <div className="arrow-icon-img">
                                <img src={IconArrow} alt="arrow_icon" />
                              </div>
                              <span className="product-discount additional-discount">
                                추가 {Number(tmp.apply_discount_rate_2)}% 할인
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="additional-discount-box">
                            <div className="arrow-icon-img" />
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="additional-discount-box">
                        <div className="arrow-icon-img" />
                      </div>
                    )}
                  </div>
                  <div className="discount-rate-vat">
                    {Number(tmp.apply_discount_rate_vat) === 0 ?"*부가세 별도" : "*부가세 포함"}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </SOL.RecentProductContainer>
    </div>
  )
};

export default MyRecentProduct;