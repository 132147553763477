import React, { FC, useEffect, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import ModalClose from "../../components/Modal/ModalClose";
import { getAlcOrderCancelList } from "../../Axios";
import { getAlcDetailStatusAndDeliveryProgress } from "../../statusUtils";
import { IOrderSummary } from "../../modules/model";
import { extractColorOptionNodeNames } from "../../Utils";

interface Order {
  imp_uid: string;
  alc_order_ID: number;
  alc_order_number: string;
  alc_order_name: string;
  alc_order_phone_number: string;
  alc_order_address_name: string;
  alc_order_zip_code: string;
  alc_order_road_address: string;
  alc_order_detail_address: string;
  alc_order_requests: string;
  alc_order_pay_status: number;
  alc_order_pay_total: number;
  alc_order_refund_total: number;
  alc_order_method: string;
  alc_order_pay_date: string;
  alc_order_progress: number;
  details: OrderDetail[]; 
}

interface OrderDetail {
  alc_detail_order_ID: number;
  color_ID_use_alc_order_detail: number;
  alc_detail_order_sell_price: number;
  alc_detail_order_quantity: number;
  alc_detail_order_delivery_progress: number;
  alc_detail_order_refund_status: number;
  alc_detail_order_refund: number;
  alc_detail_order_refund_date: string;
  product_kor_name: string;
  brand_eng_name: string;
  brand_kor_name: string;
  color_name: string;
  color_b2c_image: string;
  alc_detail_cancel_reason: number;
  alc_detail_exchange_status: number;
  alc_detail_return_status: number;
  alc_detail_exchange_status_name: string;
  alc_detail_return_status_name: string;
  alc_detail_reason_direct: string;
  alc_detail_construction_complete_date: string;
  alc_detail_delivery_complete_date: string;
  alc_detail_status: number;
  alc_detail_order_option_array: string;
}

interface OrderCancelListProps {
  customerID: number;
  orderSummary: IOrderSummary;
  handleSelectOneOrderID: (alc_order_ID: number) => void;
}

const OrderCancelList: FC<OrderCancelListProps> = ({customerID, orderSummary, handleSelectOneOrderID}) => {
  const [orders, setOrders] = useState<(Order & OrderDetail)[]>([]);
  const [orderCancelStatus] = useState(0); // TODO: setState 추가하기
  const [sortedOrders, setSortedOrders] = useState<(Order & { details: OrderDetail[] })[]>([]);

  // modal control
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    if (!customerID) return;
    getAlcOrderCancelList(
      customerID,
      orderCancelStatus,
    ).then(result => {
      if (result === 'error_alc') {
        setModalContent('알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.');
        setIsModalOpen(true);
      } else {
        setOrders(result);
      }
    });
  }, [customerID, orderCancelStatus]);

  useEffect(() => {
    const orderGroups = orders.reduce<{ [key: string]: Order & { details: OrderDetail[] } }>((acc, order) => {
        const orderIdStr = order.alc_order_ID.toString();
        if (!acc[orderIdStr]) {
            acc[orderIdStr] = { ...order, details: [] };
        }
        acc[orderIdStr].details.push(order);
        return acc;
    }, {});

    const groupedArray = Object.values(orderGroups).sort(
        (a, b) => b.alc_order_ID - a.alc_order_ID
    ) as (Order & { details: OrderDetail[] })[];
    setSortedOrders(groupedArray);
  }, [orders]);

  const handleTotalPriceByDetails = (details: any[]) => {
    const total_sell_price = details.reduce((accumulator, current_detail) => {
        return accumulator + (current_detail.alc_detail_order_sell_price * current_detail.alc_detail_order_quantity);
    }, 0);
    return total_sell_price;
  };

  return (
    <>
      <ModalClose
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        text={modalContent}
      />
      <SOL.OrderListContainer>
        <h2 
          className="order-list-title"
          style={{border: 0}}
        >
          취소/교환/반품 내역
        </h2>
        <SOL.MyMainContainer>
          <div className="temp-cancel">
            <div className="cancel-category">
              <span className="category-label">취소</span>
              <span className="category-value">
                {
                  orderSummary.order_cancel
                }
              </span>
            </div>
            <div className="cancel-category">
              <span className="category-label">반품</span>
              <span className="category-value">{orderSummary.order_return}</span>
            </div>
            <div className="cancel-category">
              <span className="category-label">교환</span>
              <span className="category-value">{orderSummary.order_exchange}</span>
            </div>
          </div>
        </SOL.MyMainContainer>
        {sortedOrders.map(orderData => (
          <SOL.OrderListItem
            key={orderData.alc_order_ID}
            onClick={() => handleSelectOneOrderID(orderData.alc_order_ID)}
          >
            <div className="order-item-header">
              <button className="order-info">
                <span className="order-label">주문번호</span>
                <div className="order-info-flex-box">
                  <p data-title="주문번호 " className='order-number'>{orderData.alc_order_number}</p>
                  <p data-title="결제일자 " className='pay-date font-pretendard'>{`(${new Date(orderData.alc_order_pay_date).toLocaleDateString()} ${new Date(orderData.alc_order_pay_date).toLocaleTimeString()})`}</p>
                </div>
              </button>
              <div className="total-price">
                <div className="price-label">총 결제금액</div>
                <span className="price-text font-pretendard">
                  {handleTotalPriceByDetails(orderData.details).toLocaleString()}원
                </span>
              </div>
            </div>
            {orderData.details.map((detailData, idx) =>
              <div
                key={idx}
                className="order-detail-box"
              >
                <div className="order-detail-left">
                  <div className='order-detail-image'>
                    <img src={detailData.color_b2c_image} alt={detailData.color_name} />
                  </div>
                  <div className='order-detail-product'>
                    <p className='brand'>{detailData.brand_kor_name}</p>
                    <p className='product'>{detailData.product_kor_name}</p>
                    <p className='color'>{detailData.color_name}</p>
                    <p className='quantity'>{detailData.alc_detail_order_quantity}개</p>
                    {detailData.alc_detail_order_option_array && (
                      <p className="offer-list-option">
                        {extractColorOptionNodeNames(JSON.parse(detailData.alc_detail_order_option_array))}
                      </p>
                    )}
                  </div>
                </div>
                <div className="order-detail-right">
                  <div className='order-detail-price'>
                    <p>{(detailData.alc_detail_order_sell_price * detailData.alc_detail_order_quantity).toLocaleString()}원</p>
                  </div>
                  <div className='order-detail-status'>
                    <div className="deilvery-status">
                      {getAlcDetailStatusAndDeliveryProgress(detailData.alc_detail_status, detailData.alc_detail_order_delivery_progress)}
                    </div>
                    <div className="exchange-return-status">
                      {detailData.alc_detail_order_delivery_progress === 5 && (" (환불 완료)")}
                    </div>
                  </div>
                  <div className='order-detail-refund-button'>
                    {/* <button
                        onClick={(e) => {}}
                    >
                        버튼
                    </button> */}
                  </div>
                </div>
              </div>
            )}
          </SOL.OrderListItem>
        ))}
      </SOL.OrderListContainer>
    </>
  )
};

export default OrderCancelList;