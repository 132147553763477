export const sortProductList = [
  {
    value: "popularity",
    label: "인기순",
  },
  {
    value: "latest",
    label: "최신순",
  },
  {
    value: "priceLow",
    label: "낮은가격순",
  },
  {
    value: "priceHigh",
    label: "높은가격순",
  },
];

export const productCategories = [
  {
    value: "",
    label_eng: "All",
    label_kor: "전체"
  },
  {
    value: "1",
    label_eng: "Pendant",
    label_kor: "펜던트 조명"
  },
  {
    value: "2",
    label_eng: "Table",
    label_kor: "테이블 & 포터블 조명"
  },
  {
    value: "3",
    label_eng: "Floor",
    label_kor: "플로어 조명"
  },
  {
    value: "4",
    label_eng: "Wall",
    label_kor: "월 조명"
  },
  {
    value: "5",
    label_eng: "Bollard",
    label_kor: "볼라드"
  },
  {
    value: "6",
    label_eng: "celling",
    label_kor: "씰링"
  },
  {
    value: "8",
    label_eng: "Furniture",
    label_kor: "가구 & 소품"
  },
];

export const productBrandBanner = [
  {
    value: "3",
    src: ""
  }
];

export const cancelReasonSelectList = [
  {
    value: 0,
    label: "취소 사유를 선택해 주세요.",
  },
  {
    value: 1,
    label: "단순 변심으로 주문 취소",
  },
  {
    value: 2,
    label: "재고 부족으로 인한 주문 취소",
  },
  {
    value: 3,
    label: "취소 후 재주문을 위한 주문 취소",
  },
  {
    value: 9,
    label: "기타 내용 작성",
  },
];

export const exchangeReasonSelectList = [
  {
    value: 0,
    label: "교환 사유를 선택해 주세요.",
  },
  {
    value: 1,
    label: "제품 하자로 인한 교환",
  },
  {
    value: 9,
    label: "기타 내용 작성",
  },
];

export const returnReasonSelectList = [
  {
    value: 0,
    label: "반품 사유를 선택해 주세요.",
  },
  {
    value: 1,
    label: "제품 하자로 인한 반품",
  },
  {
    value: 2,
    label: "단순 변심으로 반품 (7일 이내 제품 미개봉시)",
  },
  {
    value: 9,
    label: "기타 내용 작성",
  },
];

export const MainQuickMenuData = [
  {
    value: 1,
    label_kor: "펜던트",
    label_eng: "pendent",
    url: "/product",
  },
  {
    value: 2,
    label_kor: "테이블",
    label2_kor: "&포터블",
    label_eng: "table",
    url: "/product",
  },
  {
    value: 3,
    label_kor: "플로어",
    label_eng: "floor",
    url: "/product",
  },
  {
    value: 4,
    label_kor: "월",
    label_eng: "wall",
    url: "/product",
  },
  {
    value: 8,
    label_kor: "가구&소품",
    label_eng: "furniture",
    url: "/product",
  },
  {
    value: 6,
    label_kor: "씰링",
    label_eng: "celling",
    url: "/product",
  },
  {
    value: 100,
    label_kor: "프로모션",
    label_eng: "promotion",
    url: "/promotion",
  },
  {
    value: 200,
    label_kor: "하이엔드",
    label2_kor: "서비스",
    label_eng: "ablack",
    url: "/service",
  },
]