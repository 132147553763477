import React, { useEffect, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import useDetectScroll from '@smakss/react-scroll-direction';
import { useLocation, useNavigate } from "react-router-dom";
import TopIcon from "../../assets/icons/quick_top_arrow.svg";
import RecentIcon from "../../assets/icons/quick_recent_icon.svg";
import KakaoIcon from "../../assets/icons/quick_kakao_icon.svg";

const FloatingActionButton: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {scrollPosition} = useDetectScroll();
  const [additionalBottomPosition, setAdditionalBottomPosition] = useState<number>(0);

  useEffect(() => {
    if (location.pathname === '/product_detail') {
      setAdditionalBottomPosition(80);
    } else {
      setAdditionalBottomPosition(0);
    }
  }, [location.pathname]);

  return (
    <>
      {(location.pathname !== "/") && (
        <SOL.FloatingActionButtonContainer $plusBottom={additionalBottomPosition}>
          <button
            className={`${(scrollPosition.top >= 500) ? "" : "none"}`}
            onClick={() => {
              if (scrollPosition.top <= 500) return;
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }}
          >
            <img src={TopIcon} alt="scroll_top_btn" />
          </button>
          <button onClick={() => navigate('/recent_product')}>
            <img src={RecentIcon} alt="recent_product_btn" />
          </button>
          <button
            onClick={() => {
              window.open('https://pf.kakao.com/_HMQQs', "_blank");
            }}
          >
            <img src={KakaoIcon} alt="kakao_icon" />
          </button>
        </SOL.FloatingActionButtonContainer>
      )}
    </>
  )
};

export default FloatingActionButton;