import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getEstimateOneByEstimateNumber } from "../../Axios";
import * as SOL from "../../styles/solda/StyledComponentSol";
import IconArrowUp from "../../assets/icons/arrow_fold_40.svg";

const EstimateSuccess: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {aos_estimate_number} = location.state as {aos_estimate_number: string};
  const [estimateData, setEstimateData] = useState<any>(null);
  const [products, setProducts] = useState<any[]>([]);
  const [toggleProductList, setToggleProductList] = useState<boolean>(true);

  useEffect(() => {
    if (!aos_estimate_number) {
      navigate('/home');
    } else {
      getEstimateOneByEstimateNumber(
        aos_estimate_number
      ).then(result => {
        setEstimateData(result.estimate_data);
        setProducts(result.product_data);
      });
    }
  }, [aos_estimate_number, navigate]);

  return(
    <>
      {(products.length > 0 && estimateData !== null) && (
        <SOL.OrderSuccessContainer>
          <div className="order-success-wrapper">
            <div className="order-success-title">
              <h3>견적서가 저장 되었습니다.</h3>
            </div>
            <div className="order-success-info">
              <div className="info-items">
                <span className="label">견적번호</span>
                <span className="content font-pretendard">{estimateData.aos_estimate_number}</span>
              </div>
              <div className="info-items">
                <span className="label">기존 공급가</span>
                <span className="content font-pretendard">{estimateData.aos_estimate_supply_price_existing.toLocaleString()}원</span>
              </div>
              <div className="info-items">
                <span className="label">프로모션 할인</span>
                <span className="content font-pretendard">{estimateData.aos_estimate_promotion_discount_pay === 0 ? `0원` : `-${estimateData.aos_order_promotion_discount_pay.toLocaleString()}원`}</span>
              </div>
              <div className="info-items">
                <span className="label">최종 공급가</span>
                <span className="content font-pretendard">{estimateData.aos_estimate_supply_price_final.toLocaleString()}원</span>
              </div>
            </div>
            <div className="order-success-product-box">
              <div className="product-header" onClick={() => setToggleProductList(!toggleProductList)}>
                <span>발주상품 {products.length}건</span>
                <button>
                  <img className={`${toggleProductList || "rotate-180"}`} src={IconArrowUp} alt="arrow_icon" />
                </button>
              </div>
              {toggleProductList && (
                products.map((tmp, idx) => (
                  <SOL.OrderItem
                    key={tmp.color_ID_use_aos_estimate_detail}
                    className={`order-success-item ${products.length === (idx+1) && "last-item"}`}
                  >
                    <div className="order-item-wrapper">
                      <div className="order-flex-left">
                        <div
                          className="order-image"
                        >
                          <img src={tmp.color_b2c_image} alt={tmp.color_name} />
                        </div>
                        <div
                          className='order-details'
                          style={{flex: 2}}
                        >
                          <h2 className='order-brand-name'>{tmp.brand_eng_name}</h2>
                          <h1 className="order-product-name">{tmp.product_kor_name}</h1>
                          <h3 className="order-color-name">{tmp.color_name}</h3>
                        </div>
                      </div>
                      <div className='order-flex-right'>
                        <div className='order-quantity'>
                          <span>{tmp.aos_detail_estimate_quantity}개</span>
                        </div>
                        <div className='order-price-box'>
                          <div className='price-wrapper'>
                            {tmp.aos_detail_estimate_sell_price_original > tmp.aos_detail_estimate_sell_price && (
                              <p className='order-origin-price'>{(tmp.aos_detail_estimate_sell_price_original * (tmp.aos_detail_estimate_quantity)).toLocaleString()}원</p>
                            )}
                            <p className="order-total-price">{(tmp.aos_detail_estimate_sell_price * (tmp.aos_detail_estimate_quantity)).toLocaleString()}원</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SOL.OrderItem>
                ))
              )}
            </div>

            <div className='order-success-control-box'>
                <button
                  onClick={() => {navigate('/mypage', {state: { tab_name: "estimate_detail", aos_estimate_ID: estimateData.aos_estimate_ID }})}}
                >
                    견적 확인하기
                </button>
                <button className='darker' onClick={() => navigate('/home')}>메인으로 가기</button>
            </div>
          </div>
        </SOL.OrderSuccessContainer>
      )}
    </>
  )
};

export default EstimateSuccess;