import React, { FC, createContext, useContext, useState } from "react";

interface IDiscountRateInfo{
  apply_discount_rate_1: string;
  apply_discount_rate_2: string;
  apply_discount_rate_ID: string;
  apply_discount_rate_ID_portable: string;
  apply_discount_rate_portable_1: string;
  apply_discount_rate_portable_2: string;
  apply_discount_rate_vat: string;
  brand_ID: string;
  use_status: string;
};

interface IDiscountRateProductTypeInfo {
  apply_discount_rate_1 : string;
  apply_discount_rate_2 : string;
  apply_discount_rate_ID : string;
  apply_discount_rate_vat : string;
  brand_ID : string;
  product_type_ID: number[];
  use_status : string;
};

interface IDiscountRateColorInfo {
  apply_discount_rate_1 : string;
  apply_discount_rate_2 : string;
  apply_discount_rate_ID : string;
  apply_discount_rate_vat : string;
  color_ID: number[];
  use_status : string;
};

interface IDiscountRateContext {
  discountRateInfo: IDiscountRateInfo[];
  setDiscountRateInfo: React.Dispatch<React.SetStateAction<IDiscountRateInfo[]>>;
  discountRateProductTypeInfo: IDiscountRateProductTypeInfo[];
  setDiscountRateProductTypeInfo: React.Dispatch<React.SetStateAction<IDiscountRateProductTypeInfo[]>>;
  discountRateColorInfo: IDiscountRateColorInfo[];
  setDiscountRateColorInfo: React.Dispatch<React.SetStateAction<IDiscountRateColorInfo[]>>;
};

export const DiscountRateContext = createContext<IDiscountRateContext | undefined>(undefined);

export const useDiscountRateContext = () => {
  const context = useContext(DiscountRateContext);
  if (!context) {
    throw new Error('useDiscountRateContext must be used within a DiscountRateProvider');
  }
  return context;
};

export const DiscountRateProvider: FC<{ children?: React.ReactNode }> = ({children}) => {
  const [discountRateInfo, setDiscountRateInfo] = useState<IDiscountRateInfo[]>([]);
  const [discountRateProductTypeInfo, setDiscountRateProductTypeInfo] = useState<IDiscountRateProductTypeInfo[]>([]);
  const [discountRateColorInfo, setDiscountRateColorInfo] = useState<IDiscountRateColorInfo[]>([]);
  return (
    <DiscountRateContext.Provider
      value={{
        discountRateInfo,
        setDiscountRateInfo,
        discountRateProductTypeInfo,
        setDiscountRateProductTypeInfo,
        discountRateColorInfo,
        setDiscountRateColorInfo,
      }}
    >
      {children}
    </DiscountRateContext.Provider>
  );
};