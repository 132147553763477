import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleHeader from "../Header/SimpleHeader";
import * as SOL from "../../styles/solda/StyledComponentSol";
import ModalClose from "../../components/Modal/ModalClose";
import { eliminateHyphen, onlyNumber, removeWhitespacePhone, validateEmail, validatePassword, validatePhone } from "../../modules/Validation";
import { generateRandomCode } from "../../Utils";
import { findCustomerAccountWidthEmail, findCustomerEmail, sendMessage, updateNewPassword } from "../../Axios";

interface IResultEmails {
  account_status_use_alc: number;
  customer_ID: number
  customer_email: string;
  customer_activate: number;
  customer_join_division: number;
  customer_alc_status: number;
  alc_status: number;
  mou_status: number;
}

const FindAccount: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const code = location.state?.code || '';
  const category = location.state?.category || '';

  const [customerName, setCustomerName] = useState<string>('');
  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>('');
  const [codeInput, setCodeInput] = useState<string>('');
  const [inputErrors, setInputErrors] = useState({
    name: '',
    email: '',
    phone: '',
    password1: '',
    password2: '',
  });
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
  const [password2ErrorMessage, setPassword2ErrorMessage] = useState<string>('');
  const [resultEmails, setResultEmails] = useState<IResultEmails[]>([]);
  const [resultCustomerID, setResultCustomerID] = useState<number>(0);

  const [isCertification, setIsCertification] = useState<boolean>(false);
  const [certificationCode, setCertificationCode] = useState<string>('AAAAAA');
  const [certificationTimer, setCertificationTimer] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(600);
  const [renderCountdown, setRenderCountdown] = useState<string>("");
  const [phoneCertificationText, setPhoneCertificationText] = useState('');

  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');

  const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState({text: ''});

  const resetFindAccountState = () => {
    setCustomerName('');
    setCustomerEmail('');
    setCustomerPhoneNumber('');
    setCodeInput('');
    setResultEmails([]);
    setIsCertification(false);
    setCertificationCode('AAAAAA');
    setCertificationTimer(false);
    setNewPassword('');
    setNewPasswordConfirm('');
  };

  useEffect(() => {
    resetFindAccountState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    if (!category) {
      setIsModalCloseOpen(true);
      setModalContent({text: "잘못된 접근입니다."});
    }
  }, [category, navigate]);

  const requestSendMobileMessage = () => {
    if (isCertification) return;
    if (category === 'email') {
      if (customerName.trim() === '') {
        setInputErrors({ ...inputErrors, name: '이름을 입력해주세요.' });
        return;
      } else {
        setInputErrors({ ...inputErrors, name: '' });
      }
    }
    if (category === 'password') {
      if (!validateEmail(customerEmail.trim())) {
        setInputErrors({ ...inputErrors, email: '이메일 형식에 맞게 입력해 주세요.' });
        return;
      } else {
        setInputErrors({ ...inputErrors, email: '' });
      }
    }
    if (!validatePhone(customerPhoneNumber)) {
      setIsModalCloseOpen(true);
      setModalContent({text: "전화번호를 정확히 입력해주세요."});
      setInputErrors({...inputErrors, phone: "인증번호를 정확히 입력해주세요."});
      return;
    };
    const phone = eliminateHyphen(customerPhoneNumber);
    const random_number = generateRandomCode(6);
    const message_data = `에이블랙 인증 번호 : [${random_number}] 10분 이내에 입력해주세요.`;
    
    setCertificationCode(random_number);
    setInputErrors({...inputErrors, phone: ""});
    sendMessage(phone, 1, message_data).then(result => {
      if (result === 1) {
        setIsModalCloseOpen(true);
        setModalContent({text: "인증번호가 발송 되었습니다."});
        setPhoneCertificationText('인증번호가 발송 되었습니다. 인증번호를 받지 못하셨다면 입력한 번호를 확인해 주세요.');
        setCertificationTimer(true);
        setCountdown(600);
      } else {
        setIsModalCloseOpen(true);
        setModalContent({text: "인증번호 발송을 실패 했습니다. 다시 시도하거나 문의하여 주세요."});
      }
    })
  };

  const requestUpdatenewPassword = () => {
    if (!validatePassword(newPassword)) {
      setIsModalCloseOpen(true);
      setModalContent({text: "비밀번호 형식에 맞게 입력해 주세요."});
    } else if (newPassword !== newPasswordConfirm) {
      setIsModalCloseOpen(true);
      setModalContent({text: "비밀번호가 일치하지 않습니다."});
    } else {
      updateNewPassword(
        resultCustomerID,
        newPassword,
      ).then(result => {
        if (result === 'previous_password') {
          setModalContent({text: '이전과 동일한 비밀번호는 변경할 수 없습니다.'});
        } else if (result === 1) {
          setModalContent({text: '새로운 비밀번호가 변경되었습니다.'});
          resetFindAccountState();
        } else {
          setModalContent({text: '알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.'});
        }
        setIsModalCloseOpen(true);
      });
    }
  };

  useEffect(() => {
    if (!newPassword) {
      setPasswordErrorMessage('');
    } else {
      if (!validatePassword(newPassword)) {
        setPasswordErrorMessage('영문, 숫자, 특수문자를 포함한 10~20자로 구성해야 합니다.');
      } else {
        setPasswordErrorMessage('사용 가능한 비밀번호 입니다.');
      }
    }
  }, [newPassword]);

  useEffect(() => {
    if (!validatePassword(newPassword) || !newPasswordConfirm) {
      setPassword2ErrorMessage('');
    } else {
      if (newPassword !== newPasswordConfirm) {
        setPassword2ErrorMessage('새 비밀번호가 일치하지 않습니다.');
      } else {
        setPassword2ErrorMessage('새 비밀번호가 일치합니다.');
      }
    }
  }, [newPassword, newPasswordConfirm]);

  // 타이머 동작
  useEffect(() => {
    let timer: any;
    if (certificationTimer) {
      timer = setInterval(handleClock, 1000);
    } else {
      clearInterval(timer);
    };
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificationTimer]);

  const handleClock = () => {
    setCountdown(props => props - 1);
  };

  useEffect(() => {
    if (countdown < 1) {
      setCertificationTimer(false);
      setCountdown(600);
      setPhoneCertificationText("인증시간이 초과되었습니다. 다시 인증해주세요.");
      return;
    }
    let min = Math.floor(countdown / 60);
    let sec = (countdown % 60);
    setRenderCountdown(`${min.toString()} : ${sec.toString()}`);
  }, [countdown]);

  const checkCertification = () => {
    if (isCertification) return;
    if (codeInput.length < 6) {
      setPhoneCertificationText('인증번호 6자리를 입력해 주세요.');
    }
    if (codeInput === certificationCode) {
      setIsCertification(true);
    } else {
      setPhoneCertificationText('인증번호가 일치하지 않습니다.');
    }
  };

  useEffect(() => {
    if (isCertification) {
      setPhoneCertificationText("");
      setCertificationTimer(false);

      if (category === 'email') {
        // 이것도 join_apply_log 보고 승인대기중 판단 해야함
        findCustomerEmail(
          customerName,
          customerPhoneNumber
        ).then(result => {
          if (result.length === 0) {
            setResultEmails([]);
          } else {
            // account_status_use_acl => 1: 휴면/탈퇴 계정 2: 간편로그인 계정, 3: 가입 승인 대기, 4: 이용가능 계정
            setResultEmails(result);
          }
        });
      }
      if (category === 'password') {
        findCustomerAccountWidthEmail(
          customerEmail,
          customerPhoneNumber,
        ).then(result => {
          if (result.length === 0) {
            setResultCustomerID(0);
          } else {
            setResultCustomerID(result[0].customer_ID);
          }
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCertification]);

  const goToFindPassword = () => {
    navigate('/find', {state: {code: code, category: 'password'}});
  };

  return (
    <>
      <ModalClose
        isOpen={isModalCloseOpen}
        onClose={() => {
          setIsModalCloseOpen(false);
          if (category === 'email' || category === 'password') return;
          if (code !== '') {
            navigate(`/?code=${code}`);
          } else {
            navigate('/');
          }
        }}
        text={modalContent.text}
      />
      <SimpleHeader
        code={code}
      />
      <SOL.JoinContainer>
        <div className="join-wrapper">
          <div className="join-title">
            {category === 'password' ? "비밀번호 찾기" :"아이디 찾기"}
          </div>
          <div className="section">
            {category === 'email' && (
              <div className="items">
                <label>이름</label>
                <input
                  type="text"
                  value={customerName}
                  placeholder="이름을 입력해 주세요."
                  maxLength={10}
                  onChange={e => {
                    setCustomerName(e.target.value);
                  }}
                  readOnly={(isCertification || certificationTimer)}
                />
                <p className="input-error">{inputErrors.name}</p>
              </div>
            )}
            {category === 'password' && (
              <div className="items">
                <label>아이디</label>
                <input
                  type="text"
                  value={customerEmail}
                  placeholder="이메일 계정의 아이디를 작성해 주세요."
                  maxLength={30}
                  onChange={e => {
                    setCustomerEmail(e.target.value);
                  }}
                  readOnly={(isCertification || certificationTimer)}
                />
                <p className="input-error">{inputErrors.email}</p>
              </div>
            )}
            <div className="items connect-2">
              <label>휴대폰 번호</label>
              <input
                type="text"
                className="short"
                value={customerPhoneNumber}
                placeholder="휴대폰 번호를 입력해 주세요."
                maxLength={13}
                onChange={e => {
                  setCustomerPhoneNumber(removeWhitespacePhone(e.target.value));
                }}
                readOnly={(isCertification || certificationTimer)}
              />
              <button
                className="text-xs ml-2"
                onClick={() => {
                  requestSendMobileMessage();
                }}
              >
                인증번호
              </button>
              <p className="input-error">{inputErrors.phone}</p>
            </div>
            {(isCertification || certificationTimer) && (
              <div className="items">
                <input
                  type="text"
                  className="short"
                  placeholder="인증번호를 입력해 주세요."
                  onChange={e => {
                    setCodeInput(onlyNumber(e.target.value));
                  }}
                  maxLength={6}
                  value={codeInput}
                  readOnly={isCertification}
                />
                {certificationTimer && <span className="count-down">{renderCountdown}</span>}
                <button
                  className="text-xs ml-2"
                  onClick={checkCertification}
                >
                  확인
                </button>
                <p className="input-error">{phoneCertificationText}</p>
              </div>
            )}
          </div>
          {(isCertification && category === 'email') && (
            <SOL.FindAccountResult>
              {resultEmails.length > 0 ? (
                <>
                  <div className="email-result-wrapper">
                    {resultEmails.map((tmp, idx) => 
                      <div
                        className="email-results"
                        key={tmp.customer_ID}
                      >
                        <h3 className="email-result-title">
                          {tmp.customer_email}
                        </h3>
                        <div className="email-result-status">
                          <div className="status">
                            {tmp.account_status_use_alc === 4 ? "사용가능" : "사용불가"}
                          </div>
                        </div>
                        <div className="reason">
                          {tmp.account_status_use_alc === 1 && "탈퇴계정은 사용하실 수 없습니다."}
                          {tmp.account_status_use_alc === 2 && "간편로그인 계정은 사용하실 수 없습니다."}
                          {tmp.account_status_use_alc === 3 && "가입 승인대기 상태입니다."}
                          {tmp.account_status_use_alc === 4 && ""}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="email-result-control">
                    <button onClick={() => navigate('/')}>로그인</button>
                    <button
                      className="result-white-btn"
                      onClick={goToFindPassword}
                    >
                      비밀번호 찾기
                    </button>
                  </div>
                </>
              ) : (
                <div className="email-result-wrapper">
                  <h3 className="email-result-title">회원님의 정보와 일치하는 아이디가 없습니다.</h3>
                  <div className="email-result-control">
                    <button onClick={() => window.location.reload()}>아이디 찾기</button>
                    <button
                      className="result-white-btn"
                      onClick={goToFindPassword}
                    >
                      비밀번호 찾기
                    </button>
                  </div>
                </div>
              )}
            </SOL.FindAccountResult>
          )}
          {(isCertification && category === 'password') && (
            <SOL.FindAccountResult>
              {resultCustomerID > 0 ? (
                <div className="email-result-wrapper">
                  <h3 className="email-result-title">인증되었습니다.</h3>
                  <h3 className="email-result-title">새로운 비밀번호를 설정해 주세요.</h3>
                  <div className="section">
                    <div className="items modal-items">
                      <label>* 새 비밀번호</label>
                      <input
                        type="password"
                        maxLength={20}
                        value={newPassword}
                        placeholder="새로운 비밀번호를 입력해 주세요."
                        onChange={e => setNewPassword(e.target.value)}
                      />
                      <p className="input-error">{passwordErrorMessage}</p>
                    </div>
                    <div className="items modal-items">
                      <label>* 새 비밀번호 확인</label>
                      <input
                        type="password"
                        maxLength={20}
                        value={newPasswordConfirm}
                        placeholder="새로운 비밀번호를 재입력해 주세요."
                        onChange={e => setNewPasswordConfirm(e.target.value)}
                      />
                      <p className="input-error">{password2ErrorMessage}</p>
                    </div>
                  </div>
                  <div className="email-result-control">
                    <button onClick={requestUpdatenewPassword}>
                      비밀번호 재설정
                    </button>
                  </div>
                </div>
              ) : (
                <div className="email-result-wrapper">
                  <h3 className="email-result-title">회원님의 정보와 일치하는 아이디가 없습니다.</h3>
                  <div className="email-result-control">
                    <button onClick={() => navigate('/find', {state: {code: code, category: "email"}})}>아이디 찾기</button>
                    <button
                      className="result-white-btn"
                      onClick={() => window.location.reload()}
                    >
                      비밀번호 찾기
                    </button>
                  </div>
                </div>
              )}
            </SOL.FindAccountResult>
          )}
          <SOL.FindAccountControlBox>
            {category === 'email' && (
              <div
                className="find-account-control password"
                onClick={goToFindPassword}
              >
                비밀번호를 잊으셨나요? 비밀번호 찾기
              </div>
            )}
            {category === 'password' && (
              <div
                className="find-account-control password"
                onClick={() => navigate('/find', {state: {code: code, category: 'email'}})}
              >
                아이디를 잊으셨나요? 아이디 찾기
              </div>
            )}
            <div
              className="find-account-control join"
              onClick={() => navigate('/join', {state: {code: code}})}
            >
              아직 회원이 아니신가요? 회원가입 하기
            </div>
          </SOL.FindAccountControlBox>
        </div>
      </SOL.JoinContainer>
    </>
  )
};

export default FindAccount;