import Axios from 'axios';

const isProduction = process.env.NODE_ENV === 'production';

export const baseUrl: string = isProduction
    ? 'https://ablack.ambience.kr:6161/'
    : 'http://localhost:6161/';

export const baseUrlExport: string = isProduction
    ? 'https://ablack.ambience.kr:6161/'
    : 'http://localhost:6161/';

export const buildStatus: string = isProduction
    ? 'operation'
    : 'test';

export const b2bUrl: string = 'https://b2b.ambience.kr/'; 

export const handlePaymentMethod = (
	build_status : string,
	payment_method: string,
) => {
	if (build_status === 'test') {
		switch (payment_method) {
			case 'card' : return 'html5_inicis.INIpayTest';
			case 'kakaopay' : return 'kakaopay.TC0ONETIME';
			case 'tosspay' : return 'uplus.tlgdacomxpay';
		}
	} else {
		if (payment_method === 'card') {
			return 'html5_inicis';
		} else {
			return payment_method;
		}
	}
};

export const sendKakaoPush = async (
	title: string,
	content: string,
) => {
	const data = new FormData();
	data.append('title', title);
	data.append('content', content);

	try {
		const response = await Axios.post(`${baseUrl}common/send_kakao_push`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getAlcComplexMatchingList = async (
	alc_code_name: string,
) => {
	const data = new FormData();
	data.append('alc_code_name', alc_code_name);

	try {
		const response = await Axios.post(
			`${baseUrl}alc_login/get_alc_complex_matching_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
}

export const requestAlcLogin = async (
	customer_email: string,
	customer_password: string,
) => {
	let data = new FormData();
	data.append('customerEmail', customer_email);
	data.append('customerPassword', customer_password);

	try {
		const response = await Axios.post(
			`${baseUrl}alc_login/request_alc_login`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
}

export const handleRememberLogin = async (customer_ID_encrypted: string) => {
	const data = new FormData();
	data.append('customer_ID_encrypted', customer_ID_encrypted);

	try {
		const response = await Axios.post(
			`${baseUrl}alc_login/handle_remember_login`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const requestLogout = async () => {
	const data = new FormData();

	try {
		const response = await Axios.post(
			`${baseUrl}alc_login/request_logout`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
}

export const requestCustomerJoin = async (
	customer_email: string,
	customer_password: string,
	customer_name: string,
	customer_gender: number,
	customer_birthday: string,
	customer_phone_number: string,
	customer_zip_code: string,
	customer_road_address: string,
	customer_detail_address: string,
	customer_big_area: string,
	customer_small_area: string,
	customer_join_object: number,
	customer_privacy_status: number,
	customer_marketing_status: number,
	alc_complex_ID_use_c: number,
	account_ID_use_alc_code: string,
	alc_join_apply_log_reason: string,
) => {
	const data = new FormData();
	data.append('customer_email', customer_email);
	data.append('customer_password', customer_password);
	data.append('customer_name', customer_name);
	data.append('customer_gender', customer_gender.toString());
	data.append('customer_birthday', customer_birthday);
	data.append('customer_phone_number', customer_phone_number);
	data.append('customer_zip_code', customer_zip_code);
	data.append('customer_road_address', customer_road_address);
	data.append('customer_detail_address', customer_detail_address);
	data.append('customer_big_area', customer_big_area);
	data.append('customer_small_area', customer_small_area);
	data.append('customer_join_object', customer_join_object.toString());
	data.append('customer_privacy_status', customer_privacy_status.toString());
	data.append('customer_marketing_status', customer_marketing_status.toString());
	data.append('alc_complex_ID_use_c', alc_complex_ID_use_c.toString());
	data.append('account_ID_use_alc_code', account_ID_use_alc_code);
	data.append('alc_join_apply_log_reason', alc_join_apply_log_reason);

	try {
		const response = await Axios.post(
			`${baseUrl}alc_login/insert_customer_join`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const checkAlcCoupon = async (alc_coupon_code: string ) => {
	const data = new FormData();

	data.append('alc_coupon_code', alc_coupon_code);

	try {
		const response = await Axios.post(`${baseUrl}alc_login/check_alc_coupon`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const checkAlcSession = async () => {
	const data = new FormData();

	try {
		const response = await Axios.post(`${baseUrl}alc_login/check_alc_session`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getApplyDiscountRateFromSession = async (
	show_original_price: string,
) => {
	const data = new FormData();
	data.append('show_original_price', show_original_price);

	try {
		const response = await Axios.post(
			`${baseUrl}alc_login/get_apply_discount_rate_from_session`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
}

export const getBrandAlsStatus = async () => {
	const data = new FormData();

	try {
		const response = await Axios.post(`${baseUrl}alc_product/get_brand_als_status`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getProductAlc = async (
	alc_status: number,
	page_size: string,
	combination_filter: string,
) => {
	const data = new FormData();
	data.append('alc_status', alc_status.toString());
	data.append('page_size', page_size);
	data.append('combination_filter', combination_filter);

	try {
		const response = await Axios.post(`${baseUrl}alc_product/get_product_alc`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getMinimumAndMaximumPrice = async (
	brand_ID: any,
) => {
	const data = new FormData();
	data.append("brand_ID", brand_ID);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_product/get_min_max_price`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
}

export const checkExistsProductTypeByBrand = async (
	brand_ID: any,
) => {
	const data = new FormData();
	data.append("brand_ID", brand_ID);
	try {
		const response = await Axios.post(`${baseUrl}alc_product/check_exists_product_type_by_brand`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getProductOneAlc = async (
	color_ID: any,
) => {
	const data = new FormData();
	data.append('color_ID', color_ID);

	try {
		const response = await Axios.post(`${baseUrl}alc_product/get_product_one_alc`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};


export const getProductCartAlc = async (
	cart_list: any,
) => {
	const data = new FormData();
	data.append('cart_list', cart_list);

	try {
		const response = await Axios.post(`${baseUrl}alc_product/get_product_cart_alc`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getAlcRollingBanner = async () => {
	const data = new FormData();

	try {
		const response = await Axios.post(`${baseUrl}alc_main/get_alc_rolling_banner`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const sendMessage = async (
    phone_number  : string,
    message_style : number,
    message_data  : string,
) => {
    const url = `${baseUrl}common/send_message`;
    let data = new FormData();
    data.append('phone_number', phone_number);
    data.append('message_style', message_style.toString());
    data.append('message_data', message_data);
    const reqHeader = {
        headers : {
            "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
    };
    try {
        const response = await Axios.post(url, data, reqHeader);
        return response.data;
    }
    catch (err) {
		return 'error_alc';
    }
};

export const insertAlcOrder = async (
    shipping_info  : string,
    products : string,
    merchant_uid  : any,
		imp_uid  : any,
		cash_receipts_info: string,
		alc_order_pay_total: number,
) => {
    let data = new FormData();
    data.append('shipping_info', shipping_info);
    data.append('products', products);
    data.append('merchant_uid', merchant_uid);
		data.append('imp_uid', imp_uid);
		data.append('cash_receipts_info', cash_receipts_info);
		data.append('alc_order_pay_total', alc_order_pay_total.toString());

	try {
		const response = await Axios.post(`${baseUrl}alc_order/insert_alc_order`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const insertAlcOffer = async (
	shipping_info  : string,
	products : string,
	merchant_uid  : string,
) => {
	const data = new FormData();
	data.append('shipping_info', shipping_info);
	data.append('products', products);
	data.append('merchant_uid', merchant_uid);

	try {
		const response = await Axios.post(`${baseUrl}alc_order/insert_alc_offer`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getAlcOrderListBySorted = async (
	alc_customer_ID: number,
	order_status: number,
) => {
	const data = new FormData();
	data.append('alc_customer_ID', alc_customer_ID.toString());
	data.append('order_status', order_status.toString());

	try {
		const response = await Axios.post(
			`${baseUrl}alc_order/get_alc_order_list_by_sorted`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getAlcOrderCancelList = async (
	alc_customer_ID: number,
	order_status: number,
) => {
	const data = new FormData();
	data.append('alc_customer_ID', alc_customer_ID.toString());
	data.append('order_status', order_status.toString());

	try {
		const response = await Axios.post(
			`${baseUrl}alc_order/get_alc_order_cancel_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}	
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getOrderDetail = async (
	alc_order_ID: number
) => {
	const data = new FormData();
	data.append('alc_order_ID', alc_order_ID.toString());

	try {
		const response = await Axios.post(
			`${baseUrl}alc_order/get_order_detail`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data
	} catch (err) {
		return 'error_alc';
	}
};

export const refundAlcOrderAll = async (
	order_data: string,
) => {
	const data = new FormData();
	data.append('order_data', order_data);

	try {
		const response = await Axios.post(`${baseUrl}alc_order/refund_alc_order_all`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const refundAlcOrder = async (
	detail: string,
	alc_detail_cancel_reason: number,
	alc_detail_reason_direct: string,
) => {
	const data = new FormData();
	data.append('detail', detail);
	data.append('alc_detail_cancel_reason', alc_detail_cancel_reason.toString());
	data.append('alc_detail_reason_direct', alc_detail_reason_direct);

	try {
		const response = await Axios.post(`${baseUrl}alc_order/refund_alc_order`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getBrandData = async (
	brand_ID: string,
) => {
	const data = new FormData();
	data.append("brand_ID", brand_ID);

	try {
		const response = await Axios.post(`${baseUrl}alc_product/get_brand_data`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const getRecentProductData = async (
	recent_product_color_ID_list: string[],
	limit_count: number,
) => {
	const data = new FormData();
	data.append('recent_product_color_ID_list', JSON.stringify(recent_product_color_ID_list));
	data.append('limit_count', limit_count.toString());

	try {
		const response = await Axios.post(`${baseUrl}alc_product/get_recent_product_data`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			withCredentials: true,
		})
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const getSessionData = async () => {
	const data = new FormData();
	
	try {
		const response = await Axios.post(
			`${baseUrl}alc_login/get_session_data`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const getCustomerShippingInfo = async () => {
	const data = new FormData();
	try {
		const response = await Axios.post(
			`${baseUrl}alc_order/get_customer_shipping_info`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const insertExtraAddress = async (
	customer_ID_use_extra_address: number,
	extra_address_customer_name: string,
	extra_address_name: string,
	extra_address_phone_number: string,
	extra_address_zip_code: string,
	extra_address_road_address: string,
	extra_address_detail_address: string,
	extra_address_big_area: string,
	extra_address_small_area: string,
) => {
	const data = new FormData();

	data.append('customer_ID_use_extra_address', customer_ID_use_extra_address.toString());
	data.append('extra_address_customer_name', extra_address_customer_name);
	data.append('extra_address_name', extra_address_name);
	data.append('extra_address_phone_number', extra_address_phone_number);
	data.append('extra_address_zip_code', extra_address_zip_code);
	data.append('extra_address_road_address', extra_address_road_address);
	data.append('extra_address_detail_address', extra_address_detail_address);
	data.append('extra_address_big_area', extra_address_big_area);
	data.append('extra_address_small_area', extra_address_small_area);

	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/insert_extra_address`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const updateExtraAddress = async (
	extra_address_ID: number,
	extra_address_name: string,
	extra_address_customer_name: string,
	extra_address_phone_number: string,
	extra_address_zip_code: string,
	extra_address_road_address: string,
	extra_address_detail_address: string,
	extra_address_big_area: string,
	extra_address_small_area: string,
) => {
	const data = new FormData();
	data.append('extra_address_ID', extra_address_ID.toString());
	data.append('extra_address_name', extra_address_name);
	data.append('extra_address_customer_name', extra_address_customer_name);
	data.append('extra_address_phone_number', extra_address_phone_number);
	data.append('extra_address_zip_code', extra_address_zip_code);
	data.append('extra_address_road_address', extra_address_road_address);
	data.append('extra_address_detail_address', extra_address_detail_address);
	data.append('extra_address_big_area', extra_address_big_area);
	data.append('extra_address_small_area', extra_address_small_area);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/update_extra_address`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const getExtraAddressList = async () => {
	const data = new FormData();

	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/get_extra_address_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const updateInactiveExtraAddress = async (
	extra_address_ID: number,
) => {
	const data = new FormData();
	data.append('extra_address_ID', extra_address_ID.toString());

	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/update_inactive_extra_address`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const updatePassword = async (
	alc_customer_ID: number,
	alc_current_password: string,
	alc_new_password: string,
) => {
	const data = new FormData();
	data.append('alc_customer_ID', alc_customer_ID.toString());
	data.append('alc_current_password', alc_current_password);
	data.append('alc_new_password', alc_new_password);

	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/update_customer_new_password`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const getIntegratedInfo = async (
	customer_ID: number,
) => {
	const data = new FormData();
	data.append('customer_ID', customer_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/get_integrated_info`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const findCustomerEmail = async (
	customer_name: string,
	customer_phone_number: string,
) => {
	const data = new FormData();

	data.append('customer_name', customer_name);
	data.append('customer_phone_number', customer_phone_number);

	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/find_customer_email`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const findCustomerAccountWidthEmail = async (
	customer_email: string,
	customer_phone_number: string,
) => {
	const data = new FormData();

	data.append('customer_email', customer_email);
	data.append('customer_phone_number', customer_phone_number);

	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/find_customer_account_with_email`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const updateNewPassword = async (
	customer_ID: number,
	customer_password: string,
) => {
	const data = new FormData();

	data.append('customer_ID', customer_ID.toString());
	data.append('customer_password', customer_password.toString());

	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/update_new_password`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getOrderSuccessSummary = async (
	merchant_uid: string,
) => {
	const data = new FormData();
	data.append('merchant_uid', merchant_uid);

	try {
		const response = await Axios.post(
			`${baseUrl}alc_product/get_order_success_summary`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getMyMainPageData = async (
	customer_ID: number,
) => {
	const data = new FormData();
	data.append('customer_ID', customer_ID.toString());

	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/get_my_main_page_data`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const checkCustomerPassword = async (
	customer_ID: number,
	customer_password: string,
) => {
	const data = new FormData();
	data.append('customer_ID', customer_ID.toString());
	data.append('customer_password', customer_password);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/check_password`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getCustomerData = async (
	customer_ID : number,
) => {
	const data = new FormData();
	data.append('customer_ID', customer_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/get_customer_data`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const updatePhoneNumber = async (
	customer_ID: number,
	customer_phone_number: string,
) => {
	const data = new FormData();
	data.append('customer_ID', customer_ID.toString());
	data.append('customer_phone_number', customer_phone_number);

	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/update_phone_number`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const inactiveCustomerAccount = async (
	customer_ID: number,
) => {
	const data = new FormData();
	data.append('customer_ID', customer_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/inactive_customer_account`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const updateCustomerProfile = async (
	customer_ID: number,
	customer_marketing_status: number,
) => {
	const data = new FormData();
	data.append('customer_ID', customer_ID.toString());
	data.append('customer_marketing_status', customer_marketing_status.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/update_customer_profile`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getOfferSuccessSummary = async (
	merchant_uid: string,
) => {
	const data = new FormData();
	data.append('merchant_uid', merchant_uid);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_order/get_offer_success_summary`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getPromotionRateData = async (
	customer_ID: number,
) => {
	const data = new FormData();
	data.append('customer_ID', customer_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/get_promotion_rate_data`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const checkSignedUpPhoneNumber = async (
	customer_phone_number: string,
) => {
	const data = new FormData();
	data.append('customer_phone_number', customer_phone_number);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/check_signed_up_phone_number`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const checkSignedUpEmail = async (
	customer_email: string,
) => {
	const data = new FormData();
	data.append('customer_email', customer_email);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_customer/check_signed_up_email`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc";
	}
};

export const checkAvailableCode = async (
	alc_code_name: string,
) => {
	const data = new FormData();
	data.append('alc_code_name', alc_code_name);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_login/check_available_code`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const request_cancel_order = async (
	order_data: any,
	alc_detail_cancel_reason: number,
	alc_detail_reason_direct: string,
) => {
	const data = new FormData();
	data.append('order_data', JSON.stringify(order_data));
	data.append('alc_detail_cancel_reason', alc_detail_cancel_reason.toString());
	data.append('alc_detail_reason_direct', alc_detail_reason_direct);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_order/request_cancel_order`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const request_exchange_order = async (
	order_data: any,
	alc_detail_exchange_reason: number,
	alc_detail_reason_direct: string,
) => {
	const data = new FormData();
	data.append('order_data', JSON.stringify(order_data));
	data.append('alc_detail_exchange_reason', alc_detail_exchange_reason.toString());
	data.append('alc_detail_reason_direct', alc_detail_reason_direct);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_order/request_exchange_order`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const request_return_order = async (
	order_data: any,
	alc_detail_return_reason: number,
	alc_detail_reason_direct: string,
) => {
	const data = new FormData();
	data.append('order_data', JSON.stringify(order_data));
	data.append('alc_detail_return_reason', alc_detail_return_reason.toString());
	data.append('alc_detail_reason_direct', alc_detail_reason_direct);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_order/request_return_order`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getOneDetailOrderData = async (
	alc_detail_order_ID: number,
) => {
	const data = new FormData();
	data.append('alc_detail_order_ID', alc_detail_order_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_order/get_one_detail_order_data`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const requestExchangeDetailOrderOne = async (
	alc_detail_order_ID: number,
	alc_detail_exchange_reason: number,
	alc_detail_reason_direct: string,
) => {
	const data = new FormData();
	data.append('alc_detail_order_ID', alc_detail_order_ID.toString());
	data.append('alc_detail_exchange_reason', alc_detail_exchange_reason.toString());
	data.append('alc_detail_reason_direct', alc_detail_reason_direct);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_order/request_exchange_detail_order_one`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const requestReturnDetailOrderOne = async (
	alc_detail_order_ID: number,
	alc_detail_return_reason: number,
	alc_detail_reason_direct: string,
) => {
	const data = new FormData();
	data.append('alc_detail_order_ID', alc_detail_order_ID.toString());
	data.append('alc_detail_return_reason', alc_detail_return_reason.toString());
	data.append('alc_detail_reason_direct', alc_detail_reason_direct);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_order/request_return_detail_order_one`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const requestCancelBankTransferDetailOrderOne = async(
	alc_detail_order_ID: number,
	alc_detail_cancel_reason: number,
	alc_detail_reason_direct: string,
) => {
	const data = new FormData();
	data.append('alc_detail_order_ID', alc_detail_order_ID.toString());
	data.append('alc_detail_cancel_reason', alc_detail_cancel_reason.toString());
	data.append('alc_detail_reason_direct', alc_detail_reason_direct);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_order/request_cancel_bank_transfer_detail_order_one`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getAosPromotionData = async (
	alc_customer_ID: number
) => {
	const data = new FormData();
	data.append('alc_customer_ID', alc_customer_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_offer/get_aos_promotion_data`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const insertAosEstimate = async (
	customerID: number,
	promotion_ID: number,
	aos_estimate_number: string,
	aos_estimate_supply_price_existing: number,
	aos_estimate_total_separate_vat: number,
	aos_estimate_promotion_discount_pay: number,
	aos_estimate_promotion_discount_rate: number,
	aos_estimate_supply_price_final: number,
	products: string,
) => {
	const data = new FormData();
	data.append('customerID', customerID.toString());
	data.append('promotion_ID', promotion_ID.toString());
	data.append('aos_estimate_number', aos_estimate_number);
	data.append('aos_estimate_supply_price_existing', aos_estimate_supply_price_existing.toString());
	data.append('aos_estimate_total_separate_vat', aos_estimate_total_separate_vat.toString());
	data.append('aos_estimate_promotion_discount_pay', aos_estimate_promotion_discount_pay.toString());
	data.append('aos_estimate_promotion_discount_rate', aos_estimate_promotion_discount_rate.toString());
	data.append('aos_estimate_supply_price_final', aos_estimate_supply_price_final.toString());
	data.append('products', products);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_offer/insert_aos_estimate`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getEstimateList = async (
	customer_ID: number
) => {
	const data = new FormData();
	data.append('customer_ID', customer_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_offer/get_estimate_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getEstimateOne = async (
	aos_estimate_ID: number,
) => {
	const data = new FormData();
	data.append('aos_estimate_ID', aos_estimate_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_offer/get_estimate_one`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const insertAosOrder = async (
	customer_data: string,
	promotion_ID: number,
	aos_order_number: string,
	sender_shipping_info: string,
	receiver_shipping_info: string,
	products: string,
	aos_order_supply_price_existing: number,
	aos_order_total_separate_vat: number,
	aos_order_promotion_discount_pay: number,
	aos_order_promotion_discount_rate: number,
	aos_order_supply_price_final: number,
	is_respective_shipping_memo: string,
) => {
	const data = new FormData();
	data.append('customer_data', customer_data);
	data.append('promotion_ID', promotion_ID.toString());
	data.append('aos_order_number', aos_order_number);
	data.append('sender_shipping_info', sender_shipping_info);
	data.append('receiver_shipping_info', receiver_shipping_info);
	data.append('products', products);
	data.append('aos_order_supply_price_existing', aos_order_supply_price_existing.toString());
	data.append('aos_order_total_separate_vat', aos_order_total_separate_vat.toString());
	data.append('aos_order_promotion_discount_pay', aos_order_promotion_discount_pay.toString());
	data.append('aos_order_promotion_discount_rate', aos_order_promotion_discount_rate.toString());
	data.append('aos_order_supply_price_final', aos_order_supply_price_final.toString());
	data.append('is_respective_shipping_memo', is_respective_shipping_memo);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_offer/insert_aos_order`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return 'error_alc';
	}
};

export const getAosOrderSuccess = async (
	aos_order_number: string,
) => {
	const data = new FormData();
	data.append('aos_order_number', aos_order_number);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_offer/get_aos_order_success`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getAosOrderList = async (
	customer_ID: number,
	page: number,
) => {
	const data = new FormData();
	data.append('customer_ID', customer_ID.toString());
	data.append('page', page.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_offer/get_aos_order_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getAosOrderDetail = async (
	aos_order_ID: number,
) => {
	const data = new FormData();
	data.append('aos_order_ID', aos_order_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_offer/get_aos_order_detail`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getEstimateOneByEstimateNumber = async (
	aos_estimate_number: string,
) => {
	const data = new FormData();
	data.append('aos_estimate_number', aos_estimate_number);
	try {
		const response = await Axios.post(
			`${baseUrl}alc_offer/get_estimate_one_by_estimate_number`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const updateEstimateInactive = async (
	aos_estimate_ID: number,
) => {
	const data = new FormData();
	data.append('aos_estimate_ID', aos_estimate_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_offer/update_estimate_inactive`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getAlcB2bBannerActiveList = async () => {
	const data = new FormData();
	// data.append('1' , '1');`
	try {
		const response = await Axios.post(
			`${baseUrl}alc_mongo/get_alc_b2b_banner_active_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_aos"; 
	}
};
  
export const getAlcB2cBannerActiveList = async () => {
	const data = new FormData();
	try {
		const response = await Axios.post(
			`${baseUrl}alc_mongo/get_alc_b2c_banner_active_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};


export const getAlcB2bBrandBannerList = async (
	brand_ID: number,
) => {
	const data = new FormData();
	data.append('brand_ID' , brand_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_mongo/get_alc_b2b_brand_banner_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_aos"; 
	}
};

export const getAlcB2cBrandBannerList = async (
	brand_ID: number,
) => {
	const data = new FormData();
	data.append('brand_ID' , brand_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_mongo/get_alc_b2c_brand_banner_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_aos"; 
	}
};

export const getAlcb2bPromotionList = async () => {
	const data = new FormData();
	try {
		const response = await Axios.post(
			`${baseUrl}alc_mongo/get_alc_b2b_promotion_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getAlcb2cPromotionList = async () => {
	const data = new FormData();
	try {
		const response = await Axios.post(
			`${baseUrl}alc_mongo/get_alc_b2c_promotion_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getAlcb2bPromotionActiveList = async (alc_banner_group_ID :number) => {
	const data = new FormData();
	data.append('alc_banner_group_ID', alc_banner_group_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_mongo/get_alc_b2b_promotion_Active_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getAlcb2cPromotionActiveList = async (alc_banner_group_ID :number) => {
	const data = new FormData();
	data.append('alc_banner_group_ID', alc_banner_group_ID.toString());
	try {
		const response = await Axios.post(
			`${baseUrl}alc_mongo/get_alc_b2c_promotion_Active_list`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};

export const getProductDataByColorArray = async (
	color_array: number[],
) => {
	const data = new FormData();
	data.append('color_array', JSON.stringify(color_array));
	try {
		const response = await Axios.post(
			`${baseUrl}alc_product/get_product_data_by_color_array`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			}
		);
		return response.data;
	} catch (err) {
		return "error_alc"; 
	}
};