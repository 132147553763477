import React, { FC, useEffect, useState } from 'react'
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import PromotionDetail from '../../components/Promotion/PromotionDetail';
import useCheckSession from '../../hooks/useCheckSession';
import { useLocation, useNavigate } from "react-router-dom";
import { getSessionData } from '../../Axios';

const PromotionDetailPage:FC = () => {
  useCheckSession();
	const [customerAlcStatus, setCustomerAlcStatus] = useState<number>(2);

  const navigate = useNavigate();
  const location = useLocation();
  const [access, setAccess] = useState<boolean>(true);
  
  useEffect(() => {
    if (!location.state) {
      navigate("/home");
    } else {
      setAccess(true);
    }
  }, [location, navigate]);

	useEffect(() => {
		getSessionData().then((result) => {
			setCustomerAlcStatus(result.alc_status);
		});
	}, []);

  return (
    <PageWrapper title={'프로모션 세부'}>
       {access ? <PromotionDetail customerAlcStatus={customerAlcStatus} /> : <div className='w-[100%] h-[600px] bg-main_white'></div>}
    </PageWrapper>
  )
};

export default PromotionDetailPage