import React, { FC, useEffect, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import LogoBlack from "../../assets/image_folder/common_images/ablack_black.png";
import RecentProduct from "../../layout/Recent/RecentProduct";
import VerticalIcon from "../../assets/icons/icon_vertical.svg";
import ForwardArrow from "../../assets/icons/icon_navigate_arrow.svg";
import { ICustomerSummary, IOrderSummary } from "../../modules/model";
import { getAosPromotionData } from "../../Axios";
import { sortingAvailablePromotionList } from "../../Utils";

interface MyMainProps {
  customerID: number;
  customerAlcStatus: number;
  customerSummary: ICustomerSummary;
  orderSummary: IOrderSummary;
  handleChangeTab: (tab_name: string) => void;
};

const MyMain: FC<MyMainProps> = ({customerID, customerAlcStatus, customerSummary, orderSummary, handleChangeTab}) => {
  const [b2bAvailablePromotioList, setB2bAvailablePromotioList] = useState<any[]>([]);
  const [morePromotionList, setMorePromotionList] = useState<boolean>(false);

  useEffect(() => {
    if (!customerID) return;
    if (customerAlcStatus !== 1) return;
    getAosPromotionData(
      customerID,
    ).then(result => {
      const list = sortingAvailablePromotionList(result);
      setB2bAvailablePromotioList(list);
    });
  }, [customerAlcStatus, customerID]);

  return (
    <>
      <SOL.MyMainContainer>
        <div className="customer-info">
          <h3 className="section-title">
            {customerSummary.customer_name}님
          </h3>
          <div className="complex-and-ablack-box">
            <div className="complex-info">
              <span className="account-name">
                {customerSummary.account_name} 회원 입니다.
              </span>
              {customerSummary.alc_complex_ID && (
                <>
                  <span className="complex-name">({customerSummary.alc_complex_name})</span>
                  <p className="period font-pretendard">유효기간 : {customerSummary.alc_complex_registe} ~ {customerSummary.alc_complex_expiration_period}</p>
                </>
              )}
            </div>
            <div className="ablack-info">
              <div className="logo">
                <img src={LogoBlack} alt="logo" />
              </div>
              <div className="download">
                <p>하이엔드 조명 시공전문 에이블랙</p>
                <p>앱 다운로드 하기</p>
              </div>
            </div>
          </div>
        </div>
        {customerAlcStatus === 0 && (
          <>
            <div className="section-flex-box">
              <h3 className="section-title">주문 내역</h3>
              <button onClick={() => handleChangeTab('order_list')}>더보기</button>
            </div>
            <SOL.OrderListContainer
              className="my-main"
              onClick={() => handleChangeTab('order_list')}
            >
              <div className='order-summary-box my-main'>
                <button className={`summary-item`}>
                    <div className='value'>{orderSummary.order_total}</div>
                    <div className='label'>전체</div>
                </button>
                <img className="mobile-disappear" src={VerticalIcon} alt='vertical_icon' />
                <button className={`summary-item`}>
                    <div className='value'>{orderSummary.pay_waiting}</div>
                    <div className='label'>결제대기</div>
                </button>
                <img className="mobile-disappear" src={ForwardArrow} alt='vertical_arrow' />
                <button className={`summary-item`}>
                    <div className='value'>{orderSummary.pay_complete}</div>
                    <div className='label'>결제완료</div>
                </button>
                <img className="mobile-disappear" src={ForwardArrow} alt='vertical_arrow' />
                <button className={`summary-item`}>
                    <div className='value'>{orderSummary.delivery_preparing}</div>
                    <div className='label'>시공/배송 준비</div>
                </button>
                <img className="mobile-disappear" src={ForwardArrow} alt='vertical_arrow' />
                <button className={`summary-item`}>
                    <div className='value'>{orderSummary.delivery}</div>
                    <div className='label'>시공/배송 중</div>
                </button>
                <img className="mobile-disappear" src={ForwardArrow} alt='vertical_arrow' />
                <button className={`summary-item`}>
                    <div className='value'>{orderSummary.delivery_complete}</div>
                    <div className='label'>시공/배송 완료</div>
                </button>
              </div>
            </SOL.OrderListContainer>
            <div className="temp-cancel">
              <div className="cancel-category">
                <span className="category-label">취소</span>
                <span className="category-value">{orderSummary.order_cancel}</span>
              </div>
              <div className="cancel-category">
                <span className="category-label">반품</span>
                <span className="category-value">{orderSummary.order_return}</span>
              </div>
              <div className="cancel-category">
                <span className="category-label">교환</span>
                <span className="category-value">{orderSummary.order_exchange}</span>
              </div>
            </div>
          </>
        )}
        {customerAlcStatus === 1 && (
          <SOL.AvailablePromotionListContainer>
            <div className="promotion-wrapper">
              <div className="promotion-title">
                <span>적용가능한 프로모션</span>
                {b2bAvailablePromotioList.length > 3 && (
                  <button onClick={() => setMorePromotionList(!morePromotionList)}>
                    {morePromotionList ? "닫기" : "더보기"}
                  </button>
                )}
              </div>
              <div className="promotion-list-box">
                {b2bAvailablePromotioList
                  .filter((tmp, idx) => morePromotionList ? idx <= b2bAvailablePromotioList.length : idx <= 2)
                  .map((tmp, idx) =>
                  <div
                    key={idx}
                    className={`promotion-item ${idx%2 !== 0 && "bg-[#F3F3F6]"}`}
                  >
                    <div className="promotion-name">
                      <p>{tmp.aos_promotion_name}</p>
                    </div>
                    <div className="promotion-content">
                      <p className="label">적용 기간</p>
                      <p className="content">{`${tmp.aos_promotion_start_date} ~ ${tmp.aos_promotion_end_date}`}</p>
                    </div>
                    <div className="promotion-content">
                      <p className="label">적용 금액</p>
                      <p className="content">{tmp.discount_target_amount.toLocaleString()}원 이상</p>
                    </div>
                    <div className="promotion-content rate">
                      <p className="label">할인율</p>
                      <p className="content">{tmp.discount_rate}%</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </SOL.AvailablePromotionListContainer>
        )}
        <RecentProduct
          customerAlcStatus={customerAlcStatus}
        />
      </SOL.MyMainContainer> 
    </>
  )
};

export default MyMain;