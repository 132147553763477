import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { getCustomerData, inactiveCustomerAccount, requestLogout, sendMessage, updateCustomerProfile, updatePassword, updatePhoneNumber } from "../../Axios";
import ModalClose from "../Modal/ModalClose";
import CheckBlank from "../../assets/icons/blank_checkbox.svg"
import CheckFill from "../../assets/icons/fill_checkbox.svg";
import { eliminateHyphen, onlyNumber, removeWhiteSpace, removeWhitespacePhone, validatePassword, validatePhone } from "../../modules/Validation";
import { generateRandomCode } from "../../Utils";
import ModalYesClose from "../Modal/ModalYesClose";
import ModalYes from "../Modal/ModalYes";
import MarketingConsent from "../../modules/MarketingConsent";

interface UpdateProfileProps {
  customerID: number;
  passwordLength: number;
  handleChangeTab: (tab_name: string) => void;
}

const UpdateProfile: FC<UpdateProfileProps> = ({customerID, passwordLength, handleChangeTab}) => {
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState({
    customer_ID: 0,
    customer_name: '',
    customer_email: '',
    customer_phone_number: '',
    customer_birthday: '',
    customer_gender: '',
    customer_address: '',
    customer_marketing_status: 0,
    alc_complex_name: '',
    alc_status: 2,
  });
  const [asterisk, setAsterisk] = useState('');
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPassword2, setNewPassword2] = useState<string>('');

  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>('');
  const [codeInput, setCodeInput] = useState<string>('');
  const [certificationCode, setCertificationCode] = useState<string>('AAAAAA');
  const [certificationTimer, setCertificationTimer] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(600);
  const [renderCountdown, setRenderCountdown] = useState<string>("");
  const [phoneCertificationText, setPhoneCertificationText] = useState('');
  const [isCertification, setIsCertification] = useState(false);

  const [isAgreeMarketing, setIsAgreeMarketing] = useState<number>(0);

  const [isModalPassword, setIsModalPassword] = useState<boolean>(false);
  const [isModalPhoneNumber, setIsModalPhoneNumber] = useState<boolean>(false);
  const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState({text: ''});
  const [isModalYesCloseOpen, setIsModalYesCloseOpen] = useState<boolean>(false);
	const [modalYesCloseContent, setModalYesCloseContent] = useState({
    text: '',
    confirmText: '',
    onConfirm: () => {}
  });
  const [isModalYesOpen, setIsModalYesOpen] = useState<boolean>(false);
  const [modalYesContent, setModalYesContent] = useState({
    text: '',
    confirmText: '',
    onConfirm: () => {},
  });
  const [isNewMarketingConsent, setIsNewMarketingConsent] = useState<boolean>(false);

  useEffect(() => {
   if (!passwordLength)  {
    handleChangeTab('check_password');
   } else {
    if (!customerID) return;
    getCustomerData(
      customerID,
    ).then(result => {
      setCustomerData(result[0]);
      setAsterisk('*'.repeat(passwordLength));
      setIsAgreeMarketing(result[0].customer_marketing_status);
    });
   }
  }, [passwordLength, customerID, handleChangeTab]);

  const closeChangePassword = () => {
    setIsModalPassword(false);
    setCurrentPassword('');
    setNewPassword('');
    setNewPassword2('');
  };

  const requestChangePassword = () => {
    if (
      currentPassword.length < 10 ||
      newPassword.length < 10 ||
      newPassword2.length < 10 ||
      !validatePassword(currentPassword) ||
      !validatePassword(newPassword)
    ) {
      setIsModalCloseOpen(true);
      setModalContent({text: "비밀번호를 형식에 맞게 입력해 주세요. (영문 / 숫자 / 특수문자 포함 이상 10~20자)"});
      return;
    }
    if (currentPassword === newPassword) {
      setIsModalCloseOpen(true);
      setModalContent({text: "현재 비밀번호와 다른 새로운 비밀번호를 입력해 주세요."});
      return;
    }
    if (newPassword !== newPassword2) {
      setIsModalCloseOpen(true);
      setModalContent({text: "새로운 비밀번호가 일치하지 않습니다."});
      return;
    }
    updatePassword(
      customerID,
      currentPassword,
      newPassword
    ).then(result => {
      if (result === 2) {
        setModalContent({
          text: "현재 비밀번호가 일치하지 않습니다."
        });
      } else if (result.changedRows === 1) {
        setModalContent({
          text: "비밀번호 변경이 완료되었습니다."
        });
        setAsterisk('*'.repeat(newPassword.length));
        closeChangePassword();
      } else {
        setModalContent({
          text: '알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.'
        });
      };
      setIsModalCloseOpen(true);
    });
  };

  // 전화번호 인증 => 인증번호 전송, 타이머 세팅
  const requestSendMobileMessage = () => {
    if (isCertification) return;
    if (!validatePhone(customerPhoneNumber)) {
      setIsModalCloseOpen(true);
      setModalContent({text: "전화번호를 정확히 입력해주세요."});
      return;
    } ;
    const phone = eliminateHyphen(customerPhoneNumber);
    const random_number = generateRandomCode(6);
    const message_data = `에이블랙 인증 번호 : [${random_number}] 10분 이내에 입력해주세요.`;

    setCertificationCode(random_number);
    sendMessage(phone, 1, message_data).then(result => {
      if (result === 1) {
        setIsModalCloseOpen(true);
        setModalContent({text: "인증번호가 발송 되었습니다."});
        setPhoneCertificationText('인증번호가 발송되었습니다. 인증번호를 받지 못하셨다면 입력한 번호를 확인해 주세요.');
        setCertificationTimer(true);
        setCountdown(600);
      } else {
        setIsModalCloseOpen(true);
        setModalContent({text: "인증번호 발송을 실패 했습니다. 다시 시도하거나 문의하여 주세요."});
      }
    })
  };

  // 타이머 동작
  useEffect(() => {
    let timer: any;
    if (certificationTimer) {
      timer = setInterval(handleClock, 1000);
    } else {
      clearInterval(timer);
    };
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificationTimer]);

  const handleClock = () => {
    setCountdown(props => props - 1);
  };

  // 타이머 시간 초과시 초기화 및 타이머 시간 출력
  useEffect(() => {
    if (countdown < 1) {
      setCertificationTimer(false);
      setCountdown(600);
      setPhoneCertificationText("인증시간이 초과되었습니다. 다시 인증해주세요.");
      return;
    }
    let min = Math.floor(countdown / 60);
    let sec = (countdown % 60);
    setRenderCountdown(`${min.toString()} : ${sec.toString()}`);
  }, [countdown]);

  // 인증번호 검사
  const checkCertification = () => {
    if (isCertification) return;
    if (codeInput.length < 6) {
      setPhoneCertificationText('인증번호 6자리를 입력해 주세요.');
    }
    if (codeInput === certificationCode) {
      updatePhoneNumber(
        customerID,
        customerPhoneNumber,
      ).then(result => {
        if (result.changedRows === 1) {
          setIsCertification(true);
          setCustomerData({
            ...customerData,
            customer_phone_number: customerPhoneNumber,
          });
          setModalContent({text: "핸드폰 번호가 변경 되었습니다."});
        } else {
          setModalContent({text: "알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다."});
        }
      });
      setIsModalCloseOpen(true);
    } else {
      setPhoneCertificationText('인증번호가 일치하지 않습니다.');
    }
  };

  // 인증완료 시 타이머 종료
  useEffect(() => {
    if (isCertification) {
      setCertificationTimer(false);
      setPhoneCertificationText('');
      setCustomerPhoneNumber('');
      setIsModalPhoneNumber(false);
    }
  }, [isCertification]);

  const requestInactiveAccount = () => {
    inactiveCustomerAccount(customerID).then(result => {
      if (result.changedRows === 1) {
        setModalYesContent({
          text: "회원탈퇴가 완료되었습니다.",
          confirmText: "확인",
          onConfirm: () => {
            handleLogout();
          }
        })
        setIsModalYesOpen(true);
      } else {
        setModalContent({text: '알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.'});
        setIsModalCloseOpen(true);
      }
    });
  };

  const handleLogout = () => {
		requestLogout().then(result => {
      localStorage.removeItem('customer_ID_encrypted');
      localStorage.removeItem('alc_logo_img');
      navigate('/');
		});
	};

  const requestUpdateCustomerProfile = () => {
    // TODO: 변경 된 것 있는지 없는지 같은거 validation 넣어야 함 & 변경되는 사항들 여기에 계속 추가
    updateCustomerProfile(
      customerID,
      isAgreeMarketing,
    ).then(result => {
      if (result.changedRows > 0) {
        setModalContent({text: "회원정보가 변경되었습니다."});
      } else {
        setModalContent({text: "알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다."});
      }
      setIsModalCloseOpen(true);
    })
  };

  return (
    <>
      <ModalClose
        isOpen={isModalCloseOpen}
        onClose={() => setIsModalCloseOpen(false)}
        text={modalContent.text}
      />
      <ModalYesClose
				isOpen={isModalYesCloseOpen} 
				onClose={() => setIsModalYesCloseOpen(false)}
				onConfirm={modalYesCloseContent.onConfirm}
				confirmText={modalYesCloseContent.confirmText}
				text={modalYesCloseContent.text}
			/>
      <ModalYes
        isOpen={isModalYesOpen}
        onConfirm={modalYesContent.onConfirm}
        confirmText={modalYesContent.confirmText}
        text={modalYesContent.text}
      />
      <SOL.MyPageComponentContainer style={{marginBottom: "145px"}}>
        <div className="text-box">
          <h3 className="title">회원정보 수정</h3>
        </div>

        <div className="horizontal" />

        <div className="text-box">
          <h5 className="sub-title">로그인 정보</h5>
          <div className="flex-text-box">
            <span className="label">아이디(이메일)</span>
            <span className="content">{customerData.customer_email}</span>
          </div>
          <div className="flex-text-box">
            <span className="label">비밀번호</span>
            {isModalPassword || (
              <>
                <span className="content lighter">{asterisk}</span>
                <button className="update-btn" onClick={() => setIsModalPassword(true)}>변경</button>
              </>
            )}
          </div>
        </div>

        {isModalPassword && (
          <SOL.ChangeProfileInputContainer>
            <div className="input-box">
              <input
                type="password"
                placeholder="현재 비밀번호를 입력해 주세요."
                maxLength={20}
                value={currentPassword}
                onChange={(e) => {
                  setCurrentPassword(removeWhiteSpace(e.target.value));
                }}
              />
            </div>
            <div className="description-box">영문 / 숫자 / 특수문자 포함 이상 10~20자</div>
            <div className="input-box">
              <input
                type="password"
                placeholder="새 비밀번호를 입력해 주세요."
                maxLength={20}
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(removeWhiteSpace(e.target.value));
                }}
              />
            </div>
            <div className="input-box">
              <input
                type="password"
                placeholder="새 비밀번호를 재입력해 주세요."
                maxLength={20}
                value={newPassword2}
                onChange={(e) => {
                  setNewPassword2(removeWhiteSpace(e.target.value));
                }}
              />
            </div>
            <div className="control-box">
              <button
                className="cancel"
                onClick={closeChangePassword}
              >
                변경 취소
              </button>
              <button onClick={requestChangePassword}>확인</button>
            </div>
          </SOL.ChangeProfileInputContainer>
        )}

        {customerData.alc_status === 0 && (
          <>
            <div className="horizontal" />

            <div className="text-box">
              <h5 className="sub-title">회원 정보</h5>
              <div className="flex-text-box">
                <span className="label">회원승인</span>
                <span className="content">{customerData.alc_complex_name}</span>
              </div>
              <div className="flex-text-box">
                <span className="label">이름</span>
                <span className="content">{customerData.customer_name}</span>
              </div>
              <div className="flex-text-box">
                <span className="label">성별</span>
                <span className="content">{customerData.customer_gender}</span>
              </div>
              <div className="flex-text-box">
                <span className="label">생년월일</span>
                <span className="content font-pretendard">{customerData.customer_birthday}</span>
              </div>
              <div className="flex-text-box">
                <span className="label">주소</span>
                <span className="content font-pretendard multi-line">{customerData.customer_address}</span>
              </div>
              <div className="flex-text-box">
                <span className="label" />
                <span className="content font-pretendard multi-line red-notice">
                  *승인된 회원으로 주소 변경이 불가합니다.
                </span>
              </div>
              <div className="flex-text-box mb-5">
                <span className="label" />
                <span className="content font-pretendard multi-line red-notice">
                  주소 변경은 고객센터에 문의해 주세요. 070-4286-3556
                </span>
              </div>
              <div className="flex-text-box">
                <span className="label">핸드폰 번호</span>
                <span className="content lighter font-pretendard">{customerData.customer_phone_number}</span>
                <button className="update-btn" onClick={() => setIsModalPhoneNumber(true)}>변경</button>
              </div>
            </div>

            {isModalPhoneNumber && (
              <SOL.ChangeProfileInputContainer>
                <div className="input-box">
                  <input
                    type="text"
                    className="phone-number"
                    placeholder="변경할 휴대폰 번호를 입력해 주세요."
                    maxLength={13}
                    value={customerPhoneNumber}
                    onChange={(e) => {
                      setCustomerPhoneNumber(removeWhitespacePhone(e.target.value));
                    }}
                    readOnly={(isCertification || certificationTimer)}
                  />
                  <button
                    onClick={() => {
                      requestSendMobileMessage();
                    }}
                  >
                    인증번호
                  </button>
                </div>
                {(isCertification || certificationTimer) && (
                  <div className="input-box pb-[10px]">
                    <input
                      type="text"
                      className="phone-number"
                      placeholder="인증번호를 입력해 주세요."
                      maxLength={6}
                      value={codeInput}
                      onChange={(e) => {
                        setCodeInput(onlyNumber(e.target.value));
                      }}
                      readOnly={isCertification}
                    />
                    <button
                      onClick={checkCertification}
                    >
                      전화번호 변경
                    </button>
                    {certificationTimer && <span className="count-down">{renderCountdown}</span>}
                    <p className="input-error">{phoneCertificationText}</p>
                  </div>
                )}
              </SOL.ChangeProfileInputContainer>
            )}

            <div className="horizontal" />

            <div className="text-box">
              <h5 className="sub-title">마케팅 활용 동의</h5>
              <div className="flex-text-box terms">
                <button onClick={() => {
                  if (isAgreeMarketing === 0) {
                    setIsAgreeMarketing(1);
                  } else {
                    setIsAgreeMarketing(0);
                  }
                }}>
                  <img src={!isAgreeMarketing ? CheckBlank : CheckFill} alt="check-icon" />
                  <span className="">(선택) 이메일, SMS 수신 동의</span>
                </button>
                <button
                  className="terms-btn"
                  onClick={() => setIsNewMarketingConsent(true)}
                >
                  약관보기
                </button>
              </div>
            </div>

            <div className="horizontal" />

            <div className="quit-box">
              <button
                onClick={() => {
                  setIsModalYesCloseOpen(true);
                  setModalYesCloseContent({
                    text: "탈퇴 하시겠습니까?",
                    confirmText: "회원탈퇴",
                    onConfirm: () => {
                      requestInactiveAccount();
                    }
                  });
                }}
              >
                회원탈퇴
              </button>
            </div>
            <div className="save-box">
              <button
                onClick={() => {
                  setIsModalYesCloseOpen(true);
                  setModalYesCloseContent({
                    text: "변경된 사항을 저장하시겠습니까?",
                    confirmText: "저장",
                    onConfirm: () => {
                      setIsModalYesCloseOpen(false);
                      requestUpdateCustomerProfile();
                    }
                  });
                }}
              >
                저장하기
              </button>
            </div>
          </>
        )}

      </SOL.MyPageComponentContainer>
      {isNewMarketingConsent && ( <MarketingConsent onUnload={() => setIsNewMarketingConsent(false)} /> )}
    </>
  )
};

export default UpdateProfile;