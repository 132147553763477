import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCheckSession from '../../hooks/useCheckSession';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import OrderSuccess from '../../layout/Order/OrderSuccess';

const OrderSuccessPage: FC = () => {
	useCheckSession();
	const navigate = useNavigate();
	const location = useLocation();
	const [access, setAccess] = useState<boolean>(false);

	useEffect(() => {
		if (!location.state) {
			navigate("/home");
		} else {
			setAccess(true);
		}
	}, [location.state, navigate]);
	
	return (
		<PageWrapper title={'주문성공'} >
			{access ? <OrderSuccess /> : <div className='w-[100%] h-[600px] bg-main_white'></div>}
		</PageWrapper>
	);
};

OrderSuccessPage.displayName = 'OrderSuccessPage';

export default OrderSuccessPage;
