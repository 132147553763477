import React, { useEffect, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { useLocation, useNavigate } from "react-router-dom";
import HomeOn from "../../assets/mobile_nav_icons/home_on.svg";
import HomeOff from "../../assets/mobile_nav_icons/home_off.svg";
import BrandOn from "../../assets/mobile_nav_icons/brand_on.svg";
import BrandOff from "../../assets/mobile_nav_icons/brand_off.svg";
import BagOn from "../../assets/mobile_nav_icons/bag_on.svg";
import BagOff from "../../assets/mobile_nav_icons/bag_off.svg";
import MyOn from "../../assets/mobile_nav_icons/my_on.svg";
import MyOff from "../../assets/mobile_nav_icons/my_off.svg";
import { handleScrollTopPosition } from "../../Utils";
import { useCartContext } from "../../context/CartContext";

const MobileNav: React.FC = () => {
  const navigate = useNavigate();
  const locationHook = useLocation();
  const [currentLastUrl, setCurrentLastUrl] = useState<string | null>('');
  const { cartInfo } = useCartContext();

  useEffect(() => {
    const splitUrl = locationHook?.pathname?.split('/') ?? null;
    const location: string | null = splitUrl?.length > 1 ? splitUrl[splitUrl.length -1] : null;
    setCurrentLastUrl(location);
  }, [currentLastUrl, locationHook]);

  const handleTab = (pathname: string) => {
    navigate(pathname);
    handleScrollTopPosition(0);
  }

  return (
    <SOL.MobileNavContainer>
      <button
        onClick={() => handleTab("/home")}
        className={`mobile-nav-tab`}
      >
        <div className="icon-image-box">
          <img src={currentLastUrl === "home" ? HomeOn : HomeOff} alt="home_tab" />
        </div>
        <div className={`tab-name ${currentLastUrl === "home" && "active"}`}>
          HOME
        </div>
      </button>
      <button
        onClick={() => handleTab("/product")}
        className={`mobile-nav-tab`}
      >
        <div className="icon-image-box">
          <img src={currentLastUrl === "product" ? BrandOn : BrandOff} alt="brand_tab" />
        </div>
        <div className={`tab-name ${currentLastUrl === "product" && "active"}`}>
          BRAND
        </div>
      </button>
      <button
        onClick={() => handleTab("/cart")}
        className={`mobile-nav-tab`}
      >
        <div className="icon-image-box">
          <div className={`cart-badge ${cartInfo.cartCount === 0 && "cart-zero"}`}>
            {cartInfo.cartCount > 0 && cartInfo.cartCount}
          </div>
          <img src={currentLastUrl === "cart" ? BagOn : BagOff} alt="cart_tab" />
        </div>
        <div className={`tab-name ${currentLastUrl === "cart" && "active"}`}>
          BASKET
        </div>
      </button>
      <button
        onClick={() => {
          navigate("/mypage", {state: {tab_name: ''}});
          handleScrollTopPosition(0);
        }}
        className={`mobile-nav-tab`}
      >
        <div className="icon-image-box">
          <img src={currentLastUrl === "mypage" ? MyOn : MyOff} alt="my_tab" />
        </div>
        <div className={`tab-name ${currentLastUrl === "mypage" && "active"}`}>
          MY
        </div>
      </button>
    </SOL.MobileNavContainer>
  )
};

export default MobileNav;