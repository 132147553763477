import styled from "styled-components";
import theme from "../theme";


const xs = theme.screens.xs;
const sm = theme.screens.sm;
const md = theme.screens.md;
const lg = theme.screens.lg;
const xl = theme.screens.xl;

// 프로모션 컴포넌트 CSS 모음
export const MainTopBannerContainer = styled.div`
    position: fixed;
    margin-top: 80px;
    z-index: 999;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: ${theme.colors.main_white};
    cursor: pointer;
    box-shadow: 0px -3px 20px 0px ${theme.colors.black_dark_grey};
    animation-duration: .5s;
    animation-name: bottomMove;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    @keyframes bottomMove {
      from {margin-top:-80px;}
      to {margin-top:80px;}
    }
    @media ${lg} {
      margin-top: 60px;
      @keyframes bottomMove {
      from {margin-top:-60px;}
      to {margin-top:60px;}
      }
    }
    @media ${sm} {
      margin-top: initial;
      padding-top: 5px;
      height: 60px;
      line-height: 25px;
      font-size: 14px;
      @keyframes bottomMove {
      from {margin-top:-60px;}
      to {margin-top:0px;}
      }
    }
    & .TopBannerContainer {
        text-align: center;
        color: ${theme.colors.black_dark_grey};
        & b {
            color: ${theme.colors.point_yellow};
        }
        & span {
            @media ${sm} {
                display: block;
            }
        }
    }
    & .TopBanner_VideoBackground {
        width: 100%;
        height: 100vh;
        background-color: ${theme.colors.opacityBlack};
        z-index: 1;
        position: fixed;
        transform: 0.3s;
        @media ${xl} {
            display: none;
        }
        & .TopBanner_VideoContainer {
          position: absolute;
          width: 60%;
          aspect-ratio: 1 / 0.563;
          top: 40%;
          left: 50%;
          transform: translate(-50%,-50%);
          & iframe {
            width: 100%;
            height: 100%;
          }
          & .cancel {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            & svg {
              pointer-events: initial;
              color: ${theme.colors.main_white};
              position: absolute;
              z-index: 2;
              top: 30px;
              right: 30px;
              width: 30px;
              height: 30px;
            }
          }
        }
    }
`;

export const MainQuickMenuContainer = styled.div`
    max-width: 1200px;
    margin: 50px auto;
    & .MainQuickMenuContainer {
        & ul {
            display: flex;
            justify-content: space-around;
            width: 100%;
            @media ${sm} {
                flex-wrap: wrap;
            }
            & li {
                padding: 1.5vw;
                text-align: center;
                aspect-ratio: 1 / 1;
                background-color: ${theme.colors.main_white};
                @media ${sm} {
                    width: 25%;
                }
                &:hover {
                    & img {
                        scale: 1.12;
                        @media ${md} {
                            scale: 1;
                        }
                    }
                }
                & span {
                    margin-top: 15px;
                    pointer-events: none;
                    color: ${theme.colors.black_dark_grey};
                    width: 100%;
                    display: block;
                    font-size: 15px;
                    line-height: 20px;
                    @media ${sm} {
                        font-size: 13px;
                        line-height: 17px;
                    }
                }
                & img {
                    width: 100%;
                    transition: 0.3s;
                    max-width: 120px;
                    margin: 0 auto;
                }
            }
        }
    }
`;

export const PromotionBannerContainer = styled.div`
  /* 프로모션 페이지용 */
  & .PromotionPage_text-box, .PromotionPage_small-products {
    display: none;
  }
  /* 제품 정보 텍스트 공통 */
  & .Promotion_product_info {
    color: ${theme.colors.main_white};
      & .Promotion_product-brand {
        font-size: 12px;
        margin-bottom: 5px;
      }
      & .Promotion_product-color {
        font-size: 12px;
        margin-top: 7px;
        color: ${theme.colors.light_grey};
        @media ${xs} {
          display: none;
        }
      }
      & .Promotion_product-name {
        padding-top: 5px;
        font-size: 14px;
        @media ${md} {
            font-size: 12px;
        }
      }
      & .Promotion_product-Price {
        width: 100%;
        padding-top: 3px;
        font-size: 16px;
        @media ${sm} {
          padding-block: 3px;
        }
        & svg {
          display: inline;
          margin-bottom: 3px;
        }
        &.original-Price {
          text-decoration:line-through;
          font-size: 12px;
          font-weight: lighter;
        }
        &.rate-vat {
          font-size: 12px;
          color: ${theme.colors.mid_grey};
        }
      }
  }

  & .MainPromotionContainer {
    max-width: 1200px;
    margin: 50px auto 100px;
    display: flex;
    gap: 10px;
    @media ${sm} {
      flex-direction: column;
      gap: initial;
    }
    & .MainPromotion_img {
        width: 50%;
        aspect-ratio: 3/4;
        position: relative;
        cursor: pointer;
        @media ${sm} {
          width: 100%;
          aspect-ratio: 1/1;
          position: absolute;
        }
        & img {
          height: 100%;
          object-fit: cover;
        }
    }
    & .MainPromotion_box {
      width: 50%;
      display: flex;
      flex-direction: column;
      position: relative;
      @media ${sm} {
        width: 100%;
      }
      & .MainPromotion_text-box {
        padding-block: 20px;
        @media ${sm} {
          display: grid;
          place-content: center;
          width: 100%;
          height: 100vw;
          color: ${theme.colors.main_white};
          text-align: center;
        }
        & .MainPromotion_title {
          font-size: 25px;
          font-weight: 500;
          color: ${theme.colors.main_black};
          @media ${sm} {
            color: ${theme.colors.main_white};
            font-size: 25px;
          }
          @media ${xs} {
            font-size: 17px;
          }
        }
        & .MainPromotion_description {
          font-size: 15px;
          font-weight: 300;
          line-height: 20px;
          padding-top: 10px;
          @media ${sm} {
            font-size: 17px;
            line-height: 25px;
          }
          @media ${xs} {
            font-size: 15px;
            line-height: 20px;
            display: block;
          }
        }
      }
      & .MainPromotion_products {
          display: flex;
          gap: 10px;
          cursor: pointer;
          @media ${sm} {
            padding-inline: 5px;
            gap: 5px;
            order: 3;
            margin-top:10px;
          }
          & .MainPromotion_product {
              width: 50%;
              aspect-ratio: 3 / 4;
              position: relative;
              & .MainPromotion_product_img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transition: 0.3s;
              }
              & .MainPromotion_product_info {
              position: absolute;
              left: 15px;
              bottom: 15px;
              }
          }
      }
      & .MainPromotion_small-products {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        height: 100%;
        border-top: 1px solid ${theme.colors.light_grey};
        cursor: pointer;
        @media ${sm} {
          flex-direction:initial;
          gap: 0px;
          height: initial;
          border-bottom: 1px solid ${theme.colors.light_grey};
          padding-block: 20px;
        }
        & .MainPromotion_small-product {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 10px;
          border-bottom: 1px solid ${theme.colors.light_grey};
          transition: 0.3s;
          @media ${sm} {
            flex-direction: column;
            text-align: center;
            border-bottom: none;
          }
          &:hover {
            background-color: ${theme.colors.opacityBlack2};
            @media ${sm} {
              background-color: transparent;
            }
          }
          & .MainPromotion_small-product_img {
            max-width: 120px;
            min-height: 155px;
            background-color: ${theme.colors.main_white};
            overflow: hidden;
            display: grid;
            place-content: center;
            z-index: -1;
          }
          & img {
            width: 100%;
            height: 100%;
            transform: scale(1.1);
            @media ${sm} {
              width: 100%;
              aspect-ratio: 3/4;
            }
          }
          & .Promotion_small-product_info {
          color: ${theme.colors.main_black};
            & .Promotion_small-product-brand {
              color: ${theme.colors.main_black};
            }
            & .Promotion_small-product-name {
              color: ${theme.colors.main_black};
            }
            & .Promotion_small-product-color {
              color: ${theme.colors.dark_grey};
            }
            & .Promotion_small-product-Price {
              color: ${theme.colors.main_black};
              & svg {
              }
              & .Promotion_small-product-original-Price {
              color: ${theme.colors.dark_grey};
              }
              & .Promotion_small-product-rate-vat {
              color: ${theme.colors.dark_grey};
              }
            }
          }
        }
      }
    }
  }

  & .SubPromotionContainer {
    margin: 50px auto 100px;
    max-width: 1200px;
    & .SubPromotion_text-box {
      width: 100%;
      text-align: center;
      padding-block: 20px;
      & .SubPromotion_title {
        font-size: 25px;
        font-weight: 500;
        color: ${theme.colors.main_black};
        @media ${md} {
          font-size: 20px;
        }
        @media ${sm} {
          font-size: 25px;
        }
        @media ${xs} {
          font-size: 17px;
        }
      }
      & .SubPromotion_description {
        font-size: 17px;
        line-height: 25px;
        padding-block: 10px;
        font-weight: 300;
        color: ${theme.colors.black_dark_grey};
        @media ${sm} {
          font-size: 15px;
          line-height: 25px;
        }
      }
    }
    & .SubPromotion_products {
      display: flex;
      gap: 10px;
      @media ${sm} {
        gap: 15px;
        order: 3;
        margin-top:10px;
        flex-direction: column;
      }
      & .SubPromotion_product {
        width: 100%;
        aspect-ratio: 3 / 4;
        position: relative;
        overflow: hidden;
        &:hover{
          & .SubPromotion_product_img{
            transition: 0.3s;
            scale: 1.1;
            position: relative;
            width: 100%;
          }
        }
        @media ${sm} {
          width: 100%;
          aspect-ratio: 1 / 1;
        }
        & .SubPromotion_product_img {
          width: 100%;
          transition: 0.3s;
        }
        & .SubPromotion_product_info {
          position: absolute;
          left: 15px;
          bottom: 15px;
        }
      }
    }
  } 
`;

export const EventBannerContainer = styled.div`
  margin: 50px auto 0;
  width: 100%;
  max-width: 1200px;
  max-height: 300px;
  background-color: ${theme.colors.light_grey};
  & .EventBanner {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    &:hover {
      & .EventBanner_description-LinkText {
        margin-left: 10px;
        @media ${sm} {
          margin-left: initial;
        }
      }
    }
    & img {
    width: 100%;
    height: 100%;
    pointer-events: none;
    @media ${sm} {
    }
    }
    & .EventBanner_text-box {
      position: absolute;
      bottom: 20px;
      left: 20px;
      color: ${theme.colors.main_white};
      @media ${sm} {
        top: 50%;
        bottom: initial;
        transform: translateY(-50%);
      }
      & .EventBanner_title {
        font-size: 25px;
        @media ${xs} {
          font-size: 18px;
        }
      }
      & .EventBanner_description {
        font-size: 14px;
        font-weight: lighter;
        line-height: 20px;
        padding-block: 10px;
      }
      & .EventBanner_description-LinkText {
        font-size: 17px;
        transition: 0.3s;
        & svg {
          display: inline;
          margin-bottom: 3px;
        }
      }
    }
  }
`;

// 브랜드 페이지 프로모션
export const BrandPromotionContainer = styled.div `
  /* display: none; */
  & .BrandPromotion_text-box {
    text-align: center;
    color: ${theme.colors.black_dark_grey};
    & .BrandPromotion_title {
      font-size: 27px;
      font-weight: 500;
      @media ${sm} {
        margin-top: 20px;
        font-size: 18px;
      }
    }
    & .BrandPromotion_description {
      font-size: 15px;
      line-height: 20px;
      margin: 20px 0 45px;
      & p {
        @media ${xs} {
          font-size: 14px;
          line-height: 20px;
          display: block;
        }
        & span {
          @media ${xs} {
            display: block;
          }
        }
      }
    }
  }
  & .BrandPromotion_products {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    @media ${xs} {
      flex-direction: column;
      gap: 50px;
    }
    & .BrandPromotion_product {
      width: calc((100% - 20px)/2);
      aspect-ratio: 1 / 1;
      position: relative;
      cursor: pointer;
      @media ${xs} {
        width: 100vw;
      }
      & .BrandPromotion_product_img {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
      }
      & .Promotion_product_info {
        color: ${theme.colors.black_dark_grey};
        background-color: ${theme.colors.main_white};
        padding: 10px;
        & .Promotion_product-brand {
          font-size: 12px;
          margin-bottom: 5px;
        }
        & .Promotion_product-color {
          font-size: 12px;
          margin-top: 7px;
          color: ${theme.colors.dark_grey};
          @media ${xs} {
            display: none;
          }
        }
        & .Promotion_product-name {
          padding-top: 5px;
          font-size: 14px;
          @media ${md} {
              font-size: 12px;
          }
        }
        & .Promotion_product-Price {
          width: 100%;
          padding-top: 3px;
          font-size: 16px;
          @media ${sm} {
            padding-block: 3px;
          }
          & svg {
            display: inline;
            margin-bottom: 3px;
          }
          &.original-Price {
            text-decoration:line-through;
            font-size: 12px;
            font-weight: lighter;
          }
          &.rate-vat {
            font-size: 12px;
            color: ${theme.colors.mid_grey};
          }
        }
      }
    }
  }
  & .BrandPromotion_ablack-Banner {
    margin-bottom: 50px;
    cursor: pointer;
    & img {
      width: 100%;
    }
  }
`;

// 프로모션 페이지
export const PromotionPageContainer = styled.div `
  margin: 80px auto 80px;
  @media ${lg} {
    margin: 60px auto 80px;
  }
  & .PromotionPage_Banner {
    position: relative;
    & img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      filter: brightness(75%);
    }
    & p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-weight: 600;
      font-size: 35px;
      margin-bottom: 10px;
      text-align: center;
      color: ${theme.colors.main_white};
      width: 100%;
    }
  }
  & .PromotionPage_Banner_line {
    width: 1px;
    height: 100px;
    background-color: ${theme.colors.mid_grey};
    margin: 50px auto 0;
  }
  & .PromotionPage_text-box {
    display: block;
    text-align: center;
    padding: 50px 0 70px 0;
    @media ${xs} {
      padding-block: 50px;
    }
    & .PromotionPage_date {
      color: ${theme.colors.black_dark_grey};
      font-size: 18px;
      opacity: 30%;
    }
    & .PromotionPage_title {
      padding-top: 10px;
      font-size: 35px;
      font-weight: 500;
      color: ${theme.colors.main_black};
      @media ${sm} {
        font-size: 25px;
      }
    }
    & .PromotionPage_descripiton {
      font-size: 17px;
      font-weight: lighter;
      line-height: 25px;
      padding-block: 30px;
      & svg {
        margin: 20px auto;
        color: ${theme.colors.black_dark_grey};
      }
      & p {
        @media ${xs} {
          font-size: 14px;
          line-height: 20px;
          margin-top: 5px;
          display: block;
        }
      }
    }
  }
  & .MainPromotionContainer {
    position: relative;
    margin: 0 auto;
    @media ${sm} {
      display: block;
    }
    & .MainPromotion_img {
      position: relative;
      aspect-ratio: initial;
      & img {
        height: initial;
      }
    }
    & .MainPromotion_box {
      @media ${sm} {
        width: 100%;
        overflow: hidden;
      }
      & .MainPromotion_text-box {
        display: none;
      }
      & .MainPromotion_products {
        width: 100%;
        height: 100%;
        padding-inline: initial;
        flex-direction: column;
        gap: 10px;
        & .MainPromotion_product {
          width: 100%;
          height: 50%;
          transition: 0.3s;
          aspect-ratio: initial;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          gap: 10px;
          @media ${sm} {
            height: initial;
          }
          &:first-child {
            & .MainPromotion_product_info {
              text-align: right;
            }
          }
          &:last-child {
            flex-direction: row-reverse;
          }
          & .MainPromotion_product_img {
            width: 63%;
            height: initial;
            aspect-ratio: 3/4;
            position: relative;
            @media ${sm} {
              width: 60%;
            }
          }
          & .MainPromotion_product_info {
            position: relative;
            top: 0;
            left: 0;
            bottom: initial;
            color: ${theme.colors.main_black};
            padding: 10px;
            & .Promotion_product-color, .original-Price {
              color: ${theme.colors.dark_grey};
              display:block;
            }
          }
        }
      }
      & .MainPromotion_small-products {
        display: none;
      }
    }
  }
  & .PromotionPage_small-products {
    max-width: 1200px;
    width: 100%;
    margin: 100px auto;
    display: flex;
    justify-content: space-around;
    position: relative;
    & .PromotionPage_title {
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 25px;
      font-weight: 500;
      color: ${theme.colors.main_black};
    }
    & .MainPromotion_small-product {
      cursor: pointer;
      margin-top: 50px;
      width: 25%;
      border-bottom: 1px solid ${theme.colors.light_grey};
      padding-block: 20px;
      @media ${xs} {
        border-bottom: initial;
      }
      & img {
        width: 100%;
      }
      & .Promotion_product_info {
        color: ${theme.colors.main_black};
        & .Promotion_product-color, .original-Price {
          color: ${theme.colors.dark_grey};
        }
        & .Promotion_small-product-Price {
          @media ${xs} {
            font-size: 14px;
          }
        }
      }
    }

  }
  & .SubPromotionContainer {
    & .SubPromotion_text-box {
      margin: 100px 0 20px;
    }
  }
  & .EventBanner {
    margin: 100px auto;
  }
`;
export const PromotionList = styled.div `
  margin: 80px auto 80px;
  @media ${lg} {
    margin: 60px auto 80px;
  }
  & .PromotionPage_Banner {
    position: relative;
    & img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      filter: brightness(75%);
    }
    & p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-weight: 600;
      font-size: 35px;
      margin-bottom: 10px;
      text-align: center;
      color: ${theme.colors.main_white};
      width: 100%;
    }
  }
  & .Promotion_grid {
    margin: 100px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 90%;
    max-width: 1200px;
    @media ${md} {
      grid-template-columns: repeat(2, 1fr);
    }
    & .Promotion_gridCard {
      width: 100%;
      margin-bottom: 30px;
      overflow: hidden;
      & .Promotion_gridCard_imgBox {
        & img {
          width: 100%;
          aspect-ratio: 1 / 1 ;
          position: relative;
        }
      }
      & .Promotion_gridCard_textBox {
        padding-top: 10px;
        & .Promotion_gridCard_date {
          display: flex;
          justify-content: space-between;
          color: ${theme.colors.mid_grey};
          & .promotion-Active {
            color: ${theme.colors.point_red};
          }
          & .promotion-Ended {
            color: ${theme.colors.mid_grey};
          }
        }
        & h2 {
          padding-block: 10px;
          font-size: 18px;
          font-weight: 500;
          color: ${theme.colors.black_dark_grey};
        }
      }
    }
  }
`;

// 서비스 안내 
export const ServicePageContainer = styled.div `
  margin: 80px auto 0;
  @media ${lg} {
    margin: 60px auto 0;
  }
  & .ServicePage_container {
    width: 100%;
    max-width: 1200px;
    margin: 100px auto 0;
    & .ServicePage_title {
      font-weight: 600;
      font-size: 35px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  & .ServicePage_Banner_container {
    position: relative;
    & .ServicePage_Banner_background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 500px;
      object-fit: cover;
      @media ${xs} {
        filter: brightness(75%);
      }
    }
    & .ServicePage_Banner_Text-box {
      margin: 0 auto;
      max-width: 1200px;
      height: 500px;
      padding: 50px 0 0 50px;
      color: ${theme.colors.main_black};
      font-size: 20px;
      font-weight: 500;
      
      & p {
        pointer-events: none;
      }
      & .ServicePage_Banner_logo {
        width: 250px;
        margin-bottom: 15px;
      }
      & .ServicePage_Banner_AppDownload-box {
        margin-top: 30px;
        & .ServicePage_Banner_AppDownload-btn {
          & svg {
            margin: 20px 10px;
            width: 40px;
            height: 40px;
            &:hover {
              & path {
                color: ${theme.colors.black_dark_grey};
                @media ${xs} {
                  color: initial;
                }
              }
            }
          }
        }
        & .none {
          display: none;
        }
      }
      & .ServicePage_Banner_B2B-btn {
        padding: 15px;
        background-color: ${theme.colors.main_black};
        border-radius: 10px;
        transition: 0.3s;
        margin-top: 30px;
        & a {
          color: ${theme.colors.main_white};
        }
        &:hover {
          background-color: ${theme.colors.main_white};
          & a {
            color: ${theme.colors.main_black};
          }
        }
      }
      & .none {
        display: none;
      }
    }
  }
  & .ServicePage_APpoint {
    background-color: ${theme.colors.light_grey};
    text-align: center;
    padding-block: 30px;
    width: 90%;
    max-width: 800px;
    border-radius: 15px;
    @media ${xs} {
      width: 100%;
      border-radius: initial;
      margin-top: 100px;
    }
    & p {
      line-height: 23px;
      font-weight: 300;
      color: ${theme.colors.black_dark_grey};
      @media ${xs} {
        font-size: 14px;
        line-height: 23px;
        & span {
          display: block;
        }
      }
    }
  }
  & .ServicePage_AppFunction {
    & .ServicePage_AppFunction_box {
      margin-block: 50px;
      display: flex;
      justify-content: center;
      gap: 10vw;
      height: 600px;
      @media ${sm} {
        height: 500px;
        flex-direction: column;
      }
      & img {
        @media ${sm} {
          height: 80%;
          object-fit: contain;
        }
      }
      & ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media ${sm} {
          flex-direction: initial;
        }
        & .ServicePage_AppFunction_icon {
          display: flex;
          gap: 10px;
          align-items: center;
          padding: 10px 20px;
          @media ${sm} {
            gap: 5px;
            padding-inline: 15px;
            flex-direction: column;
            font-size: 13px;
            &:not(:last-child) {
              border-right: 1px solid ${theme.colors.light_grey};
            }
          }
          & .ServicePage_Appfuntion_icon-box {
            width: 50px;
            height: 50px;
            padding: 5px;
            color: ${theme.colors.black_dark_grey};
            & svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  & .ServicePage_ablack-Information {
    & iframe {
      margin: 50px auto;
      width: 100%;
      aspect-ratio: 1.778/1;
    }
    & ul {
      display: flex;
      flex-direction: column;
      margin-block: 50px;
      gap: 50px;
      & .ServicePage_ablack-Information_list { 
        display: flex;
        align-items: end;
        @media ${sm} {
          flex-direction: column;
          align-items: flex-start;
          width: 90%;
          margin: 0 auto;
        }
        & img {
          width: 50%;
          @media ${sm} {
            width: 100%;
          }
        }
        & .ServicePage_ablack-Information_list_text-box {
          padding-inline: 20px;
          margin-top: 10px;
          @media ${sm} {
            padding-inline: initial;
          }
          & .ServicePage_ablack-Information_list_number {
            font-size: 50px;
            font-weight: bold;
            color: ${theme.colors.black_dark_grey};
            margin-bottom: 10px;
            @media ${xs} {
              display: none;
            }
          }
          & .ServicePage_ablack-Information_list_title {
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;

            & span {
              display: block;
              @media ${sm} {
                display: inline;
              }
            }
          }
          & .ServicePage_ablack-Information_list_description {
            margin-top: 20px;
            font-size: 15px;
            line-height: 20px;
            @media ${sm} {
              font-size: 14px;
            }
            & b {
              font-weight: bold;
            }
          }
        }
      }
    }

  }
`;

// 모달
export const ModalContainer = styled.div`
  position: fixed;
  z-index: 990;
  @media ${xs} {
    bottom: 60px;
  }
  & .Modal_background {
    width: 100vw;
    height: 100vh;
    background-color: ${theme.colors.opacityBlack};
  }
  & .Modal {
    position: absolute;
    width: 500px;
    height: 500px;
    top: 55%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: 1s;
    animation-name: opacityModal;
    animation-duration: 1s;
    @keyframes opacityModal {
      from {opacity:0; transform: translate(-50%,50%);}
      to {opacity:1; transform: translate(-50%,-50%);}
    }
    &.move {
      transform: translate(-50%, 20%);
      opacity: 0;
    }
    @media ${sm} {
      top: 45%;
    }
    @media ${xs} {
      width: 100%;
      height: initial;
      aspect-ratio: 1 / 1;
      top: initial;
      left: initial;
      bottom: 0;
      transform: initial;
      @keyframes opacityModal {
      from {opacity:0; transform: translateY(50%);}
      to {opacity:1; transform: translateY(0);}
      }
      &.move {
        transform: translate(0, 50%);
      }
    } 
    & .Modal_img {
      height: calc(100%);
      & img {
        width: 100%;
        object-fit: container;
      }
      overflow: hidden;
    }
    & .Modal_button {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: ${theme.colors.main_white};
      color: ${theme.colors.main_black};
      @media ${xs} {
        font-size: 14px;
      }
      & button {
        width: 50%;
        &:first-child {
          border-right: 1px solid ${theme.colors.dark_grey};
        }
      }
      & svg {
        display: inline;
        margin-bottom: 2px;
        font-size: 25px;
        @media ${xs} {
          font-size: 18px;
        }
      }
    }
  }
`;