import React, { FC, useEffect, useState } from 'react';
import useCheckSession from '../../hooks/useCheckSession';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Cart from '../../layout/Cart/Cart';
import MobileNav from '../../layout/Header/MobileNav';
import RecentProduct from '../../layout/Recent/RecentProduct';
import { getSessionData } from '../../Axios';
import { handleScrollTopPosition } from '../../Utils';


const CartPage: FC = () => {
	const [customerID, setCustomerID] = useState(0);
	const [customerAlcStatus, setCustomerAlcStatus] = useState<number>(2);

	useCheckSession();

	useEffect(() => {
		handleScrollTopPosition(0);
		getSessionData().then(result => {
			if (result === 2) {
				
			} else {
				setCustomerID(result.alc_customer_ID)
				setCustomerAlcStatus(result.alc_status)
			}
		});
	}, []);
	
	return (
		<PageWrapper title={'장바구니'} >
			<Cart
				customerID={customerID}
				customerAlcStatus={customerAlcStatus}
			/>
			<div className='md:px-[15px] sm:px-[15px] xs:px-0'>
				<RecentProduct
					customerAlcStatus={customerAlcStatus}
				/>
			</div>
				<MobileNav />
		</PageWrapper>
	);
};
CartPage.displayName = 'CartPage';
export default CartPage;
