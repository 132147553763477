import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Reset } from 'styled-reset';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import './styles/styles.scss';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
      <ThemeProvider theme={theme}>
        <Reset />
        <App />
      </ThemeProvider>
  </Router>
);

reportWebVitals();
