import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { getOneDetailOrderData, refundAlcOrder, requestCancelBankTransferDetailOrderOne, requestExchangeDetailOrderOne, requestReturnDetailOrderOne } from "../../Axios";
import { cancelReasonSelectList, exchangeReasonSelectList, returnReasonSelectList } from "../../modules/Variable";
import IconArrow from "../../assets/icons/arrow_fold_40.svg";
import ModalLoading from "../Modal/ModalLoading";
import ModalClose from "../Modal/ModalClose";
import ModalYes from "../Modal/ModalYes";

interface IDetailOrderData {
  alc_order_ID: number;
  imp_uid: string;
  alc_order_pay_status: number;
  alc_order_method: string;
  alc_detail_order_ID: number
  alc_detail_cancel_reason: number;
  alc_detail_construction_complete_date: string;
  alc_detail_delivery_complete_date: string;
  alc_detail_exchange_status: number;
  alc_detail_exchange_status_name: string;
  alc_detail_order_delivery_company: number;
  alc_detail_order_delivery_number: number;
  alc_detail_order_delivery_progress: number
  alc_detail_order_modified: string;
  alc_detail_order_quantity: number;
  alc_detail_order_refund: number
  alc_detail_order_refund_date: string;
  alc_detail_order_refund_status: number;
  alc_detail_order_registe: string;
  alc_detail_order_sell_price: number;
  alc_detail_order_sell_price_original: number;
  alc_detail_reason_direct: string;
  alc_detail_return_status: number;
  alc_detail_return_status_name: string;
  alc_detail_status: number
  alc_order_number_use_alc_order_detail: string;
  brand_eng_name: string;
  brand_kor_name: string;
  color_ID_use_alc_order_detail: number;
  color_b2c_image: string; 
  color_name: string;
  product_kor_name: string;
};

interface IOneCancelOrderProps {
  customerID: number;
  handleSelectOneOrderID: (alc_order_ID: number) => void;
}

const OneCancelOrder: FC<IOneCancelOrderProps> = ({customerID, handleSelectOneOrderID}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [detailOrderData, setDetailOrderData] = useState<IDetailOrderData>({
    alc_order_ID: 0,
    imp_uid: '',
    alc_order_pay_status: 0,
    alc_order_method: '',
    alc_detail_order_ID: 0,
    alc_detail_cancel_reason: 0,
    alc_detail_construction_complete_date: '',
    alc_detail_delivery_complete_date: '',
    alc_detail_exchange_status: 0,
    alc_detail_exchange_status_name: '',
    alc_detail_order_delivery_company: 0,
    alc_detail_order_delivery_number: 0,
    alc_detail_order_delivery_progress: 0,
    alc_detail_order_modified: '',
    alc_detail_order_quantity: 0,
    alc_detail_order_refund: 0,
    alc_detail_order_refund_date: '',
    alc_detail_order_refund_status: 0,
    alc_detail_order_registe: '',
    alc_detail_order_sell_price: 0,
    alc_detail_order_sell_price_original: 0,
    alc_detail_reason_direct: '',
    alc_detail_return_status: 0,
    alc_detail_return_status_name: '',
    alc_detail_status: 1,
    alc_order_number_use_alc_order_detail: '',
    brand_eng_name: '',
    brand_kor_name: '',
    color_ID_use_alc_order_detail: 0,
    color_b2c_image: '', 
    color_name: '',
    product_kor_name: '',
  });
  const [totalPriceOrder, setTotalPriceOrder] = useState<number>(0);
  const [totalPriceOriginal, setTotalPriceOriginal] = useState<number>(0);

  const [toggleCancelReason, setToggleCancelReason] = useState<boolean>(false);
  const [cancelReasonStatus, setCancelReasonStatus] = useState<number>(0);
  const [cancelReasonLabel, setCancelReasonLabel] = useState<string>('');
  const [cancelOtherReason, setCancelOtherReason] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>('');
  const [isModalYesOpen, setIsModalYesOpen] = useState<boolean>(false);
  const [modalYesContent, setModalYesContent] = useState({
    text: '',
    confirmText: '',
    onConfirm: () => {}
  });

  useEffect(() => {
    if (!location.state.alc_detail_order_ID) return;
    getOneDetailOrderData(
      location.state.alc_detail_order_ID,
    ).then(result => {
      if (result.status === 1) {
        setDetailOrderData(result.detail_order);
        setTotalPriceOrder(result.order_price[0].total_price);
        setTotalPriceOriginal(result.order_price[0].total_price_original);
      } else {
        
      }
    });
  }, [location.state.alc_detail_order_ID]);

  useEffect(() => {
    if (cancelReasonStatus === 9) {
      setCancelOtherReason('');
    }
    let reason_list: any[] = [];
    switch (location.state.category) {
      case 'cancel' : reason_list = cancelReasonSelectList; break;
      case 'exchange' : reason_list = exchangeReasonSelectList; break;
      case 'return' : reason_list = returnReasonSelectList; break;
      default : reason_list = [];
    }
    let selected_reason = reason_list.find(tmp => tmp.value === cancelReasonStatus);
    if (selected_reason) {
      setCancelReasonLabel(selected_reason.label);
    }
  }, [cancelReasonStatus, location.state.category]);

  const requestExchangeDetailOrder = () => {
    if (!cancelReasonStatus) {
      setModalContent('교환사유를 선택해 주세요.');
      setIsModalOpen(true);
    } else if (cancelReasonStatus === 9 && cancelOtherReason.trim() === "") {
      setModalContent('교환 상세사유를 작성해 주세요.');
      setIsModalOpen(true);
    } else {
      setIsLoading(true);
      requestExchangeDetailOrderOne(
        location.state.alc_detail_order_ID,
        cancelReasonStatus,
        cancelOtherReason,
      ).then(result => {
        if (result === 1) {
          setIsModalYesOpen(true);
          setModalYesContent({
            text: '교환 신청이 완료되었습니다.',
            confirmText: '확인',
            onConfirm: () => {
              setIsModalYesOpen(false);
              handleSelectOneOrderID(detailOrderData.alc_order_ID);
            }
          });
        } else {
          setIsModalYesOpen(true);
          setModalYesContent({
            text: '교환신청 중 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.',
            confirmText: '확인',
            onConfirm: () => {
              setIsModalYesOpen(false);
              handleSelectOneOrderID(detailOrderData.alc_order_ID);
            }
          });
        }
      });
    }
  };

  const requestReturnDetailOrder = () => {
    if (!cancelReasonStatus) {
      setModalContent('반품사유를 선택해 주세요.');
      setIsModalOpen(true);
    } else if (cancelReasonStatus === 9 && cancelOtherReason.trim() === "") {
      setModalContent('반품 상세사유를 작성해 주세요.');
      setIsModalOpen(true);
    } else {
      setIsLoading(true);
      requestReturnDetailOrderOne(
        location.state.alc_detail_order_ID,
        cancelReasonStatus,
        cancelOtherReason,
      ).then(result => {
        if (result === 1) {
          setIsModalYesOpen(true);
          setModalYesContent({
            text: '반품 신청이 완료되었습니다.',
            confirmText: '확인',
            onConfirm: () => {
              setIsModalYesOpen(false);
              handleSelectOneOrderID(detailOrderData.alc_order_ID);
            }
          });
        } else {
          setIsModalYesOpen(true);
          setModalYesContent({
            text: '반품신청 중 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.',
            confirmText: '확인',
            onConfirm: () => {
              setIsModalYesOpen(false);
              handleSelectOneOrderID(detailOrderData.alc_order_ID);
            }
          });
        }
      });
    }
  };

  const requestCancelDetailOrder = () => {
    if (!cancelReasonStatus) {
      setModalContent('취소사유를 선택해 주세요.');
      setIsModalOpen(true);
    } else if (cancelReasonStatus === 9 && cancelOtherReason.trim() === "") {
      setModalContent('취소 상세사유를 작성해 주세요.');
      setIsModalOpen(true);
    } else {
      if (detailOrderData.alc_order_pay_status === 2 || detailOrderData.alc_detail_order_delivery_progress === 0) {
        setModalContent('결제 대기 상태에서는 부분 취소가 불가능합니다.');
        setIsModalOpen(true);
        return;
      }
      if (detailOrderData.alc_detail_order_delivery_progress > 2) {
        setModalContent('배송 중인 상품은 취소가 불가능합니다.');
        setIsModalOpen(true);
        return;
      }

      if (detailOrderData.alc_order_method === 'bankTransfer') {
        setIsLoading(true);
        requestCancelBankTransferDetailOrderOne(
          location.state.alc_detail_order_ID,
          cancelReasonStatus,
          cancelOtherReason,
        ).then(result => {
          if (result === 1) {
            setIsModalYesOpen(true);
            setModalYesContent({
              text: '환불 신청이 완료되었습니다.',
              confirmText: '확인',
              onConfirm: () => {
                setIsModalYesOpen(false);
                handleSelectOneOrderID(detailOrderData.alc_order_ID);
              }
            });
          } else {
            setIsModalYesOpen(true);
            setModalYesContent({
              text: '환불신청 중 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.',
              confirmText: '확인',
              onConfirm: () => {
                setIsModalYesOpen(false);
                handleSelectOneOrderID(detailOrderData.alc_order_ID);
              }
            });
          }
        });
      } else {
        setIsLoading(true);
        refundAlcOrder(
          JSON.stringify(detailOrderData),
          cancelReasonStatus,
          cancelOtherReason,
        ).then(result => {
          if (result === 'error_alc') {
            setIsLoading(false);
            setModalContent('알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.');
            setIsModalOpen(true);
          } else if (result.code === 0) {
            setIsModalYesOpen(true);
            setModalYesContent({
              text: '부분 환불이 완료되었습니다.',
              confirmText: '확인',
              onConfirm: () => {
                setIsModalYesOpen(false);
                handleSelectOneOrderID(detailOrderData.alc_order_ID);
              },
            });
          } else {
            setModalContent(
              `${result.message} 위 사유로 인하여 부분 환불 요청이 안되었습니다. 070-4286-3556로 문의해주시기 바랍니다.`
            );
            setIsLoading(false);
            setIsModalOpen(true);
          }
        });
      }
    }
  };
  
  return (
    <>
      <ModalLoading isOpen={isLoading} />
      <ModalClose
        isOpen={isModalOpen} 
        onClose={() => {
          setIsModalOpen(false);
        }} 
        text={modalContent}
      />
      <ModalYes
        isOpen={isModalYesOpen}
        onConfirm={modalYesContent.onConfirm}
        confirmText={modalYesContent.confirmText}
        text={modalYesContent.text}
      />
      <SOL.OrderSelectOneContainer>
        <div className="order-select-one-header">
          <h2 className='order-select-one-title w-[100%] pb-[10px] border-b-2 border-solid border-main_black'>
            {location.state.category === 'cancel' && "취소 신청"}
            {location.state.category === 'exchange' && "교환 신청"}
            {location.state.category === 'return' && "반품 신청"}
          </h2>
        </div>
        <SOL.OrderListItem style={{padding: "10px 30px"}}>
          {detailOrderData.alc_detail_order_ID !== 0 && (
            <div
              className="order-detail-box"
              style={{border: 0}}
            >
              <div className="order-detail-left">
                <div className='order-detail-image'>
                    <img src={detailOrderData.color_b2c_image} alt={detailOrderData.color_name} />
                </div>
                <div className='order-detail-product'>
                    <p className='brand'>{detailOrderData.brand_kor_name}</p>
                    <p className='product'>{detailOrderData.product_kor_name}</p>
                    <p className='color'>{detailOrderData.color_name}</p>
                    <p className='quantity'>{detailOrderData.alc_detail_order_quantity}개</p>
                </div>
              </div>
              <div className="order-detail-right order-cancel">
                <div className='order-detail-price'>
                    <p className='detail-original-price'>{(detailOrderData.alc_detail_order_sell_price_original * detailOrderData.alc_detail_order_quantity).toLocaleString()}원</p>
                    <p className='detail-rated-price'>{(detailOrderData.alc_detail_order_sell_price * detailOrderData.alc_detail_order_quantity).toLocaleString()}원</p>
                </div>
              </div>
            </div>
          )}
        </SOL.OrderListItem>
        <SOL.CancelOrderInfo>
          <div className="cancel-order-title">
            {location.state.category === 'cancel' && "취소 사유"}
            {location.state.category === 'exchange' && "교환 사유"}
            {location.state.category === 'return' && "반품 사유"}
          </div>
          {location.state.category === 'cancel' && (
            <div className="cancel-reason-section">
              <div className="select-box">
                <div
                  className={`selected-reason ${cancelReasonStatus === 0 && "none-select"}`}
                  onClick={() => setToggleCancelReason(!toggleCancelReason)}
                >
                  <span>{cancelReasonLabel}</span>
                  <img src={IconArrow} alt="select-arrow" className={`${!toggleCancelReason && "rotate-180"}`} />
                </div>
                {toggleCancelReason && (
                  cancelReasonSelectList.filter(tmp => tmp.value !== 0).map((tmp, idx) =>
                    <SOL.BasicSelectListItem
                      key={tmp.value}
                      onClick={() => {
                        setCancelReasonStatus(tmp.value);
                        setToggleCancelReason(false);
                      }}
                    >
                      {tmp.label}
                    </SOL.BasicSelectListItem>
                  )
                )}
              </div>
            </div>
          )}
          {location.state.category === 'exchange' && (
            <div className="cancel-reason-section">
              <div className="select-box">
                <div
                  className={`selected-reason ${cancelReasonStatus === 0 && "none-select"}`}
                  onClick={() => setToggleCancelReason(!toggleCancelReason)}
                >
                  <span>{cancelReasonLabel}</span>
                  <img src={IconArrow} alt="select-arrow" className={`${!toggleCancelReason && "rotate-180"}`} />
                </div>
                {toggleCancelReason && (
                  exchangeReasonSelectList.filter(tmp => tmp.value !== 0).map((tmp, idx) =>
                    <SOL.BasicSelectListItem
                      key={tmp.value}
                      onClick={() => {
                        setCancelReasonStatus(tmp.value);
                        setToggleCancelReason(false);
                      }}
                    >
                      {tmp.label}
                    </SOL.BasicSelectListItem>
                  )
                )}
              </div>
            </div>
          )}
          {location.state.category === 'return' && (
            <div className="cancel-reason-section">
              <div className="select-box">
                <div
                  className={`selected-reason ${cancelReasonStatus === 0 && "none-select"}`}
                  onClick={() => setToggleCancelReason(!toggleCancelReason)}
                >
                  <span>{cancelReasonLabel}</span>
                  <img src={IconArrow} alt="select-arrow" className={`${!toggleCancelReason && "rotate-180"}`} />
                </div>
                {toggleCancelReason && (
                  returnReasonSelectList.filter(tmp => tmp.value !== 0).map((tmp, idx) =>
                    <SOL.BasicSelectListItem
                      key={tmp.value}
                      onClick={() => {
                        setCancelReasonStatus(tmp.value);
                        setToggleCancelReason(false);
                      }}
                    >
                      {tmp.label}
                    </SOL.BasicSelectListItem>
                  )
                )}
              </div>
            </div>
          )}
          {cancelReasonStatus === 9 && (
            <div className="other-reason-box">
              <textarea
                placeholder="50자 내의 상세 사유를 작성해 주세요."
                maxLength={50}
                value={cancelOtherReason}
                onChange={(e) => {
                  setCancelOtherReason(e.target.value);
                }}
              ></textarea>
            </div>
          )}
          <div className="horizontal-divide" />
          <div className="cancel-order-title">
            환불/결제 정보
          </div>
          <SOL.OrderPaymentInfoContainer style={{padding: "0 30px"}}>
            <div className='payment-info-box'>
              <div className='payment-info-flex'>
                  <span className="font-medium">주문 금액</span>
                  <span className="font-medium">{totalPriceOriginal.toLocaleString()}원</span>
              </div>
              <div className='payment-info-flex'>
                  <span className="font-medium">할인 금액</span>
                  <span className="font-medium">-{(totalPriceOriginal - totalPriceOrder).toLocaleString()}원</span>
              </div>
              <div className='payment-info-flex'>
                  <span className="font-medium">배송비</span>
                  {/* FIXME: 여기도 인테리어 채널 같은 곳은 물류팀과 회의해서 배송비 계산해줘야 함 */}
                  <span className="font-medium">0원</span>
              </div>
              <div className='payment-info-flex'>
                  <span className="font-medium">할인 금액</span>
                  <span className="font-medium">{totalPriceOrder.toLocaleString()}원</span>
              </div>
              <div className='horizontal' />
              <div className='payment-info-flex'>
                  {/* <span className="font-medium">{orderData.alc_order_pay_status_name}</span> */}
              </div>
              <div className="payment-info-flex">
                {/* <span>{orderData.alc_order_method_kor}</span> */}
                <span>{totalPriceOrder.toLocaleString()}원</span>
              </div>
            </div>
          </SOL.OrderPaymentInfoContainer>
          <div className="horizontal-divide" />
          <div className="refund-announce-box">
            <h3>* 환불 안내</h3>
            <p>- 무통장 입급</p>
            <p className="indentation">입금전 : 즉시 취소</p>
            <p className="indentation">입금 후 : 고객센터 확인 후 계좌 환불</p>
            <br />
            <p>- 신용카드/ 체크카드 결제</p>
            <p className="indentation">전체 취소 : 당일 취소 후 카드사에 따라 입금 진행</p>
            <p className="indentation">부분 취소 : 영업일 3~5일 취소 후 카드사에 따라 입금 진행</p>
          </div>
          <div className="button-box">
            <SOL.BasicButton
              $width={230}
              onClick={() => navigate(-1)}
            >
              뒤로가기
            </SOL.BasicButton>
            <SOL.BasicButton
              $width={230}
              className="black"
              onClick={() => {
                if (location.state.category === 'cancel') {
                  requestCancelDetailOrder();
                } else if (location.state.category === 'exchange') {
                  requestExchangeDetailOrder();
                } else if (location.state.category === 'return') {
                  requestReturnDetailOrder();
                }
              }}
            >
              {location.state.category === 'cancel' && '취소 신청'}
              {location.state.category === 'exchange' && '교환 신청'}
              {location.state.category === 'return' && '반품 신청'}
            </SOL.BasicButton>
          </div>
        </SOL.CancelOrderInfo>
      </SOL.OrderSelectOneContainer>
    </>
  )
};

export default OneCancelOrder;