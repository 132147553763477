import React, { FC } from "react";
import * as SOL from "../styles/solda/StyledComponentSol";

const PrivacyPolicy: FC = () => {

  return(
    <SOL.TermsTemplate>
        <div className="about_box">
            <div className="about_title text-center"><p className="noto_b f-size-20">개인정보처리방침</p></div>

            <div className="terms_title"><p className="noto_b f-size-18">앰비언스(이하 회사)는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은<br /> 처리방침을 두고 있습니다.</p></div>


            <div className="terms_title"><p className="noto_b f-size-18">회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</p></div>



            <div className="terms_title"><p className="noto_b f-size-18">1. 개인정보 수집 및 이용 목적</p></div>



            <div className="terms_title"><p className="noto_b f-size-18">회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.</p></div>


            <div className="terms_content">
                <p className="noto_r f-size-16">가. 홈페이지 회원가입 및 관리
                    <span className="terms-indent">
                        회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
                    </span>
                </p>

                <p className="noto_r f-size-16">나. 민원사무 처리
                    <span className="terms-indent">
                        민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을 목적으로 개인정보를 처리합니다.
                    </span>
                </p>

                <p className="noto_r f-size-16">다. 재화 또는 서비스 제공
                    <span className="terms-indent">
                        물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산 등을 목적으로 개인정보를 처리합니다.
                    </span>
                </p>

                <p className="noto_r f-size-16">라. 마케팅 및 광고에의 활용
                    <span className="terms-indent">
                        신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
                    </span>
                </p>
            </div>


            <div className="terms_title"><p className="noto_b f-size-18">2. 수집하는 개인정보의 항목 및 수집방법</p></div>



            <div className="terms_title"><p className="noto_b f-size-18">① 수집 항목</p></div>


            <div className="terms_content">
                <p className="noto_r f-size-16">
                가. 회원 가입 시 수집항목
                    <span className="terms-indent">
                        - 필수항목 : 이름, 성별, 로그인ID, 비밀번호, 이메일, 생년월일, 주소, 휴대폰번호
                    </span>
                    <span className="terms-indent">
                        - 선택항목 : 자택번호, SMS 수신동의, 이메일 수신동의
                    </span>
                    <span className="terms-indent">
                        - 수집목적 : 회원특정 및 고지사항 전달, 본인의사 확인 및 불만처리 등 원활한 의사소통 경로의 확보
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    나. 상품구매 시 수집항목
                    <span className="terms-indent">
                        - 주문고객정보 : 주문자 이름, 이메일주소, 휴대폰번호, 전화번호, 주소지, 배송시 요청사항
                    </span>
                    <span className="terms-indent">
                        - 배송지정보: 수령인 이름, 휴대폰번호, 전화번호, 주소, 배송지 요구사항
                    </span>
                    <span className="terms-indent">
                        - 결제정보 : 결제를 진행하기 위해 필요한 결제 관련 정보 (신용카드, 결제 계좌정보)
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    다. 자동 수집
                    <span className="terms-indent">
                    - 결제기록, 접속 IP 정보, 쿠키, 서비스 이용 기록, 접속로그 등
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    라. 기타 (이벤트나 마케팅 활동 등)
                    <span className="terms-indent">
                    - 수집정보 : 주소, 생년월일, 전화번호, 휴대폰번호, 이름, 이메일 등
                    </span>
                    <span className="terms-indent">
                    - 그 외 특정 목적을 위해 단기적으로 개인정보를 수집할 경우에는 별도로 공지하고 수집합니다.
                    </span>
                </p>
            </div>

            <div className="terms_title"><p className="noto_b f-size-18">② 수집 방법</p></div>

            <div className="terms_content">
                <p className="noto_r f-size-16">
                    다음과 같은 방법으로 개인정보를 수집합니다.
                    <span className="terms-indent">
                        -홈페이지(회원가입), 서면양식, 전화, 팩스, 상담게시판, 이벤트응모, 배송요청(비회원 구매 포함), 서비스이용, 회원정보 수정
                    </span>
                    <span className="terms-indent">
                        -제휴회사로부터의 제공
                    </span>
                </p>
            </div>

            <div className="terms_title"><p className="noto_b f-size-18">3. 개인정보 수집에 대한 동의</p></div>

            <div className="terms_content">
                <p className="noto_r f-size-16">
                    회사는 귀하께서 회사의 개인정보취급방침 또는 이용약관의 내용에 대해 「동의함」 버튼을 체크할 수 있는 절차를 마련하여, 「동의함」버튼을 체크하면 개인정보 수집에 대해 동의한 것으로 봅니다.
                </p>
            </div>


            <div className="terms_title"><p className="noto_b f-size-18">4. 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</p></div>

            <div className="terms_content">
                <p className="noto_r f-size-16">
                    ① 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
                </p>

                <p className="noto_r f-size-16">
                    ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
                </p>


                <p className="noto_r f-size-16">
                <span className="terms-indent">
                    가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
                </span>


                <span className="terms-indent">
                    나. 쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 ‘도구 {">"} 인터넷 옵션 {">"} 개인정보’ 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
                </span>

                <span className="terms-indent">
                    다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
                </span>
            </p>
            </div>

            <div className="terms_title"><p className="noto_b f-size-18">5. 개인정보의 처리 및 보유 기간</p></div>


            <div className="terms_content">
                <p className="noto_r f-size-16">
                    가. 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.
                </p>


                <p className="noto_r f-size-16">
                    나. 각각의 개인정보 처리 및 보유 기간은 다음과 같이 수집 목적 또는 제공 받은 목적이 달성되면 파기됩니다.
                    <span className="terms-indent">
                        - 회원가입 정보 : 회원을 탈퇴하거나 회원에서 제명된 때
                    </span>
                    <span className="terms-indent">
                        - 대금지급 정보 : 대금의 완제일 또는 채권소멸시효기간이 만료된 때
                    </span>
                    <span className="terms-indent">
                        - 배송정보 : 재화 또는 용역 등 상품이 인도되거나 제공된 때
                    </span>
                    <span className="terms-indent">
                        - 설문조사, 이벤트 등 일시적 목적을 위하여 수집한 경우 : 당해 설문조사, 이벤트 등이 종료한 때
                    </span>
                </p>
                <p className="noto_r f-size-16">
                다. 단, 상법 및 ‘전자상거래등에서 소비자 보호에 관한 법률’ 등 관련 법령의 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다.
                    <span className="terms-indent">
                    - 계약 또는 청약철회 등에 관한 기록 : 5년
                    </span>
                    <span className="terms-indent">
                    - 대금결제 및 재화등의 공급에 관한 기록 : 5년
                    </span>
                    <span className="terms-indent">
                    - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
                    </span>
                </p>
            </div>
            <div className="terms_title"><p className="noto_b f-size-18">6. 개인정보의 제3자 제공 및 공유</p></div>


            <div className="terms_content">
                <p className="noto_r f-size-16">
                    회사는 이용자의 동의가 있거나 관련 법령의 규정에 의한 경우를 제외하고는 어떠한 경우에도 "개인정보의 수집항목 및 수집 목적", "개인정보의 이용목적"에서 고지한 범위를 넘어 이용자의 개인정보를 이용하거나 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.
                <span className="terms-indent">
                - 이용자들이 사전에 동의한 경우.
                </span>
                <span className="terms-indent">
                - 서비스 제공에 따른 요금정산을 위해 필요한 경우.
                </span>
                <span className="terms-indent">
                - 계약 이행을 위해 필요한 경우(제품 배송/설치, 서비스 업무)
                </span>
                <span className="terms-indent">
                - 타인에게 정신적, 물질적 피해를 줌으로써 그에 대한 법적 조치를 취하기 위해 고객정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우.
                </span>
                <span className="terms-indent">
                - 통계작성, 마케팅분석 또는 시장조사를 위해 필요한 경우로 특정 개인을 식별할 수 없는 형태로 가공하여 외부 기관 또는 단체 등에 제공하는 경우.
                </span>
                <span className="terms-indent">
                - 기타 관련 법령의 규정에 의하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우.
                </span>
                <span className="terms-indent">
                - 서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우.
                </span>
                </p>
            </div>

            <div className="terms_title"><p className="noto_b f-size-18">7. 정보주체의 권리, 의무 및 그 행사방법 이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.</p></div>


            <div className="terms_content">
                <p className="noto_r f-size-16">
                    가. 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
                    <span className="terms-indent">
                    - 개인정보 열람요구
                    </span>
                    <span className="terms-indent">
                    - 오류 등이 있을 경우 정정 요구
                    </span>
                    <span className="terms-indent">
                    - 삭제요구
                    </span>
                    <span className="terms-indent">
                    - 처리정지 요구
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    나. 제1항에 따른 권리 행사는회사에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
                </p>

                <p className="noto_r f-size-16">
                    다. 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
                </p>

                <p className="noto_r f-size-16">
                    라. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                </p>
            </div>


            <div className="terms_title"><p className="noto_b f-size-18">8. 개인정보의 파기회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.</p></div>


            <div className="terms_content">
                <p className="noto_r f-size-16">
                    가. 파기절차
                    <span className="terms-indent">
                        이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    나. 파기기한
                    <span className="terms-indent">
                        이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
                    </span>
                </p>
                <p className="noto_r f-size-16">
                    다. 파기방법
                    <span className="terms-indent">
                        전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                    </span>
                    <span className="terms-indent">
                        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                    </span>
                </p>
            </div>

            <div className="terms_title"><p className="noto_b f-size-18">9. 개인정보의 안전성 확보 조치 회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</p></div>


            <div className="terms_content">
                <p className="noto_r f-size-16">
                    가. 개인정보 취급 직원의 최소화 및 교육
                    <span className="terms-indent">
                    개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    나. 정기적인 자체 감사 실시
                    <span className="terms-indent">
                    개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    다. 내부관리계획의 수립 및 시행
                    <span className="terms-indent">
                    개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    라. 개인정보의 암호화
                    <span className="terms-indent">
                    이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    마. 해킹 등에 대비한 기술적 대책
                    <span className="terms-indent">
                    회사은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    바. 개인정보에 대한 접근 제한
                    <span className="terms-indent">
                    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    사. 접속기록의 보관 및 위변조 방지
                    <span className="terms-indent">
                    개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    아. 문서보안을 위한 잠금장치 사용
                    <span className="terms-indent">
                    개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    자. 비인가자에 대한 출입 통제
                    <span className="terms-indent">
                    개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
                    </span>
                </p>
            </div>

            <div className="terms_title"><p className="noto_b f-size-18">10. 개인정보 보호책임자</p></div>


            <div className="terms_content">
                <p className="noto_r f-size-16">
                    가. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                    <span className="terms-indent">
                    성명 : 
                    </span>
                    <span className="terms-indent">
                    직책: 
                    </span>
                    <span className="terms-indent">
                    소속부서 : 
                    </span>
                    <span className="terms-indent">
                    전화번호 : 
                    </span>
                    <span className="terms-indent">
                    팩스번호 : 
                    </span>
                    <span className="terms-indent">
                    전자우편 : 
                    </span>
                </p>

                <p className="noto_r f-size-16">
                    나. 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
                </p>
            </div>


            <div className="terms_title"><p className="noto_b f-size-18">11. 개인정보 처리방침 변경</p></div>


            <div className="terms_content">
                <p className="noto_r f-size-16">
                    가. 이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
                </p>
            </div>




            <div className="terms_title"><p className="noto_b f-size-18">본 방침은 2021. 10. 1. 부터 시행됩니다.</p></div>
        </div>
    </SOL.TermsTemplate>
  )
};

export default PrivacyPolicy;