import React, { FC } from "react";
import useCheckSession from "../../hooks/useCheckSession";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import TermsOfService from "../../modules/TermsOfService";
import MobileNav from "../../layout/Header/MobileNav";

const TermsOfServicePage: FC = () => {
  useCheckSession();

  return (
    <PageWrapper title={'이용약관'}>
      <TermsOfService />
      <MobileNav />
    </PageWrapper>
  );
};

export default TermsOfServicePage;