import React, { FC, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { getProductOneAlc, getSessionData } from '../../Axios';
import ProductBuy from '../../components/Product/ProductBuy';
import ProductTab from '../../components/Product/ProductTab';
import { useDiscountRateContext } from '../../context/DiscountRateContext';
import * as SOL from "../../styles/solda/StyledComponentSol";
import { handleScrollTopPosition } from '../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';

type Product = {
	color_ID: number;
	color_b2c_image: string;
	brand_eng_name: string;
	product_kor_name: string;
	color_name: string;
	color_b2c_sell_price: number;
	color_b2c_detail_image: string;
	color_b2c_detail_image2: string;
	color_b2c_detail_image3: string;
	brand_ID: number;
	producut_type_ID: number;
};

interface IProductDetailProps {
    color_ID?: string;
};

const ProductDetail: FC<IProductDetailProps> = ({color_ID}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { discountRateInfo, discountRateProductTypeInfo, discountRateColorInfo } = useDiscountRateContext();
	const [customerAlcStatus, setCustomerAlcStatus] = useState<number>(0);
	const [access, setAccess] = useState<boolean>(false);

	const [product, setProduct] = useState<Product>({
		color_ID: 0,
		color_b2c_image: '',
		brand_eng_name: '',
		product_kor_name: '',
		color_name: '',
		color_b2c_sell_price: 0,
		color_b2c_detail_image: '',
		color_b2c_detail_image2: '',
		color_b2c_detail_image3: '',
		brand_ID: 0,
		producut_type_ID: 0,
	});
	const alc_recent_product = JSON.parse(localStorage.getItem('alc_recent_product') || '[]');

	useEffect(() => {
		if (!location.state) return;
		if (location.state.filter) {
			localStorage.setItem('alc_product_filter', JSON.stringify(location.state.filter));
		}
		if (location.state.page) {
			localStorage.setItem('alc_product_page', location.state.page.toString());
		}
	}, [location]);

	useEffect(() => {
		handleScrollTopPosition(0);
		getSessionData().then(result => {
			setCustomerAlcStatus(result.alc_status);
		})
	}, []);
	
	useEffect(() => {
		getProductOneAlc(
			color_ID,
		).then((result: any) => {
			setProduct(result[0]);
			if (result.length === 0) {
				setAccess(false);
			} else {
				setAccess(true);
			}
		});
	}, [color_ID, discountRateInfo]);

	// 현재 보고있는 아이템 최근 본 상품 리스트 로컬에 저장
	useEffect(() => {
		const isProductInRecent = alc_recent_product.some((item: any) => item === color_ID);
		const recent_product_index = alc_recent_product.indexOf(color_ID);
		let list = alc_recent_product;
		if (!isProductInRecent) {
			if (list.length >= 20) {
				list.pop();
			}
			list.unshift(color_ID);
		} else {
			list.splice(recent_product_index, 1);
			list.unshift(color_ID);
		}
		localStorage.setItem('alc_recent_product', JSON.stringify(list));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<SOL.ProductDetailContainer>
				{access ? (
					<>
						<ProductBuy
							product={product}
							apply_discount_rate={discountRateInfo}
							apply_discount_rate_product_type={discountRateProductTypeInfo}
							apply_discount_rate_color={discountRateColorInfo}
							customerAlcStatus={customerAlcStatus}
						/>
						<ProductTab product={product} />
					</>
				) : (
					<div className='no-product-detail'>
						<div className='no-product-detail-wrapper'>
							<h3>
								찾으시는 상품 정보가 없습니다.
							</h3>
							<div className='control-box'>
								<button
									onClick={() => navigate(-1)}
								>
									이전으로 돌아가기
								</button>
								<button
									className='to-main'
									onClick={() => navigate("/home")}
								>
									메인으로 돌아가기
								</button>
							</div>
						</div>
					</div>
				)}
			</SOL.ProductDetailContainer>
		</>
	);
};

ProductDetail.propTypes = {
	color_ID: PropTypes.string,
};

ProductDetail.defaultProps = {
	color_ID: '',
};

export default ProductDetail;
