import React, { FC } from "react";
import useCheckSession from "../../hooks/useCheckSession";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import MyRecentProduct from "../../components/My/MyRecentProduct";
import MobileNav from "../../layout/Header/MobileNav";

const RecentProductPage: FC = () => {
  useCheckSession();

  return (
    <PageWrapper title={'최근 본 상품'}>
      <MyRecentProduct />
      <MobileNav />
    </PageWrapper>
  );
};

export default RecentProductPage;