import React, { FC } from "react";
import useCheckSession from "../../hooks/useCheckSession";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import PrivacyPolicy from "../../modules/PrivacyPolicy";
import MobileNav from "../../layout/Header/MobileNav";

const PrivacyPolicyPage: FC = () => {
  useCheckSession();

  return (
    <PageWrapper title={'개인정보처리방침'}>
      <PrivacyPolicy />
      <MobileNav />
    </PageWrapper>
  );
};

export default PrivacyPolicyPage;