import React, { FC } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import OrderMobile from "../../layout/Order/OrderMobile";

const OrderMobilePage: FC = () => {

  return (
    <PageWrapper title="결제하기">
      <OrderMobile />
    </PageWrapper>
  )
};

export default OrderMobilePage;