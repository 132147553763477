
import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { applyDiscountRateForPurpose, discountRateCalculator, formatColorName } from '../../Utils';
import * as SOL from "../../styles/solda/StyledComponentSol";
import IconCartEmpty from "../../assets/icons/icon_add_cart_empty.svg";
import IconCartRed from "../../assets/icons/icon_add_cart_red.svg";
import IconArrow from "../../assets/icons/icon_only_arrow.svg";
import { useCartContext } from '../../context/CartContext';
import ModalClose from '../Modal/ModalClose';
import { useShowInventoryContext } from '../../context/ShowInventoryContext';

interface IProductProps {
	product_element?: any;
	apply_discount_rate: any;
	apply_discount_rate_product_type: any;
	apply_discount_rate_color: any;
	customerAlcStatus: number;
	selectProduct: (color_ID: number) => void;
}
const Product: FC<IProductProps> = ({
	product_element,
	apply_discount_rate,
	apply_discount_rate_product_type,
	apply_discount_rate_color,
	customerAlcStatus,
	selectProduct,
}) => {
	const { countCart } = useCartContext();
	const { showInventoryInfo } = useShowInventoryContext();
	const [isAddCart, setIsAddCart] = useState<boolean>(false);
	const [discountRate, setDiscountRate] = useState({
		apply_discount_rate_1: '',
		apply_discount_rate_2: '',
		apply_discount_rate_ID: '',
		apply_discount_rate_vat: '1',
		brand_ID: '',
		product_type_ID: [],
		color_ID: [],
		use_status: '',
	});
	const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState<string>('');

	useEffect(() => {
		const cart = JSON.parse(localStorage.getItem('cart') || '[]');
		const isProductInCart = cart.some((item: any) => item.color_ID === product_element.color_ID);
		if (isProductInCart) {
			setIsAddCart(true);
		} else {
			setIsAddCart(false);
		}
		countCart();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAddCart, product_element.color_ID]);

	useEffect(() => {
		let color_array = apply_discount_rate_color.filter((tmp: {color_ID: any[]; use_status: any;}) => (
			tmp.color_ID.includes(product_element.color_ID) && Number(tmp.use_status) === 1
		));
		let type_array = apply_discount_rate_product_type.filter((tmp: {brand_ID: any; product_type_ID: any[]; use_status: any;}) => (
			tmp.product_type_ID.includes(product_element.product_type_ID) && Number(tmp.brand_ID) === product_element.brand_ID && Number(tmp.use_status) === 1
		));
		let rate_array = apply_discount_rate.filter((tmp: { brand_ID: any; use_status: any;}) => (
			Number(tmp.brand_ID) === product_element.brand_ID && Number(tmp.use_status) === 1
		));
		
		if (color_array.length > 0) {
			setDiscountRate(applyDiscountRateForPurpose(1, color_array));
		} else if (type_array.length > 0) {
			setDiscountRate(applyDiscountRateForPurpose(2, type_array));
		} else if (product_element.product_portable_status === 1) {
			setDiscountRate(applyDiscountRateForPurpose(4, rate_array));
		} else if (rate_array.length > 0) {
			setDiscountRate(applyDiscountRateForPurpose(3, rate_array));
		} else {
			setDiscountRate({
				apply_discount_rate_1: '',
				apply_discount_rate_2: '',
				apply_discount_rate_ID: '',
				apply_discount_rate_vat: '1',
				brand_ID: '',
				product_type_ID: [],
				color_ID: [],
				use_status: '',
			});
		}
	}, [apply_discount_rate, apply_discount_rate_product_type, apply_discount_rate_color, product_element]);

	const addToCart = (event: React.MouseEvent<HTMLDivElement>, product: any) => {
		event.preventDefault();
		event.stopPropagation();
		if (product.color_option_status === 1) {
			setModalContent('해당 상품은 상세 페이지에서 옵션을 선택하셔야 합니다.');
			setIsModalCloseOpen(true);
			return;
		};
		const cart = JSON.parse(localStorage.getItem('cart') || '[]');
		const isProductInCart = cart.some((item: any) => item.color_ID === product.color_ID);
		if (isProductInCart) {
			for (let i = 0; i < cart.length; i++) {
				if (cart[i].color_ID === product.color_ID) {
					cart.splice(i, 1);
					localStorage.setItem('cart', JSON.stringify(cart));
				}
			}
			setIsAddCart(false);
		} else {
			const newCartItem = { color_ID: product.color_ID, quantity: 1 };
			if (cart.length >= 50) {
				setModalContent('장바구니는 50개까지 저장할 수 있습니다.');
				setIsModalCloseOpen(true);
			} else {
				localStorage.setItem('cart', JSON.stringify([...cart, newCartItem]));
				setIsAddCart(true);
			}
		}
	};

	return (
		<>
			<ModalClose
				isOpen={isModalCloseOpen}
				onClose={() => setIsModalCloseOpen(false)}
				text={modalContent}
			/>
			<SOL.ProductCard
				onClick={() => selectProduct(product_element.color_ID)}
			>
				<div className="product-image-container">
					<img 
						src={product_element.color_b2c_image} 
						alt={product_element.product_kor_name + product_element.color_name}
					/>
					{(
						product_element.inventory_warehouse_date_status &&
						(
							(customerAlcStatus === 1 && 
								[4, 3].includes(
									Number(product_element.inventory_status)
								)
							) || 
							(customerAlcStatus === 4 && showInventoryInfo.show_inventory === false)
						)
						) && (
						<div className='inventory-warehouse'>
							{product_element.inventory_warehouse_date_status}
							{customerAlcStatus === 4 && (
								<>
									{' '}: {product_element.inventory_total_count}개
								</>
							)}
						</div>
					)}
						<div
							className={`add-to-cart-icon`}
							onClick={(e)=>{
								addToCart(e, product_element);
							}}
						>
							<img src={(isAddCart ? IconCartRed : IconCartEmpty)} alt='cart-icon' />
						</div>
				</div>
				<div className="product-details">
					<div className="product-brand">{product_element.brand_eng_name}</div>
					<div className="product-name">{product_element.product_kor_name}</div>
					<div className="product-color">{formatColorName(product_element.color_name)}</div>
					{(parseInt(discountRate.apply_discount_rate_1) > 0) ?
					(
						<div className="product-price">
							<span className="original-price">
								{product_element.color_b2c_sell_price.toLocaleString()}원
							</span>
							<span className="final-price">
								{
									discountRateCalculator(
										product_element.color_b2c_sell_price,
										Number(discountRate.apply_discount_rate_1),
										Number(discountRate.apply_discount_rate_2)
									).toLocaleString()
								}원
							</span>
							{(parseInt(discountRate.apply_discount_rate_1) > 0) && (
								<>
									<span className="product-discount">
										{Number(discountRate.apply_discount_rate_1)}%
									</span>
									{parseInt(discountRate.apply_discount_rate_2) > 0 && (
										<>
											<div className='arrow-icon-img'>
												<img src={IconArrow} alt='arrow' />
											</div>
											<span className="product-discount additional-discount">
												추가 {Number(discountRate.apply_discount_rate_2)}%
											</span>
											{/* <span className='compound-discount'>
												(약 {Number(discountRate.apply_discount_rate_1) + Number(discountRate.apply_discount_rate_2)}%)
											</span> */}
										</>
									)}
								</>
							)}
						</div>
					) : (
						<div className="product-price">
							<span className='original-price h-3'></span>
							<span className="final-price">
								{product_element.color_b2c_sell_price.toLocaleString()}원
							</span>
						</div>
					)
					}
					<div className='product-discount-rate-vat'>
						{Number(discountRate.apply_discount_rate_vat) === 0 ? "*부가세 별도" : "*부가세 포함"}
					</div>
				</div>
			</SOL.ProductCard>
		</>
	);
};
Product.propTypes = {
	product_element: PropTypes.any,
	apply_discount_rate: PropTypes.any,
	apply_discount_rate_product_type: PropTypes.any,
	apply_discount_rate_color: PropTypes.any,
};
Product.defaultProps = {
	product_element: [],
	apply_discount_rate: {},
	apply_discount_rate_product_type: {},
	apply_discount_rate_color: {},
};

export default Product;
