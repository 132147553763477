export const getPaymentStatus = (status: number): string => {
  switch (status) {
      case 1: return '결제';
      case 2: return '결제 대기';
      case 3: return '부분 결제';
      case 4: return '전체 환불';
      default: return '미확인';
  }
};

export const getPaymentMethod = (method: string): string => {
  switch (method) {
      case 'card': return '카드결제';
      case 'kakaopay': return '카카오페이';
      case 'tosspay': return '토스페이';
      case 'bankTransfer': return '무통장입금';
      default: return '미확인';
  }
};

export const getDeliveryProgress = (progress: number): string => {
  switch (progress) {
      case 0: return '결제확인 전';
      case 1: return '배송 준비 중';
      case 2: return '부분배송 중';
      case 3: return '전체배송 완료';
      case 4: return '배송 취소';
      default: return '미확인';
  }
};

export const getDeliveryPartialProgress = (status: number): string => {
  switch (status) {
      case 0: return '결제확인 전';
      case 1: return '배송 준비 중';
      case 2: return '배송 중';
      case 3: return '배송 완료';
      case 4: return '배송 취소';
      default: return '미확인';
  }
};

export const getPaymentStatusAndDeliveryProgress = (pay_status: number, delivery_progress: number): string => {
  if (pay_status === 2) {
    return "결제대기"
  } else {
    switch (delivery_progress) {
      case 0: return '결제완료';
      case 1: return '배송 준비 중';
      case 2: return '부분배송 중';
      case 3: return '전체배송 완료';
      case 4: return '배송 취소';
      default: return '미확인';
    }
  }
};

export const getAlcDetailStatusAndDeliveryProgress = (
  alc_detail_status: number, sub_status: number
) => {
  if (alc_detail_status === 1) {
    switch (sub_status) {
      case 0: return '결제 대기';
      case 1: return '결제 완료';
      case 2: return '시공/배송 준비';
      case 3: return '시공/배송 중';
      case 4: return '시공/배송 완료';
      case 5: return '배송 취소'
      default: return ''
    }
  } else if (alc_detail_status === 2) {
    return '취소 완료'
  } else if (alc_detail_status === 3) {
    switch (sub_status) {
      case 0: return '';
      case 1: return '교환 신청';
      case 2: return '교환 진행중';
      case 3: return '교환 완료';
      case 4: return '교환 취소';
    }
  } else if (alc_detail_status === 4) {
    switch (sub_status) {
      case 0: return '';
      case 1: return '반품 신청';
      case 2: return '반품 진행중';
      case 3: return '반품 완료';
      case 4: return '반품 취소';
    }
  }
};

export const getAmbienceAddress = {
  extra_address_ID: 0,
  alc_order_sender_name: '앰비언스',
  alc_order_sender_phone_number: '070-4286-3556',
  alc_order_sender_address_name: '앰비언스',
  alc_order_sender_zip_code: '48060',
  alc_order_sender_road_address: '부산 해운대구 센텀1로 28 더블유비씨더팔레스오피스텔',
  alc_order_sender_detail_address: '101 702 앰비언스',
  alc_order_sender_big_area: '부산',
  alc_order_sender_small_area: '해운대구',
};