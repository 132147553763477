import { useEffect } from 'react';

export const useDisableAllKeys = (isActive: boolean) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (isActive && (event.key === 'Enter' || event.key === ' ')) {
                event.preventDefault();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isActive]);
};

export default useDisableAllKeys;