import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOrderContext } from "../../context/OrderContext";
import ModalLoading from "../Modal/ModalLoading";
import ModalClose from "../Modal/ModalClose";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { checkAlcSession, insertAosOrder } from "../../Axios";
import IconArrowUp from "../../assets/icons/arrow_fold_40.svg";
import { ICustomerData } from "../../modules/model";
import { extractColorOptionNodeNames } from "../../Utils";

interface IOfferDetailProps {
  customerData: ICustomerData;
  products: any[];
  respectiveShippingMemo: boolean;
  priceData: any;
  appliedPromotion: any;
  changeRespectiveDetailMemo: (idx: number, memo_text: string) => void;
}

const OfferDetails: FC<IOfferDetailProps> = ({
  customerData,
  products,
  respectiveShippingMemo,
  priceData,
  appliedPromotion,
  changeRespectiveDetailMemo,
}) => {
  const navigate = useNavigate();
  const {
    shippingInfo,
    setShippingInfo,
    senderShippingInfo,
  } = useOrderContext();
  const [productQuantities, setProductQuantities] = useState<{[color_ID: number]: number}>({});
  const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState({text: ''});
  const [isLoading, setIsLoading] = useState(false);

  const [toggleProductInfo, setToggleProductInfo] = useState<boolean>(true);
  const [togglePaymentInfo, setTogglePaymentInfo] = useState<boolean>(true);

  useEffect(() => {
    const newTotalAmount = products.reduce((acc, product) => {
        const quantity = productQuantities[product.color_ID] || product.quantity;
        return acc + (product.color_b2c_sell_price * quantity);
    }, 0);
    setShippingInfo(prev => ({ ...prev, totalAmount: newTotalAmount }));
  }, [products, productQuantities, setShippingInfo]);

  useEffect(() => {
    const initialQuantities = products.reduce((acc, product) => ({
        ...acc,
        [product.color_ID]: product.quantity,
    }), {});
    setProductQuantities(initialQuantities);
  }, [products]);

  const handleOffer = () => {
    if (
      !senderShippingInfo.alc_order_sender_name ||
      !senderShippingInfo.alc_order_sender_road_address ||
      !senderShippingInfo.alc_order_sender_detail_address ||
      !senderShippingInfo.alc_order_sender_phone_number
    ) {
      setIsModalCloseOpen(true);
      setModalContent({
        text: '보내는 배송지 정보를 입력해 주세요.',
      });
      return;
    }
    if (!shippingInfo.address || !shippingInfo.phoneNumber || !shippingInfo.name || !shippingInfo.postalCode) {
      setIsModalCloseOpen(true);
      setModalContent({
        text: '받는 배송지 정보를 입력해 주세요.',
      });
      return;
    }
    checkAlcSession();
    setIsLoading(true);
    const aos_order_number = `aos_${new Date().getTime()}`;
    insertAosOrder(
      JSON.stringify(customerData),
      appliedPromotion ? appliedPromotion.aos_promotion_ID : 0,
      aos_order_number,
      JSON.stringify(senderShippingInfo),
      JSON.stringify(shippingInfo),
      JSON.stringify(products),
      priceData.supply_price,
      priceData.total_vat,
      priceData.promotion_discount,
      appliedPromotion ? appliedPromotion.discount_rate : 0,
      (priceData.supply_price + priceData.total_vat - priceData.promotion_discount),
      respectiveShippingMemo.toString(),
    ).then(result => {
      setIsLoading(false);
      if (result === 1) {
        navigate('/offer_success', {state: {aos_order_number: aos_order_number}});
      } else {
        setIsModalCloseOpen(true);
        setModalContent({
            text: '알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.'
        });
      }
    });
  };

  return (
    <>
      <ModalLoading isOpen={isLoading} />
      <ModalClose
        isOpen={isModalCloseOpen} 
        onClose={() => setIsModalCloseOpen(false)}
        text={modalContent.text}
      />
      <SOL.OrderDetailsContainer>
        <SOL.OrderDetailsWrapper>
          <SOL.OrderSectionTitle onClick={() => setToggleProductInfo(!toggleProductInfo)}>
            <span>발주상품</span>
            <img className={`${toggleProductInfo || "rotate-180"}`} src={IconArrowUp} alt="arrow_icon" />
          </SOL.OrderSectionTitle>
          {toggleProductInfo && (
            <div className="order-items-box">
              {products.map((tmp, idx) => (
                <SOL.OrderItem
                  key={idx}
                  className={`${products.length === idx+1 && "last-product"}`}
                >
                  <div className="order-item-wrapper">
                    <div className="order-flex-left">
                      <div
                        className="order-image"
                        onClick={() => navigate(`/product_detail?color_ID=${tmp.color_ID}`)}
                      >
                        <img src={tmp.color_b2c_image} alt={tmp.color_name} />
                      </div>
                      <div className='order-details'>
                        <h2 className='order-brand-name'>{tmp.brand_eng_name}</h2>
                        <h1 className="order-product-name">{tmp.product_kor_name}</h1>
                        <h3 className="order-color-name">{tmp.color_name}</h3>
                        {tmp.color_option_path && (
                          <h3 className="order-color-option-name">
                            {`[ 옵션: `}
                            {extractColorOptionNodeNames(JSON.parse(tmp.color_option_path))}
                            {` ]`}
                          </h3>
                        )}
                      </div>
                    </div>
                    <div className="order-flex-right">
                      <div className='order-quantity'>
                        <span>{tmp.quantity}개</span>
                      </div>
                      <div className='order-price-box'>
                        <div className='price-wrapper'>
                          <p className="order-total-price">{(tmp.color_b2c_sell_price * (productQuantities[tmp.color_ID] || tmp.quantity)).toLocaleString()}원</p>
                          {parseInt(tmp.apply_discount_rate_vat) === 0 ? (
                            <p className="order-vat">*부가세 미포함 {((tmp.color_b2c_sell_price * (productQuantities[tmp.color_ID] || tmp.quantity)) * 0.1).toLocaleString()}원</p>
                          ) : (
                            <p className="order-vat">*부가세 포함</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {respectiveShippingMemo && (
                    <div className="respective-shipping-memo">
                      <label>개별 배송메모</label>
                      <input
                        className="memo"
                        type="text"
                        maxLength={100}
                        placeholder="배송지 변경, 기타 배송 메모를 작성해 주세요."
                        value={tmp.memo}
                        onChange={(e: any) => {
                          changeRespectiveDetailMemo(idx, e.target.value);
                        }}
                      />
                    </div>
                  )}
                </SOL.OrderItem>
              ))}
              <div className='total-order-price-box'>
                <span className='total-order-price-label'>기존 공급가</span>
                <span className='total-order-price'>{(priceData.supply_price).toLocaleString()}원</span>
              </div>
            </div>
          )}

          <SOL.HorizontalDivider />

          <SOL.OrderSectionTitle onClick={() => setTogglePaymentInfo(!togglePaymentInfo)}>
            <span>결제정보</span>
            <img className={`${togglePaymentInfo || "rotate-180"}`} src={IconArrowUp} alt="arrow_icon" />
          </SOL.OrderSectionTitle>

          {togglePaymentInfo && (
            <SOL.PaymentInfo>
              <div className="payment-wrapper">
                <div className="payment-rows">
                  <span className="label">기존 공급가</span>
                  <span className="content">{priceData.supply_price.toLocaleString()}원</span>
                </div>
                <div className="payment-rows">
                  <span className="label">부가세</span>
                  <span className="content">{priceData.total_vat.toLocaleString()}원</span>
                </div>
                <div className="payment-rows">
                  <span className="label">프로모션 할인가</span>
                  <span className="content">{priceData.promotion_discount === 0 ? "0" : `-${priceData.promotion_discount.toLocaleString()}`}원</span>
                </div>
                <div className="divide-line" />
                <div className="payment-rows">
                  <span className="label">최종 공급가</span>
                  <span className="content final-price">{(priceData.supply_price + priceData.total_vat - priceData.promotion_discount).toLocaleString()}원</span>
                </div>
                <div className="payment-notice">
                  *제품에 따라 배송비는 별도로 추가될 수 있습니다.
                </div>
              </div>
            </SOL.PaymentInfo>
          )}

          <SOL.HorizontalDivider />

          <SOL.OrderTotalAmountContainer>
            <div className='flex-layout'>
              <button
                className="order-btn-checkout font-pretendard"
                onClick={handleOffer}
              >
                {(priceData.supply_price + priceData.total_vat - priceData.promotion_discount).toLocaleString()}원 오더하기
              </button>
            </div>
          </SOL.OrderTotalAmountContainer>
        </SOL.OrderDetailsWrapper>
      </SOL.OrderDetailsContainer>
    </>
  );
};

export default OfferDetails;