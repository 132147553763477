import React, { FC, useEffect, useState} from 'react';
import { IShippingInfo } from '../../context/OrderContext';
import * as SOL from "../../styles/solda/StyledComponentSol";
import { useLocation, useNavigate } from 'react-router-dom';
import IconArrowUp from "../../assets/icons/arrow_fold_40.svg";
import { getOrderSuccessSummary } from '../../Axios';
import IconCopy from "../../assets/icons/icon_copy.svg";
import ModalClose from '../../components/Modal/ModalClose';
import { extractColorOptionNodeNames } from '../../Utils';

interface IOrderSummary {
    alc_order_ID : number,
    imp_uid : string,
    alc_order_number : string,
    alc_order_pay_total : number,
    alc_order_method : string;
    alc_order_bank_transfer_depositor_name: string;
    alc_order_registe : string,
};

const OrderSuccess: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { shippingInfo, products, merchant_uid } = location.state as { shippingInfo: IShippingInfo, products: any[], merchant_uid: string };
    const [toggleProductList, setToggleProductList] = useState<boolean>(true);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [orderSummary, setOrderSummary] = useState<IOrderSummary>({
        alc_order_ID : 0,
        imp_uid : '',
        alc_order_number : '',
        alc_order_pay_total : 0,
        alc_order_method : '',
        alc_order_bank_transfer_depositor_name: '',
        alc_order_registe : '',
    });
    const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState({text: ''});

    useEffect(() => {
        const total_sell_price = products.reduce((accumulator, current_product) => {
            return accumulator + (current_product.color_b2c_sell_price * current_product.quantity);
        }, 0);
        setTotalPrice(total_sell_price);
    }, [products]);

    useEffect(() => {
        if (!merchant_uid) return;
        getOrderSuccessSummary(merchant_uid).then(result => {
            setOrderSummary(result[0]);
        })
    }, [merchant_uid]);

    useEffect(() => {
        localStorage.removeItem('shippingInfo');
        localStorage.removeItem('senderShippingInfo');
        localStorage.removeItem('orderProducts');
        localStorage.removeItem('total_amount');
    }, []);
    
	return (
        <>
            <ModalClose 
                isOpen={isModalCloseOpen} 
                onClose={() => setIsModalCloseOpen(false)}
                text={modalContent.text}
            />
            <SOL.OrderSuccessContainer>
                <div className='order-success-wrapper'>
                    <div className='order-success-title'>
                        <h3>주문이 {orderSummary.alc_order_method === 'bankTransfer' ? "확인" : "완료"} 되었습니다.</h3>
                        <p>{shippingInfo.name}님, 주문해 주셔서 감사합니다.</p>
                    </div>
                    {orderSummary.alc_order_method === 'bankTransfer' && (
                        <div className='bank-transfer-section'>
                            <div className='bank-transfer-price font-pretendard'>
                                {totalPrice.toLocaleString()}<span>원</span>
                            </div>
                            <div className='input-form-box'>
                                <input
                                    type='text'
                                    value={"주식회사 앰비언스 | 부산은행 113-2011-0981-05"}
                                    readOnly
                                />
                                <button
                                    className='copy-icon'
                                    onClick={async () => {
                                        try {
                                            await navigator.clipboard.writeText("부산은행 113-2011-0981-05");
                                            setIsModalCloseOpen(true);
                                            setModalContent({text: "복사를 성공했습니다."});
                                        } catch (err) {
                                            setIsModalCloseOpen(true);
                                            setModalContent({text: "복사를 실패했습니다."});
                                        }
                                    }}
                                >
                                    <img src={IconCopy} alt='icon_copy' />
                                </button>
                            </div>
                        </div>
                    )}
                    <div className='order-success-info'>
                        <div className='info-items'>
                            <span className='label'>주문번호</span>
                            <span className='content font-pretendard'>{merchant_uid}</span>
                        </div>
                            <>
                                <div className='info-items'>
                                    <span className='label'>주문일자</span>
                                    <span className='content font-pretendard'>{orderSummary.alc_order_registe}</span>
                                </div>
                                {orderSummary.alc_order_method === 'bankTransfer' && (
                                    <div className='info-items'>
                                         <span className='label'>입금자명</span>
                                         <span className='content font-pretendard'>{orderSummary.alc_order_bank_transfer_depositor_name}</span>
                                    </div>
                                )}
                                <div className='info-items'>
                                    <span className='label'>{orderSummary.alc_order_method === 'bankTransfer' ? "주문" : "결제"}금액</span>
                                    <span className='content font-pretendard'>{totalPrice.toLocaleString()}원</span>
                                </div>
                            </>
                    </div>
                    <div className='order-success-shipping-info'>
                        <p className='shipping-name'>{shippingInfo.name}</p>
                        <p className='shipping-phone font-pretendard'>{shippingInfo.phoneNumber}</p>
                        <p className='shipping-address font-pretendard'>({shippingInfo.postalCode}) {shippingInfo.address} {shippingInfo.addressDetails}</p>
                    </div>
                    <div className='order-success-product-box'>
                        <div className='product-header' onClick={() => setToggleProductList(!toggleProductList)}>
                            <span>주문상품 {products.length}건</span>
                            <button>
                                <img className={`${toggleProductList || "rotate-180"}`} src={IconArrowUp} alt='arrow_icon' />
                            </button>
                        </div>
                        {toggleProductList && (
                            products.map((tmp, idx) => (
                                <SOL.OrderItem key={idx} className={`order-success-item ${products.length === (idx+1) && "last-item"}`}>
                                    <div className='order-item-wrapper'>
                                        <div className='order-flex-left'>
                                            <div
                                                className='order-image'
                                                onClick={() => {}}
                                            >
                                                <img src={tmp.color_b2c_image} alt={tmp.color_name} />
                                            </div>
                                            <div
                                                className='order-details'
                                                style={{flex: 2}}
                                            >
                                                <h2 className='order-brand-name'>{tmp.brand_eng_name}</h2>
                                                <h1 className="order-product-name">{tmp.product_kor_name}</h1>
                                                <h3 className="order-color-name">{tmp.color_name}</h3>
                                                {tmp.color_option_path && (
                                                    <h3
                                                        style={{marginTop: "8px", lineHeight: "13px", fontSize: "10px"}}
                                                        className="order-color-option-name"
                                                    >
                                                        {`[ 옵션: `}
                                                        {extractColorOptionNodeNames(JSON.parse(tmp.color_option_path))}
                                                        {` ]`}
                                                    </h3>
                                                )}
                                            </div>
                                        </div>
                                        <div className='order-flex-right'>
                                            <div className='order-quantity'>
                                                <span>{tmp.quantity}개</span>
                                            </div>
                                            <div className='order-price-box'>
                                                <div className='price-wrapper'>
                                                    {!tmp.apply_discount_rate_1 || (
                                                        <p className='order-origin-price'>{(tmp.color_b2c_sell_price_ogirinal * (tmp.quantity)).toLocaleString()}원</p>
                                                    )}
                                                    <p className="order-total-price">{(tmp.color_b2c_sell_price * (tmp.quantity)).toLocaleString()}원</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SOL.OrderItem>
                            ))
                        )}
                    </div>
                    <div className='order-success-control-box'>
                        <button
                            onClick={() => navigate('/mypage', {state: {tab_name: "order_list"}})}
                        >
                            주문 확인하기
                        </button>
                        <button className='darker' onClick={() => navigate('/home')}>메인으로 가기</button>
                    </div>
                </div>
            </SOL.OrderSuccessContainer>
        </>
	);
};

export default OrderSuccess;
