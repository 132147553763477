import { IAlcBannerProductList, IColorOptionArray } from "./modules/model";
import { from_locale_string_to_number } from "./modules/Validation";

export const findRange = (number: number) => {
  let start = number % 10 === 0 ? number : Math.floor(number / 10) * 10;
  let end = start + 9;

  if (number <= 9) {
    start = 1;
    end = 9;
  };

  return {
    start,
    end,
  };
}

export const handleScrollToTop = () => {
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  const scrollToPosition = 0.8 * vh;
  setTimeout(() => {
    window.scrollTo({
      top: scrollToPosition,
      left: 0,
    });
  }, 50);
};

export const handleScrollTopPosition = (top: number) => {
  window.scrollTo({ top: top });
};

export const formatColorName = (color: string) => {
  return color.toLowerCase();
};

export const applyDiscountsForList = (
  products: any[],
  apply_discount_rate: any[],
  apply_discount_rate_product_type: any[],
  apply_discount_rate_color: any[],
) => {
  return products.map((product_element: any) => {
    // 선택한 옵션이 있으면 옵션가로 변경
    if (product_element.selected_option) {
      product_element.color_b2c_sell_price_ogirinal = from_locale_string_to_number(product_element.selected_option.color_option_node_product_normal_price);
    }
    let color_array = apply_discount_rate_color.filter(tmp => (
      tmp.color_ID.includes(product_element.color_ID) &&
      Number(tmp.use_status) === 1
    ));
    let type_array = apply_discount_rate_product_type.filter(tmp => (
      tmp.product_type_ID.includes(product_element.product_type_ID) &&
      Number(tmp.brand_ID) === product_element.brand_ID &&
      Number(tmp.use_status) === 1
    ));
    let rate_array = apply_discount_rate.filter(tmp => (
      Number(tmp.brand_ID) === product_element.brand_ID &&
      Number(tmp.use_status) === 1
    ));

    if (color_array.length > 0) {
      const discountRate = parseFloat(color_array[0].apply_discount_rate_1) / 100;
      let discountedPrice = product_element.color_b2c_sell_price_ogirinal * (1 - discountRate);
      if (Number(color_array[0].apply_discount_rate_2) > 0) {
        const discountRate2 = parseFloat(color_array[0].apply_discount_rate_2) / 100;
        discountedPrice = discountedPrice * (1 - discountRate2);
      }

      return {
        ...product_element,
        color_b2c_sell_price: discountedPrice,
        apply_discount_rate_1: color_array[0].apply_discount_rate_1,
        apply_discount_rate_2: color_array[0].apply_discount_rate_2,
        apply_discount_rate_vat: color_array[0].apply_discount_rate_vat,
      }
    } else if (type_array.length > 0) {
      const discountRate = parseFloat(type_array[0].apply_discount_rate_1) / 100;
      let discountedPrice = product_element.color_b2c_sell_price_ogirinal * (1 - discountRate);
      if (Number(type_array[0].apply_discount_rate_2) > 0) {
        const discountRate2 = parseFloat(type_array[0].apply_discount_rate_2) / 100;
        discountedPrice = discountedPrice * (1 - discountRate2);
      }

      return {
        ...product_element,
        color_b2c_sell_price: discountedPrice,
        apply_discount_rate_1: type_array[0].apply_discount_rate_1,
        apply_discount_rate_2: type_array[0].apply_discount_rate_2,
        apply_discount_rate_vat: type_array[0].apply_discount_rate_vat,
      }
    } else if (rate_array.length > 0) {
      if (product_element.product_portable_status === 1) {
        const discountRate = parseFloat(rate_array[0].apply_discount_rate_portable_1) / 100;
        let discountedPrice = product_element.color_b2c_sell_price_ogirinal * (1 - discountRate);
        if (Number(rate_array[0].apply_discount_rate_portable_2) > 0) {
          const discountRate2 = parseFloat(rate_array[0].apply_discount_rate_portable_2) / 100;
          discountedPrice = discountedPrice * (1 - discountRate2);
        }

        return {
          ...product_element,
          color_b2c_sell_price: discountedPrice,
          apply_discount_rate_1: rate_array[0].apply_discount_rate_portable_1,
          apply_discount_rate_2: rate_array[0].apply_discount_rate_portable_2,
          apply_discount_rate_vat: rate_array[0].apply_discount_rate_vat_portable,
        }
      } else {
        const discountRate = parseFloat(rate_array[0].apply_discount_rate_1) / 100;
        let discountedPrice = product_element.color_b2c_sell_price_ogirinal * (1 - discountRate);
        if (Number(rate_array[0].apply_discount_rate_2) > 0) {
          const discountRate2 = parseFloat(rate_array[0].apply_discount_rate_2) / 100;
          discountedPrice = discountedPrice * (1 - discountRate2);
        }

        return {
          ...product_element,
          color_b2c_sell_price: discountedPrice,
          apply_discount_rate_1: rate_array[0].apply_discount_rate_1,
          apply_discount_rate_2: rate_array[0].apply_discount_rate_2,
          apply_discount_rate_vat: rate_array[0].apply_discount_rate_vat,
        }
      }
    }
    return product_element;
  });
};

export const applyDiscountsForBannerProduct = (
  products: any[],
  apply_discount_rate: any[],
  apply_discount_rate_product_type: any[],
  apply_discount_rate_color: any[],
) => {
  return products.map((product_element: any) => {
    let color_array = apply_discount_rate_color.filter(tmp => (
      tmp.color_ID.includes(product_element.alc_banner_product_color_ID) &&
      Number(tmp.use_status) === 1
    ));
    let type_array = apply_discount_rate_product_type.filter(tmp => (
      tmp.product_type_ID.includes(product_element.alc_banner_product_type_ID) &&
      Number(tmp.brand_ID) === Number(product_element.alc_banner_product_brand_ID) &&
      Number(tmp.use_status) === 1
    ));
    let rate_array = apply_discount_rate.filter(tmp => (
      Number(tmp.brand_ID) === Number(product_element.alc_banner_product_brand_ID) &&
      Number(tmp.use_status) === 1
    ));

    if (color_array.length > 0) {
      const discountRate = parseFloat(color_array[0].apply_discount_rate_1) / 100;
      let discountedPrice = product_element.alc_banner_product_original_price * (1 - discountRate);
      if (Number(color_array[0].apply_discount_rate_2) > 0) {
        const discountRate2 = parseFloat(color_array[0].apply_discount_rate_2) / 100;
        discountedPrice = discountedPrice * (1 - discountRate2);
      }
      return {
        ...product_element,
        alc_banner_product_final_price: discountedPrice,
        apply_discount_rate_1: color_array[0].apply_discount_rate_1,
        apply_discount_rate_2: color_array[0].apply_discount_rate_2,
        apply_discount_rate_vat: color_array[0].apply_discount_rate_vat,
      }
    } else if (type_array.length > 0) {
      const discountRate = parseFloat(type_array[0].apply_discount_rate_1) / 100;
      let discountedPrice = product_element.alc_banner_product_original_price * (1 - discountRate);
      if (Number(type_array[0].apply_discount_rate_2) > 0) {
        const discountRate2 = parseFloat(type_array[0].apply_discount_rate_2) / 100;
        discountedPrice = discountedPrice * (1 - discountRate2);
      }
      return {
        ...product_element,
        alc_banner_product_final_price: discountedPrice,
        apply_discount_rate_1: type_array[0].apply_discount_rate_1,
        apply_discount_rate_2: type_array[0].apply_discount_rate_2,
        apply_discount_rate_vat: type_array[0].apply_discount_rate_vat,
      }
    } else if (rate_array.length > 0) {
      if (product_element.alc_banner_product_portable_status === 1) {
        const discountRate = parseFloat(rate_array[0].apply_discount_rate_portable_1) / 100;
        let discountedPrice = product_element.alc_banner_product_original_price * (1 - discountRate);
        if (Number(rate_array[0].apply_discount_rate_portable_2) > 0) {
          const discountRate2 = parseFloat(rate_array[0].apply_discount_rate_portable_2) / 100;
          discountedPrice = discountedPrice * (1 - discountRate2);
        }
        return {
          ...product_element,
          alc_banner_product_final_price: discountedPrice,
          apply_discount_rate_1: rate_array[0].apply_discount_rate_portable_1,
          apply_discount_rate_2: rate_array[0].apply_discount_rate_portable_2,
          apply_discount_rate_vat: rate_array[0].apply_discount_rate_vat_portable,
        } 
      } else {
        const discountRate = parseFloat(rate_array[0].apply_discount_rate_1) / 100;
        let discountedPrice = product_element.alc_banner_product_original_price * (1 - discountRate);
        if (Number(rate_array[0].apply_discount_rate_2) > 0) {
          const discountRate2 = parseFloat(rate_array[0].apply_discount_rate_2) / 100;
          discountedPrice = discountedPrice * (1 - discountRate2);
        }
        return {
          ...product_element,
          alc_banner_product_final_price: discountedPrice,
          apply_discount_rate_1: rate_array[0].apply_discount_rate_1,
          apply_discount_rate_2: rate_array[0].apply_discount_rate_2,
          apply_discount_rate_vat: rate_array[0].apply_discount_rate_vat,
        }
      }
    }

    return product_element
  });
};

// 랜덤코드 생성
export const generateRandomCode = (n: number) => {
  let str = ''
  for (let i = 0; i < n; i++) {
    str += Math.floor(Math.random() * 10)
  }
  return str;
};

export const applyDiscountByPromotion = (
  promotion_list: any[],
) => {
  const list: any[] = [];
  promotion_list.forEach((promotion_row: any) => {
    JSON.parse(promotion_row.aos_promotion_apply_rate).forEach((rate_row: any) => {
      let obj = {
        aos_promotion_ID: promotion_row.aos_promotion_ID,
        discount_rate: rate_row.discount_rate,
        discount_target_amount: rate_row.discount_target_amount,
        granted_ablack_point: rate_row.granted_ablack_point,
        aos_promotion_name: promotion_row.aos_promotion_name,
      }
      list.push(obj);
    });
  });

  // 전체 항목에서 discount_rate가 가장 큰 항목을 선택
  const result = Object.values(
    list.reduce((acc, obj) => {
      if (!acc[obj.discount_target_amount] || obj.discount_rate > acc[obj.discount_target_amount].discount_rate) {
        acc[obj.discount_target_amount] = obj;
      }
      return acc;
    }, {})
  );

  // discount_rate가 큰 순서대로 정렬
  result.sort((a: any, b: any) => b.discount_rate - a.discount_rate);

  return result;
};

export const sortingAvailablePromotionList = (
  promotion_list: any[],
) => {
  const list: any[] = [];
  promotion_list.forEach((promotion_row: any) => {
    JSON.parse(promotion_row.aos_promotion_apply_rate).forEach((rate_row: any) => {
      let obj = {
        aos_promotion_ID: promotion_row.aos_promotion_ID,
        discount_rate: rate_row.discount_rate,
        discount_target_amount: rate_row.discount_target_amount,
        granted_ablack_point: rate_row.granted_ablack_point,
        aos_promotion_name: promotion_row.aos_promotion_name,
        aos_promotion_start_date: promotion_row.aos_promotion_start_date,
        aos_promotion_end_date: promotion_row.aos_promotion_end_date,
      }
      list.push(obj);
    });
  });

  const result = Object.values(
    list.reduce((acc, obj) => {
      if (!acc[obj.discount_target_amount] || obj.discount_rate > acc[obj.discount_target_amount].discount_rate) {
        acc[obj.discount_target_amount] = obj;
      }
      return acc;
    }, {})
  );
  result.sort((a: any, b: any) => a.discount_target_amount - b.discount_target_amount);
  return result;
};

// 판매 금액 1차, 2차 할인율 계산기
export const discountRateCalculator = (
  original_price: number,
  discount_rate_1: number,
  discount_rate_2: number = 0,
) => {
  let calculated_price = original_price - (original_price * discount_rate_1 / 100);

  if (discount_rate_2 > 0) {
    calculated_price = calculated_price - (calculated_price * discount_rate_2 / 100);
  }

  return calculated_price;
};

export const applyDiscountRateForPurpose = (
  division: number, // 1. 특정제품, 2. 브랜드 & 제품타입, 3. 브랜드, 4. 포터블
  filtered_rate: any[],
) => {
  let obj = {
    apply_discount_rate_1: '',
    apply_discount_rate_2: '',
    apply_discount_rate_ID: '',
    apply_discount_rate_vat: '1',
    brand_ID: '',
    product_type_ID: [],
    color_ID: [],
    use_status: '',
  }

  if (filtered_rate.length === 0) {
    return obj;
  }

  if (division === 1) {
    obj = {
      apply_discount_rate_1: filtered_rate[0].apply_discount_rate_1,
      apply_discount_rate_2: filtered_rate[0].apply_discount_rate_2,
      apply_discount_rate_ID: filtered_rate[0].apply_discount_rate_ID,
      apply_discount_rate_vat: filtered_rate[0].apply_discount_rate_vat,
      brand_ID: '',
      product_type_ID: [],
      color_ID: filtered_rate[0].color_ID,
      use_status: filtered_rate[0].use_status,
    }
  } else if (division === 2) {
    obj = {
      apply_discount_rate_1: filtered_rate[0].apply_discount_rate_1,
      apply_discount_rate_2: filtered_rate[0].apply_discount_rate_2,
      apply_discount_rate_ID: filtered_rate[0].apply_discount_rate_ID,
      apply_discount_rate_vat: filtered_rate[0].apply_discount_rate_vat,
      brand_ID: filtered_rate[0].brand_ID,
      product_type_ID: filtered_rate[0].product_type_ID,
      color_ID: [],
      use_status: filtered_rate[0].use_status,
    }
  } else if (division === 3) {
    obj = {
      apply_discount_rate_1: filtered_rate[0].apply_discount_rate_1,
      apply_discount_rate_2: filtered_rate[0].apply_discount_rate_2,
      apply_discount_rate_ID: filtered_rate[0].apply_discount_rate_ID,
      apply_discount_rate_vat: filtered_rate[0].apply_discount_rate_vat,
      brand_ID: filtered_rate[0].brand_ID,
      product_type_ID: [],
      color_ID: [],
      use_status: filtered_rate[0].use_status,
    }
  } else if (division === 4) {
    obj = {
      apply_discount_rate_1: filtered_rate[0].apply_discount_rate_portable_1,
      apply_discount_rate_2: filtered_rate[0].apply_discount_rate_portable_2,
      apply_discount_rate_ID: filtered_rate[0].apply_discount_rate_ID_portable,
      apply_discount_rate_vat: filtered_rate[0].apply_discount_rate_vat_portable,
      brand_ID: filtered_rate[0].brand_ID,
      product_type_ID: [],
      color_ID: [],
      use_status: filtered_rate[0].use_status,
    }
  }
  return obj;
};

// 옵션 리스트 내의 노드들 직렬화 해서 해당 배열에서 cart 로컬 스토리지의 color_option_ID 랑 매치해서 옵션 정보 product_list에 추가하기
export const serializationOptionArray = (
  cart_local_list: any[],
  cart_result_list: any[],
) => {
  const merged_array = cart_local_list.map((tmp, idx) => ({
    ...tmp,
    ...cart_result_list[idx],
  }));

  merged_array.forEach(element => {
    if (!element.color_option_array || element.color_option_status !== 1) return;
    const option_array: IColorOptionArray = JSON.parse(element.color_option_array);

    const extractChildrenNodes = (node: any) => {
      const list: any = [];
      const traverse = (currentNode: IColorOptionArray) => {
        if (currentNode.children.length === 0) return;
        currentNode.children.forEach((node_element: IColorOptionArray) => {
          const { children, ...filtered_element } = node_element;
          list.push(filtered_element);
        });
        currentNode.children.forEach(traverse);
      }
      traverse(node);
      return list;
    };
    if (option_array.children.length === 0) return;
    const serialization_list: any = extractChildrenNodes(option_array);

    const color_option_ID = element.color_option_ID;
    const color_option_path = element?.color_option_path;
    const color_option_node_name_list = extractColorOptionNodeNames(JSON.parse(color_option_path));
    
    let selected_option = serialization_list.filter((tmp: any) => tmp.id === color_option_ID);
    element.color_b2c_sell_price_ogirinal = from_locale_string_to_number(selected_option[0].color_option_node_product_normal_price);
    element.color_b2c_sell_price = from_locale_string_to_number(selected_option[0].color_option_node_product_normal_price);
    element.color_option_node_list = selected_option[0].color_option_node_list;
    element.color_option_node_name_list = color_option_node_name_list;
  });
  return merged_array;
};

export const extractColorOptionNodeNames = (node: any): string[] => {
  const names: string[] = [];
  const traverse = (currentNode: any) => {
    if (!currentNode) return;
    let text = ``;
    if (Number(currentNode.color_option_node_status) === 1) {
      text = `${currentNode.color_option_node_list}`;
    } else {
      text = `${currentNode.color_option_node_group}`;
    }
    if (currentNode.children.length > 0) {
      names.push(text + " / ");
    } else {
      names.push(text);
    }
    if (currentNode.children && currentNode.children.length > 0) {
      currentNode.children.forEach(traverse);
    }
  };
  traverse(node);
  return names;
};

export const extractOnlyLastNodeName = (node: any[]) => {
  let last_node_name: string = '';

  last_node_name = node[node.length-1];
  
  return last_node_name;
};

export const mappingBannerProductData = (
  mongo_array: IAlcBannerProductList[],
  sql_array: any[],
) => {
  // console.log('mongo => ', mongo_array);
  const list: any = [];
  mongo_array.forEach(element => {
    const sql_obj = sql_array.find(tmp => tmp.color_ID === element.alc_banner_product_color_ID);
    // console.log('sql_obj => ', sql_obj);
    let obj = {
      alc_banner_product_color_ID: element.alc_banner_product_color_ID,
      alc_banner_product_link_url: `https://shop.ambience.kr/product_detail?color_ID=${element.alc_banner_product_color_ID}`,
      alc_banner_product_order: element.alc_banner_product_order,
      alc_banner_product_brand_name: sql_obj.brand_eng_name,
      alc_banner_product_brand_ID: sql_obj.brand_ID,
      alc_banner_product_name: sql_obj.product_kor_name,
      alc_banner_product_type_ID : sql_obj.product_type_ID,
      alc_banner_product_portable_status : sql_obj.product_portable_status,
      alc_banner_product_original_price: sql_obj.color_b2c_sell_price,
      alc_banner_product_final_price: sql_obj.color_b2c_sell_price,
      alc_banner_product_color: sql_obj.color_name,
      alc_banner_product_img: element.alc_banner_product_img,
      alc_banner_product_img_mobile: element.alc_banner_product_img_mobile,
      alc_banner_product_img_hover: element.alc_banner_product_img_hover,
    }
    list.push(obj);
  });
  return list;
};