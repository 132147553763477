import React, { FC, useEffect, useState } from 'react';
import useCheckSession from '../../hooks/useCheckSession';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import ProductList from '../../layout/Product/ProductList';
import MobileNav from '../../layout/Header/MobileNav';
import { getMinimumAndMaximumPrice, getSessionData } from '../../Axios';

const ProductPage: FC = () => {
	useCheckSession();
	const { search } = window.location;
	const brand_ID = new URLSearchParams(search).get('brand_ID') || '';
	const [customerAlcStatus, setCustomerAlcStatus] = useState(2);
	const [minMaxPrice, setMinMaxPrice] = useState({
		min_price: 0,
		max_price: 0,
	});

	useEffect(() => {
		getMinimumAndMaximumPrice(brand_ID).then(result => {
			if (result.length > 0) {
				setMinMaxPrice(result[0]);
			}
		})
	}, [brand_ID]);

	useEffect(() => {
		getSessionData().then(result => {
			setCustomerAlcStatus(result.alc_status);
		});
	}, []);

	return (
		<PageWrapper title={'상품리스트'} >
			<ProductList
				customerAlcStatus={customerAlcStatus}
				brand_ID={brand_ID}
				minMaxPrice={minMaxPrice}
			/>
			<MobileNav />
		</PageWrapper>
	);
};
ProductPage.displayName = 'ProductPage';
export default ProductPage;
