import React, { FC, useEffect, useRef, useState } from 'react'
import * as DABIN from "../../styles/dabin/StyledComponentDabin";
import { IAlcBannerData,AlcBannerDataDefaultValue } from "../../modules/model";

interface IMainPromotionProps {
  MainPagePromotionData: {
    headerBanner: IAlcBannerData;
    mainBanner: IAlcBannerData;
    subBanner: IAlcBannerData;
    eventBanner: IAlcBannerData;
  };
};

const MainTopBanner: FC<IMainPromotionProps> = ({ MainPagePromotionData }) => {

  useEffect(() => {
    setHeaderBannerData(MainPagePromotionData.headerBanner)
  },[MainPagePromotionData.headerBanner]);

  const [headerBannerData, setHeaderBannerData] = useState<IAlcBannerData>(AlcBannerDataDefaultValue);

  const handleClick = () => {
    window.location.href =`${headerBannerData.alc_banner_link_url}`;
    window.scrollTo(0, 0);
  };
  const handleCancel = ()=> {
    setShowVideo(false);
    
  }

  const [showVideo, setShowVideo] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setShowVideo(true);
    }, 1000);
  };

  const handleMouseLeave = () => {
    // 타이머 취소
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  
  return (
    <>
      <DABIN.MainTopBannerContainer>
        {headerBannerData.alc_banner_ID > 0 && (
          <>
            <div 
              className='TopBannerContainer'
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
                <span>{headerBannerData.alc_banner_description_1} <b>{headerBannerData.alc_banner_description_2} </b></span>{headerBannerData.alc_banner_description_3}
            </div>
            {/* 비디오 영상 */}
            {showVideo && (
              <div className='TopBanner_VideoBackground' onClick={handleCancel}>
                <div className="TopBanner_VideoContainer">
                    <iframe
                      src={`${headerBannerData.alc_banner_video_url}`}
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                      title="Ablack Movie">
                    </iframe>
                </div>
              </div>
            )}
          </>
        )}
      </DABIN.MainTopBannerContainer>
    </>
  )
}

export default MainTopBanner