import React, { FC } from "react";
import * as SOL from "../styles/solda/StyledComponentSol";
import NewWindow from "react-new-window";

interface MarketingConsentProps {
  onUnload: () => void;
};

const MarketingConsent: FC<MarketingConsentProps> = ({onUnload}) => {

  return (
    <NewWindow onUnload={onUnload}>
      <SOL.TermsTemplate>
        <div className="about-box">
          <div className="about_title text-center"><p className="noto_b f-size-20">마케팅정보 수신동의</p></div>
          <div className="terms_content">
            <p className="noto_r f-size-16">
              개인정보보호법 제22조 제4항에 의해 선택정보 사항에 대해서는 기재하지 않으셔도 서비스를 이용하실 수 있습니다.
            </p>
          </div>
          <div className="terms_title"><p className="noto_b f-size-18">1. 마케팅 및 광고에의 활용</p></div>
          <div className="terms_content">
            <p className="noto_r f-size-16">
              - 신규 기능 개발 및 맞춤 서비스 제공
            </p>
            <p className="noto_r f-size-16">
              - 뉴스레터 발송, 새로운 기능(제품)의 안내
            </p>
            <p className="noto_r f-size-16">
              - 할인 및 쿠폰 등 이벤트 등 광고성 정보 제공
            </p>
          </div>
          <div className="terms_title">
            <p className="noto_b f-size-18">
              2. 에이블랙 스토어 서비스를 운용함에 있어 각종 정보를 서비스 화면, SMS, 이메일 등의 방법으로 회원에게 제공할 수 있으며, 결제안내 등 의무적으로 안내되어야 하는 정보성 내용 및 일부 혜택성 정보는 수신동의 여부와 무관하게 제공합니다.
            </p>
          </div>
        </div>
      </SOL.TermsTemplate>
    </NewWindow>
  )
};

export default MarketingConsent;