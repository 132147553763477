import React, { useEffect, useState } from "react"
import * as SOL from "../../styles/solda/StyledComponentSol";
import back_arrow from "../../assets/icons/pagination_back_arrow.svg";
import front_arrow from "../..//assets/icons/pagination_front_arrow.svg";
import back_arrow_double from "../../assets/icons/pagination_back_arrow_double.svg";
import front_arrow_double from "../../assets/icons/pagination_front_arrow_double.svg";

interface Props {
  totalCount : number;
  listCount  : number;
  handlePage : (page: number) => void;
  page       : number;
};

const Pagination: React.FC<Props> = ({totalCount, listCount, handlePage, page}) => {
  const [paginationList, setPaginationList] = useState<any>([]);
  const [currentPages, setCurrentPages] = useState<number[]>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [nowPage, setNowPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(0);

  useEffect(() => {
    if(totalCount !== 0) {
      let list = handlePagination(totalCount, listCount);
      setPaginationList(list);
      setCurrentPages(list[currentPageIndex]);
    } else if (totalCount === 0) {
      setPaginationList([]);
      setCurrentPages([]);
    }
  }, [totalCount, listCount, currentPageIndex]);

  useEffect(() => {
    if (typeof page !== 'number') return;
    if (page > 5) {
      if (page % 5 === 0) {
        setCurrentPageIndex(Math.floor(page/5)-1);
      } else {
        setCurrentPageIndex(Math.floor(page/5));
      }
    }
    setNowPage(page);
    if (page === 1) {
      setCurrentPageIndex(0);
    }
  }, [page]);

  const handlePagination = (totalPages: number, listCount: number) => {
    let total = totalPages;
    let page_count = 0;

    if(total%listCount === 0) {
      page_count = Math.floor(total/listCount);
    } else {
      page_count = Math.floor(total/listCount)+1;
    };

    setLastPage(page_count);

    let list = [];
    for(let i = 1; i < page_count+1; i++) {
      list.push(i);
    };

    let result = [];
    for(let i = 0; i < list.length; i += 5) {
      let tempArray;
      tempArray = list.slice(i, i+5);
      result.push(tempArray);
    };
    return result;
  };

  return (
    <SOL.Pagination>
      {currentPageIndex >= 1 ? (
        <div className="page-navigation-box">
          <button
            onClick={() => {
                if(currentPageIndex < 1) return;
                setCurrentPageIndex(0);
                handlePage(1);
            }}
          >
            <img src={back_arrow_double} alt="" />
          </button>
          <button
            className={`${!currentPageIndex ? "cursor-default" : ""}`}
            onClick={() => {
                if(currentPageIndex < 1) return;
                setCurrentPageIndex(currentPageIndex-1);
                const prevPage = paginationList[currentPageIndex-1][4];
                handlePage(prevPage);
            }}
          >
            <img src={back_arrow} alt="" />
          </button>
        </div>
      ) : (
        <div className="page-navigation-box" />
      )}
      {currentPages.map((tmp, idx) =>
        <div
          key={idx}
          className={`pagination-link ${nowPage === tmp ? "active" : "cursor-pointer"}`}
          onClick={() => {
            handlePage(tmp);
          }}
        >
          {tmp}
        </div>
      )}
      {(paginationList.length > currentPageIndex+1) ? (
        <div className="page-navigation-box">
          <button
            className={`${currentPageIndex === paginationList.length-1 ? "cursor-default" : ""}`}
            onClick={() => {
                if(paginationList.length-1 > currentPageIndex) {
                    setCurrentPageIndex(currentPageIndex+1);
                    const nextPage = paginationList[currentPageIndex+1][0];
                    handlePage(nextPage);
                }
            }}
          >
            <img src={front_arrow} alt="" />
          </button>
          <button
            onClick={() => {
              setCurrentPageIndex(paginationList.length-1);
              handlePage(lastPage);
            }}
          >
            <img src={front_arrow_double} alt="" />
          </button>
        </div>
      ) : (
        <div className="page-navigation-box" />
      )}
    </SOL.Pagination>
  )
};

export default Pagination;