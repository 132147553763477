import React, { FC } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import NotFound from "../../layout/NotFound/NotFound";

const NotFoundPage: FC = () => {

  return (
    <PageWrapper title="페이지를 찾을 수 없습니다">
      <NotFound />
    </PageWrapper>
  )
};

export default NotFoundPage;