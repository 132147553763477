import React, { FC, useState, useEffect } from "react";
import { useDiscountRateContext } from "../../context/DiscountRateContext";
import { OrderContainer } from '../../styles/solda/StyledComponentSol';
import { getIntegratedInfo, getProductCartAlc } from "../../Axios";
import { applyDiscountsForList, serializationOptionArray } from "../../Utils";
import OfferDetails from "../../components/Offer/OfferDetails";
import OfferShipping from "../../components/Offer/OfferShipping";
import { useLocation, useNavigate } from "react-router-dom";
import { ICustomerData } from "../../modules/model";

interface IOfferProps {
  customerID: number;
}

interface IProduct {
  apply_discount_rate_1: string;
  apply_discount_rate_vat: string;
  brand_ID: number;
  brand_eng_name: string;
  brand_kor_name: string;
  color_ID: number;
  color_b2c_detail_image: string;
  color_b2c_image: string;
  color_b2c_image_hover: string;
  color_b2c_sell_price: number;
  color_b2c_sell_price_ogirinal: number;
  color_name: string;
  product_kor_name: string;
  quantity: number;
  memo: string;
}

const Offer: FC<IOfferProps> = ({customerID}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { discountRateInfo, discountRateProductTypeInfo, discountRateColorInfo } = useDiscountRateContext();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [respectiveShippingMemo, setRespectiveShippingMemo] = useState<boolean>(false);
  const [selectedProductsWithQuantities, setSelectedProductsWithQuantities] = useState<any[]>([]);
  const [priceData, setPriceData] = useState<any>({
    supply_price: 0,
    total_vat: 0,
    promotion_discount: 0,
  });
  const [appliedPromotion, setAppliedPromotion] = useState<any>(null);
  const [customerData, setCustomerData] = useState<ICustomerData>({
    account_detail_address: '',
    account_name: '',
    account_phone_number1: '',
    account_road_address: '',
    account_zip_code: '',
    customer_ID: 0,
    customer_big_area: '',
    customer_detail_address: '',
    customer_name: '',
    customer_phone_number: '',
    customer_road_address: '',
    customer_small_area: '',
    customer_zip_code: '',
  });

  useEffect(() => {
    if (!customerID) return;
    getIntegratedInfo(
      customerID,
    ).then(result => {
      setCustomerData(result[0]);
    });
  }, [customerID]);

  useEffect(() => {
    if (!location.state) {
      navigate('/home');
    } else if (!location.state.selected_product || location.state.selected_product.length === 0) {
      navigate('/home');
    } else if (!location.state.price_data) {
      navigate('/home');
    } else {
      setSelectedProductsWithQuantities(location.state.selected_product);
      setPriceData(location.state.price_data);
      if (location.state.applied_promotion !== null) {
        setAppliedPromotion(location.state.applied_promotion);
      }
    }
  }, [location, navigate]);

  useEffect(() => {
    if (selectedProductsWithQuantities.length === 0) return;
    getProductCartAlc(JSON.stringify(selectedProductsWithQuantities)).then(result => {
      try {
        const obj: any = {};
        selectedProductsWithQuantities.forEach((tmp: any) => {
          obj[tmp.color_ID] = (obj[tmp.color_ID] || 0) +1;
        });
        let list: any = [];
        selectedProductsWithQuantities.forEach((tmp: any) => {
          if (obj[tmp.color_ID] > 0) {
            list.push(result.filter((result_item: any) => result_item.color_ID === tmp.color_ID)[0]);
						obj[tmp.color_ID] = obj[tmp.color_ID] - 1;
          }
        });
        let apply_option_price = serializationOptionArray(selectedProductsWithQuantities, list);
        const productsWithQuantity = apply_option_price.map((product: any) => ({
					...product,
          memo: '',
					quantity: selectedProductsWithQuantities.find((item: { color_ID: number, color_option_ID: number }) => (
            item.color_ID === product.color_ID && item.color_option_ID === product.color_option_ID
          ))?.quantity || 1,
          color_option_ID: product.color_option_ID || 0,
				}));
        const updatedProducts = applyDiscountsForList(
          productsWithQuantity,
          discountRateInfo,
          discountRateProductTypeInfo,
          discountRateColorInfo,
        );
        setProducts(updatedProducts);
      } catch (err) {
        console.error("Fetch 실패", err);
      } finally {

      }
    });
  }, [discountRateInfo, discountRateProductTypeInfo, discountRateColorInfo, selectedProductsWithQuantities]);

  const changeRespectiveDetailMemo = (idx: number, memo_text: string) => {
    let product_array: IProduct[] = [...products];
    product_array[idx].memo = memo_text;
    setProducts(product_array);
  };

  const handleRespectiveShippingMemo = () => {
		setRespectiveShippingMemo(!respectiveShippingMemo);
	};

  return (
    <OrderContainer>
      <div className="title">발주/오더</div>
      <div className="shipping-and-details">
        <OfferShipping
          customerData={customerData}
          respectiveShippingMemo={respectiveShippingMemo}
          handleRespectiveShippingMemo={handleRespectiveShippingMemo}
        />
        <OfferDetails
          customerData={customerData}
          products={products}
          respectiveShippingMemo={respectiveShippingMemo}
          priceData={priceData}
          appliedPromotion={appliedPromotion}
          changeRespectiveDetailMemo={changeRespectiveDetailMemo}
        />
      </div>
    </OrderContainer>
  )
};

export default Offer;