import React, { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { buildStatus, insertAlcOrder } from "../../Axios";
import ModalLoading from "../../components/Modal/ModalLoading";

const OrderMobile: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(()=> {
    const cashReceiptsInfo = {
      depositor_name: '',
      cash_receipts: 0,
      phone_number: '',
      business_number: '',
    };
    const shippingInfo = JSON.parse(localStorage.getItem('shippingInfo') || '');
    const orderProducts = JSON.parse(localStorage.getItem('orderProducts') || '');
    const total_amount = localStorage.getItem('total_amount');
    
    const imp_success = searchParams.get('imp_success');
    const imp_uid = searchParams.get('imp_uid');
    const merchant_uid = searchParams.get('merchant_uid');
    const amount = searchParams.get('amount');
    const totalAmount = (buildStatus === 'test' ? 1000 : total_amount);
    if (imp_success === 'true') {
      if (Number(totalAmount) === Number(amount)) {
        insertAlcOrder(
          JSON.stringify(shippingInfo),
          JSON.stringify(orderProducts),
          merchant_uid,
          imp_uid,
          JSON.stringify(cashReceiptsInfo),
          Number(total_amount),
        ).then(result => {
          if (result === 1) {
            navigate('/order_success', {state: {shippingInfo: shippingInfo, products: orderProducts, merchant_uid: merchant_uid}});
          } else {
            navigate('/order_failure');
          }
        })
      } else {
        navigate('/order_failure');
      }
    } else {
      navigate('/order_failure');
    }
  }, [navigate, searchParams]);

  return (
    <>
      <ModalLoading isOpen={true} />
    </>
  )
};

export default OrderMobile;