import React, { FC, ReactNode } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { OrderProvider } from './context/OrderContext';
import HomePage from './pages/Home/HomePage';
import ProductPage from './pages/Product/ProductPage';
import ProductDetailPage from './pages/Product/ProductDetailPage';
import CartPage from './pages/Cart/CartPage';
import OrderPage from './pages/Order/OrderPage';
import OrderSuccessPage from './pages/Order/OrderSuccessPage';
import FloatingActionButton from './layout/Shared/FloatingActionButton';
import Header from './layout/Header/Header';
import Footer from './layout/Footer/Footer';
import { CartProvider } from './context/CartContext';
import JoinPage from './pages/Join/JoinPage';
import LoginPage from './pages/Login/LoginPage';
import { DiscountRateProvider } from './context/DiscountRateContext';
import OfferPage from './pages/Offer/OfferPage';
import MyPage from './pages/My/MyPage';
import FindAccount from './layout/Join/FindAccount';
import OrderFailurePage from './pages/Order/OrderFailurePage';
import RecentProductPage from './pages/RecentProduct/RecentProductPage';
import TermsOfServicePage from './pages/Utility/TermsOfServicePage';
import PrivacyPolicyPage from './pages/Utility/PrivacyPolicyPage';
import OfferSuccessPage from './pages/Offer/OfferSuccessPage';
import HomeniqPage from './pages/Enterprise/HomeniqPage';
import { SearchTextProvider } from './context/SearchTextContext';
import OrderMobilePage from './pages/Order/OrderMobilePage';
import NotFoundPage from './pages/Utility/NotFoundPage';
import EstimateSuccessPage from './pages/Estimate/EstimateSuccessPage';
import { ShowInventoryProvider } from './context/ShowInventoryContext';
import PromotionPage from './pages/Service/PromotionPage';
import ServicePage from './pages/Service/ServicePage';
import PromotionDetailPage from './pages/Service/PromotionDetailPage';

const providers = [
  OrderProvider,
  CartProvider,
  DiscountRateProvider,
  SearchTextProvider,
  ShowInventoryProvider,
];

const combineProviders = (components: FC<{ children?: ReactNode }>[]): FC<{ children?: ReactNode }> => {
  return components.reduce<FC<{ children?: ReactNode }>>(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }): JSX.Element => {
        return (
          <CurrentComponent>
            <AccumulatedComponents>{children}</AccumulatedComponents>
          </CurrentComponent>
        );
      };
    },
    ({ children }) => <>{children}</> 
  );
};

const AppProvider = combineProviders(providers);

const App: FC = () => {
  const location = useLocation();
  
  return (
    <AppProvider>
      {(
        location.pathname !== '/' && 
        location.pathname !== '/join' &&
        location.pathname !== '/homeniq' &&
        location.pathname !== '/not_found'
      ) && <Header />}
      <Routes>
          {/* 쿠폰 페이지 */}
          <Route path='/' element={<LoginPage />} />
          {/* 기업 개별 페이지 */}
          <Route path='/homeniq' element={<HomeniqPage />} />
          {/* 회원가입 페이지 */}
          <Route path='/join' element={<JoinPage />} />
          <Route path='/find' element={<FindAccount />} />
          {/* 홈 페이지 */}
          <Route path='/home' element={<HomePage />} />
          {/* 상품 페이지 */}
          <Route path='/product' element={<ProductPage />} />
          <Route path='/product_detail' element={<ProductDetailPage />} />
          {/* 카트 페이지 */}
          <Route path='/cart' element={<CartPage />} />
          {/* 마이 페이지 */}
          <Route path='/mypage' element={<MyPage />} />
          {/* 오더 페이지 */}
          <Route path='/order' element={<OrderPage />} />
          <Route path='/order_success' element={<OrderSuccessPage />} />
          <Route path='/order_failure' element={<OrderFailurePage />} />
          <Route path='/order_mobile_success' element={<OrderMobilePage />} />
          {/* 오퍼 페이지 */}
          <Route path='/offer' element={<OfferPage />} />
          <Route path='/offer_success' element={<OfferSuccessPage />} />
          {/* 견적서 페이지 */}
          <Route path='/estimate_success' element={<EstimateSuccessPage />} />
          {/* 서비스 안내 페이지 */}
          <Route path='/promotion' element={<PromotionPage />} />
          <Route path='/promotion_detail' element={<PromotionDetailPage />} />
          <Route path='/service' element={<ServicePage />} />
          {/* 기타 페이지 */}
          <Route path='/recent_product' element={<RecentProductPage />} />
          <Route path='/terms_of_service' element={<TermsOfServicePage />} />
          <Route path='/privacy_policy' element={<PrivacyPolicyPage />} />
          <Route path='/*' element={<Navigate to="/not_found" replace />} />
          <Route path='/not_found' element={<NotFoundPage />} />
      </Routes>
      <FloatingActionButton />
      {(
        location.pathname !== '/' && 
        location.pathname !== '/join' && 
        location.pathname !== '/find' &&
        location.pathname !== '/homeniq' &&
        location.pathname !== '/not_found'
      ) && <Footer />}
    </AppProvider>

  );
}

export default App;
