import React, { FC } from "react";
import useCheckSession from "../../hooks/useCheckSession";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import OrderFailure from "../../layout/Order/OrderFailure";

const OrderFailurePage: FC = () => {
  useCheckSession();

  return (
    <PageWrapper title={'주문실패'}>
      <OrderFailure />
    </PageWrapper>
  )
};

OrderFailurePage.displayName = 'OrderFailurePage';

export default OrderFailurePage;