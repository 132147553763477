import React, { FC } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import LouisPoulsen from "../../assets/image_folder/brand_gallery/main_brandBN_louispoulsen.jpg";
import Verpan from "../../assets/image_folder/brand_gallery/main_brandBN_verpan.jpg";
import Luceplan from "../../assets/image_folder/brand_gallery/main_brandBN_luceplan.jpg";
import Vibia from "../../assets/image_folder/brand_gallery/main_brandBN_vibia.jpg";
import Lyfa from "../../assets/image_folder/brand_gallery/main_brandBN_lyfa.jpg";
import { useNavigate } from "react-router-dom";
import { handleScrollTopPosition } from "../../Utils";

const BrandGallery: FC = () => {
  const navigate = useNavigate();

  const handleBrand = (pathname: string) => {
    navigate(pathname);
    handleScrollTopPosition(0);
  }
  
  return (
    <>
      <div className="mt-[120px] text-[30px] font-semibold text-center font-pretendard">
        BRANDS
      </div>
      <SOL.BrandGallery>
        <div className="gallery-wrapper">
          <button
            onClick={() => handleBrand("/product?brand_ID=3")}
            className={`item-box one`}
            style={{
              backgroundImage: `url(${Verpan})`
            }}
          />
          <button
            onClick={() => handleBrand("/product?brand_ID=1")}
            className={`item-box two`}
            style={{
              backgroundImage: `url(${LouisPoulsen})`
            }}
          />
          <button
            onClick={() => handleBrand("/product?brand_ID=2")}
            className={`item-box three`}
            style={{
              backgroundImage: `url(${Luceplan})`
            }}
          />
          <button
            onClick={() => handleBrand("/product?brand_ID=19")}
            className={`item-box four`}
            style={{
              backgroundImage: `url(${Vibia})`
            }}
          />
          <button
            onClick={() => handleBrand("/product?brand_ID=65")}
            className={`item-box five`}
            style={{
              backgroundImage: `url(${Lyfa})`
            }}
          />
        </div>
      </SOL.BrandGallery>
    </>
  )
};

export default BrandGallery;