import React, { FC } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Join from "../../layout/Join/Join";

const JoinPage: FC = () => {

  return (
    <PageWrapper title={'회원가입'}>
      <Join />
    </PageWrapper>
  )
};

export default JoinPage;