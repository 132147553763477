// 이메일 유효성 검사
export const validateEmail = (text: string) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
  return regex.test(text);
}

export const onlyNumber = (number:string) => {
  return number.replace(/[^0-9]/g, '');
};

// 숫자만 입력 및 폰번호 변경
export const removeWhitespacePhone = (text:string) => {
  return text.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
};

export const removeWhiteSpace = (text: string) => {
  return text.replace(' ', '');
}

// 숫자만 입력 및 사업자번호 변경
export const removeWhitespaceBusiness = (text: string) => {
  return text.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
}

// 전화번호형식 확인
export const validatePhone = (phone:string) => {
  const regex = /^\d{3}-\d{3,4}-\d{4}$/;
  return regex.test(phone);
};

// 전화번호에 - 제거
export const eliminateHyphen = (str: string) => {
  let words = str.split('-');
  let result = ""
  for(const tmp of words) {
      result += tmp;
  };
  return result;
};

// 비밀번호 유효성 검사 => 영문/숫자/특수문자 포함 10~20자
export const validatePassword = (password: string) => {
  if (password.length < 10 || password.length > 20) return false;
  const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).+$/;
  return regex.test(password);
}

export const get_today_object = () => {
  const today = new Date();
  const weekstr = ['일', '월', '화', '수', '목', '금', '토'];

  const year = today.getFullYear();
  const month =
    today.getMonth() + 1 > 9
      ? today.getMonth() + 1
      : '0' + (today.getMonth() + 1);
  const day = today.getDate() > 9 ? today.getDate() : '0' + today.getDate();
  const hour = today.getHours();
  const minute = today.getMinutes();

  return {
    year: today.getFullYear(), // 년도
    month: today.getMonth() + 1, // 월
    date: today.getDate(), //일
    day: today.getDay(), // 요일
    day_kor_name: weekstr[today.getDay()], // 요일
    year_month_day_time: year + '-' + month + '-' + day + ' ' + hour + '시' + minute + '분',
    year_month_day_time2: year + '-' + month + '-' + day + 'T' + hour + ':' + minute, // datetime-local에 들어가는 format
    year_month_day: year + '-' + month + '-' + day,
    year_month: year + '-' + month,
  };
};

export const get_today_plus_object = (plus_number:number) => {
  const today = new Date();
  today.setDate(today.getDate() + plus_number);
  const weekstr = ['일', '월', '화', '수', '목', '금', '토'];

  const year = today.getFullYear();
  const month =
    today.getMonth() + 1 > 9
      ? today.getMonth() + 1
      : '0' + (today.getMonth() + 1);
  const day = today.getDate() > 9 ? today.getDate() : '0' + today.getDate();

  return {
    year: today.getFullYear(), // 년도
    month: today.getMonth() + 1, // 월
    date: today.getDate(), //일
    day: today.getDay(), // 요일
    day_kor_name: weekstr[today.getDay()], // 요일
    year_month_day: year + '-' + month + '-' + day,
    year_month: year + '-' + month,
  };
};

// localeFormat => 숫자
export const from_locale_string_to_number = (locale_string: string) => {
  let str = locale_string.replaceAll(',', '');
  
  return Number(str);
};