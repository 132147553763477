import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAosPromotionData, getProductCartAlc, insertAosEstimate } from '../../Axios';
import ModalClose from '../../components/Modal/ModalClose';
import { applyDiscountByPromotion, applyDiscountsForList, extractOnlyLastNodeName, handleScrollTopPosition, serializationOptionArray } from '../../Utils';
import * as SOL from "../../styles/solda/StyledComponentSol";
import { useOrderContext } from '../../context/OrderContext';
import { useCartContext } from '../../context/CartContext';
import IconBigBag from "../../assets/icons/icon_bag_large.svg";
import { useDiscountRateContext } from '../../context/DiscountRateContext';
import ModalYesClose from '../../components/Modal/ModalYesClose';
import { useMediaQuery } from 'react-responsive';

interface IProduct {
	brand_ID: number;
	color_ID: number;
	color_b2c_image: string;
	brand_eng_name: string;
	product_kor_name: string;
	color_name: string;
	color_b2c_sell_price_ogirinal: number;
	color_b2c_sell_price: number;
	color_b2c_detail_image: string;
	quantity: number;
	apply_discount_rate_1: string;
	apply_discount_rate_2: string;
	apply_discount_rate_vat: string;
	inventory_status: number;
	inventory_warehouse_date: string;
	inventory_warehouse_date_status: string;
	color_option_status: number;
	color_option_array: string;
	color_option_node_list: string;
	color_option_node_name_list: string[];
	color_option_ID: number;
	color_option_path: string;
}

interface ICartProps {
	customerID: number;
	customerAlcStatus: number;
}

interface ISelectedProducts {
	color_ID: number;
	option_ID: number;
}

const Cart: FC<ICartProps> = ({customerID, customerAlcStatus}) => {
	const navigate = useNavigate();
	const isMobile = useMediaQuery({query: "(max-width: 500px)"});
	const { countCart } = useCartContext();
	const { discountRateInfo, discountRateColorInfo, discountRateProductTypeInfo } = useDiscountRateContext();
	const { resetInputErrors, resetSenderShippingInfo, resetShippingInfo } = useOrderContext();
	const [products, setProducts] = useState<IProduct[]>([]);
	const [selectedProducts, setSelectedProducts] = useState<ISelectedProducts[]>([]);
	const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
	const [modalContent, setModalContent] = useState({text: ''});
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [checkoutButtonName, setcheckoutButtonName] = useState<string>('구매하기');
	const [isModalYesCloseOpen, setIsModalYesCloseOpen] = useState<boolean>(false);
	const [modalYesCloseContent, setModalYesCloseContent] = useState({
    text: '',
    confirmText: '',
    onConfirm: () => {}
  });

	// 선택된 상품 모달
	const [isSelectedProductModal, setIsSelectedProductModal] = useState(false);
	const [aosOrderModalButton, setAosOrderModalButton] = useState<number>(1); // 1. 오더하기, 2. 견적서 저장

	// AOS(B2B) state
	const [promotionList, setPromotionList] = useState<any[]>([]); // 현재 적용가능한 프로모션 리스트
	const [currentPromotionApplyPrice, setCurrentPromotionApplyPrice] = useState<number>(0); // 현재 적용된 프로모션 할인액
	const [appliedPromotion, setAppliedPromotion] = useState<any>(null);

	const totalPrice = products.reduce((acc, product) => {
		if (selectedProducts.filter(tmp => tmp.color_ID === product.color_ID && tmp.option_ID === product.color_option_ID).length > 0) {
			return acc + (product.color_b2c_sell_price * product.quantity);
		}
		return acc;
	}, 0);

	const totalPriceOrigin = products.reduce((acc, product) => {
		if (selectedProducts.filter(tmp => tmp.color_ID === product.color_ID && tmp.option_ID === product.color_option_ID).length > 0) {
			return acc + (product.color_b2c_sell_price_ogirinal * product.quantity);
		}
		return acc;
	}, 0);

	const totalDiscountRateVat = products.reduce((acc, product) => {
		if (selectedProducts.filter(tmp => tmp.color_ID === product.color_ID && tmp.option_ID === product.color_option_ID).length > 0 && product.apply_discount_rate_vat === '0') {
			return acc + ((product.color_b2c_sell_price * product.quantity)*0.1);
		}
		return acc;
	}, 0);

	useEffect(() => {
		let width = window.innerWidth - document.documentElement.offsetWidth;
		if (!isSelectedProductModal) {
			document.body.style.paddingRight = `0`;
			document.body.style.overflowY = 'auto';
		} else {
			document.body.style.paddingRight = `${width}px`;
			document.body.style.overflowY = 'hidden';
		};

		return () => {
			document.body.style.paddingRight = `0`;
			document.body.style.overflowY = 'auto';
		}
	}, [isSelectedProductModal]);

	useEffect(() => {
		if (promotionList.length === 0) return;
		const list = applyDiscountByPromotion(promotionList);
		let sum = totalPrice+totalDiscountRateVat;
		let active_promotion: any = list.find((tmp: any) => tmp.discount_target_amount <= (sum * (1 - (parseFloat(tmp.discount_rate)/100))));
		if (!active_promotion) {
			setCurrentPromotionApplyPrice(0);
			setAppliedPromotion(null);
		} else {
			setCurrentPromotionApplyPrice(Math.floor(sum * (parseFloat(active_promotion.discount_rate)/100)));
			setAppliedPromotion(active_promotion);
		}
	}, [promotionList, totalDiscountRateVat, totalPrice]);

	const toggleProductSelection = (cart_obj: IProduct) => {
		if (!cart_obj.color_option_ID) {
			const list = [...selectedProducts];
			if (list.filter(tmp => tmp.color_ID === cart_obj.color_ID).length > 0) {
				setSelectedProducts(list.filter(tmp => tmp.color_ID !== cart_obj.color_ID));
			} else {
				list.push({color_ID: cart_obj.color_ID, option_ID: 0});
				setSelectedProducts(list);
			}
		} else {
			const list = [...selectedProducts];
			if (list.filter(tmp => tmp.color_ID === cart_obj.color_ID && tmp.option_ID === cart_obj.color_option_ID).length > 0) {
				setSelectedProducts(list.filter(tmp => tmp.color_ID !== cart_obj.color_ID || tmp.option_ID !== cart_obj.color_option_ID));
			} else {
				list.push({color_ID: cart_obj.color_ID, option_ID: cart_obj.color_option_ID});
				setSelectedProducts(list);
			}
		}
	};

	const toggleSelectAll = () => {
		if (selectedProducts.length === products.length) {
		  setSelectedProducts([]);
		} else {
			const list: ISelectedProducts[] = [];
			products.forEach(tmp => {
				list.push({
					color_ID: tmp.color_ID,
					option_ID: tmp.color_option_ID,
				});
			});
			setSelectedProducts(list);
		}
	};

	useEffect(() => {
		if (!customerID) return;
		if (customerAlcStatus === 1) {
			getAosPromotionData(customerID).then(promotion_result => {
				setPromotionList(promotion_result);
			});
		}
	}, [customerAlcStatus, customerID]);

	useEffect(() => {
		if (selectedProducts.length === products.length) {
			setIsAllChecked(true);
		} else {
			setIsAllChecked(false);
		}
	}, [products.length, selectedProducts.length]);

	const deleteSelectedProducts = () => {
		if (selectedProducts.length === 0) return;
		const cartList = JSON.parse(localStorage.getItem('cart') || '[]');
		const updated_cart_list = [...cartList];
		const updated_product_list = [...products];
		selectedProducts.forEach((product) => {
			const target_index = updated_product_list.findIndex(tmp => tmp.color_ID === product.color_ID && tmp.color_option_ID === product.option_ID);
			updated_product_list.splice(target_index, 1);
			updated_cart_list.splice(target_index, 1);
		});
		localStorage.setItem('cart', JSON.stringify(updated_product_list));
		setProducts(updated_product_list);
		setSelectedProducts([]);
		countCart();
		handleScrollTopPosition(0);
	};
	
	const deleteAllProducts = () => {
		localStorage.removeItem('cart');
		setProducts([]);
		setSelectedProducts([]);
		countCart();
		handleScrollTopPosition(0);
	};

	const handleQuantityChange = (product_element: IProduct, newQuantity: number) => {
		const updatedQuantity = Math.max(newQuantity, 1);
		setProducts(products.map(product => 
			(product.color_ID === product_element.color_ID && product.color_option_ID === product_element.color_option_ID) ? { ...product, quantity: updatedQuantity } : product
		));
		const cartList = JSON.parse(localStorage.getItem('cart') || '[]');
		const updatedCartList = cartList.map((item: { color_ID: number; option_ID: number; quantity: number }) => {
			if (item.color_ID === product_element.color_ID && item.option_ID === product_element.color_option_ID) {
				return { ...item, quantity: updatedQuantity };
			}
			return item;
		});
		localStorage.setItem('cart', JSON.stringify(updatedCartList));
	};

	const checkTogglePriceHeaderSwitch = () => {
		const switch_value = localStorage.getItem('show_original_price') || 'false';
		if (JSON.parse(switch_value)) {
			setIsModalCloseOpen(true); 
			setModalContent({
				text: "우측 상단의 '할인율 숨기기' 버튼을 비활성화 시켜주세요."
			});
		}
		return JSON.parse(switch_value);
	};

	const handleOrder = () => {
		const selectedProductsWithQuantities = selectedProducts.map(tmp => {
			const product = products.find(product => product.color_ID === tmp.color_ID && product.color_option_ID === tmp.option_ID);
			return {
				color_ID: product?.color_ID,
				quantity: product ? product.quantity : 1,
				color_option_path: product?.color_option_path,
				color_option_ID: product?.color_option_ID,
			};
		});
		resetInputErrors();
		navigate('/order', { state: selectedProductsWithQuantities });
	};

	const handleOffer = () => {
		if (selectedProducts.length === 0) {
			setIsModalCloseOpen(true); 
			setModalContent({
				text: '결제하실 상품을 선택해주세요.'
			});
			return;
		}
		if (checkTogglePriceHeaderSwitch()) {
			return;
		}
		const selectedProductsWithQuantities = selectedProducts.map(tmp => {
			const product = products.find(product => product.color_ID === tmp.color_ID && product.color_option_ID === tmp.option_ID);
			return {
				color_ID: product?.color_ID,
				quantity: product ? product.quantity : 1,
				color_option_path: product?.color_option_path,
				color_option_ID: product?.color_option_ID,
			};
		});
		resetInputErrors();
		resetShippingInfo();
		resetSenderShippingInfo();
		navigate(
			'/offer',
			{
				state: {
					selected_product: selectedProductsWithQuantities,
					price_data: {
						supply_price: totalPrice,
						total_vat: totalDiscountRateVat,
						promotion_discount: currentPromotionApplyPrice,
					},
					applied_promotion: appliedPromotion,
				},
			},
		);
	};

	// 장바구니 리스트 불러오는 제일 중요한 함수
	useEffect(() => {
		const cartList = JSON.parse(localStorage.getItem('cart') || '[]');
		if (cartList.length === 0) return;
		getProductCartAlc(JSON.stringify(cartList)).then(result => {
			try {
				const obj: any = {};
				cartList.forEach((tmp: any) => {
					obj[tmp.color_ID] = (obj[tmp.color_ID] || 0) +1;
				});
				let list: any = [];
				cartList.forEach((tmp: any) => {
					if (obj[tmp.color_ID] > 0) {
						list.push(result.filter((result_item: any) => result_item.color_ID === tmp.color_ID)[0]);
						obj[tmp.color_ID] = obj[tmp.color_ID] - 1;
					}
				});
				let apply_option_price = serializationOptionArray(cartList, list);

				const productsWithQuantity = apply_option_price.map((product: IProduct) => ({
					...product,
					quantity: cartList.find((item: { color_ID: number }) => item.color_ID === product.color_ID)?.quantity || 1,
					color_option_ID: product.color_option_ID || 0,
				}));

				const updatedProducts = applyDiscountsForList(
					productsWithQuantity,
					discountRateInfo,
					discountRateProductTypeInfo,
					discountRateColorInfo,
				);
				setProducts(updatedProducts);
			} catch (err) {
				console.error("Fetch 실패", err);
			} finally {

			}
		});
	}, [discountRateColorInfo, discountRateInfo, discountRateProductTypeInfo]);

	const addEstimate = () => {
		if (selectedProducts.length === 0) {
			setIsModalCloseOpen(true);
			setModalContent({
				text: '제품을 하나 이상 선택하셔야 합니다.'
			});
		} else {
			if (checkTogglePriceHeaderSwitch()) {
				return;
			}
			setIsModalYesCloseOpen(true);
			setModalYesCloseContent({
				confirmText: '저장',
				text: '선택하신 제품을 견적서로 저장하시겠습니까?',
				onConfirm: () => {
					handleInsertEstimate();
				}
			})

			const handleInsertEstimate = () => {
				const estimateNumber = `Est_${new Date().getTime()}`;
				const selected_list = products.filter(product =>
					selectedProducts.some(tmp =>
						product.color_ID === tmp.color_ID && product.color_option_ID === tmp.option_ID
				));
				insertAosEstimate(
					customerID,
					appliedPromotion ? appliedPromotion.aos_promotion_ID : 0,
					estimateNumber,
					totalPrice,
					totalDiscountRateVat,
					currentPromotionApplyPrice,
					appliedPromotion ? appliedPromotion.discount_rate : 0,
					(totalPrice+totalDiscountRateVat-currentPromotionApplyPrice),
					JSON.stringify(selected_list),
				).then(result => {
					navigate('/mypage', {state: {tab_name: 'estimate_list'}});
				});
			}
		}
	};

	useEffect(() => {
		if (customerAlcStatus === 0) {
			setcheckoutButtonName("구매하기");
		} else {
			setcheckoutButtonName("오더하기");
		}
	  }, [customerAlcStatus]);

	return (
		<>
			<ModalClose 
				isOpen={isModalCloseOpen} 
				onClose={() => setIsModalCloseOpen(false)}
				text={modalContent.text}
			/>
			<ModalYesClose
				isOpen={isModalYesCloseOpen}
				onClose={() => setIsModalYesCloseOpen(false)}
				onConfirm={modalYesCloseContent.onConfirm}
				confirmText={modalYesCloseContent.confirmText}
				text={modalYesCloseContent.text}
			/>
			{isSelectedProductModal && (
				<SOL.ModalBackground
					onClick={e => {
						if (e.target === e.currentTarget) {
							setIsSelectedProductModal(false);
						}
					}}
				>
					<SOL.CartProductCheckModal>
						<div className='cart-modal-item-flex-header'>
							<div className='image'>상품정보</div>
							<div className='detail'></div>
							<div className='quantity'>수량</div>
							<div className='final-price'>합계</div>
						</div>
						<div className='cart-modal-overflow-box'>
							{products.filter(product => 
								selectedProducts.some(tmp1 => tmp1.color_ID === product.color_ID) && selectedProducts.some(tmp2 => tmp2.option_ID === product.color_option_ID)
							).map((element, index) =>
								<SOL.CartProductCheckModalItem
									key={index}
									className={`${index+1 === products.filter(product => 
										selectedProducts.some(tmp1 => tmp1.color_ID === product.color_ID) && selectedProducts.some(tmp2 => tmp2.option_ID === product.color_option_ID)
									).length && "last-item"}`}
								>
									<div className='cart-modal-item-flex-body'>
										<div className='cart-modal-item-img-box'>
											<img src={element.color_b2c_image} alt={element.color_name} />
										</div>
										<div className='cart-modal-item-detail-box'>
											<p className='modal-item-brand'>{element.brand_eng_name}</p>
											<p className='modal-item-name'>{element.product_kor_name}</p>
											<p className='modal-item-color'>{element.color_name}</p>
											{(element.color_option_array !== "" && element.color_option_status === 1) && (
												<h3 className='modal-item-section'>
													{isMobile ? (
														extractOnlyLastNodeName(element.color_option_node_name_list)
													) : (
														`[ 옵션: ${element.color_option_node_name_list} ]`
													)}
												</h3>
											)}
										</div>
										<div className='cart-modal-item-quantity-box'>
											<p className='modal-item-quantity'>{element.quantity}개</p>
										</div>
										<div className='cart-modal-item-final-price-box'>
											<p className='modal-item-final-price'>
												{(element.color_b2c_sell_price * element.quantity).toLocaleString()}원
											</p>
										</div>
									</div>
								</SOL.CartProductCheckModalItem>
							)}
						</div>
						<div className='cart-modal-price-info-box'>
							<div className='price-info-layout'>
								<div className='price-info'>
									<span>총 주문 금액</span>
									<span>{totalPriceOrigin.toLocaleString()}원</span>
								</div>
								<div className='price-info'>
									<span>총 할인 금액</span>
									{totalPrice === totalPriceOrigin ? (
										<span>0원</span>
									) : (
										<span>-{(totalPriceOrigin-totalPrice).toLocaleString()}원</span>
									)}
								</div>
								<div className='price-info'>
									<span>총 배송비</span>
									<span>0원</span>
								</div>
								<div className='price-info total-payment'>
									<span>총 결제 금액</span>
									<span className='price'>{totalPrice.toLocaleString()}원</span>
								</div>
							</div>
						</div>
						<div className='cart-modal-button-box'>
							<>
								{customerAlcStatus === 0 && (
									<button
										onClick={handleOrder}
									>
										구매하기
									</button>
								)}
								{customerAlcStatus === 1 && (
									<>
										{aosOrderModalButton === 1 && (
											<button
												onClick={handleOffer}
											>
												오더하기
											</button>
										)}
										{aosOrderModalButton === 2 && (
											<button
												onClick={addEstimate}
											>
												견적서 저장
											</button>
										)}
									</>
								)}
								<button
									className='close-button'
									onClick={() => setIsSelectedProductModal(false)}
								>
									닫기
								</button>
							</>
						</div>
					</SOL.CartProductCheckModal>
				</SOL.ModalBackground>
			)}
			
			<SOL.CartContainer className={`${products.length > 0 ? 'with-items' : ''}`}>
				<h1 className="cart-header">장바구니</h1>
				<div className="cart-items">
					{products.length > 0 && (
						<div className='cart-item-header'>
							<div className='select-all-checkbox'>
								<input
									type='checkbox'
									id="check-all"
									checked={isAllChecked}
									readOnly
									onClick={e => e.preventDefault()}
								/>
								<label htmlFor='check-all' onClick={toggleSelectAll}></label>
								<span onClick={toggleSelectAll}>전체선택</span>
							</div>
							{customerAlcStatus === 0 ? (
								<>
									<div className='cart-image'>상품정보</div>
									<div className='cart-details'></div>
									<div className='cart-price'>판매가</div>
									<div className='cart-quantity'>수량</div>
									<div className='cart-total'>합계</div>
									<div className='cart-buy'></div>
								</>
							) : (
								<>
									<div className='cart-image'>상품정보</div>
									<div className='cart-details'></div>
									<div className='cart-price'>소비자 단가</div>
									<div className='cart-quantity'>수량</div>
									<div className='cart-total'>공급가</div>
									<div className='cart-vat'>부가세</div>
								</>
							)}
						</div>
					)}
					{products.length > 0 ? (
						products.map((product, index) => (
							<SOL.CartItem
								key={index}
								className={`${products.length === index+1 && "last-product"} ${index === 0 && "first-product"}`}
								$checkboxIndex={index}
							>
								<div className='cart-item-check'>
									<input
										type="checkbox"
										id={`cart-checkbox-${index}`}
										className='cart-check'
										checked={selectedProducts.some(tmp => tmp.color_ID === product.color_ID && tmp.option_ID === product.color_option_ID)}
										onChange={() => toggleProductSelection(product)}
									/>
									<label htmlFor={`cart-checkbox-${index}`}></label>
								</div>
								<div
									className="cart-image"
									onClick={() => navigate(`/product_detail?color_ID=${product.color_ID}`)}
								>
									<img src={product.color_b2c_image} alt={product.color_name} />
								</div>
								<div
									className={`cart-details ${product.color_option_array !== "" && product.color_option_status === 1 && "has-option"}`}
									onClick={e => {
										if (e.target !== e.currentTarget) {
											navigate(`/product_detail?color_ID=${product.color_ID}`);
										}
									}}
								>
									<h2 className="cart-brand-name">{product.brand_eng_name}</h2>
									<h1 className="cart-product-name">{product.product_kor_name}</h1>
									<h3 className="cart-color-name">{product.color_name}</h3>
									{(product.color_option_array !== "" && product.color_option_status === 1) && (
										<h3 className='cart-option-section'>
											[ 옵션: {product.color_option_node_name_list} ]
										</h3>
									)}
									{customerAlcStatus === 1 && (
										<h4 className={`cart-inventory ${(product.color_option_array !== "" && product.color_option_status === 1) && "has-option"}`}>
											{product.inventory_warehouse_date_status}
										</h4>
									)}
								</div>
								{customerAlcStatus === 0 ? (
									<>
										<div className='cart-price'>
											{product.apply_discount_rate_1 ? (
												<>
													<p className="cart-original-price">{product.color_b2c_sell_price_ogirinal.toLocaleString()}원</p>
													{/* <span className='discount-rate'>{product.apply_discount_rate_1}%</span> */}
													<p className="cart-sale-price">{product.color_b2c_sell_price.toLocaleString()}원</p>
												</>
											) : (
												<p className="cart-sale-price">{product.color_b2c_sell_price.toLocaleString()}원</p>
											)}
										</div>
										<div className="cart-quantity">
											<button onClick={() => handleQuantityChange(product, product.quantity - 1)}>-</button>
											<input type="number" value={product.quantity} readOnly />
											<button onClick={() => handleQuantityChange(product, product.quantity + 1)}>+</button>
										</div>
										<div className="cart-total">
											{product.apply_discount_rate_1 ? (
												<>
													<p className='cart-total-original'>{(product.color_b2c_sell_price_ogirinal * product.quantity).toLocaleString()}원</p>
													<p className="cart-total-price">{(product.color_b2c_sell_price * product.quantity).toLocaleString()}원</p>
												</>
											) : (
												<p className="cart-total-price no-original">{(product.color_b2c_sell_price * product.quantity).toLocaleString()}원</p>
											)}
										</div>
										<div className='cart-buy'>
											{customerAlcStatus === 0 && (
												<button
													onClick={() => {
														const selectedOneProductWithQuantities = [product];
														resetInputErrors();
														navigate('/order', { state: selectedOneProductWithQuantities });
													}}
												>
													바로 구매
												</button>
											)}
										</div>
									</>
								) : (
									<>
										<div className='cart-price'>
											<p className="cart-sale-price">{product.color_b2c_sell_price_ogirinal.toLocaleString()}원</p>
										</div>
										<div className='cart-quantity'>
											<button onClick={() => handleQuantityChange(product, product.quantity - 1)}>-</button>
											<input type="number" value={product.quantity} readOnly />
											<button onClick={() => handleQuantityChange(product, product.quantity + 1)}>+</button>
										</div>
										<div className='cart-total'>
											<p className="cart-total-price">{(product.color_b2c_sell_price * product.quantity).toLocaleString()}원</p>
										</div>
										<div className='cart-vat'>
											{parseInt(product.apply_discount_rate_vat) === 0 ? (
												<div>
													<p>
														<span className='no-include-vat'>부가세&nbsp;</span>
														미포함
													</p>
													<p>{((product.color_b2c_sell_price * product.quantity)*0.1).toLocaleString()}원</p>
												</div>
											) : (
												<p className='include-vat'>포함</p>
											)}
										</div>
									</>
								)}
							</SOL.CartItem>
						))
					) : (
						<div className="empty-cart-message">
							<div>
								<img src={IconBigBag} alt='' />
								<p>장바구니가 비어있습니다.</p>
							</div>
						</div>
					)}
				</div>
				{products.length > 0 && (
					<div className='cart-control-box'>
						<div>
							<div className="cart-controls">
								<button
									onClick={deleteSelectedProducts}
								>
									선택 삭제
								</button>
								<button
									className='delete-button'
									onClick={deleteAllProducts}
								>
									장바구니 비우기
								</button>
							</div>
							{(customerAlcStatus === 1 && appliedPromotion) && (
								<div className='applied-promtion'>
									<h3>적용중인 프로모션</h3>
									{appliedPromotion.aos_promotion_name !== '' &&
										<h4>
											-{appliedPromotion.aos_promotion_name}
										</h4>
									}
									<p className='promtion-notice'>
										*해당 금액 이상 구매하여 할인율이 자동 적용되었습니다.
									</p>
									<div className='promotion-content'>
										<div className='label'>프로모션 해당 금액</div>
										<div className='content'>{appliedPromotion.discount_target_amount.toLocaleString()}원</div>
									</div>
									<div className='promotion-content'>
										<div className='label'>적용 할인율</div>
										<div className='content'>{appliedPromotion.discount_rate}%</div>
									</div>
								</div>
							)}
						</div>
						{customerAlcStatus === 0 && (
							<div className="checkout">
								<div className='checkout-layout'>
									<div className="checkout-rows cart-selected-total-price">
										<span>총 주문 금액</span>
										<span className="total-amount">
											{totalPriceOrigin.toLocaleString()}원
										</span>
									</div>
									<div className='checkout-rows'>
										<span>총 할인 금액</span>
										{totalPrice === totalPriceOrigin ? (
											<span>0원</span>
										) : (
											<span>-{(totalPriceOrigin-totalPrice).toLocaleString()}원</span>
										)}
									</div>
									<div className='checkout-rows'>
										<span>총 배송비</span>
										<span>0원</span>
									</div>
									<div className='checkout-rows total-payment'>
										<span>총 결제 금액</span>
										<span className='price'>{totalPrice.toLocaleString()}원</span>
									</div>
									<div className='checkout-rows'>
										<button
											className={`checkout-button`}
											onClick={() => {
												if (selectedProducts.length === 0) {
													setIsModalCloseOpen(true); 
													setModalContent({
													text: '결제하실 상품을 선택해주세요.'
													});
													return;
												}
												setIsSelectedProductModal(true);
											}}
										>
											{checkoutButtonName}
										</button>
									</div>
								</div>
							</div>
						)}
						{customerAlcStatus === 1 && (
							<>
								<div className="checkout">
									<div className='checkout-layout'>
										<div className="checkout-rows cart-selected-total-price">
											<span>기존 공급가</span>
											<span className="total-amount">
												{(totalPrice).toLocaleString()}원
											</span>
										</div>
										<div className='checkout-rows'>
											<span>부가세</span>
											<span>{totalDiscountRateVat.toLocaleString()}원</span>
										</div>
										<div className='checkout-rows'>
											<span>프로모션 할인</span>
											<span>{currentPromotionApplyPrice === 0 ? "0" : `-${currentPromotionApplyPrice.toLocaleString()}`}원</span>
										</div>
										<div className='checkout-rows total-payment'>
											<span>최종공급가</span>
											<span className='price'>{(totalPrice+totalDiscountRateVat-currentPromotionApplyPrice).toLocaleString()}원</span>
										</div>
										<div className='checkout-notice'>*제품에 따라 배송비는 별도로 추가될 수 있습니다.</div>
										<div className='checkout-rows'>
											<button
												className='checkout-button white'
												onClick={() => {
													if (selectedProducts.length === 0) {
														setIsModalCloseOpen(true); 
														setModalContent({
														text: '결제하실 상품을 선택해주세요.'
														});
														return;
													}
													setAosOrderModalButton(2);
													setIsSelectedProductModal(true);
												}}
											>
												견적서 저장
											</button>
											<button
												className={`checkout-button`}
												onClick={() => {
													if (selectedProducts.length === 0) {
														setIsModalCloseOpen(true); 
														setModalContent({
														text: '결제하실 상품을 선택해주세요.'
														});
														return;
													}
													setAosOrderModalButton(1);
													setIsSelectedProductModal(true);
												}}
											>
												{checkoutButtonName}
											</button>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				)}
			</SOL.CartContainer>
		</>
	);
};

export default Cart;