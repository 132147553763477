import React, { FC } from 'react'
import { baseUrl } from '../../Axios';
import { useNavigate } from 'react-router-dom';

interface IPromotionProps {
  promotion? : any;
}

const Promotion : FC<IPromotionProps> = ({
  promotion,
}) => {

  const currentDate = new Date();
  const navigate = useNavigate();

  const isPromotionActive = (): boolean => {
    const start = new Date (promotion.alc_banner_start_date);
    const end = new Date (promotion.alc_banner_end_date);
    return currentDate >= start && currentDate <= end;
  };

  return (
    <>
        <div
          className='Promotion_gridCard'
          onClick={()=>{
            navigate('/promotion_detail', {state: {alc_banner_group_ID : promotion.alc_banner_group_ID}})
          }}
        >
          <div className="Promotion_gridCard_imgBox">
            <img src={`${baseUrl}${promotion.alc_banner_img_mobile}`} alt="프로모션 이미지" />
          </div>
          <div className='Promotion_gridCard_textBox'>
            <div className="Promotion_gridCard_date">
              <div>
                <span>{promotion.alc_banner_start_date} </span>
                -
                <span> {promotion.alc_banner_end_date}</span>
              </div>
              <span className={`${isPromotionActive() ? "promotion-Active" : "promotion-Ended"}`}>
              {isPromotionActive() ? "진행중" : "종료"}
              </span>
            </div>
            <h2>{promotion.alc_banner_title}</h2>
          </div>
        </div>
    </>
  )
}

export default Promotion