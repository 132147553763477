import styled from "styled-components";
// import theme from "../theme";

/* const pretendard = css`
  @import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");
  font-family: 'Pretendard' !important;
`; */


export const SplashWrap = styled.div`
    width:100%;
      
    &::-webkit-scrollbar{
        display:none; 
    }

    &{
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    & img{
        width:100%;
        height:auto;
    }

    .SplashView_pc{
        display:none;
    }

    .ablack_movBox{
        background-color:#000;
        padding:10px 0 80px;
    }

    .ablack_movBox div{
        width:100%;
        height:60vw;
        margin:0 auto; 
    }

    & button{
        display:inline-block;
        width:300px;
        height:60px;
        background-color:#fff;
        font-size:18px;
        text-align:center;
        border: 1px solid #ccc;
        border-radius:30px;
        position: fixed;
        bottom:3%;
        left:50%;
        transform : translateX(-50%);
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.6);
    }

    @media (min-width: 900px){
        .SplashView_mo{
            display:none;
        }

        .SplashView_pc{
            display:block;
        }

        & button{
            width:400px;
        }

        .ablack_movBox{
            padding:10px 0 120px;
        }

        .ablack_movBox div{
            width:860px;
            height:484px;
        }
    }
`;


export const loginInfoBox = styled.div`
    width:90%;
    margin: 20px auto;
    padding:20px; 
    background-color:rgba(0,0,0,0.4);
    border: 1px solid #444;
    border-radius:8px;
    text-align:center;
    line-height:20px;

    & img{
        width:24px;
        height:24px;
        margin:0 auto 10px;
    }

    .loginInfoBox_call{
        margin-top:6px;
        font-size:14px;
        color:#aaa;
    }

    @media (min-width: 500px){
        width:400px;
        margin:20px 0;
    }
`;

export const ErrorPage = styled.section`
    background-color: #101010;
    position: relative;
    color: #fff;
    width: 100%;
    height: 100vh;
    @media screen and (max-width : 500px) {overflow: hidden;}
        & .err-box {
            width: 1200px;
            position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
            transition: 0.5s;
            @media screen and (max-width : 500px) {
            top: 296.87px; transform: translate(-50%, 0);
            }  
                & img {
                    width: 313px; margin: 0 auto 36.58px; transition: 0.5s;
                    @media screen and (max-width : 500px) {width: 260px; margin: 0 auto 38.62px;}
                }
                & .mid-box {
                    text-align: center; margin-bottom: 50px; transition: 0,5s;
                    @media screen and (max-width : 500px) {margin-bottom: 106px;}
                    & p:nth-child(1) {font-size: 1.8125rem; margin-bottom: 10px;}
                    & p:nth-child(2) {font-size: 0.875rem; line-height: 20px;}
                }
                & .back-btn {
                    & button {
                    width: 300px; height: 40px; margin: 0 auto; 
                    text-align: center; line-height: 40px; font-size: 0.875rem;
                    border-radius: 30px; 
                    display: block; 
                    }
                    & button:nth-child(1) {
                    background: #FFF; border: 1px solid #000; color: #000; 
                    transition: 0.5s; margin-bottom: 11px;
                    }
                    & button:nth-child(2) {
                    background: #000; border: 1px solid #d8d8d8; color:#FFF;
                    }
                }
                
        }
`;

