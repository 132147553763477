import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { getAosOrderSuccess } from "../../Axios";
import IconArrowUp from "../../assets/icons/arrow_fold_40.svg";
import { extractColorOptionNodeNames } from "../../Utils";

const OfferSuccess: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [aosOrderData, setAosOrderData] = useState<any>({});
  const [products, setProducts] = useState<any[]>([]);
  const [toggleProductList, setToggleProductList] = useState<boolean>(true);
  const {aos_order_number} = location.state as { aos_order_number: string };

  useEffect(() => {
    if (!aos_order_number) {
      navigate('/home');
    } else {
      getAosOrderSuccess(
        aos_order_number,
      ).then(result => {
        setAosOrderData(result.order_data);
        setProducts(result.product_data);
      });
    }
  }, [aos_order_number, navigate]);

  return (
    <>
    {aosOrderData.aos_order_number && (
      <SOL.OrderSuccessContainer>
        <div className="order-success-wrapper">
          <div className="order-success-title">
            <h3>발주가 완료 되었습니다.</h3>
          </div>
          <div className="order-success-info">
            <div className="info-items">
              <span className="label">발주번호</span>
              <span className="content font-pretendard">{aosOrderData.aos_order_number}</span>
            </div>
            <div className="info-items">
              <span className="label">발주일자</span>
              <span className="content font-pretendard">{aosOrderData.aos_order_registe}</span>
            </div>
            <div className="info-items">
              <span className="label">기존 공급가</span>
              <span className="content font-pretendard">{aosOrderData.aos_order_supply_price_existing.toLocaleString()}원</span>
            </div>
            <div className="info-items">
              <span className="label">부가세</span>
              <span className="content font-pretendard">{aosOrderData.aos_order_total_separate_vat === 0 ? `0원` : `${aosOrderData.aos_order_total_separate_vat.toLocaleString()}원`}</span>
            </div>
            <div className="info-items">
              <span className="label">프로모션 할인</span>
              <span className="content font-pretendard">{aosOrderData.aos_order_promotion_discount_pay === 0 ? `0원` : `-${aosOrderData.aos_order_promotion_discount_pay.toLocaleString()}원`}</span>
            </div>
            <div className="info-items">
              <span className="label">최종 공급가</span>
              <span className="content font-pretendard">{aosOrderData.aos_order_supply_price_final.toLocaleString()}원</span>
            </div>
          </div>
          <div className="order-success-product-box">
            <div className="product-header" onClick={() => setToggleProductList(!toggleProductList)}>
              <span>발주상품 {products.length}건</span>
              <button>
                <img className={`${toggleProductList || "rotate-180"}`} src={IconArrowUp} alt="arrow_icon" />
              </button>
            </div>
            {toggleProductList && (
              products.map((tmp, idx) => (
                <SOL.OrderItem
                  key={idx}
                  className={`order-success-item ${products.length === (idx+1) && "last-item"}`}
                >
                  <div className="order-item-wrapper">
                    <div className="order-flex-left">
                      <div
                        className="order-image"
                      >
                        <img src={tmp.color_b2c_image} alt={tmp.color_name} />
                      </div>
                      <div
                        className='order-details'
                        style={{flex: 2}}
                      >
                        <h2 className='order-brand-name'>{tmp.brand_eng_name}</h2>
                        <h1 className="order-product-name">{tmp.product_kor_name}</h1>
                        <h3 className="order-color-name">{tmp.color_name}</h3>
                        {tmp.aos_order_detail_option_array && (
                          <h3
                            style={{marginTop: "8px", lineHeight: "13px", fontSize: "10px"}}
                            className="order-color-option-name"
                          >
                            {`[ 옵션: `}
                            {extractColorOptionNodeNames(JSON.parse(tmp.aos_order_detail_option_array))}
                            {` ]`}
                          </h3>
                        )}
                      </div>
                    </div>
                    <div className='order-flex-right'>
                      <div className='order-quantity'>
                        <span>{tmp.aos_order_detail_quantity}개</span>
                      </div>
                      <div className='order-price-box'>
                        <div className='price-wrapper'>
                          {tmp.aos_order_detail_sell_price_original > tmp.aos_order_detail_sell_price && (
                            <p className='order-origin-price'>
                              {(tmp.aos_order_detail_sell_price_original * (tmp.aos_order_detail_quantity)).toLocaleString()}원
                            </p>
                          )}
                          <div className="order-total-price">
                            {(tmp.aos_order_detail_sell_price * (tmp.aos_order_detail_quantity)).toLocaleString()}원
                            {tmp.aos_order_detail_separate_vat > 0 &&
                              <p className="order-total-price-vat">부가세 별도</p>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SOL.OrderItem>
              ))
            )}
          </div>

          <div className='order-success-control-box'>
              <button
                  onClick={() => {navigate('/mypage', {state: {tab_name: "offer_list"}})}}
              >
                  발주 확인하기
              </button>
              <button className='darker' onClick={() => navigate('/home')}>메인으로 가기</button>
          </div>
        </div>
      </SOL.OrderSuccessContainer>
    )}
    </>
  )
};

export default OfferSuccess;