import React, { FC, useEffect, useRef, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import LogoAmbience from "../../assets/image_folder/common_images/ambience_logo_color_60.svg";
import ModalLoading from "../Modal/ModalLoading";
import { extractColorOptionNodeNames } from "../../Utils";

interface IPDFViewProps {
  pdfDivision: number; // 1. 견적서, 2. 발주서
  customerData: any;
  mainData: any;
  subData: any;
}

const PDFViewer: FC<IPDFViewProps> = ({pdfDivision, customerData, mainData, subData}) => {
  const printRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [completeLoading, setCompleteLoading] = useState<number>(0);
  const [pagingSubData, setPagingSubData] = useState<any[]>([]);

  // 로딩된 이미지 개수가 subData 개수와 같을 때 스피너 종료
  useEffect(() => {
    if (subData.length === 0) return;
    setPagingSubData(subData);
    if (completeLoading === subData.length) {
      setIsLoading(false);
    }
  }, [completeLoading, subData]);

  const downloadPDF = async () => {
    setIsLoading(true);
    // 프린트하기 위해서 페이징 처리 새로 하기
    let left_length = subData.length;
    let array = [...subData];
    if (array.length > 4) {
      array.splice(
        4,
        0,
        {
          primary_ID: 0,
          page_number: 1,
          page_info: 'first',
        },
        {
          primary_ID: 0,
          page_number: 2,
          page_info: 'top',
        }
      );
      left_length -= 4;
    };
    let cycle = Math.floor(left_length / 7);

    for (let i = 0; i < cycle; i++) {
      array.splice(
        (i + 1) * 9 + 4,
        0,
        {
          primary_ID: 0,
          page_number: i + 2,
          page_info: 'bottom',
        },
        {
          primary_ID: 0,
          page_number: i + 3,
          page_info: 'top',
        }
      );
    }
    setPagingSubData(array);

    const element = await printRef.current;
    if (!element) return;
    
    const canvas = await html2canvas(element, {
      useCORS: false,
    });
    let imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4', true);

    const scale = subData.length === 4 ? 0.82 : 0.88;
    const pageWidth = 210;  // A4 페이지의 가로(mm)
    const pageHeight = 297; // A4 페이지의 세로(mm)
    const imgWidth = pageWidth * scale; // 이미지 가로를 90%로 축소
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // 이미지의 세로 길이를 비율에 맞춰 계산

    let heightLeft = imgHeight; // 남은 이미지 높이
    let position = 0;  // 이미지 출력 위치 (첫 페이지는 상단부터)

    // 첫 페이지에 이미지 출력
    pdf.addImage(imgData, 'PNG', (pageWidth - imgWidth) / 2, position, imgWidth, imgHeight);
    heightLeft -= pageHeight; // 남은 높이를 줄임

    // 남은 이미지가 있는 경우, 계속해서 새로운 페이지에 출력
    while (heightLeft > 0) {
        position = (heightLeft - imgHeight); // 다음 페이지의 시작 위치를 계산
        pdf.addPage(); // 새로운 페이지 추가
        pdf.addImage(imgData, 'PNG', (pageWidth - imgWidth) / 2, position, imgWidth, imgHeight);
        heightLeft -= pageHeight; // 남은 높이를 계속 줄임
    }

    let file_name = 'aos_file';
    if (pdfDivision === 2) {
      file_name = mainData.aos_order_number;
    } else {
      file_name = mainData.aos_estimate_number;
    }

    /* window.open(pdf.output('bloburl')); // 새 창으로 띄우기 */
    pdf.save(`${file_name}.pdf`); // 저장
    setIsLoading(false);
  };

  return (
    <>
      <ModalLoading isOpen={isLoading} />
      <SOL.PDFContainer>
        <div className="title-box">
          <h1>{pdfDivision === 1 ? "견적서" : "발주서"} 확인</h1>
          <button onClick={downloadPDF}>
            {pdfDivision === 1 ? "견적서" : "발주서"} 다운로드
          </button>
        </div>
        <div className="border border-light_grey border-solid">
          {/* PDF 영역 */}
          <SOL.PDFComponent
            ref={printRef}
          >
            <div className="pdf-headline">
              <div className="title">
                <h3>
                  {pdfDivision === 1 ? "견적서" : "발주서"}
                </h3>
              </div>
              <div className="logo-img-box">
                <img src={LogoAmbience} alt="amb_logo" />
              </div>
            </div>
            <div className="pdf-headline">
              <span>{mainData.register_date} {pdfDivision === 1 && "(견적 유효기간 30일)"}</span>
              <span>page 1</span>
            </div>
            <div className="company-info-box">
              <div className="channel b2b">
                <p className="label font-semibold">
                  Invoice to
                </p>
                <p className="account-name">
                  {customerData.account_name}
                </p>
              </div>
              <div className="channel ambience">
                <p className="label font-semibold">
                  Company
                </p>
                <p className="name">
                  (주)앰비언스
                </p>
                <div className="content">
                  <p>등록번호 : 607-86-03758</p>
                  <p>대표자 : 김대웅</p>
                  <p>주소 : 부산광역시 해운대구 센텀1로 28, 101동 702호</p>
                  <p>Tel : 070-4286-3556&nbsp;&nbsp;&nbsp;Fax : 051-558-3579</p>
                  <p>이메일 : retail@ambience.kr</p>
                </div>
              </div>
            </div>
            <SOL.CartContainer style={{margin: 0}}>
              <div className="cart-items">
                <div className="cart-item-header border-t-2 border-b border-black border-solid">
                  <div className="font-medium px-2">NO</div>
                  <div className="cart-image"></div>
                  <div className='cart-details font-medium'>제품정보</div>
                  <div className='cart-price font-medium'>소비자가 / 공급가</div>
                  <div className='cart-quantity font-medium'>수량</div>
                  <div className='cart-total font-medium'>합계</div>
                  <div className='cart-vat font-medium'>부가세</div>
                </div>
              </div>
            </SOL.CartContainer>
            {pagingSubData.length > 0 && (
              pagingSubData.map((product: any, index: number) =>
                product.primary_ID === 0 ?
                <SOL.CartItem
                  key={index}
                  $checkboxIndex={0}
                  className="for-pdf empty-place"
                  style={{border: 0}}
                >
                  <div className={`page-index ${product.page_info === 'first' ? "h-[100px]" : "h-[20px]"} ${product.page_info !== 'top' ? "bottom" : "top"}`}>
                    <span>
                      {product.page_info === 'top' && `page ${product.page_number}`}
                    </span>
                  </div>
                </SOL.CartItem>
                :
                <SOL.CartItem
                  key={index}
                  $checkboxIndex={index}
                  className={`for-pdf ${product.primary_ID === 0 ? "bg-white" : index%2 === 0 && "bg-[#F3F3F6]"}`}
                  style={{border: 0}}
                >
                  <div className="px-4">
                    {product.primary_ID > 0 && `${product.product_number}`}
                  </div>
                  <div className="cart-image bg-white">
                    <img
                      src={product.color_b2c_image_base64}
                      alt={product.color_name}
                      onLoad={() => {
                        setCompleteLoading(prev => prev+1);
                      }}
                    />
                  </div>
                  <div className="cart-details">
                    <h2 className="cart-brand-name">{product.brand_eng_name}</h2>
                    <h1 className="cart-product-name">{product.product_kor_name}</h1>
                    <h3 className="cart-color-name">{product.color_name}</h3>
                    {product.option_path && (
                      <h3 className="cart-option-section using-pdf">
                        {`[ 옵션: `}
                        {extractColorOptionNodeNames(JSON.parse(product.option_path))}
                        {` ]`}
                      </h3>
                    )}
                  </div>
                  <div className="cart-price">
                    <p className="cart-original-price" style={{textDecoration: "none"}}>{product.price_original.toLocaleString()}원</p>
                    <p className="cart-sale-price">{product.price_supply.toLocaleString()}원</p>
                  </div>
                  <div className="cart-quantity">
                    <span>{product.quantity}</span>
                  </div>
                  <div className="cart-total">
                    <p className="cart-total-price">{(product.price_supply * product.quantity).toLocaleString()}원</p>
                  </div>
                  <div className="cart-vat">
                    {parseInt(product.vat) > 0 ? (
                      <div>
                        <p>
                          <span className='no-include-vat'>부가세&nbsp;</span>
                          미포함
                        </p>
                        <p>{((product.price_supply * product.quantity)*0.1).toLocaleString()}원</p>
                      </div>
                    ) : (
                      <p className="include-vat">포함</p>
                    )}
                  </div>
                </SOL.CartItem>
              )
            )}
            {mainData.aos_ID > 0 && (
              <div className="price-info">
                <div className="company-info-box price-box">
                  <div className="channel" />
                  <div className="channel">
                    <div className="price-general">
                      <div className="label">
                        기존 공급가 합계
                      </div>
                      <span className="price-text">
                        {(mainData.price_existing).toLocaleString()}원
                      </span>
                    </div>
                    <div className="middle-line" />
                    <div className="price-general">
                      <div className="label">
                        부가세
                      </div>
                      <span className="price-text">
                        {(mainData.total_vat).toLocaleString()}원
                      </span>
                    </div>
                    <div className="middle-line" />
                    <div className="price-general">
                      <div className="label">
                        프로모션 할인가
                      </div>
                      <span className="price-text">
                        {mainData.promotion_discount_pay === 0 ? "0원" : `-${mainData.promotion_discount_pay.toLocaleString()}원`}
                      </span>
                    </div>
                    <div className="middle-line" />
                    <div className="price-general final">
                      <div className="label">
                        최종공급가
                        <span className="description">
                          &nbsp;(부가세포함)
                        </span>
                      </div>
                      <span className="price-text">
                        {mainData.price_final.toLocaleString()}원
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="company-info-box"
                  style={{borderWidth: 0, margin: 0, paddingTop: "10px"}}
                >
                  <div className="channel" />
                  <div className="channel">
                    <span className="payment-place-label">납부처</span>
                    <span className="payment-place-content">주식회사 앰비언스 | 부산은행 113-2011-0981-05</span>
                  </div>
                </div>
                {/* 비고 내용 확정되면 추가하기 */}
                {/* <div
                  className="company-info-box"
                  style={{borderWidth: 0, margin: 0}}
                >
                  <div className="channel" />
                  <div className="channel">
                    <span className="payment-place-label">비고</span>
                    <span className="payment-place-content"></span>
                  </div>
                </div> */}
              </div>
            )}
          </SOL.PDFComponent>
          {/* PDF 영역 */}
        </div>
      </SOL.PDFContainer>
    </>
  )
};

export default PDFViewer;