/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, FC, useEffect } from 'react';
import ModalClose from '../../components/Modal/ModalClose';
import { getAlcOrderListBySorted } from '../../Axios';
import * as SOL from "../../styles/solda/StyledComponentSol";
import { getAlcDetailStatusAndDeliveryProgress } from '../../statusUtils';
import VerticalIcon from "../../assets/icons/icon_vertical.svg";
import ForwardArrow from "../../assets/icons/icon_navigate_arrow.svg";
import { IOrderSummary } from '../../modules/model';
import { get_today_plus_object } from '../../modules/Validation';
import { extractColorOptionNodeNames } from '../../Utils';

interface Order {
    imp_uid: string;
    alc_order_ID: number;
    alc_order_number: string;
    alc_order_name: string;
    alc_order_phone_number: string;
    alc_order_address_name: string;
    alc_order_zip_code: string;
    alc_order_road_address: string;
    alc_order_detail_address: string;
    alc_order_requests: string;
    alc_order_pay_status: number;
    alc_order_pay_total: number;
    alc_order_refund_total: number;
    alc_order_method: string;
    alc_order_pay_date: string;
    alc_order_progress: number;
    details: OrderDetail[]; 
}
  
interface OrderDetail {
    alc_detail_order_ID: number;
    color_ID_use_alc_order_detail: number;
    alc_detail_order_sell_price: number;
    alc_detail_order_sell_price_original: number;
    alc_detail_order_quantity: number;
    alc_detail_order_delivery_progress: number;
    alc_detail_order_refund_status: number;
    alc_detail_order_refund: number;
    alc_detail_order_refund_date: string;
    product_kor_name: string;
    brand_eng_name: string;
    brand_kor_name: string;
    color_name: string;
    color_b2c_image: string;
    alc_detail_exchange_status: number;
    alc_detail_return_status: number;
    alc_detail_exchange_status_name: string;
    alc_detail_return_status_name: string;
    alc_detail_construction_complete_date: string;
    alc_detail_delivery_complete_date: string;
    alc_detail_status: number;
    alc_detail_order_option_array: string;
}

interface OrderListProps {
    customerID: number;
    handleSelectOneOrderID: (alc_order_ID: number) => void;
    orderSummary: IOrderSummary;
    handleSelectOneDetailOrderID: (alc_detail_order_ID: number, category: string) => void;
}

const OrderList: FC<OrderListProps> = ({customerID, handleSelectOneOrderID, orderSummary, handleSelectOneDetailOrderID}) => {
    // const [expandedOrders, setExpandedOrders] = useState<number[]>([]);
    const [sortedOrders, setSortedOrders] = useState<(Order & { details: OrderDetail[] })[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [orderStatus, setOrderStatus] = useState(0); // 0: 전체, 1: 결제대기, 2:결제완료, 3: 시공/배송 준비중, 4: 시공/배송 중, 5: 시공/배송 완료
    const [orders, setOrders] = useState<(Order & OrderDetail)[]>([]);
    const orderStatusName = ["전체", "결제대기", "결제완료", "시공/배송 준비", "시공/배송 중", "시공/배송 완료"];
    // const [selectOneOrderID, setSelectOneOrderID] = useState(0);

    useEffect(() => {
        if (!customerID) return;
        getAlcOrderListBySorted(
            customerID,
            orderStatus
        ).then(result => {
            if (result === 'error_alc') {
                setModalContent('알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.');
                setIsModalOpen(true);
            } else {
                setOrders(result);
            }
        })
    }, [customerID, orderStatus]);

    const handleTotalPriceByDetails = (details: any[]) => {
        const total_sell_price = details.reduce((accumulator, current_detail) => {
            return accumulator + (current_detail.alc_detail_order_sell_price * current_detail.alc_detail_order_quantity);
        }, 0);
        return total_sell_price;
    };

    const validateAvailableExchangeAndReturn = (detail_order: any) => {
        let validation = true;
        if (detail_order.alc_detail_order_delivery_progress !== 4) {
          validation = false;
        } else if (
          detail_order.alc_detail_construction_complete_date > get_today_plus_object(7).year_month_day ||
          detail_order.alc_detail_delivery_complete_date > get_today_plus_object(7).year_month_day
        ) {
          validation = false;
        } else if (
          detail_order.alc_detail_order_refund_status !== 0 ||
          detail_order.alc_detail_exchange_status !== 0 ||
          detail_order.alc_detail_return_status !== 0
        ) {
          validation = false;
        }
        return validation;
    }
    
    useEffect(() => {
        const orderGroups = orders.reduce<{ [key: string]: Order & { details: OrderDetail[] } }>((acc, order) => {
            const orderIdStr = order.alc_order_ID.toString();
            if (!acc[orderIdStr]) {
                acc[orderIdStr] = { ...order, details: [] };
            }
            acc[orderIdStr].details.push(order);
            return acc;
        }, {});

        const groupedArray = Object.values(orderGroups).sort(
            (a, b) => b.alc_order_ID - a.alc_order_ID
        ) as (Order & { details: OrderDetail[] })[];
        setSortedOrders(groupedArray);
    }, [orders]);
    
    return (
        <>
            <ModalClose 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                text={modalContent}
            />
            <SOL.OrderListContainer>
                <h2 className='order-list-title'>주문내역</h2>
                <div className='order-summary-box'>
                    <button className={`summary-item ${orderStatus === 0 && "active"}`} onClick={() => setOrderStatus(0)}>
                        <div className='value'>{orderSummary.order_total}</div>
                        <div className='label'>전체</div>
                    </button>
                    <img className='mobile-disappear' src={VerticalIcon} alt='vertical_icon' />
                    <button className={`summary-item ${orderStatus === 1 && "active"}`} onClick={() => setOrderStatus(1)}>
                        <div className='value'>{orderSummary.pay_waiting}</div>
                        <div className='label'>결제대기</div>
                    </button>
                    <img className='mobile-disappear' src={ForwardArrow} alt='vertical_arrow' />
                    <button className={`summary-item ${orderStatus === 2 && "active"}`} onClick={() => setOrderStatus(2)}>
                        <div className='value'>{orderSummary.pay_complete}</div>
                        <div className='label'>결제완료</div>
                    </button>
                    <img className='mobile-disappear' src={ForwardArrow} alt='vertical_arrow' />
                    <button className={`summary-item ${orderStatus === 3 && "active"}`} onClick={() => setOrderStatus(3)}>
                        <div className='value'>{orderSummary.delivery_preparing}</div>
                        <div className='label'>시공/배송 준비</div>
                    </button>
                    <img className='mobile-disappear' src={ForwardArrow} alt='vertical_arrow' />
                    <button className={`summary-item ${orderStatus === 4 && "active"}`} onClick={() => setOrderStatus(4)}>
                        <div className='value'>{orderSummary.delivery}</div>
                        <div className='label'>시공/배송 중</div>
                    </button>
                    <img className='mobile-disappear' src={ForwardArrow} alt='vertical_arrow' />
                    <button className={`summary-item ${orderStatus === 5 && "active"}`} onClick={() => setOrderStatus(5)}>
                        <div className='value'>{orderSummary.delivery_complete}</div>
                        <div className='label'>시공/배송 완료</div>
                    </button>
                </div>
                <SOL.MyMainContainer>
                    <div className='temp-cancel'>
                        <div className="cancel-category">
                            <span className="category-label">취소</span>
                            <span className="category-value">{orderSummary.order_cancel}</span>
                        </div>
                        <div className="cancel-category">
                            <span className="category-label">반품</span>
                            <span className="category-value">{orderSummary.order_return}</span>
                        </div>
                        <div className="cancel-category">
                            <span className="category-label">교환</span>
                            <span className="category-value">{orderSummary.order_exchange}</span>
                        </div>
                    </div>
                </SOL.MyMainContainer>
                <div className='order-sort-filter'>
                    {sortedOrders.length > 0 &&
                        <div className='sort-name'>
                            {orderStatusName[orderStatus]}
                        </div>
                    }
                    {/* TODO: 주문내역 기간 조회 후순위 */}
                    {/* <div className='calendar-box'>
                        <button></button>
                        <span>~</span>
                        <button></button>
                        <button>조회</button>
                    </div> */}
                </div>
                {sortedOrders.map(orderData => (
                    <SOL.OrderListItem
                        key={orderData.alc_order_ID}
                        onClick={(e: any) => {
                            if (e.target.dataset.button === 'request') return;
                            handleSelectOneOrderID(orderData.alc_order_ID);
                        }}
                    >
                        <div className="order-item-header">
                            <button className='order-info'>
                                <div className='order-label'>주문번호</div>
                                <div className='order-info-flex-box'>
                                    <p data-title="주문번호 " className='order-number'>{orderData.alc_order_number}</p>
                                    <p data-title="결제일자 " className='pay-date font-pretendard'>
                                        {`(${new Date(orderData.alc_order_pay_date).toLocaleDateString()} ${new Date(orderData.alc_order_pay_date).toLocaleTimeString()})`}
                                    </p>
                                </div>
                            </button>
                            <div className='total-price'>
                                <div className='price-label'>총 결제금액</div>
                                <span className='price-text font-pretendard'>
                                    {handleTotalPriceByDetails(orderData.details).toLocaleString()}원
                                </span>
                            </div>
                            {/* <div className="header-info">
                                <p data-title="배송지 " data-content={`(${orderData.alc_order_zip_code}) ${orderData.alc_order_road_address} ${orderData.alc_order_detail_address} ${orderData.alc_order_address_name}`}/>
                                <p data-title="결제상태 " data-content={getPaymentStatus(orderData.alc_order_pay_status)}/>
                                <p data-title="결제방식 " data-content={getPaymentMethod(orderData.alc_order_method)}/>
                                <p data-title="배송상태 " data-content={getDeliveryProgress(orderData.alc_order_progress)}/>
                                <p data-title="총 결제금액 " data-content={`${orderData.alc_order_pay_total.toLocaleString()} 원`}/>
                                {orderData.alc_order_refund_total !== 0 && (
                                    <p data-title="환불 금액 " data-content={`${orderData.alc_order_refund_total.toLocaleString()} 원`}/>
                                )}
                                <p data-title="요청사항 " data-content={orderData.alc_order_requests}/>
                            </div> */}
                        </div>
                        {orderData.details.map((detailData, idx) => 
                            <div
                                key={idx}
                                className='order-detail-box'
                            >
                                <div className='order-detail-left'>
                                    <div className='order-detail-image'>
                                        <img src={detailData.color_b2c_image} alt={detailData.color_name} />
                                    </div>
                                    <div className='order-detail-product'>
                                        <p className='brand'>{detailData.brand_kor_name}</p>
                                        <p className='product'>{detailData.product_kor_name}</p>
                                        <p className='color'>{detailData.color_name}</p>
                                        <p className='quantity'>{detailData.alc_detail_order_quantity}개</p>
                                        {detailData.alc_detail_order_option_array && (
                                            <p className="offer-list-option">
                                            {extractColorOptionNodeNames(JSON.parse(detailData.alc_detail_order_option_array))}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className='order-detail-right'>
                                    <div className='order-detail-price'>
                                        <p className='detail-original-price'>{(detailData.alc_detail_order_sell_price_original * detailData.alc_detail_order_quantity).toLocaleString()}원</p>
                                        <p className='detail-rated-price'>{(detailData.alc_detail_order_sell_price * detailData.alc_detail_order_quantity).toLocaleString()}원</p>
                                    </div>
                                    <div className='order-detail-status'>
                                        <div className='deilvery-status'>
                                            {getAlcDetailStatusAndDeliveryProgress(detailData.alc_detail_status, detailData.alc_detail_order_delivery_progress)}
                                        </div>
                                        <div className='exchange-return-status'>
                                            {detailData.alc_detail_order_refund_status === 2 && (" (취소 요청)")}
                                            {detailData.alc_detail_exchange_status !== 0 && ` (${detailData.alc_detail_exchange_status_name})`}
                                            {detailData.alc_detail_return_status !== 0 && ` (${detailData.alc_detail_return_status_name})`}
                                        </div>
                                    </div>
                                    <div className='order-detail-refund-button'>
                                        {(detailData.alc_detail_order_delivery_progress <= 2 && detailData.alc_detail_order_refund_status === 0) && (
                                            <button
                                                data-button='request'
                                                onClick={() => handleSelectOneDetailOrderID(detailData.alc_detail_order_ID, 'cancel')}
                                            >
                                                취소 신청
                                            </button>
                                        )}
                                        {validateAvailableExchangeAndReturn(detailData) && (
                                            <div className="flex gap-2">
                                                <button
                                                    data-button='request'
                                                    onClick={() => handleSelectOneDetailOrderID(detailData.alc_detail_order_ID, 'exchange')}
                                                >
                                                    교환 신청
                                                </button>
                                                <button
                                                    data-button='request'
                                                    onClick={() => handleSelectOneDetailOrderID(detailData.alc_detail_order_ID, 'return')}
                                                >
                                                    반품 신청
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </SOL.OrderListItem>
                ))}
            </SOL.OrderListContainer>
        </>
    );
};

export default OrderList;
