import React, { createContext, useContext, useState, FC } from 'react';

export interface IShippingInfo {
    postalCode: string;
    address: string;
    addressDetails: string;
    addressName: string;
    bigArea: string,
    smallArea: string,
    name: string;
    phoneNumber: string;
    requestDetails: string;
    selectedMethod: string;
    verificationNumber: string;
    isVerified: boolean;
    totalAmount: number
}

export interface IInputErrors {
  addressName: string;
  name: string;
  phoneNumber: string;
  verificationNumber: string;
  postalCode: string;
  address: string;
  addressDetails: string;
}

export interface ISenderShippingInfo {
  extra_address_ID: number;
  alc_order_sender_name: string;
  alc_order_sender_phone_number: string;
  alc_order_sender_address_name: string;
  alc_order_sender_zip_code: string;
  alc_order_sender_road_address: string;
  alc_order_sender_detail_address: string;
  alc_order_sender_big_area: string;
  alc_order_sender_small_area: string;
}

interface IOrderContext {
    shippingInfo: IShippingInfo;
    setShippingInfo: React.Dispatch<React.SetStateAction<IShippingInfo>>;
    resetShippingInfo: () => void;
    inputErrors: IInputErrors;
    setInputErrors: React.Dispatch<React.SetStateAction<IInputErrors>>;
    resetInputErrors: () => void;
    senderShippingInfo: ISenderShippingInfo;
    setSenderShippingInfo: React.Dispatch<React.SetStateAction<ISenderShippingInfo>>;
    resetSenderShippingInfo: () => void;
}

export const OrderContext = createContext<IOrderContext | undefined>(undefined);

export const useOrderContext = () => {
    const context = useContext(OrderContext);
    if (!context) {
      throw new Error('useOrderContext must be used within a OrderProvider');
    }
    return context;
};

export const OrderProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [shippingInfo, setShippingInfo] = useState<IShippingInfo>({
    postalCode: '',
    address: '',
    addressDetails: '',
    addressName: '',
    bigArea: '',
    smallArea: '',
    name: '',
    phoneNumber: '',
    requestDetails: '',
    selectedMethod: 'card',
    verificationNumber: '',
    isVerified: false,
    totalAmount: 0,
  });
  const [inputErrors, setInputErrors] = useState<IInputErrors>({
    postalCode: '',
    address: '',
    addressDetails: '',
    addressName: '',
    name: '',
    phoneNumber: '',
    verificationNumber: '',
  });
  const resetInputErrors = () => {
    setInputErrors({
      postalCode: '',
      address: '',
      addressDetails: '',
      addressName: '',
      name: '',
      phoneNumber: '',
      verificationNumber: '',
    });
  };
  const resetShippingInfo = () => {
    setShippingInfo({
      postalCode: '',
      address: '',
      addressDetails: '',
      addressName: '',
      bigArea: '',
      smallArea: '',
      name: '',
      phoneNumber: '',
      requestDetails: '',
      selectedMethod: 'card',
      verificationNumber: '',
      isVerified: false,
      totalAmount: 0,
    });
  };

  const [senderShippingInfo, setSenderShippingInfo] = useState<ISenderShippingInfo>({
    extra_address_ID: 0,
    alc_order_sender_name: '',
    alc_order_sender_phone_number: '',
    alc_order_sender_address_name: '',
    alc_order_sender_zip_code: '',
    alc_order_sender_road_address: '',
    alc_order_sender_detail_address: '',
    alc_order_sender_big_area: '',
    alc_order_sender_small_area: '',
  });

  const resetSenderShippingInfo = () => {
    setSenderShippingInfo({
      extra_address_ID: 0,
      alc_order_sender_name: '',
      alc_order_sender_phone_number: '',
      alc_order_sender_address_name: '',
      alc_order_sender_zip_code: '',
      alc_order_sender_road_address: '',
      alc_order_sender_detail_address: '',
      alc_order_sender_big_area: '',
      alc_order_sender_small_area: '',
    });
  };

  return (
    <OrderContext.Provider
      value={{
        shippingInfo,
        setShippingInfo,
        resetShippingInfo,
        inputErrors,
        setInputErrors,
        resetInputErrors,
        senderShippingInfo,
        setSenderShippingInfo,
        resetSenderShippingInfo,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};