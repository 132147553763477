import React, { FC, useEffect, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { getAosOrderList } from "../../Axios";
import { extractColorOptionNodeNames } from "../../Utils";

interface IOfferListProps {
  customerID: number;
  handleSelectOneOfferID: (aos_order_ID: number) => void;
}

const OfferList: FC<IOfferListProps> = ({ customerID, handleSelectOneOfferID}) => {
  const [details, setDetails] = useState<any[]>([]);
  const [groupedOrders, setGroupedOrders] = useState<any[]>([]);
  const [page] = useState<number>(1);

  useEffect(() => {
    if (!customerID) return;
    getAosOrderList(
      customerID,
      page,
    ).then(result => {
      setDetails(result);
    });
  }, [customerID, page]);

  useEffect(() => {
    if (details.length === 0) return;
    const orderGroups = details.reduce((acc, order) => {
      const orderIdStr = order.aos_order_ID.toString();
      if (!acc[orderIdStr]) {
        acc[orderIdStr] = { ...order, details: [] };
    }
    acc[orderIdStr].details.push(order);
    return acc;
    }, {});
    const groupedArray = Object.values(orderGroups).sort(
      (a: any, b: any) => b.aos_order_ID - a.aos_order_ID
    );
    setGroupedOrders(groupedArray);
  }, [details]);

  return (
    <>
      <SOL.OrderListContainer
        style={{minWidth: "100%"}}
      >
        <h2 className='order-list-title'>주문내역</h2>
        {groupedOrders.length === 0 ? (
          <SOL.EmptyBox>
            <div>
              <p>견적내역이 비어있습니다.</p>
            </div>
          </SOL.EmptyBox>
        ) : (
          groupedOrders.map(orderData => (
            <SOL.OrderListItem
              key={orderData.aos_order_ID}
              onClick={(e: any) => {
                if (e.target.dataset.button === 'request') return;
                handleSelectOneOfferID(orderData.aos_order_ID);
              }}
            >
              <div className="order-item-header">
                <button className="order-info">
                  <div className="order-label">주문번호</div>
                  <div className="order-info-flex-box">
                    <p className="order-number">{orderData.aos_order_number}</p>
                    <p className="pay-date font-pretendard">
                      {`(${new Date(orderData.aos_order_registe).toLocaleDateString()} ${new Date(orderData.aos_order_registe).toLocaleTimeString()})`}
                    </p>
                  </div>
                </button>
                <div className="total-price">
                  <div className="price-label">총 결제금액</div>
                  <span className="price-text font-pretendard">
                    {orderData.aos_order_supply_price_final.toLocaleString()}원
                  </span>
                </div>
              </div>
              {orderData.details.map((detailData: any, idx: number) =>
                <div
                  key={detailData.aos_order_detail_ID}
                  className='order-detail-box'
                >
                  <div className="order-detail-left">
                    <div className="order-detail-image">
                      <img src={detailData.color_b2c_image} alt={detailData.color_name} />
                    </div>
                    <div
                      className='order-detail-product'
                    >
                      <p className='brand'>{detailData.brand_kor_name}</p>
                      <p className='product'>{detailData.product_kor_name}</p>
                      <p className='color'>{detailData.color_name}</p>
                      <p className='quantity'>{detailData.aos_order_detail_quantity}개</p>
                      {detailData.aos_order_detail_option_array && (
                        <p className="offer-list-option">
                          {extractColorOptionNodeNames(JSON.parse(detailData.aos_order_detail_option_array))}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="order-detail-right">
                    <div className="order-detail-price">
                      <p className="detail-original-price">
                        {(detailData.aos_order_detail_sell_price_original * detailData.aos_order_detail_quantity).toLocaleString()}원
                      </p>
                      <p className="detail-rated-price">
                        {((detailData.aos_order_detail_sell_price * detailData.aos_order_detail_quantity).toLocaleString())}
                      </p>
                    </div>
                    <div className="order-detail-status">
                      <div className="deilvery-status">{detailData.aos_order_detail_delivery_progress_name}</div>
                      {/* <div className="exchange-return-status">발주 확인 중</div> */}
                    </div>
                    <div className="order-detail-status">
                      <div className="deilvery-status">{detailData.aos_order_pay_status_name}</div>
                      <div className="exchange-return-status">{detailData.aos_order_status_name}</div>
                    </div>
                    {/* <div className="order-detail-refund-button">
                      <button
                        data-button='request'
                      >
                        준비중
                      </button>
                    </div> */}
                  </div>
                </div>
              )}
            </SOL.OrderListItem>
          ))
        )}
      </SOL.OrderListContainer>
    </>
  )
};

export default OfferList;