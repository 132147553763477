import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkAvailableCode, handleRememberLogin, requestAlcLogin } from "../../Axios";
import * as SOL from "../../styles/solda/StyledComponentSol";
import * as H from "../../styles/han/StyledComponentHan";
import Background from "../../assets/image_folder/common_images/temp_background.jpg";
import ABlack from "../../assets/image_folder/common_images/ablack_white_main.png";
import { validateEmail } from "../../modules/Validation";
import ModalClose from "../../components/Modal/ModalClose";
import noticeInfo from "../../../src/assets/icons/icon_noticeInfo.png"

const Login: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [customerPassword, setCustomerPassword] = useState<string>('');
  const [isModalClose, setIsModalClose] = useState(false);
  const [modalContent, setModalContent] = useState({text:''});

  // 자동 로그인
  useEffect(() => {
    const customer_ID_encrypted = localStorage.getItem('customer_ID_encrypted') || '';
    if (customer_ID_encrypted !== '') {
      handleRememberLogin(customer_ID_encrypted).then(result => {
        if (result === 'error_alc') {
          handleAlertModal("알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.");
          localStorage.removeItem('customer_ID_encrypted');
				  localStorage.removeItem('alc_logo_img');
        } else if (result === 2) {
          handleAlertModal("아이디 또는 비밀번호가 일치하지 않습니다.");
          localStorage.removeItem('customer_ID_encrypted');
				  localStorage.removeItem('alc_logo_img');
        } else {
          const apply_discount_rate = JSON.parse(result[0].apply_discount_rate);
          const temp_object: any = {};
          apply_discount_rate.forEach((element: any) => {
            temp_object[element.brand_ID] = element;
          });
          localStorage.setItem('customer_ID_encrypted', JSON.stringify(result[0].customer_ID_encrypted));
          localStorage.setItem('alc_logo_img', result[0].alc_logo_img);
          if (result[0].alc_status !== 1 && result[0].alc_status !== 3) {
            localStorage.setItem('show_original_price', 'false');
          }
          navigate('/home');
        }
      })
    }else{
      const query = new URLSearchParams(location.search);
      const code = query.get("code");
      const login_status = query.get("login_status");
      if(login_status === '1'){
        return
      }
      if (code === 'homeniq' || code === 'homeniq_master') {
        navigate('/homeniq', {state: {code: code}});
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 일반 로그인
  const onCheckLogin = useCallback((customer_email: string, customer_password: string,) => {
    if (customer_email === '' || customer_password === '') return handleAlertModal("이메일과 비밀번호를 입력해주세요.");
    if (!validateEmail(customer_email)) return handleAlertModal("이메일 형식을 확인해주세요.");
    requestAlcLogin(
      customer_email,
      customer_password
    ).then(result => {
      if (result === 'error_alc') {
        handleAlertModal("알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.");
      } else if (result === 2) {
        handleAlertModal("아이디 또는 비밀번호가 일치하지 않습니다.");
      } else {
        const apply_discount_rate = JSON.parse(result[0].apply_discount_rate);
        const temp_object: any = {};
        apply_discount_rate.forEach((element: any) => {
          temp_object[element.brand_ID] = element;
        });
        localStorage.setItem('customer_ID_encrypted', JSON.stringify(result[0].customer_ID_encrypted));
        localStorage.setItem('alc_logo_img', result[0].alc_logo_img);
        if (result[0].alc_status !== 1 && result[0].alc_status !== 3) {
          localStorage.setItem('show_original_price', 'false');
        }
        navigate('/home');
      }
    });
  }, [navigate]);

  const handleAlertModal = (text: string) => {
    setIsModalClose(true);
    setModalContent({
      text: text,
    });
  };

  return (
    <>
      <ModalClose
        isOpen={isModalClose}
        onClose={() => setIsModalClose(false)}
        text={modalContent.text}
      />
      <SOL.LoginContainer
        style={{
          backgroundImage: `url(${Background})`
        }}
      >
        <div className="login-wrapper">
          <div className="title">
            <img src={ABlack} alt="a_black_text" />
            <p>빛의 시작과 끝 - 빛 너머의 모든것, 에이블랙</p>
          </div>
          <div className="input-box">
            <input
              type="text"
              onChange={e => setCustomerEmail(e.target.value)}
              placeholder="아이디를 입력해주세요."
              maxLength={30}
            />
            <br />
            <input
              type="password"
              onChange={e => setCustomerPassword(e.target.value)}
              placeholder="비밀번호를 입력해주세요."
              maxLength={20}
              onKeyDown={(e) => {
                if (e.nativeEvent.isComposing) return;
                if (customerPassword.trim().length === 0) return;
                if (e.key === 'Enter') {
                  onCheckLogin(customerEmail, customerPassword);
                }
              }}
            />
            <button
              className="login-button"
              onClick={() => onCheckLogin(customerEmail, customerPassword)}
            >
              로그인
            </button>
          </div>
          <div className="control-box">
            <button
              onClick={() => {
                const query = new URLSearchParams(location.search);
                const code = query.get("code");
                navigate('/find', {state: {code: code, category: "email"}});
              }}
            >
              아이디 찾기
            </button>
            <span>|</span>
            <button
              onClick={() => {
                const query = new URLSearchParams(location.search);
                const code = query.get("code");
                navigate('/find', {state: {code: code, category: "password"}});
              }}
            >
              비밀번호 찾기
            </button>
            <span>|</span>
            <button
              onClick={() => {
                const query = new URLSearchParams(location.search);
                const code = query.get("code");
                if (!code) {
                  navigate('/join');
                } else {
                  // 코드 확인해서 잘못된 코드이면 state로 넘겨주지 않음
                  checkAvailableCode(code.toUpperCase()).then(result => {
                    if (result.length === 0) {
                      navigate('/join');
                    } else {
                      navigate('/join', {state: {code: code}});
                    }
                  });
                }
              }}
            >
              회원가입
            </button>
          </div>
          <H.loginInfoBox>
            <img src={noticeInfo} alt="notice" />
            <p>
              회원가입 후, 승인 처리가 되면 이용하실 수 있습니다.
            </p>
            <p className="loginInfoBox_call">
              고객센터 070-4286-3556
            </p>
          </H.loginInfoBox>
        </div>
      </SOL.LoginContainer>
    </>
  )
};

export default Login;