import React, { FC, useEffect, useState } from "react";
import useCheckSession from "../../hooks/useCheckSession";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Offer from "../../layout/Offer/Offer";
import { useNavigate } from "react-router-dom";
import { getSessionData } from "../../Axios";
import { useOrderContext } from "../../context/OrderContext";

const OfferPage: FC = () => {
  const navigate = useNavigate();
  useCheckSession();
  const { resetShippingInfo } = useOrderContext();
  const [access, setAccess] = useState(false);
  const [customerID, setCustomerID] = useState<number>(0);

  useEffect(() => {
    getSessionData().then(result => {
      setCustomerID(result.alc_customer_ID);
      if (result.alc_status === 1) {
        setAccess(true);
        resetShippingInfo();
      } else {
        setAccess(false);
        navigate('/home');
      } 
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <PageWrapper title={'오더하기'}>
      {access && <Offer customerID={customerID} />}
    </PageWrapper>
  );
};

OfferPage.displayName = 'OfferPage';

export default OfferPage;