import React, { useEffect, useState, FC } from 'react'
import { getSessionData } from "../../Axios";
import useCheckSession from '../../hooks/useCheckSession';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import PromotionList from '../../layout/Promotion/PromotionList';
import MobileNav from '../../layout/Header/MobileNav';


const PromotionPage: FC = () => {
  useCheckSession();
	const [customerAlcStatus, setCustomerAlcStatus] = useState<number>(2);

	useEffect(() => {
		getSessionData().then((result) => {
			setCustomerAlcStatus(result.alc_status);
		});
	}, []);

  return (
    <>
    <PageWrapper title={'프로모션 리스트'} >
        <PromotionList 
          customerAlcStatus={customerAlcStatus}
        />
        <MobileNav />
	</PageWrapper>
    </>
  )
};

export default PromotionPage