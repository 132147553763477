import React, { FC, useEffect, useState } from 'react'
import { baseUrl, getAlcb2bPromotionList, getAlcb2cPromotionList } from '../../Axios';
import * as DABIN from "../../styles/dabin/StyledComponentDabin";
import Promotion from "../../components/Promotion/Promotion";

interface IPromotionListProps  {
    customerAlcStatus: number;
}
const PromotionList: FC<IPromotionListProps> = ({customerAlcStatus}) => {
  const [promotionData, setPromotionData] = useState<[]>([]);

  useEffect(() => {
    if (customerAlcStatus === 2 ) return;
    if (customerAlcStatus === 0 || customerAlcStatus === 4) {
      getAlcb2cPromotionList().then((result) => {
        setPromotionData(result.main_banner_data)
      });
    } else if (customerAlcStatus === 1) {
      getAlcb2bPromotionList().then ((result) => {
        setPromotionData(result.main_banner_data)
      })
    }
  }, [customerAlcStatus]);

  return (
    <>
      <DABIN.PromotionList>
        <div className='PromotionPage_Banner'>
          <img 
            src={`${baseUrl}files/image_folder/alc_promotion_images/common/d73cdb22b821bbdcb567d80ff039a6f4d67d4c5f9af204cabf4525b36ed9324926ff119e33b93e475f52d7fbdc80e6c658d49b6c85cc0e8e3b7db30b3fb1b173.png`}
            alt="MainBannerImage"
          />
          <p>에이블랙 프로모션</p>
        </div> {/* 상단 제목 END */}
        <div className="Promotion_grid">
          {promotionData.map((element: any, index: number) => 
				  	<Promotion
              promotion = {element}
              key = {index}/>
				  )}
        </div>
      </DABIN.PromotionList>
    </>
  )
}

export default PromotionList