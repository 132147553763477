import React, { FC } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Login from "../../layout/Login/Login";

const LoginPage: FC = () => {

  return (
    <PageWrapper title={'로그인'}>
      <Login />
    </PageWrapper>
  )
};

export default LoginPage;