import React, { FC, useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import OfferSuccess from "../../layout/Offer/OfferSuccess";
import useCheckSession from "../../hooks/useCheckSession";
import { useLocation, useNavigate } from "react-router-dom";

const OfferSuccessPage: FC = () => {
  useCheckSession();
  const navigate = useNavigate();
  const location = useLocation();
  const [access, setAccess] = useState<boolean>(true);

  useEffect(() => {
    if (!location.state) {
      navigate("/home");
    } else {
      setAccess(true);
    }
  }, [location, navigate]);

  return (
    <PageWrapper title={'오더성공'}>
      {access ? <OfferSuccess /> : <div className='w-[100%] h-[600px] bg-main_white'></div>}
    </PageWrapper>
  )
};

OfferSuccessPage.displayName = 'OfferSuccessPage';

export default OfferSuccessPage;