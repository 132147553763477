import React, { FC, useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import useCheckSession from "../../hooks/useCheckSession";
import { useLocation, useNavigate } from "react-router-dom";
import EstimateSuccess from "../../layout/Estimate/EstimateSuccess";

const EstimateSuccessPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [access, setAccess] = useState<boolean>(true);

  useCheckSession();

  useEffect(() => {
    if (!location.state) {
      navigate('/home');
    } else {
      setAccess(true);
    }
  }, [location, navigate]);

  return (
    <PageWrapper title={'견적서 저장'}>
      {access ? <EstimateSuccess /> : <div className='w-[100%] h-[600px] bg-main_white'></div>}
    </PageWrapper>
  )
};

EstimateSuccessPage.displayName = "EstimateSuccessPage";

export default EstimateSuccessPage;