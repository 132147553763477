import { checkAlcSession } from '../Axios';
import { useNavigate } from 'react-router-dom';

const useCheckSession = () => {
	const navigate = useNavigate();
	
	checkAlcSession().then((result: any) => {
		if (result === '') {
			navigate('/');
		}
	});
};

export default useCheckSession;