import React, { FC } from 'react';
import useCheckSession from '../../hooks/useCheckSession';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import ProductDetail from '../../layout/Product/ProductDetail';
import MobileNav from '../../layout/Header/MobileNav';

const ProductDetailPage: FC = () => {
	useCheckSession();
	const { search } = window.location;
	const color_ID = new URLSearchParams(search).get('color_ID') || '';
	
	return (
		<PageWrapper title={'상품세부'} >
			<ProductDetail color_ID={color_ID} />
			<MobileNav />
		</PageWrapper>
	);
};
ProductDetailPage.displayName = 'ProductDetailPage';
export default ProductDetailPage;
