import React from 'react';
import useDisableEnterKey from '../../hooks/useDisableAllKeys';
import * as SOL from "../../styles/solda/StyledComponentSol";

interface ModalYesProps {
  isOpen: boolean;
  onConfirm?: () => void;
  confirmText?: string;
  text: string;
}

const ModalYes: React.FC<ModalYesProps> = ({ isOpen,  onConfirm, confirmText, text }) => {
  useDisableEnterKey(isOpen);
  if (!isOpen) return null;
  return (
    <SOL.ModalOverlay>
      <div className="modal">
        <div className='modal-wrapper'>
          <h3>알림</h3>
          <p className='modal-text'>{text}</p>
          {onConfirm && confirmText && (
            <button className="modal-confirm-btn" onClick={onConfirm}>
              {confirmText}
            </button>
          )}
        </div>
      </div>
    </SOL.ModalOverlay>
  );
};

export default ModalYes;
