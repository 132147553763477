import React, { FC, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { useNavigate } from "react-router-dom";
import { requestLogout } from "../../Axios";
import IconClose from "../../assets/icons/icon_close_black.svg";
import ModalClose from "../../components/Modal/ModalClose";
import ModalYesClose from "../../components/Modal/ModalYesClose";

interface MySideNavProps {
  customerID: number;
  customerAlcStatus: number;
  handleChangeTab: (tab_name: string) => void;
  myPageTabName: string;
  toggleMobileNav: boolean;
  handleCloseMobileNav: () => void;
};

const MySideNav: FC<MySideNavProps> = ({
  customerID, customerAlcStatus, myPageTabName, handleChangeTab, toggleMobileNav, handleCloseMobileNav
}) => {
  const navigate = useNavigate();

  const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState({text: ''});
  const [isModalYesCloseOpen, setIsModalYesCloseOpen] = useState<boolean>(false);
	const [modalYesCloseContent, setModalYesCloseContent] = useState({
    text: '',
    confirmText: '',
    onConfirm: () => {}
  });

  const handleLogout = () => {
		requestLogout().then(result => {
			if (result === 1) {
				localStorage.removeItem('customer_ID_encrypted');
				localStorage.removeItem('alc_logo_img');
				navigate('/');
				setIsModalCloseOpen(true);
        setModalContent({text: "로그아웃 되었습니다."});
			} else {
        setIsModalCloseOpen(true);
        setModalContent({text: "알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다."});
			}
		});
	};

  const notiPreparing = () => {
    setIsModalCloseOpen(true);
    setModalContent({
      ...modalContent,
      text: "준비중에 있습니다. 070-4286-3556로 문의해주시기 바랍니다.",
    });
  };

  const ALCSideNav: FC = () => {
    return (
      <SOL.MySideNav className={`${toggleMobileNav && "mobile-show"}`}>
        <div className="mx-auto max-w-[900px]">
          <div className="nav-title-box">
            <button className="nav-title" onClick={() => handleChangeTab('')}>마이페이지</button>
            <button className="close-btn" onClick={handleCloseMobileNav}>
              <img src={IconClose} alt="close_btn" />
            </button>
          </div>
          <div className="nav-box">
            <button
              className={`${myPageTabName === "order_list" && "current-link"}`}
              onClick={() => handleChangeTab('order_list')}
            >
              주문 내역
            </button>
            <button
              className={`${myPageTabName === "order_cancel_list" && "current-link"}`}
              onClick={() => handleChangeTab('order_cancel_list')}
            >
              취소/교환/반품 내역
            </button>
          </div>
          <div className="nav-box">
            <button
              className={`${(myPageTabName === "check_password" || myPageTabName === "update_profile") && "current-link"}`}
              onClick={() => {
                if (myPageTabName === 'update_profile') return;
                handleChangeTab('check_password');
              }}
            >
              회원정보 수정
            </button>
          </div>
          <div className="nav-box">
            <button onClick={notiPreparing}>공지사항</button>
            <button
              className="logout"
              onClick={() => {
                setIsModalYesCloseOpen(true);
                setModalYesCloseContent({
                  text: "로그아웃 하시겠습니까?",
                  confirmText: "로그아웃",
                  onConfirm: () => {
                    handleLogout();
                  }
                });
              }}
            >
              로그아웃
            </button>
          </div>
        </div>
      </SOL.MySideNav>
    )
  };

  const AOSSideNav: FC = () => {
    return (
      <SOL.MySideNav className={`${toggleMobileNav && "mobile-show"}`}>
        <div className="mx-auto max-w-[900px]">
          <div className="nav-title-box">
            <button className="nav-title" onClick={() => handleChangeTab('')}>마이페이지</button>
            <button className="close-btn" onClick={handleCloseMobileNav}>
              <img src={IconClose} alt="close_btn" />
            </button>
          </div>
          <div className="nav-box">
            <button
              className={`${(myPageTabName === "offer_list" || myPageTabName === "offer_detail") && "current-link"}`}
              onClick={() => handleChangeTab('offer_list')}
            >
              주문 내역
            </button>
            <button
              className={`${(myPageTabName === "estimate_list" || myPageTabName === "estimate_detail") && "current-link"}`}
              onClick={() => handleChangeTab('estimate_list')}
            >
              견적 내역
            </button>
            <button
              className={`${myPageTabName === "my_address" && "current-link"}`}
              onClick={() => handleChangeTab('my_address')}
            >
              배송지 관리
            </button>
            <button
              className={`${(myPageTabName === "check_password" || myPageTabName === "update_profile") && "current-link"}`}
              onClick={() => {
                if (myPageTabName === 'update_profile') return;
                handleChangeTab('check_password');
              }}
            >
              회원정보 수정
            </button>
            <button
              className="logout"
              onClick={() => {
                setIsModalYesCloseOpen(true);
                setModalYesCloseContent({
                  text: "로그아웃 하시겠습니까?",
                  confirmText: "로그아웃",
                  onConfirm: () => {
                    handleLogout();
                  }
                });
              }}
            >
              로그아웃
            </button>
          </div>
        </div>
      </SOL.MySideNav>
    )
  }

  return (
    <>
      <ModalClose
        isOpen={isModalCloseOpen}
        onClose={() => setIsModalCloseOpen(false)}
        text={modalContent.text}
      />
      <ModalYesClose
				isOpen={isModalYesCloseOpen} 
				onClose={() => setIsModalYesCloseOpen(false)}
				onConfirm={modalYesCloseContent.onConfirm}
				confirmText={modalYesCloseContent.confirmText}
				text={modalYesCloseContent.text}
			/>
      {customerAlcStatus === 0 && <ALCSideNav />}
      {(customerAlcStatus === 1 || customerAlcStatus === 4) && <AOSSideNav />}
    </>
  )
};

export default MySideNav;