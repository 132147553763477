import React, { FC, useEffect, useState } from "react";
import * as SOL from "../../styles/solda/StyledComponentSol";
import { getExtraAddressList, insertExtraAddress, updateExtraAddress, updateInactiveExtraAddress } from "../../Axios";
import DaumPostcode from 'react-daum-postcode';
import ModalClose from "../Modal/ModalClose";
import IconMapMarker from "../../assets/icons/map_marker.svg";
import IconClose from "../../assets/icons/icon_close_black.svg";
import { removeWhitespacePhone, validatePhone } from "../../modules/Validation";

interface IMainAddress {
  customer_name: string;
  customer_phone_number: string;
  customer_zip_code: string;
  customer_road_address: string;
  customer_detail_address: string;
  customer_big_area: string;
  customer_small_area: string;
}

interface IAddress {
  name: string;
  postal_code: string;
  address: string;
  address_details: string;
  address_name: string;
  big_area: string;
  small_area: string;
  phone_number: string;
};

interface MyAddressProps {
  customerID: number;
};

const MyAddress: FC<MyAddressProps> = ({customerID}) => {
  const [mainAddress, setMainAddress] = useState<IMainAddress>({
    customer_name: '',
    customer_phone_number: '',
    customer_zip_code: '',
    customer_road_address: '',
    customer_detail_address: '',
    customer_big_area: '',
    customer_small_area: '',
  });
  const [extraAddressList, setExtraAddressList] = useState<any[]>([]);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [extraAddressID, setExtraAddressID] = useState<number>(0);
  const [newAddress, setNewAddress] = useState<IAddress>({
    name: '',
    postal_code: '',
    address: '',
    address_details: '',
    address_name: '',
    big_area: '',
    small_area: '',
    phone_number: '',
  });
  const [addressInputErrors, setAddressInputErrors] = useState({
    address_name: '',
    name: '',
    phone_number: '',
    postal_code: '',
    address: '',
    address_details: '',
  })
  const [modalUsage, setModalUsage] = useState<number>(0); // 1: 추가, 2: 수정 * 0일 땐 비활성화
  const [addressModalContent, setAddressModalContent] = useState({
    title: '',
    button: '',
  });
  const [isModalCloseOpen, setIsModalCloseOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState({text: ''});


  const handleComplete = (data: any) => {
    setNewAddress({
      ...newAddress,
      postal_code: data.zonecode,
      address: data.roadAddress === '' ? data.autoRoadAddress : data.roadAddress,
      address_details: data.buildingName,
      big_area: data.sido,
      small_area: data.sigungu,
    });
    setIsAddressModalOpen(false);
  };

  const handleExtraAddressList = () => {
    if (!customerID) return;
    getExtraAddressList().then(result => {
      if (result === 'error_alc') {

      } else {
        setMainAddress(result.main_address[0]);
        setExtraAddressList(result.extra_address);
      }
    });
  };

  const checkValidation = () => {
    let validation = true;
    let error_text = {...addressInputErrors};
    if (newAddress.address_name.trim() === '') {
      validation = false;
      error_text.address_name = '주소명을 입력해 주세요.';
    } else {
      error_text.address_name = '';
    }
    if (newAddress.name.trim() === '') {
      validation = false;
      error_text.name = '이름을 입력해 주세요.';
    } else {
      error_text.name = '';
    }
    if (!validatePhone(newAddress.phone_number)) {
      validation = false;
      error_text.phone_number = '연락처를 입력해 주세요.';
    } else {
      error_text.phone_number = '';
    }
    if (newAddress.postal_code === '') {
      validation = false;
      error_text.address = '주소를 입력해 주세요.';
    } else {
      error_text.address = '';
    }
    if (newAddress.address.trim() === '') {
      validation = false;
      error_text.address = '주소를 입력해 주세요.';
    } else {
      error_text.address = '';
    }
    if (newAddress.address_details.trim() === '') {
      validation = false;
      error_text.address_details = '상세주소를 입력해 주세요.';
    } else {
      error_text.address_details = '';
    }
    setAddressInputErrors(error_text);
    return validation;
  };

  const handleInsertExtraAddress = () => {
    if (checkValidation()) {
      insertExtraAddress(
        customerID,
        newAddress.name,
        newAddress.address_name,
        newAddress.phone_number,
        newAddress.postal_code,
        newAddress.address,
        newAddress.address_details,
        newAddress.big_area,
        newAddress.small_area,
      ).then(result => {
        setModalUsage(0);
        if (result === "error_alc") {
          setIsModalCloseOpen(true);
          setModalContent({
            text: '알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.'
          });
        } else if (result.serverStatus === 2) {
          setIsModalCloseOpen(true);
          setModalContent({
            text: '새로운 주소가 등록되었습니다.',
          });
          handleExtraAddressList();
        }
      });
    }
  };

  const handleUpdateExtraAddress = () => {
    if (checkValidation()) {
      updateExtraAddress(
        extraAddressID,
        newAddress.address_name,
        newAddress.name,
        newAddress.phone_number,
        newAddress.postal_code,
        newAddress.address,
        newAddress.address_details,
        newAddress.big_area,
        newAddress.small_area,
      ).then(result => {
        setModalUsage(0);
        if (result.changedRows === 1) {
          setIsModalCloseOpen(true);
          setModalContent({
            text: '주소가 수정되었습니다.',
          });
          handleExtraAddressList();
        } else {
          setIsModalCloseOpen(true);
          setModalContent({
            text: '알 수 없는 오류가 발생되었습니다. 070-4286-3556로 문의해주시기 바랍니다.'
          });
          handleExtraAddressList();
        }
      });
    }
  };

  useEffect(() => {
    handleExtraAddressList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerID]);

  useEffect(() => {
    const resetNewAddress = () => {
      setNewAddress({
        name: '',
        postal_code: '',
        address: '',
        address_details: '',
        address_name: '',
        big_area: '',
        small_area: '',
        phone_number: '',
      });
    }
    setIsAddressModalOpen(false);
    if (modalUsage === 1) {
      setAddressModalContent({
        title: '배송지 등록',
        button: '등록하기',
      });
      resetNewAddress();
      setExtraAddressID(0);
    } else if (modalUsage === 2) {
      setAddressModalContent({
        title: '배송지 수정',
        button: '수정하기',
      });
    } else {
      setAddressModalContent({
        title: '',
        button: '',
      });
      resetNewAddress();
      setExtraAddressID(0);
    }
  }, [modalUsage]);
 
  return (
    <>
      <ModalClose
        isOpen={isModalCloseOpen} 
        onClose={() => setIsModalCloseOpen(false)}
        text={modalContent.text}
      />
      {(modalUsage > 0) && (
        <SOL.ModalBackground>
          <SOL.ModalAddressBook>
            <div className="title-box">
              <div>{addressModalContent.title}</div>
              <button className="title-btn" onClick={() => {
                setModalUsage(0);
              }}>
                <img src={IconClose} alt="close-btn" />
              </button>
            </div>
            <div className="modal-wrapper">
              <SOL.ModalAddAddress>
                <div className="notice">
                  입력한 정보를 확인 후 저장해 주세요.
                </div>
                <div className="modal-address-input">
                  <div className="label">배송지명</div>
                  <input
                    type="text"
                    value={newAddress.address_name}
                    placeholder="예) 앰비언스 사무실"
                    maxLength={20}
                    onChange={e => {
                      setNewAddress({
                        ...newAddress,
                        address_name: e.target.value,
                      });
                    }}
                  />
                  <p className="input-error">{addressInputErrors.address_name}</p>
                </div>
                <div className="modal-address-input">
                  <div className="label">이름</div>
                  <input
                    type="text"
                    value={newAddress.name}
                    placeholder="이름"
                    maxLength={10}
                    onChange={e => {
                      setNewAddress({
                        ...newAddress,
                        name: e.target.value,
                      });
                    }}
                  />
                  <p className="input-error">{addressInputErrors.name}</p>
                </div>
                <div className="modal-address-input">
                  <div className="label">휴대전화</div>
                  <input
                    type="text"
                    value={newAddress.phone_number}
                    maxLength={13}
                    onChange={e => {
                      let text = removeWhitespacePhone(e.target.value);
                      setNewAddress({
                        ...newAddress,
                        phone_number: text,
                      });
                    }}
                  />
                  <p className="input-error">{addressInputErrors.phone_number}</p>
                </div>
                <div className="modal-address-input">
                  <div className="label">주소</div>
                  <input
                    type="text"
                    className="short"
                    maxLength={5}
                    value={newAddress.postal_code}
                    readOnly
                  />
                  <button
                    className="postal-code"
                    onClick={() => {
                      if (isAddressModalOpen) {
                        setIsAddressModalOpen(false);
                      } else {
                        setIsAddressModalOpen(true);
                      }
                    }}
                  >
                    {isAddressModalOpen ? "닫기" : "우편번호"}
                  </button>
                </div>
                {isAddressModalOpen && (
                  <DaumPostcode onComplete={handleComplete} autoClose={false} />
                )}
                <div className="modal-address-input">
                  <input
                    type="text"
                    maxLength={40}
                    value={newAddress.address}
                    readOnly
                  />
                </div>
                <div className="modal-address-input">
                  <input
                    type="text"
                    maxLength={40}
                    value={newAddress.address_details}
                    onChange={e => {
                      setNewAddress({
                        ...newAddress,
                        address_details: e.target.value,
                      });
                    }}
                  />
                  <p className="input-error">
                    {addressInputErrors.address !== '' ? addressInputErrors.address : addressInputErrors.address_details}
                  </p>
                </div>
                <button
                  className="add-btn"
                  onClick={() => {
                    if (modalUsage === 1) {
                      handleInsertExtraAddress();
                    } else if (modalUsage === 2) {
                      handleUpdateExtraAddress();
                    }
                  }}
                >
                  {addressModalContent.button}
                </button>
              </SOL.ModalAddAddress>
            </div>
          </SOL.ModalAddressBook>
        </SOL.ModalBackground>
      )}
      <SOL.MyAddressContainer>
        <div className="title">
          배송지 관리
        </div>
        <SOL.ExtraAddress>
          <div className="items">
            <div className="address-name">회원가입 등록 주소</div>
            <div className="address-content">
              <div className="customer-name">{mainAddress.customer_name}</div>
              <div className="phone-number">{mainAddress.customer_phone_number}</div>
              <span className="phone-number">({mainAddress.customer_zip_code}) </span>
              <span className="road-address">{mainAddress.customer_road_address}</span>
              <p className="detail-address">{mainAddress.customer_detail_address}</p>
            </div>
          </div>
        </SOL.ExtraAddress>
        <button
          className="add-new-address"
          onClick={() => {
            if (extraAddressList.length >= 20) {
              setIsModalCloseOpen(true);
              setModalContent({
                text: '배송지 목록은 최대 20개까지 등록할 수 있습니다.',
              });
            } else {
              setModalUsage(1);
            }
          }}
        >
          <img src={IconMapMarker} alt="map_marker" />
          <span>새로운 배송지 추가</span>
        </button>
        <div className="address-notice">
          *배송지는 최대 20개 까지 등록하실 수 있습니다.
        </div>
        <div className="extra-address-wrapper">
          {extraAddressList.length > 0 ? (
            extraAddressList.map((tmp, idx) =>
              <SOL.ExtraAddress key={idx}>
                <div className="items">
                  <div className="address-name">
                    {tmp.extra_address_name}
                  </div>
                  <div className="address-content">
                    <div className="customer-name">{tmp.extra_address_customer_name}</div>
                    <div className="phone-number">{tmp.extra_address_phone_number}</div>
                    <span>({tmp.extra_address_zip_code}) </span>
                    <span className="road-address">{tmp.extra_address_road_address}</span>
                    <p className="detail-address">{tmp.extra_address_detail_address}</p>
                  </div>
                </div>
                <div className="address-control-box">
                  <button
                    onClick={() => {
                      setModalUsage(2);
                      setExtraAddressID(tmp.extra_address_ID);
                      setNewAddress({
                        name: tmp.extra_address_customer_name || '',
                        postal_code: tmp.extra_address_zip_code || '',
                        address: tmp.extra_address_road_address || '',
                        address_details: tmp.extra_address_detail_address || '',
                        address_name: tmp.extra_address_name || '',
                        big_area: tmp.extra_address_big_area || '',
                        small_area: tmp.extra_address_small_area || '',
                        phone_number: tmp.extra_address_phone_number || '',
                      });
                    }}
                  >
                    수정
                  </button>
                  <button
                    onClick={() => {
                      updateInactiveExtraAddress(
                        tmp.extra_address_ID,
                      ).then(result => {
                        if (result.serverStatus === 2 || result.affectedRows === 1) {
                          handleExtraAddressList();
                          setIsModalCloseOpen(true);
                          setModalContent({
                            text: "주소가 삭제되었습니다."
                          });
                        } else {

                        }
                      });
                    }}
                  >
                    삭제
                  </button>
                </div>
              </SOL.ExtraAddress>
            )) : (
              <div>
                추가 주소가 없습니다.
              </div>
            )}
        </div>
      </SOL.MyAddressContainer>
    </>
  );
};

export default MyAddress;