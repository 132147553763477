import React, { FC } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import useCheckSession from "../../hooks/useCheckSession";
import My from "../../layout/My/My";
import MobileNav from "../../layout/Header/MobileNav";

const MyPage: FC = () => {
  useCheckSession();

  return (
    <PageWrapper title={'마이페이지'}>
      <My />
      <MobileNav />
    </PageWrapper>
  );
};

export default MyPage;